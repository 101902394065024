import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { hideCustomerly, showCustomerly } from '@/plugins/customerly';

import AppNavbar from '@/components/structure/AppNavbar.vue';
import AppNavigation from '@/components/structure/AppNavigation.vue';
import { RoutesNames } from './routesNames';
import SignupNavbar from '@/components/structure/SignupNavbar.vue';
import { getCurrentSession } from '@/composables/auth';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: RoutesNames.root,
        redirect: { name: RoutesNames.onboarding_start },
    },
    {
        path: '/help/privacy-policy',
        name: RoutesNames.privacy_cookie,
        meta: { public: true, title: 'privacy Policy' },
        components: {
            default: () => import('../views/help/PrivacyCookiePolicy.vue'),
        },
    },
    {
        path: '/help',
        name: RoutesNames.help,
        meta: { public: true, title: 'Contacts' },
        components: {
            default: () => import('../views/help/ContactPage.vue'),
        },
    },
    {
        path: '/help/terms-and-conditions',
        name: RoutesNames.terms_conditions,
        meta: { public: true, title: 'Terms ad conditions' },
        components: {
            default: () => import('../views/help/TermsAndConditions.vue'),
        },
    },
    {
        path: '/help/terms-and-conditions-of-services',
        name: RoutesNames.terms_services,
        meta: { public: true, title: 'Terms ad conditions of services' },
        components: {
            default: () => import('../views/help/TermsOfServices.vue'),
        },
    },
    {
        path: '/help/api-key-documentation',
        name: RoutesNames.api_key_documentation,
        meta: { public: true, title: 'API Key documentation' },
        components: {
            default: () => import('../views/help/ApiKeyHelp.vue'),
        },
    },
    {
        path: '/talent',
        name: RoutesNames.talent,
        redirect: { name: RoutesNames.signup_talent },
    },
    {
        path: '/signup',
        component: () => import('../views/signup/SignupContainerView.vue'),
        children: [
            {
                path: '',
                name: RoutesNames.onboarding_start,
                component: () => import('../views/signup/SignUpView.vue'),
                meta: { public: true, title: 'Signup' },
            },
            {
                path: 'talent',
                name: RoutesNames.signup_talent,
                component: () => import('../views/signup/SignUpView.vue'),
                meta: { public: true, title: 'Signup Talent' },
            },
            {
                path: 'password',
                name: RoutesNames.onboarding_setPassword,
                component: () => import('../views/signup/SetPasswordView.vue'),
                meta: { public: true, title: 'Change Password' },
            },
            {
                path: 'stepone',
                name: RoutesNames.onboarding_step_1,
                component: () => import('../views/signup/OnboardingStep1View.vue'),
                meta: { title: 'Onboarding step 1' },
            },
            {
                path: 'steptwo',
                name: RoutesNames.onboarding_step_2,
                component: () => import('../views/signup/OnboardingStep2View.vue'),
                meta: { title: 'Onboarding step 2' },
            },
        ],
    },
    {
        path: '/subscriptions',
        name: RoutesNames.subscription,
        components: {
            default: () => import('../views/ChooseSubscription.vue'),
            top: AppNavbar,
        },
        meta: { title: 'Subscription', company: true },
    },
    {
        path: '/dashboard',
        name: RoutesNames.dashboard,
        components: {
            default: () => import('../views/UserDashboard.vue'),
            left: AppNavigation,
        },
        meta: { title: 'User Dashboard', user: true },
    },
    {
        path: '/search',
        name: RoutesNames.search,
        components: {
            default: () => import('../views/SearchView.vue'),
            top: AppNavbar,
            left: AppNavigation,
        },
        meta: { title: 'User Search', user: true },
    },
    {
        path: '/userjobs/:type',
        name: RoutesNames.jobs,
        components: {
            default: () => import('../views/JobsView.vue'),
            top: AppNavbar,
            left: AppNavigation,
        },
        meta: { title: 'User Jobs', user: true },
    },
    {
        path: '/matches',
        name: RoutesNames.matches,
        components: {
            default: () => import('../views/MatchesView.vue'),
            top: AppNavbar,
            left: AppNavigation,
        },
        meta: { title: 'User Matches', user: true },
    },
    {
        path: '/learning',
        name: RoutesNames.learning,
        components: {
            default: () => import('../views/LearningView.vue'),
            top: AppNavbar,
            left: AppNavigation,
        },
        meta: { title: 'User Learning', user: true },
    },
    {
        path: '/company/open-hr',
        name: RoutesNames.open_hr,
        components: {
            default: () => import('../views/openHr/OpenHrStepOne.vue'),
        },
        meta: { title: 'Open HR Active', company: true },
    },
    {
        path: '/company/open-hr/list',
        name: RoutesNames.openhr_requests_list,
        components: {
            default: () => import('../views/openHr/OpenHrList.vue'),
        },
        meta: { title: 'Open HR Requests', company: true },
    },
    {
        path: '/company/open-hr/connect',
        name: RoutesNames.open_hr_connect,
        components: {
            default: () => import('../views/openHr/OpenHrConnect.vue'),
            top: AppNavbar,
        },
        meta: { title: 'Open HR Connect', company: true },
    },
    {
        path: '/company/open-hr/connect/:id',
        name: RoutesNames.open_hr_company_detail,
        components: {
            default: () => import('../views/openHr/OpenHrConnectDetail.vue'),
            top: AppNavbar,
        },
        meta: { title: 'Open HR Detail', company: true },
    },
    {
        path: '/company/cv-upload',
        name: RoutesNames.upload_cv,
        components: {
            default: () => import('../views/company/CvUpload.vue'),
            top: AppNavbar,
        },
        meta: { title: 'Upload CV', company: true },
    },
    {
        path: '/company/cv-upload/:id',
        name: RoutesNames.upload_cv_detail,
        components: {
            default: () => import('../views/company/CvUploadDetail.vue'),
        },
        meta: { title: 'Upload CV detail', company: true },
    },
    {
        path: '/company/manage',
        name: RoutesNames.manage_company,
        components: {
            default: () => import('../views/company/ManageCompany.vue'),
            top: AppNavbar,
        },
        meta: { title: 'Manage company', company: true },
    },
    {
        path: '/company/hiring',
        name: RoutesNames.company_dashboard,
        components: {
            default: () => import('../views/company/dashboard/DashboardView.vue'),
        },
        meta: { title: 'Hiring Dashboard', company: true },
    },
    {
        path: '/company/management',
        components: {
            default: () => import('@/views/company/ManagementContainer.vue'),
        },
        children: [
            {
                path: '',
                name: RoutesNames.management_dashboard,
                components: {
                    default: () =>
                        // contiene già AppNavbar
                        import('@/views/company/dashboard/ManagementDashboardView.vue'),
                },
                meta: { title: 'Management Dashboard', company: true },
            },
            {
                path: 'employee',
                name: RoutesNames.employee,
                component: () =>
                    import('@/views/company/dashboard/search/SearchTalent.vue'),
                meta: { title: 'Employee', company: true },
            },
            {
                path: 'employee/:id',
                name: RoutesNames.employee_details,
                meta: { title: 'Employee detail', company: true },
                components: {
                    default: () =>
                        import('../views/company/dashboard/search/TalentDetailV2.vue'),
                    top: AppNavbar,
                },
            },
            {
                path: 'folders/manage',
                name: RoutesNames.manage_folders,
                meta: { title: 'Manage Folders', company: true },
                components: {
                    default: () => import('@/views/company/FoldersView.vue'),
                    top: AppNavbar,
                },
            },
            {
                path: 'cv-upload',
                name: RoutesNames.managment_upload_cv,
                components: {
                    default: () => import('../views/company/CvUpload.vue'),
                    top: AppNavbar,
                },
                meta: { title: 'Upload CV', company: true },
            },
            {
                path: 'cv-upload/:id',
                name: RoutesNames.managment_upload_cv_details,
                components: {
                    default: () => import('../views/company/CvUploadDetail.vue'),
                },
                meta: { title: 'Upload CV detail', company: true },
            },
        ],
    },
    {
        path: '/company/dashboard',
        components: {
            default: () => import('../views/company/DashboardContainer.vue'),
        },
        children: [
            {
                path: 'talents',
                name: RoutesNames.company_dashboard_talent,
                component: () => import('../views/company/dashboard/DashboardTalent.vue'),
                meta: { title: 'Company Dashboard Talent', company: true },
            },
            {
                path: 'talents/search',
                name: RoutesNames.company_dashboard_talent_search,
                components: {
                    default: () =>
                        import('../views/company/dashboard/search/SearchTalent.vue'),
                },
                meta: { title: 'Search Talent', company: true },
            },
            {
                path: '',
                name: RoutesNames.company_dashboard_jobs,
                components: {
                    default: () => import('../views/company/dashboard/DashboardJobs.vue'),
                },
                children: [
                    {
                        path: 'jobs',
                        components: {
                            default: () =>
                                import(
                                    '../views/company/dashboard/jobs/CreateContainer.vue'
                                ),
                        },
                        children: [
                            {
                                path: 'create/:jobId?',
                                name: RoutesNames.company_dashboard_jobs_create,
                                component: () =>
                                    import(
                                        '../views/company/dashboard/jobs/create/CreateJob.vue'
                                    ),
                                meta: { title: 'Create Job', company: true },
                            },
                            {
                                path: 'configure/:jobId?',
                                name: RoutesNames.company_dashboard_jobs_create_step_1,
                                meta: { title: 'Create Job step 1', company: true },
                                component: () =>
                                    import(
                                        '../views/company/dashboard/jobs/create/Step1ConfigureJob.vue'
                                    ),
                            },
                            {
                                path: 'informations/:jobId?',
                                meta: { title: 'Create Job step 2', company: true },
                                name: RoutesNames.company_dashboard_jobs_create_step_2,
                                component: () =>
                                    import(
                                        '../views/company/dashboard/jobs/create/Step2JobInformation.vue'
                                    ),
                            },
                            {
                                path: 'details/:jobId?',
                                meta: { title: 'Create Job step 3', company: true },
                                name: RoutesNames.company_dashboard_jobs_create_step_3,
                                component: () =>
                                    import(
                                        '../views/company/dashboard/jobs/create/Step3JobDetails.vue'
                                    ),
                            },
                            {
                                path: 'summary/:jobId?',
                                meta: { title: 'Create Job step 4', company: true },
                                name: RoutesNames.company_dashboard_jobs_create_step_4,
                                component: () =>
                                    import(
                                        '../views/company/dashboard/jobs/create/Step4JobSummary.vue'
                                    ),
                            },
                            {
                                path: 'list',
                                meta: { title: 'Job List', company: true },
                                name: RoutesNames.company_dashboard_job_list,
                                component: () =>
                                    import('../views/company/dashboard/jobs/JobList.vue'),
                            },
                            {
                                path: 'view/:jobId',
                                meta: { title: 'Job Detail', company: true },
                                name: RoutesNames.company_dashboard_jobs_view,
                                component: () =>
                                    import(
                                        '../views/company/dashboard/jobs/create/Step4JobSummary.vue'
                                    ),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/company/dashboard/jobs/applications/:jobId',
        name: RoutesNames.company_dashboard_jobs_applications,
        meta: { title: 'Job Applications', company: true },
        components: {
            default: () => import('../views/company/dashboard/jobs/ApplicationList.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/company/dashboard/teams',
        name: RoutesNames.company_dashboard_teams,
        components: {
            default: () => import('../views/company/dashboard/teams/TeamContainer.vue'),
        },
        children: [
            {
                path: 'one/:id?',
                name: RoutesNames.company_dashboard_teams_create,
                meta: { title: 'Team List Create', company: true },
                component: () => import('../views/company/dashboard/teams/StepOne.vue'),
            },
            {
                path: 'two/:id?',
                name: RoutesNames.company_dashboard_teams_create_2,
                meta: { title: 'Team List Create step 2', company: true },
                component: () => import('../views/company/dashboard/teams/StepTwo.vue'),
            },
            {
                path: 'three/:id?',
                name: RoutesNames.company_dashboard_teams_create_3,
                meta: { title: 'Team List Create step 3', company: true },
                component: () => import('../views/company/dashboard/teams/StepThree.vue'),
            },
            {
                path: 'detail/:id?',
                name: RoutesNames.team_list_detail,
                meta: { title: 'Team List Create step 3', company: true },
                components: {
                    default: () =>
                        import('../views/company/dashboard/teams/TeamDetail.vue'),
                },
            },
        ],
    },
    {
        path: '/company/dashboard/talents/search/:id',
        name: RoutesNames.company_dashboard_talent_search_detail,
        meta: { title: 'Talent detail', company: true },
        components: {
            default: () => import('../views/company/dashboard/search/TalentDetailV2.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/company/dashboard/talents/search/history',
        name: RoutesNames.company_dashboard_talent_search_history,
        meta: { title: 'Saved Searches', company: true },
        components: {
            default: () =>
                import('../views/company/dashboard/search/SearchTalentHistory.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/company/tags/manage',
        name: RoutesNames.manage_labels,
        meta: { title: 'Manage Labels', company: true },
        components: {
            default: () => import('../views/company/LabelsView.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/company/analytics',
        name: RoutesNames.analytics,
        meta: { title: 'Analytics', company: true },
        components: {
            default: () => import('../views/company/AnalyticsView.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/job/:id',
        name: RoutesNames.jobDetail,
        meta: { title: 'Job detail', company: true },
        components: {
            default: () => import('../views/JobDetail.vue'),
            top: AppNavbar,
            left: AppNavigation,
        },
    },
    {
        path: '/jobs/:company',
        name: RoutesNames.company_job_list,
        components: {
            default: () => import('../views/company/jobs/JobOfferts.vue'),
        },
        meta: { public: true, title: 'Company Job list' },
    },
    {
        path: '/jobs/:company/:jobId',
        name: RoutesNames.company_job_candidate,
        components: {
            default: () => import('../views/company/jobs/CandidateToOffert.vue'),
        },
        meta: { public: true, title: 'Candidate Job' },
    },
    {
        path: '/iframe/jobs/:company',
        name: RoutesNames.iframe_job_list,
        components: {
            default: () => import('../views/company/jobs/iframe/JobList.vue'),
        },
        meta: { public: true, title: 'Company Job list' },
    },
    {
        path: '/iframe/jobs/:company/:jobId',
        name: RoutesNames.iframe_job_detail,
        components: {
            default: () => import('../views/company/jobs/iframe/JobDetail.vue'),
        },
        meta: { public: true, title: 'Candidate Job' },
    },
    {
        path: '/company/profile/edit',
        meta: { title: 'Edit company profile', company: true },
        name: RoutesNames.edit_company_profile,
        components: {
            default: () => import('../views/company/EditCompanyProfile.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/profile',
        meta: { title: 'Profile' },
        name: RoutesNames.user_profile,
        components: {
            default: () => import('../views/user/UserProfile.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/profile/settings',
        meta: { title: 'Settings' },
        name: RoutesNames.profile_settings,
        components: {
            default: () => import('../views/user/ProfileSettings.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/profile/edit',
        meta: { title: 'Edit user profile' },
        name: RoutesNames.edit_user_profile,
        components: {
            default: () => import('../views/user/EditUserProfile.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/profile/cv/:id',
        name: RoutesNames.edit_user_cv,
        components: {
            default: () => import('../views/user/UserCV.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/privacy',
        name: RoutesNames.privacy,
        meta: { title: 'Accept Privacy Policy' },
        components: {
            default: () => import('../views/signup/PrivacyView.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/privacy/personalize',
        name: RoutesNames.personalize_privacy,
        meta: { title: 'Privacy Policy' },
        components: {
            default: () => import('../views/signup/PersonalizePrivacyView.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/company/terms',
        name: RoutesNames.company_terms,
        meta: { title: 'Company Terms and Conditions', company: true },
        components: {
            default: () => import('../views/EmptyView.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/confirm/email',
        name: RoutesNames.confirmEmail,
        meta: { public: true, title: 'Confirm Email' },
        components: {
            default: () => import('../views/signup/ConfirmEmail.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/userconfirm/:registrationCode',
        name: RoutesNames.userconfirm,
        meta: { public: true, title: 'User confirm' },
        components: {
            default: () => import('../views/signup/ConfirmEmail.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/reset-password',
        name: RoutesNames.resetPassword,
        components: {
            default: () => import('../views/signup/ResetPasswordView.vue'),
            top: SignupNavbar,
        },
        meta: { public: true, title: 'Reset password' },
    },
    {
        path: '/signin',
        name: RoutesNames.signIn,
        components: {
            default: () => import('../views/signup/SignInView.vue'),
            top: SignupNavbar,
        },
        meta: { public: true, title: 'Signin' },
    },
    {
        path: '/admin/dashboard',
        name: RoutesNames.admin_dashboard,
        meta: { title: 'Admin Dashboard' },
        components: {
            default: () => import('../views/admin/DashboardAdmin.vue'),
            top: AppNavbar,
        },
    },
    {
        path: '/manteinance',
        name: RoutesNames.manteinance,
        meta: { public: true, title: 'Website in manteinance' },
        components: {
            default: () => import('../views/ManteinanceView.vue'),
            top: SignupNavbar,
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: RoutesNames.not_found,
        meta: { public: true, title: '404 Not found' },
        components: {
            default: () => import('../views/404View.vue'),
            top: SignupNavbar,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    document.title = (to.meta.title as string) || 'WHPlatform';

    const tokenExists = (await getCurrentSession()) != undefined;
    const userType = localStorage.getItem('userType');
    //await getUser();

    if (
        to.name == RoutesNames.open_hr ||
        to.name == RoutesNames.company_dashboard_jobs_create_step_1 ||
        to.name == RoutesNames.iframe_job_list ||
        to.name == RoutesNames.iframe_job_detail ||
        to.name == RoutesNames.company_dashboard_teams_create ||
        to.name == RoutesNames.company_dashboard_teams_create_2 ||
        to.name == RoutesNames.company_dashboard_teams_create_3 ||
        to.name == RoutesNames.team_list_detail
    ) {
        hideCustomerly();
    } else {
        //showCustomerly();
    }

    if (from.query && from.query.redirect && to.name === RoutesNames.company_dashboard) {
        if (from.query.redirect === 'jobCandidacy' && from.query.id) {
            const jobId = from.query.id as string;
            return next({
                name: RoutesNames.company_dashboard_jobs_applications,
                params: { jobId },
            });
        }
    }

    if (!to.name) {
        return next({ name: RoutesNames.onboarding_start });
    }

    if (!Object.keys(RoutesNames).includes(String(to.name))) {
        next({ name: RoutesNames.not_found });
    }

    if (to.meta?.public) {
        // routes for not authenticated users
        if (to.name === RoutesNames.onboarding_start) {
            if (tokenExists && userType) {
                if (userType === 'user') {
                    next({ name: RoutesNames.dashboard });
                } else {
                    next({ name: RoutesNames.company_dashboard });
                }
            } else {
                next();
            }
        } else {
            next();
        }
    } else {
        // routes for only authenticated users
        if (tokenExists) {
            if (to.meta?.company && userType === 'user') {
                next({ name: RoutesNames.dashboard });
            } else if (to.meta?.user && userType === 'company') {
                next({ name: RoutesNames.company_dashboard });
            } else {
                next();
            }
        } else {
            next({ name: RoutesNames.signIn });
        }
    }
});

export default router;
