import {
    InsertSurveyUserAnswerDocument,
    Maybe,
    Survey,
    SurveyAllDocument,
    SurveyQuestion,
    SurveyQuestionProposal,
} from '@/graphql/types/graphql';
import { Ref, ref, watch } from 'vue';

import _ from 'lodash';
import mutation from '@/graphql/mutation';
import query from '@/graphql/query';
import { useDialogsStore } from '@/store/dialogs';
import { useSurveyStore } from '@/store/survey';

export function useGetServeyAll(languageId: string) {
    const { result, loading, onError } = query(SurveyAllDocument, {
        language_id: languageId,
    });
    return { result, loading, onError };
}

export function getSurvey(languageId: string) {
    const { result, onError } = useGetServeyAll(languageId);

    watch(result, (data) => {
        const surveys = data?.SurveyAll as Survey[];
        if (surveys[0] != null) {
            const survey: Survey = surveys[0];
            const surveyStore = useSurveyStore();
            surveyStore.id = survey.id;
            surveyStore.releaseId = survey.releaseId ? survey.releaseId : '';
            surveyStore.title = survey.title ? survey.title : '';
            surveyStore.questions = survey.questions as SurveyQuestion[];
            _.forEach(surveyStore.questions, (question) => {
                question.proposals = question.proposals as SurveyQuestionProposal[];
                question.proposals = _.sortBy(question.proposals, ['orderby']);
            });
            surveyStore.questions = _.sortBy(surveyStore.questions, ['orderby']);
            useDialogsStore().survey = true;
        } else {
            useSurveyStore().$reset();
            useDialogsStore().survey = false;
        }
    });

    onError((result) => {
        console.error(result.message);
    });
}

export function useSaveSurveyAnswer() {
    const { mutate, loading, onDone, onError } = mutation(InsertSurveyUserAnswerDocument);
    onError((result) => {
        console.error(result.message);
    });

    const result: Ref<Maybe<SurveyQuestionProposal>> = ref(null);

    onDone((response) => {
        result.value = response?.data
            ?.insertSurveyUserAnswer as Maybe<SurveyQuestionProposal>;
    });
    return { mutate, loading, onDone, result, onError };
}
