import {
    ActiveCompanyUploadCvDocument,
    ActiveManyCompanyUploadCvDocument,
    AddBulkTalentsToSavedSearchDocument,
    AddCompanyUploadCvDocument,
    AddCvToSavedSearchDocument,
    AddMeToCompanyDocument,
    ArchiveCompanyUploadCvDocument,
    ArchiveManyCompanyUploadCvDocument,
    CanEditCompanyCvDataElaborationsDocument,
    CandidateByJobAnnouncementIdDocument,
    ChangeUserRoleInCompanyDocument,
    CityListForSearchDocument,
    CompaniesForUserDocument,
    Company,
    CompanyApiKeyDocument,
    CompanyByIdDocument,
    CompanyEmployeeRole,
    CompanyPublicByIdDocument,
    CompanySearchCvOutput,
    CompanySearchDocument,
    CompanySearchRequest,
    CompanyUploadCvDataElaborationCategory,
    CompanyUploadCvGroupByIdDocument,
    CompanyUploadCvGroupByIdRequest,
    CompanyUploadCvGroupsByCompanyIdDocument,
    CompanyUploadDashboardType,
    ConfirmUserToCompanyDocument,
    CreateCompanyUploadCvGroupDocument,
    CreateHiringProcessDocument,
    CvReference,
    DatailForCompanyUploadCv2Document,
    DeleteCompanyUploadCvDocument,
    DeleteManyCompanyUploadCvDocument,
    DeleteSavedSearchDocument,
    EditCompanyUploadCvDataElaborations2Document,
    GenerateApiKeyDocument,
    GetCompanyEmployeesDocument,
    HiringProcessByIdDocument,
    InferenceMapForCvDocument,
    InsertCompanyDocument,
    InsertManySavedTalentsDocument,
    InsertSavedSearchDocument,
    InsertSavedTalentDocument,
    InsertTalentsAsPreferredInSavedSearchDocument,
    LogsForCompanyUploadCvDocument,
    Maybe,
    RefuseUserToCompanyDocument,
    RemoveAsHiredCompanyCvDocument,
    RemoveCandidateAsPreferredDocument,
    RemoveCvFromSavedSearchDocument,
    RemoveManyCandidatesAsPreferredDocument,
    RemoveManyCvFromSavedSearchDocument,
    RemoveManySavedTalentsDocument,
    RemoveSavedTalentDocument,
    RemoveTalentsAsPreferredInSavedSearchDocument,
    RemoveUserToCompanyDocument,
    SavedTalentListByCompanyIdDocument,
    SearchCompanyCvDocument,
    SearchCompanyCvInput,
    SearchCompanyCvOrigin,
    SearchCompanyUploadCvDocument,
    SearchCompanyUploadCvRequest,
    SearchKeywordDocument,
    SearchKeywordRequest,
    SearchSavedSearchesDocument,
    SearchSavedSearchesInput,
    SendHiringInterviewComunicationDocument,
    SetAsHiredCompanyCvDocument,
    SetCandidateAsAcceptedDocument,
    SetCandidateAsHiredDocument,
    SetCandidateAsPreferredDocument,
    SetCandidateAsRefusedDocument,
    SetCompanyUploadCvAsInternalDocument,
    SetCompanyUploadCvAsTotalDocument,
    SetManyCandidatesAsAcceptedDocument,
    SetManyCandidatesAsPreferredDocument,
    SetManyCompanyUploadCvAsInternalDocument,
    SetManyCompanyUploadCvAsTotalDocument,
    UpdateCompanyDocument,
    UpdateSavedSearchDocument,
} from '@/graphql/types/graphql';
import { Ref, ref, watch } from 'vue';

import { RoutesNames } from '@/router/routesNames';
import mutation from '@/graphql/mutation';
import query from '@/graphql/query';
import { saveOnLocalStorage } from '@/utils/helpers';
import { useCompanyStore } from '@/store/companies';
import { useErrorStore } from '@/store/error';
import { useRoute } from 'vue-router';
import { useSearchTalentStore } from '@/store/searchTalent';
import { useUserStore } from '@/store/user';

export function useSearchCompany(input: CompanySearchRequest) {
    const { result, loading, onError } = query(CompanySearchDocument, {
        input: input,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useSearchCompanyPublicById(companyId: string) {
    const { result, loading, onError } = query(CompanyPublicByIdDocument, {
        id: companyId,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useGetCompanyById(id: string) {
    const { result, loading, onError } = query(CompanyByIdDocument, {
        id: id,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

function handleCompany(company: Maybe<Company>) {
    const store = useCompanyStore();
    store.userCompany = company;

    const phone = company?.contacts?.find((el) => el?.type === 'PHONE');
    if (phone && store.userCompany) {
        store.userCompany.phone = phone.value;
    }

    const email = company?.contacts?.find((el) => el?.type === 'EMAIL');
    if (email && store.userCompany) {
        store.userCompany.email = email.value;
    }
}

export function useUpdateCompany() {
    const { mutate, loading, onDone, onError } = mutation(UpdateCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    const result: Ref<Maybe<Company>> = ref(null);

    onDone((response) => {
        handleCompany(response?.data?.updateCompany as Maybe<Company>);
        result.value = response?.data?.updateCompany as Maybe<Company>;
    });
    return { mutate, loading, onDone, result };
}

export function useAddCompany(showToast = true) {
    const { mutate, loading, onDone, onError } = mutation(InsertCompanyDocument);

    onError((error) => {
        console.error(error.message);
        if (showToast) {
            useErrorStore().setError((error as any).message);
        }
    });

    const result: Ref<Maybe<Company>> = ref(null);

    onDone((response) => {
        //getUser();
        getUserCompanies();
        result.value = response?.data?.insertCompany as Maybe<Company>;
    });
    return { mutate, loading, onDone, result, onError };
}

export function useAddMeToCompany() {
    const { mutate, loading, onDone, onError } = mutation(AddMeToCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    onDone((response) => {
        // getUser();
        getUserCompanies();
    });

    return { mutate, loading, onDone };
}

export function useAddCompanyUploadCV() {
    const { mutate, loading, onDone, onError } = mutation(AddCompanyUploadCvDocument);

    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useCreateCompanyUploadCVGroup() {
    const { mutate, loading, onDone, onError } = mutation(
        CreateCompanyUploadCvGroupDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useCompanyUploadCVGroupsByCompany(
    id: string,
    offset: number,
    limit: number,
    dashboardType: CompanyUploadDashboardType
) {
    const { result, loading, onError } = query(CompanyUploadCvGroupsByCompanyIdDocument, {
        input: {
            companyId: id,
            lengthGroup: limit,
            offsetGroup: offset,
            dashboardType,
        },
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useConfirmUserToCompany() {
    const { mutate, loading, onDone, onError } = mutation(ConfirmUserToCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone };
}

export function useRefuseUserToCompany() {
    const { mutate, loading, onDone, onError } = mutation(RefuseUserToCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone };
}

export function useGetCvUploadGroup(input: CompanyUploadCvGroupByIdRequest) {
    const { result, loading, onError } = query(CompanyUploadCvGroupByIdDocument, {
        input,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useChangeUserRoleInCompany() {
    const { mutate, loading, onDone, onError } = mutation(
        ChangeUserRoleInCompanyDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone };
}

export function useRemoveUserToCompany() {
    const { mutate, loading, onDone, onError } = mutation(RemoveUserToCompanyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone };
}

export function useSearchCompanyUploadCV(input: SearchCompanyUploadCvRequest) {
    const { result, loading, onError } = query(SearchCompanyUploadCvDocument, {
        input,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useSearchCompanyCV(input: SearchCompanyCvInput) {
    const searchStore = useSearchTalentStore();
    searchStore.results = [];

    const { result, loading, onError } = query(SearchCompanyCvDocument, {
        input,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useUploadCVCount(input: SearchCompanyCvInput) {
    const { result, loading, onError } = query(SearchCompanyCvDocument, {
        input,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useDetailForCompanyUploadCv(id: string, companyId: string) {
    const { result, loading, onError } = query(DatailForCompanyUploadCv2Document, {
        id,
        companyId,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useCreateHiringProcess() {
    const { mutate, loading, onDone, onError } = mutation(CreateHiringProcessDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useInferenceMapForCV(
    id: string,
    companyId: string,
    type: CompanyUploadCvDataElaborationCategory
) {
    const { result, loading, onError } = query(InferenceMapForCvDocument, {
        id,
        companyId,
        type,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useInsertSavedTalent() {
    const { mutate, loading, onDone, onError } = mutation(InsertSavedTalentDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveSavedTalent() {
    const { mutate, loading, onDone, onError } = mutation(RemoveSavedTalentDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useHiringProcessById(id: string) {
    const { result, loading, onError } = query(HiringProcessByIdDocument, {
        id,
    });
    onError((error) => {
        console.error(error.message);
        // useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useInsertTalentsAsPreferredInSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(
        InsertTalentsAsPreferredInSavedSearchDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveTalentsAsPreferredInSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(
        RemoveTalentsAsPreferredInSavedSearchDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function doSearchTalent(currentRoute: RoutesNames, searchId?: string) {
    const userStore = useUserStore();
    if (userStore.currentCompany?.companyId) {
        const searchStore = useSearchTalentStore();

        const labels = searchStore.labels.map((l) => l.name as string);
        const folders = searchStore.folders.map((l) => l.name as string);

        if (currentRoute == RoutesNames.employee) {
            labels.push('Employee');
        }

        const origin = searchStore.origin;
        searchStore.loadingSearchResult = true;
        let params: SearchCompanyCvInput = {
            companyId: userStore.currentCompany?.companyId as string,
            origin,
            skills: searchStore.skills.map((s) => s.id),
            labels: labels,
            folders: folders,
            hiringType: searchStore.hiringType ? searchStore.hiringType : null,
            locationType: searchStore.locationType ? searchStore.locationType : null,
            locationCity: searchStore.city,
            keywords: searchStore.keywords,
            cities: searchStore.cities,
            qualifications: searchStore.qualification
                ? [searchStore.qualification.id]
                : [],
            limit: 100,
            orderBy: searchStore.orderBy,
            state: searchStore.cvState,
            protectedCategories: searchStore.specialCategory,
        };

        saveOnLocalStorage(
            {
                origin: params.origin,
                cvState: params.state,
                skills: searchStore.skills,
                hiringType: params.hiringType,
                locationType: params.locationType,
                locationCity: params.locationCity,
                qualifications: searchStore.qualification,
                keywords: searchStore.keywords,
            },
            'cvSearchParams'
        );

        if (searchId) {
            params = {
                companyId: userStore.currentCompany?.companyId as string,
                searchId: searchId,
            };
        }
        const { result, loading } = useSearchCompanyCV(params);

        watch(loading, () => {
            searchStore.loadingSearchResult = loading.value;
        });

        watch(result, (data) => {
            searchStore.results = data?.searchCompanyCv?.data as [CompanySearchCvOutput];
            searchStore.counts = data?.searchCompanyCv?.count;

            searchStore.hasPrevSearch = false;

            saveOnLocalStorage(data?.searchCompanyCv?.data, 'cvResults');
            saveOnLocalStorage(data?.searchCompanyCv?.count, 'cvCountResults');
        });
    }
}

export function useGetCompanyPublicById(id: string) {
    const { result, loading, onError } = query(CompanyPublicByIdDocument, {
        id: id,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useCandidatesByJobAnnouncementId(
    id: string,
    length: number,
    offset: number
) {
    const { result, loading, onError } = query(CandidateByJobAnnouncementIdDocument, {
        jobAnnouncementId: id,
        length,
        offset,
    });

    return { result, loading, onError };
}

export function useEditCompanyUploadCvDataElaborations() {
    const { mutate, loading, onDone, onError } = mutation(
        EditCompanyUploadCvDataElaborations2Document
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function getDetail(id: string, companyId: string) {
    const searchStore = useSearchTalentStore();
    searchStore.loadingTalentDetail = true;
    searchStore.talent = null;

    const { result, onError } = useDetailForCompanyUploadCv(id, companyId);

    watch(result, (data) => {
        if (data && data.datailForCompanyUploadCv2) {
            searchStore.talent = data.datailForCompanyUploadCv2;

            if (searchStore.talent?.resumeExperience?.qualifications.length > 0) {
                searchStore.talent.resumeExperience.qualifications =
                    searchStore.talent.resumeExperience.qualifications.sort(
                        (a: any, b: any) => {
                            return b.percentage - a.percentage;
                        }
                    );
            }

            if (searchStore.talent?.resumeExperience?.skills.length > 0) {
                searchStore.talent.resumeExperience.skills =
                    searchStore.talent.resumeExperience.skills.sort((a: any, b: any) => {
                        return b.percentage - a.percentage;
                    });
            }

            if (searchStore.talent?.origin !== SearchCompanyCvOrigin.External) {
                getLogs(id);
            }
        }
        searchStore.loadingTalentDetail = false;
    });
    onError(() => {
        searchStore.loadingTalentDetail = false;
    });
}

export function useSetCandidateAsPreferred() {
    const { mutate, loading, onDone, onError } = mutation(
        SetCandidateAsPreferredDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveCandidateAsPreferred() {
    const { mutate, loading, onDone, onError } = mutation(
        RemoveCandidateAsPreferredDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSetCandidateAsRefused() {
    const { mutate, loading, onDone, onError } = mutation(SetCandidateAsRefusedDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSetCandidateAsAccepted() {
    const { mutate, loading, onDone, onError } = mutation(SetCandidateAsAcceptedDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSetManyCandidatesAsAccepted() {
    const { mutate, loading, onDone, onError } = mutation(
        SetManyCandidatesAsAcceptedDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSetManyCandidatesAsPreferred() {
    const { mutate, loading, onDone, onError } = mutation(
        SetManyCandidatesAsPreferredDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveManyCandidatesAsPreferred() {
    const { mutate, loading, onDone, onError } = mutation(
        RemoveManyCandidatesAsPreferredDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useAddBulkTalentsToSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(
        AddBulkTalentsToSavedSearchDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useAddCvToSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(AddCvToSavedSearchDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveCvFromSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(
        RemoveCvFromSavedSearchDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useInsertManySavedTalents() {
    const { mutate, loading, onDone, onError } = mutation(InsertManySavedTalentsDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveManySavedTalents() {
    const { mutate, loading, onDone, onError } = mutation(RemoveManySavedTalentsDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useInsertSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(InsertSavedSearchDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSearchSavedSearches(input: SearchSavedSearchesInput) {
    const { result, loading, onError } = query(SearchSavedSearchesDocument, {
        input,
    });
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });
    return { result, loading, onError };
}

export function useCompaniesForUser() {
    const { result, loading, onError } = query(CompaniesForUserDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { result, loading, onError };
}

export function getUserCompanies() {
    const userStore = useUserStore();

    userStore.fetching = true;
    userStore.loadedCompanies = false;

    const { result } = useCompaniesForUser();
    watch(result, (data) => {
        if (data?.CompaniesForUser) {
            userStore.allUserCompanies = data.CompaniesForUser;
            const reorganizedCompanies = data.CompaniesForUser.map((c) => {
                const employee =
                    c && c.employees && c.employees.length > 0
                        ? c.employees.find((el) => el?.user?.id === userStore.user?.id)
                        : null;
                const data = {
                    companyId: c!.id,
                    companyName: c!.name,
                    company_approval_date: c!.isApproved ? 'true' : null,
                    id: null,
                    role: employee ? (employee.role as CompanyEmployeeRole) : null,
                    user_approval_date:
                        employee && employee.state === 'CONFIRMED' ? 'true' : null,
                };
                return data;
            });
            userStore.loadedCompanies = true;
            userStore.companies = reorganizedCompanies;
            userStore.resetFilteredCompanies();
        }

        userStore.fetching = false;
    });
}

export function useArchiveCompanyUploadCV() {
    const { mutate, loading, onDone, onError } = mutation(ArchiveCompanyUploadCvDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useDeleteSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(DeleteSavedSearchDocument);
    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useUpdateSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(UpdateSavedSearchDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useDeleteCompanyUploadCV() {
    const { mutate, loading, onDone, onError } = mutation(DeleteCompanyUploadCvDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useCanEditCompanyCvDataElaborations(
    companyId: string,
    reference: CvReference
) {
    const { result, loading, onError } = query(CanEditCompanyCvDataElaborationsDocument, {
        companyId,
        reference,
    });

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { result, loading, onError };
}

export function useActiveCompanyUploadCV() {
    const { mutate, loading, onDone, onError } = mutation(ActiveCompanyUploadCvDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useCompanyUploadCVAsInternal() {
    const { mutate, loading, onDone, onError } = mutation(
        SetCompanyUploadCvAsInternalDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useCompanyUplodCvAsTotal() {
    const { mutate, loading, onDone, onError } = mutation(
        SetCompanyUploadCvAsTotalDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useCompanyCvLog(id: string) {
    const { result, loading, onError } = query(LogsForCompanyUploadCvDocument, {
        id,
    });

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { result, loading, onError };
}

export function useSetManyCompanyUploadCVAsInternal() {
    const { mutate, loading, onDone, onError } = mutation(
        SetManyCompanyUploadCvAsInternalDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSetManyCompanyUplodCvAsTotal() {
    const { mutate, loading, onDone, onError } = mutation(
        SetManyCompanyUploadCvAsTotalDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useActiveManyCompanyUploadCV() {
    const { mutate, loading, onDone, onError } = mutation(
        ActiveManyCompanyUploadCvDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useArchiveManyCompanyUploadCV() {
    const { mutate, loading, onDone, onError } = mutation(
        ArchiveManyCompanyUploadCvDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useDeleteManyCompanyUploadCV() {
    const { mutate, loading, onDone, onError } = mutation(
        DeleteManyCompanyUploadCvDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function getLogs(id: string) {
    const searchStore = useSearchTalentStore();
    const { result, onError } = useCompanyCvLog(id);

    watch(result, (data) => {
        if (data?.logsForCompanyUploadCv) {
            searchStore.talentLogs = data.logsForCompanyUploadCv;
        }
    });
}

export function useSendHiringInterview() {
    const { mutate, loading, onDone, onError } = mutation(
        SendHiringInterviewComunicationDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveManyCvFromSavedSearch() {
    const { mutate, loading, onDone, onError } = mutation(
        RemoveManyCvFromSavedSearchDocument
    );

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSearchKeyword(input: SearchKeywordRequest) {
    const { result, loading, onError } = query(SearchKeywordDocument, {
        input,
    });

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { result, loading, onError };
}

export function useCityListForSearch(searchtext: string) {
    const { result, loading, onError } = query(CityListForSearchDocument, {
        searchtext,
    });

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { result, loading, onError };
}
export function setSetCandidateAsHired() {
    const { mutate, loading, onDone, onError } = mutation(SetCandidateAsHiredDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useSavedTalentListByCompanyId(companyId: string) {
    const { result, loading, onError } = query(SavedTalentListByCompanyIdDocument, {
        companyId,
    });

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { result, loading, onError };
}

export function useSetAsHiredCompanyCv() {
    const { mutate, loading, onDone, onError } = mutation(SetAsHiredCompanyCvDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useRemoveAsHiredCompanyCv() {
    const { mutate, loading, onDone, onError } = mutation(RemoveAsHiredCompanyCvDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useGetCompanyApiKey(companyId: string) {
    const { result, loading, onError } = query(CompanyApiKeyDocument, {
        id: companyId,
    });
    onError((error) => {
        console.error(error.message);
    });
    return { result, loading, onError };
}

export function useGenerateApiKey() {
    const { mutate, loading, onDone, onError } = mutation(GenerateApiKeyDocument);

    onError((error) => {
        console.error(error.message);
        useErrorStore().setError((error as any).message);
    });

    return { mutate, loading, onDone, onError };
}

export function useGetCompanyEmployees(companyId: string) {
    const { result, loading, onError } = query(GetCompanyEmployeesDocument, {
        companyId: companyId,
    });

    onError((error) => {
        console.error(error.message);
    });

    return { result, loading, onError };
}

export interface CVOtherInfo {
    name: string;
    type: CVOtherInfoType;
    value: string | number | boolean | null;
    editingValue: string | number | boolean | null;
    label: string;
}

export interface CVOtherInfoDefinition {
    name: string;
    type: CVOtherInfoType;
    companyName?: string | null | undefined;
}

export enum CVOtherInfoType {
    String,
    Number,
    Boolean,
}

export function setOtherInfoValue(rawValue: any, type: CVOtherInfoType) {
    let value: string | number | boolean | null = null;
    if (rawValue != null) {
        switch (type) {
            case CVOtherInfoType.String:
                value = rawValue as string;
                break;
            case CVOtherInfoType.Number:
                value = rawValue as number;
                break;
            case CVOtherInfoType.Boolean:
                value = rawValue as boolean;
                break;
        }
    }
    return value;
}
