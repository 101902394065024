import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateISO_yyyy_mm_dd: any;
    Upload: any;
};

export type Alist = {
    __typename?: 'ALIST';
    name?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AssignFoldersToTalentInput = {
    folderIds: Array<InputMaybe<Scalars['ID']>>;
    reference: CvReference;
};

export type AssignLabelsToTalentInput = {
    labelIds: Array<InputMaybe<Scalars['ID']>>;
    reference: CvReference;
};

export type Businessarea = {
    __typename?: 'Businessarea';
    description: Scalars['String'];
    id: Scalars['ID'];
    type: BusinessareaType;
};

export enum BusinessareaType {
    Agency = 'agency',
    Company = 'company',
    Public = 'public',
}

export type Clist = {
    __typename?: 'CLIST';
    name?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum CvCitizenshipStatus {
    GreenCard = 'Green_Card',
    H1BVisa = 'H1B_Visa',
    H4Visa = 'H4_Visa',
    O1Visa = 'O1_Visa',
    Other = 'Other',
    S1Visa = 'S1_Visa',
    T1Visa = 'T1_Visa',
    UsCitizen = 'US_Citizen',
}

export enum CvExperienceYears {
    FiveNine = 'FIVE_NINE',
    OtherTen = 'OTHER_TEN',
    ThreeFive = 'THREE_FIVE',
    Two = 'TWO',
}

export enum CvPets {
    Bird = 'BIRD',
    Cat = 'CAT',
    Dog = 'DOG',
    Fish = 'FISH',
    Gerbil = 'GERBIL',
    Others = 'OTHERS',
    Snake = 'SNAKE',
}

export type CandidateByJobAnnouncementIdResponse = {
    __typename?: 'CandidateByJobAnnouncementIdResponse';
    count?: Maybe<Scalars['Int']>;
    data?: Maybe<Array<Maybe<CandidateOutput>>>;
};

export enum CandidateExperienceLevel {
    Junior = 'JUNIOR',
    Middle = 'MIDDLE',
    Senior = 'SENIOR',
}

export type CandidateOutput = {
    __typename?: 'CandidateOutput';
    candidateState?: Maybe<CandidateState>;
    candidateStateReason?: Maybe<Scalars['String']>;
    correspondence?: Maybe<Scalars['Float']>;
    cvDocUrl?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    handledBy?: Maybe<CompanySearchCvHandlingOutput>;
    id?: Maybe<Scalars['ID']>;
    isPreferred?: Maybe<Scalars['Boolean']>;
    numberOfRatings?: Maybe<Scalars['Int']>;
    origin?: Maybe<SearchCompanyCvOrigin>;
    primaryQualification?: Maybe<Scalars['String']>;
    processNames?: Maybe<Array<Maybe<Scalars['String']>>>;
    processStatus?: Maybe<CompanyCvProcessStatus>;
    qualification?: Maybe<CompanySearchCvOutputQualification>;
    rating?: Maybe<Scalars['Float']>;
    savedBy?: Maybe<Scalars['Int']>;
    type?: Maybe<Scalars['String']>;
    uploadFileDate?: Maybe<Scalars['String']>;
    visibilityState?: Maybe<CompanyCvVisibilityState>;
};

export enum CandidateState {
    Accepted = 'ACCEPTED',
    Hired = 'HIRED',
    Preferred = 'PREFERRED',
    Refused = 'REFUSED',
}

export type Company = {
    __typename?: 'Company';
    biografy?: Maybe<Scalars['String']>;
    businessArea?: Maybe<Businessarea>;
    contacts?: Maybe<Array<Maybe<CompanyContact>>>;
    country?: Maybe<CompanyCountry>;
    coverImage?: Maybe<Media>;
    creationDate?: Maybe<Scalars['String']>;
    employees?: Maybe<Array<Maybe<CompanyEmployee>>>;
    expertiseAreas?: Maybe<Array<Maybe<CompanyExpertiseArea>>>;
    hasOpenActive?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    isApproved?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    privacyUrl?: Maybe<Scalars['String']>;
    quarters?: Maybe<Array<Maybe<CompanyQuarter>>>;
    services?: Maybe<Array<Maybe<CompanyService>>>;
    socials?: Maybe<Array<Maybe<Social>>>;
    statistics?: Maybe<Array<Maybe<CompanyStatistic>>>;
    url: Scalars['String'];
    vatNumber?: Maybe<Scalars['String']>;
    website?: Maybe<Scalars['String']>;
};

export enum CompanyApprovalState {
    Approved = 'APPROVED',
    Pending = 'PENDING',
}

export enum CompanyCvDataElaborationLogAction {
    Add = 'ADD',
    Delete = 'DELETE',
    Update = 'UPDATE',
}

export enum CompanyCvDataElaborationOrigin {
    Ai = 'AI',
    Enrichment = 'ENRICHMENT',
    Linkedin = 'LINKEDIN',
    Literalsearch = 'LITERALSEARCH',
    OpenAi = 'OPEN_AI',
    UserEdit = 'USER_EDIT',
}

export type CompanyCvEvaluation = {
    __typename?: 'CompanyCVEvaluation';
    evaluation?: Maybe<CompanyCvEvaluationInfo>;
    languageId?: Maybe<Scalars['String']>;
};

export type CompanyCvEvaluationInfo = {
    __typename?: 'CompanyCVEvaluationInfo';
    scores?: Maybe<CompanyCvEvaluationScores>;
    text?: Maybe<Scalars['String']>;
};

export type CompanyCvEvaluationScores = {
    __typename?: 'CompanyCVEvaluationScores';
    experience?: Maybe<Scalars['Float']>;
    leadership?: Maybe<Scalars['Float']>;
    personality?: Maybe<Scalars['Float']>;
    potential?: Maybe<Scalars['Float']>;
    reliability?: Maybe<Scalars['Float']>;
};

export type CompanyCvOtherInfo = {
    __typename?: 'CompanyCVOtherInfo';
    USCitizenship?: Maybe<Scalars['Boolean']>;
    additionalCertificationDate?: Maybe<Scalars['String']>;
    additionalCertificationIssuingOrganization?: Maybe<Scalars['String']>;
    additionalCertificationName?: Maybe<Scalars['String']>;
    additionalCertificationNumber?: Maybe<Scalars['String']>;
    addressLine1?: Maybe<Scalars['String']>;
    addressLine2?: Maybe<Scalars['String']>;
    ageOver18?: Maybe<Scalars['Boolean']>;
    alreadyApplied?: Maybe<Scalars['Boolean']>;
    alreadyAppliedInfo?: Maybe<Scalars['String']>;
    availableWorkDaysHours?: Maybe<Scalars['String']>;
    certificationDate?: Maybe<Scalars['String']>;
    certificationIssuingOrganization?: Maybe<Scalars['String']>;
    certificationName?: Maybe<Scalars['String']>;
    certificationNumber?: Maybe<Scalars['String']>;
    checkbox1Title?: Maybe<Scalars['String']>;
    checkbox1Value?: Maybe<Scalars['Boolean']>;
    checkbox2Title?: Maybe<Scalars['String']>;
    checkbox2Value?: Maybe<Scalars['Boolean']>;
    checkbox3Title?: Maybe<Scalars['String']>;
    checkbox3Value?: Maybe<Scalars['Boolean']>;
    citizenshipStatus?: Maybe<CvCitizenshipStatus>;
    citizenshipStatusOther?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    currentSalary?: Maybe<Scalars['Int']>;
    currentlyEmployed?: Maybe<Scalars['Boolean']>;
    currentlyEmployedYear?: Maybe<Scalars['Boolean']>;
    desiredSalary?: Maybe<Scalars['Int']>;
    experienceAzure?: Maybe<Scalars['Boolean']>;
    experienceCoreNetworkingTechnologies?: Maybe<Scalars['Boolean']>;
    experienceCustomerService?: Maybe<Scalars['Boolean']>;
    experienceFirewall?: Maybe<Scalars['Boolean']>;
    experienceIT?: Maybe<Scalars['Boolean']>;
    experienceLeadership?: Maybe<Scalars['Boolean']>;
    experienceLeargeScaleNetworkingSystems?: Maybe<Scalars['Boolean']>;
    experienceManagingPartner?: Maybe<Scalars['Boolean']>;
    experienceOffice365?: Maybe<Scalars['Boolean']>;
    experienceProjectManager?: Maybe<Scalars['Boolean']>;
    experienceVirtualization?: Maybe<Scalars['Boolean']>;
    experienceYears?: Maybe<CvExperienceYears>;
    hasPets?: Maybe<Scalars['Boolean']>;
    hasPetsInfo?: Maybe<CvPets>;
    haveTransportation?: Maybe<Scalars['Boolean']>;
    hiringStartDate?: Maybe<Scalars['String']>;
    isVolunteer?: Maybe<Scalars['Boolean']>;
    isVolunteerInfo?: Maybe<Scalars['String']>;
    militaryBranch?: Maybe<Scalars['String']>;
    militaryDetails?: Maybe<Scalars['String']>;
    militaryRank?: Maybe<Scalars['String']>;
    militarySkills?: Maybe<Scalars['String']>;
    militaryYears?: Maybe<Scalars['Int']>;
    otherPeopleWorkers?: Maybe<Scalars['Boolean']>;
    otherPeopleWorkersInfo?: Maybe<Scalars['String']>;
    petsCount?: Maybe<Scalars['Int']>;
    postalCode?: Maybe<Scalars['String']>;
    selfDescription?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    submitDrugTest?: Maybe<Scalars['Boolean']>;
    universitySchoolAddressLine1?: Maybe<Scalars['String']>;
    universitySchoolAddressLine2?: Maybe<Scalars['String']>;
    universitySchoolCity?: Maybe<Scalars['String']>;
    universitySchoolCountry?: Maybe<Scalars['String']>;
    universitySchoolDiploma?: Maybe<Scalars['String']>;
    universitySchoolGraduate?: Maybe<Scalars['Boolean']>;
    universitySchoolName?: Maybe<Scalars['String']>;
    universitySchoolPostalCode?: Maybe<Scalars['String']>;
    universitySchoolState?: Maybe<Scalars['String']>;
    universitySchoolYears?: Maybe<Scalars['Int']>;
    vocationalSchoolAddressLine1?: Maybe<Scalars['String']>;
    vocationalSchoolAddressLine2?: Maybe<Scalars['String']>;
    vocationalSchoolCity?: Maybe<Scalars['String']>;
    vocationalSchoolCountry?: Maybe<Scalars['String']>;
    vocationalSchoolDiploma?: Maybe<Scalars['String']>;
    vocationalSchoolGraduate?: Maybe<Scalars['Boolean']>;
    vocationalSchoolName?: Maybe<Scalars['String']>;
    vocationalSchoolPostalCode?: Maybe<Scalars['String']>;
    vocationalSchoolState?: Maybe<Scalars['String']>;
    vocationalSchoolYears?: Maybe<Scalars['Int']>;
    workEvenings?: Maybe<Scalars['Boolean']>;
    workInHighSchool?: Maybe<Scalars['Boolean']>;
    workInHighSchoolInfo?: Maybe<Scalars['String']>;
    workOnWeekend?: Maybe<Scalars['Boolean']>;
    workOvertime?: Maybe<Scalars['Boolean']>;
};

export type CompanyCvOtherInfoInput = {
    USCitizenship?: InputMaybe<Scalars['Boolean']>;
    additionalCertificationDate?: InputMaybe<Scalars['String']>;
    additionalCertificationIssuingOrganization?: InputMaybe<Scalars['String']>;
    additionalCertificationName?: InputMaybe<Scalars['String']>;
    additionalCertificationNumber?: InputMaybe<Scalars['String']>;
    addressLine1?: InputMaybe<Scalars['String']>;
    addressLine2?: InputMaybe<Scalars['String']>;
    ageOver18?: InputMaybe<Scalars['Boolean']>;
    alreadyApplied?: InputMaybe<Scalars['Boolean']>;
    alreadyAppliedInfo?: InputMaybe<Scalars['String']>;
    availableWorkDaysHours?: InputMaybe<Scalars['String']>;
    certificationDate?: InputMaybe<Scalars['String']>;
    certificationIssuingOrganization?: InputMaybe<Scalars['String']>;
    certificationName?: InputMaybe<Scalars['String']>;
    certificationNumber?: InputMaybe<Scalars['String']>;
    checkbox1Title?: InputMaybe<Scalars['String']>;
    checkbox1Value?: InputMaybe<Scalars['Boolean']>;
    checkbox2Title?: InputMaybe<Scalars['String']>;
    checkbox2Value?: InputMaybe<Scalars['Boolean']>;
    checkbox3Title?: InputMaybe<Scalars['String']>;
    checkbox3Value?: InputMaybe<Scalars['Boolean']>;
    citizenshipStatus?: InputMaybe<CvCitizenshipStatus>;
    citizenshipStatusOther?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    currentSalary?: InputMaybe<Scalars['Int']>;
    currentlyEmployed?: InputMaybe<Scalars['Boolean']>;
    currentlyEmployedYear?: InputMaybe<Scalars['Boolean']>;
    desiredSalary?: InputMaybe<Scalars['Int']>;
    experienceAzure?: InputMaybe<Scalars['Boolean']>;
    experienceCoreNetworkingTechnologies?: InputMaybe<Scalars['Boolean']>;
    experienceCustomerService?: InputMaybe<Scalars['Boolean']>;
    experienceFirewall?: InputMaybe<Scalars['Boolean']>;
    experienceIT?: InputMaybe<Scalars['Boolean']>;
    experienceLeadership?: InputMaybe<Scalars['Boolean']>;
    experienceLeargeScaleNetworkingSystems?: InputMaybe<Scalars['Boolean']>;
    experienceManagingPartner?: InputMaybe<Scalars['Boolean']>;
    experienceOffice365?: InputMaybe<Scalars['Boolean']>;
    experienceProjectManager?: InputMaybe<Scalars['Boolean']>;
    experienceVirtualization?: InputMaybe<Scalars['Boolean']>;
    experienceYears?: InputMaybe<CvExperienceYears>;
    hasPets?: InputMaybe<Scalars['Boolean']>;
    hasPetsInfo?: InputMaybe<CvPets>;
    haveTransportation?: InputMaybe<Scalars['Boolean']>;
    hiringStartDate?: InputMaybe<Scalars['String']>;
    isVolunteer?: InputMaybe<Scalars['Boolean']>;
    isVolunteerInfo?: InputMaybe<Scalars['String']>;
    militaryBranch?: InputMaybe<Scalars['String']>;
    militaryDetails?: InputMaybe<Scalars['String']>;
    militaryRank?: InputMaybe<Scalars['String']>;
    militarySkills?: InputMaybe<Scalars['String']>;
    militaryYears?: InputMaybe<Scalars['Int']>;
    otherPeopleWorkers?: InputMaybe<Scalars['Boolean']>;
    otherPeopleWorkersInfo?: InputMaybe<Scalars['String']>;
    petsCount?: InputMaybe<Scalars['Int']>;
    postalCode?: InputMaybe<Scalars['String']>;
    selfDescription?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    submitDrugTest?: InputMaybe<Scalars['Boolean']>;
    universitySchoolAddressLine1?: InputMaybe<Scalars['String']>;
    universitySchoolAddressLine2?: InputMaybe<Scalars['String']>;
    universitySchoolCity?: InputMaybe<Scalars['String']>;
    universitySchoolCountry?: InputMaybe<Scalars['String']>;
    universitySchoolDiploma?: InputMaybe<Scalars['String']>;
    universitySchoolGraduate?: InputMaybe<Scalars['Boolean']>;
    universitySchoolName?: InputMaybe<Scalars['String']>;
    universitySchoolPostalCode?: InputMaybe<Scalars['String']>;
    universitySchoolState?: InputMaybe<Scalars['String']>;
    universitySchoolYears?: InputMaybe<Scalars['Int']>;
    vocationalSchoolAddressLine1?: InputMaybe<Scalars['String']>;
    vocationalSchoolAddressLine2?: InputMaybe<Scalars['String']>;
    vocationalSchoolCity?: InputMaybe<Scalars['String']>;
    vocationalSchoolCountry?: InputMaybe<Scalars['String']>;
    vocationalSchoolDiploma?: InputMaybe<Scalars['String']>;
    vocationalSchoolGraduate?: InputMaybe<Scalars['Boolean']>;
    vocationalSchoolName?: InputMaybe<Scalars['String']>;
    vocationalSchoolPostalCode?: InputMaybe<Scalars['String']>;
    vocationalSchoolState?: InputMaybe<Scalars['String']>;
    vocationalSchoolYears?: InputMaybe<Scalars['Int']>;
    workEvenings?: InputMaybe<Scalars['Boolean']>;
    workInHighSchool?: InputMaybe<Scalars['Boolean']>;
    workInHighSchoolInfo?: InputMaybe<Scalars['String']>;
    workOnWeekend?: InputMaybe<Scalars['Boolean']>;
    workOvertime?: InputMaybe<Scalars['Boolean']>;
};

export enum CompanyCvProcessStatus {
    Completed = 'COMPLETED',
    Created = 'CREATED',
    CvAlreadyInDatabase = 'CV_ALREADY_IN_DATABASE',
    Error = 'ERROR',
    Loaded = 'LOADED',
    Processing = 'PROCESSING',
    Uploaded = 'UPLOADED',
}

export type CompanyCvSectors = {
    __typename?: 'CompanyCVSectors';
    lang?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type CompanyContact = {
    __typename?: 'CompanyContact';
    type?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export enum CompanyCountry {
    Eu = 'EU',
    Usa = 'USA',
}

export type CompanyCvHandlerOutput = {
    __typename?: 'CompanyCvHandlerOutput';
    companyId?: Maybe<Scalars['ID']>;
    companyImage?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    userEmail?: Maybe<Scalars['String']>;
    userPhone?: Maybe<Scalars['String']>;
};

export enum CompanyCvState {
    Active = 'ACTIVE',
    Anonymized = 'ANONYMIZED',
    Archived = 'ARCHIVED',
    InDeletion = 'IN_DELETION',
}

export enum CompanyCvVisibilityState {
    Internal = 'INTERNAL',
    Total = 'TOTAL',
}

export type CompanyEmployee = {
    __typename?: 'CompanyEmployee';
    id?: Maybe<Scalars['ID']>;
    role?: Maybe<Scalars['String']>;
    state?: Maybe<Scalars['String']>;
    user?: Maybe<UserPublic>;
};

export enum CompanyEmployeeRole {
    Admin = 'ADMIN',
    Employee = 'EMPLOYEE',
    HumanResource = 'HUMAN_RESOURCE',
    Trainer = 'TRAINER',
}

export type CompanyExpertiseArea = {
    __typename?: 'CompanyExpertiseArea';
    description?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type CompanyExpertiseAreaInput = {
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    title: Scalars['String'];
};

export enum CompanyHeadquarterType {
    Headquarter = 'HEADQUARTER',
    OperationalHeadquarters = 'OPERATIONAL_HEADQUARTERS',
    RegisteredOffice = 'REGISTERED_OFFICE',
}

export type CompanyPublic = {
    __typename?: 'CompanyPublic';
    biografy?: Maybe<Scalars['String']>;
    businessArea?: Maybe<Businessarea>;
    contacts?: Maybe<Array<Maybe<CompanyContact>>>;
    country?: Maybe<CompanyCountry>;
    coverImage?: Maybe<Media>;
    creationDate?: Maybe<Scalars['String']>;
    employees?: Maybe<Array<Maybe<CompanyEmployee>>>;
    expertiseAreas?: Maybe<Array<Maybe<CompanyExpertiseArea>>>;
    id: Scalars['ID'];
    isApproved?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
    quarters?: Maybe<Array<Maybe<CompanyQuarter>>>;
    services?: Maybe<Array<Maybe<CompanyService>>>;
    socials?: Maybe<Array<Maybe<Social>>>;
    url: Scalars['String'];
    vatNumber?: Maybe<Scalars['String']>;
    website?: Maybe<Scalars['String']>;
};

export type CompanyQuarter = {
    __typename?: 'CompanyQuarter';
    city?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    location?: Maybe<Scalars['String']>;
    type?: Maybe<CompanyHeadquarterType>;
};

export type CompanyQuarterInsertRequest = {
    city?: InputMaybe<Scalars['String']>;
    location?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<CompanyHeadquarterType>;
};

export type CompanyQuarterUpdateRequest = {
    city?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    location?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<CompanyHeadquarterType>;
};

export type CompanyRealtionShareSearchInput = {
    id?: InputMaybe<Scalars['ID']>;
    length?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
    requesterCompanyId: Scalars['String'];
    state?: InputMaybe<CompanyShareRuleState>;
    type?: InputMaybe<CompanyShareRuleRelationType>;
    vatNumber?: InputMaybe<Scalars['String']>;
};

export type CompanyRealtionShareSearchOutput = {
    __typename?: 'CompanyRealtionShareSearchOutput';
    count?: Maybe<Scalars['Int']>;
    data?: Maybe<Array<Maybe<CompanyRelationShare>>>;
};

export type CompanyRelationShare = {
    __typename?: 'CompanyRelationShare';
    company?: Maybe<CompanyPublic>;
    dataVisibility?: Maybe<SharingCompanyRuleVisibility>;
    id?: Maybe<Scalars['ID']>;
    state?: Maybe<CompanyShareRuleState>;
    type?: Maybe<SharingCompanyRuleType>;
};

export type CompanySavedSearchQuery = {
    companyId?: InputMaybe<Scalars['String']>;
    fullName?: InputMaybe<Scalars['String']>;
    groupId?: InputMaybe<Scalars['String']>;
    hiringType?: InputMaybe<HiringType>;
    keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    locationCity?: InputMaybe<Scalars['String']>;
    locationType?: InputMaybe<LocationType>;
    origin?: InputMaybe<SearchCompanyCvOrigin>;
    qualifications?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    searchId?: InputMaybe<Scalars['String']>;
    skills?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    state?: InputMaybe<CompanyCvState>;
};

export enum CompanySavedSearchResultState {
    Inserted = 'INSERTED',
    Preferred = 'PREFERRED',
}

export type CompanySearchCvCountOutput = {
    __typename?: 'CompanySearchCvCountOutput';
    number?: Maybe<Scalars['Int']>;
    origin?: Maybe<SearchCompanyCvOrigin>;
};

export type CompanySearchCvElementDataOutput = {
    __typename?: 'CompanySearchCvElementDataOutput';
    category?: Maybe<CompanyUploadCvDataElaborationCategory>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type CompanySearchCvHandlingOutput = {
    __typename?: 'CompanySearchCvHandlingOutput';
    companyImage?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
};

export type CompanySearchCvOutput = {
    __typename?: 'CompanySearchCvOutput';
    correspondence?: Maybe<Scalars['Int']>;
    fileName?: Maybe<Scalars['String']>;
    folders?: Maybe<Array<Maybe<Folder>>>;
    fullName?: Maybe<Scalars['String']>;
    handledBy?: Maybe<CompanySearchCvHandlingOutput>;
    id?: Maybe<Scalars['ID']>;
    isPreferred?: Maybe<Scalars['Boolean']>;
    keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
    lastUpdateDate?: Maybe<Scalars['String']>;
    numberOfRatings?: Maybe<Scalars['Int']>;
    origin?: Maybe<SearchCompanyCvOrigin>;
    primaryQualification?: Maybe<Scalars['String']>;
    processNames?: Maybe<Array<Maybe<Scalars['String']>>>;
    qualification?: Maybe<CompanySearchCvOutputQualification>;
    rating?: Maybe<Scalars['Float']>;
    savedBy?: Maybe<Scalars['Int']>;
    state?: Maybe<CompanyCvState>;
    tags?: Maybe<Array<Maybe<Label>>>;
    type?: Maybe<Scalars['String']>;
    uploadFileDate?: Maybe<Scalars['String']>;
    visibilityState?: Maybe<CompanyCvVisibilityState>;
};

export type CompanySearchCvOutputQualification = {
    __typename?: 'CompanySearchCvOutputQualification';
    name?: Maybe<Scalars['String']>;
    translations?: Maybe<Array<Maybe<CompanySearchCvOutputQualificationTranslation>>>;
};

export type CompanySearchCvOutputQualificationTranslation = {
    __typename?: 'CompanySearchCvOutputQualificationTranslation';
    id?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type CompanySearchRequest = {
    approvalState?: InputMaybe<CompanyApprovalState>;
    city?: InputMaybe<Scalars['String']>;
    distanceInKm?: InputMaybe<Scalars['Int']>;
    length?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    openHR?: InputMaybe<OpenHrStatus>;
    requesterCompanyId?: InputMaybe<Scalars['String']>;
    text?: InputMaybe<Scalars['String']>;
    vatNumber?: InputMaybe<Scalars['String']>;
};

export type CompanyService = {
    __typename?: 'CompanyService';
    description?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type CompanyServiceInput = {
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    title: Scalars['String'];
};

export enum CompanyShareRuleRelationType {
    All = 'ALL',
    Received = 'RECEIVED',
    Sended = 'SENDED',
}

export enum CompanyShareRuleState {
    Accepted = 'ACCEPTED',
    None = 'NONE',
    Refused = 'REFUSED',
    Revoked = 'REVOKED',
    Suspended = 'SUSPENDED',
    ToBeAccepted = 'TO_BE_ACCEPTED',
}

export type CompanyStatistic = {
    __typename?: 'CompanyStatistic';
    name?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type CompanyTeamList = {
    __typename?: 'CompanyTeamList';
    creationDate?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    lastUpdate?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    savedSearches?: Maybe<Array<Maybe<CompanyTeamListSavedSearch>>>;
};

export type CompanyTeamListSavedSearch = {
    __typename?: 'CompanyTeamListSavedSearch';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    query?: Maybe<SavedSearchQueryOutput>;
    results?: Maybe<Array<Maybe<CompanyTeamListSavedSearchResult>>>;
    visualization?: Maybe<SavedSearchVisualizationOutput>;
};

export type CompanyTeamListSavedSearchResult = {
    __typename?: 'CompanyTeamListSavedSearchResult';
    percentUsage?: Maybe<Scalars['Int']>;
    talent?: Maybe<CompanySearchCvOutput>;
};

export type CompanyUploadCv = {
    __typename?: 'CompanyUploadCV';
    creationDate?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    processNames?: Maybe<Array<Maybe<Scalars['String']>>>;
    processStatus?: Maybe<CompanyCvProcessStatus>;
    url?: Maybe<Scalars['String']>;
};

export enum CompanyUploadCvDataElaborationCategory {
    Activity = 'ACTIVITY',
    Area = 'AREA',
    Education = 'EDUCATION',
    Evaluation = 'EVALUATION',
    Experience = 'EXPERIENCE',
    HardSkill = 'HARD_SKILL',
    Keyword = 'KEYWORD',
    MainQualify = 'MAIN_QUALIFY',
    PersonalInfo = 'PERSONAL_INFO',
    Qualify = 'QUALIFY',
    Skill = 'SKILL',
    SoftSkill = 'SOFT_SKILL',
    TopKeyword = 'TOP_KEYWORD',
    WorkPreference = 'WORK_PREFERENCE',
}

export enum CompanyUploadCvDataElaborationRelationType {
    Involves = 'INVOLVES',
    Relates = 'RELATES',
}

export type CompanyUploadCvGroup = {
    __typename?: 'CompanyUploadCVGroup';
    creationDate?: Maybe<Scalars['String']>;
    dashboardType?: Maybe<CompanyUploadDashboardType>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    resume?: Maybe<Array<Maybe<ResumeStatusFiles>>>;
    status?: Maybe<CompanyUploadGroupStatus>;
    uploadCount?: Maybe<Scalars['Int']>;
    uploads?: Maybe<Array<Maybe<CompanyUploadCv>>>;
};

export type CompanyUploadCvGroupByIdRequest = {
    companyId: Scalars['ID'];
    groupId: Scalars['ID'];
    lengthUploads?: InputMaybe<Scalars['Int']>;
    offsetUploads?: InputMaybe<Scalars['Int']>;
};

export type CompanyUploadCvGroupResponse = {
    __typename?: 'CompanyUploadCVGroupResponse';
    count?: Maybe<Scalars['Int']>;
    data?: Maybe<Array<Maybe<CompanyUploadCvGroup>>>;
};

export type CompanyUploadCvGroupsByCompanyIdRequest = {
    companyId: Scalars['ID'];
    dashboardType?: InputMaybe<CompanyUploadDashboardType>;
    lengthGroup?: InputMaybe<Scalars['Int']>;
    lengthUploads?: InputMaybe<Scalars['Int']>;
    offsetGroup?: InputMaybe<Scalars['Int']>;
    offsetUploads?: InputMaybe<Scalars['Int']>;
};

export type CompanyUploadCvSubscription = {
    __typename?: 'CompanyUploadCVSubscription';
    data: CompanyUploadCvGroup;
    mutation: Scalars['String'];
};

export type CompanyUploadCvDataElaborationLog = {
    __typename?: 'CompanyUploadCvDataElaborationLog';
    action?: Maybe<CompanyCvDataElaborationLogAction>;
    companyName?: Maybe<Scalars['String']>;
    creationDate?: Maybe<Scalars['String']>;
    field?: Maybe<Scalars['String']>;
    newContent?: Maybe<Scalars['String']>;
    oldContent?: Maybe<Scalars['String']>;
    userFullName?: Maybe<Scalars['String']>;
};

export type CompanyUploadCvElaborationDetail = {
    __typename?: 'CompanyUploadCvElaborationDetail';
    age?: Maybe<Scalars['Int']>;
    city?: Maybe<Scalars['String']>;
    cvDocUrl?: Maybe<Scalars['String']>;
    cvVideoUrl?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    handledBy?: Maybe<CompanyCvHandlerOutput>;
    id?: Maybe<Scalars['ID']>;
    isPreferred?: Maybe<Scalars['Boolean']>;
    lastUpdateDate?: Maybe<Scalars['String']>;
    otherInfo?: Maybe<CompanyCvOtherInfo>;
    phone?: Maybe<Scalars['String']>;
    primaryQualification?: Maybe<CompanyUploadCvElaborationDetailQualification>;
    processNames?: Maybe<Array<Maybe<Scalars['String']>>>;
    processStatus?: Maybe<CompanyCvProcessStatus>;
    resumeExperience?: Maybe<CompanyUploadCvElaborationDetailResumeExperience>;
    scores?: Maybe<Array<Maybe<CompanyUploadCvElaborationDetailScore>>>;
    sources?: Maybe<Array<Maybe<Scalars['String']>>>;
    summary?: Maybe<Scalars['String']>;
    topSkills?: Maybe<Array<Maybe<CompanyUploadCvElaborationDetailSkill>>>;
    type?: Maybe<Scalars['String']>;
};

/** returns candidate details and percentage of correspondence with the job announcement */
export type CompanyUploadCvElaborationDetail2 = {
    __typename?: 'CompanyUploadCvElaborationDetail2';
    evaluations?: Maybe<Array<Maybe<CompanyCvEvaluation>>>;
    fileName?: Maybe<Scalars['String']>;
    folders?: Maybe<Array<Maybe<Folder>>>;
    handledBy?: Maybe<CompanyCvHandlerOutput>;
    id?: Maybe<Scalars['ID']>;
    isPreferred?: Maybe<Scalars['Boolean']>;
    jobMatch?: Maybe<Scalars['Int']>;
    keywords?: Maybe<Array<Maybe<DataElaborationValueAndPercentageOutput>>>;
    lastUpdateDate?: Maybe<Scalars['String']>;
    origin?: Maybe<SearchCompanyCvOrigin>;
    otherInfo?: Maybe<CompanyCvOtherInfo>;
    personalInfo?: Maybe<CompanyUploadCvElaborationDetailPersonalInfo>;
    processNames?: Maybe<Array<Maybe<Scalars['String']>>>;
    processStatus?: Maybe<CompanyCvProcessStatus>;
    resumeExperience?: Maybe<CompanyUploadCvElaborationDetailResumeExperience2>;
    scores?: Maybe<Array<Maybe<CompanyUploadCvElaborationDetailScore>>>;
    sectors?: Maybe<Array<Maybe<CompanyCvSectors>>>;
    sources?: Maybe<Array<Maybe<Scalars['String']>>>;
    state?: Maybe<CompanyCvState>;
    /** test tags description */
    tags?: Maybe<Array<Maybe<Label>>>;
    topKeywords?: Maybe<Array<Maybe<CompanyUploadCvElaborationTopKeywords>>>;
    type?: Maybe<Scalars['String']>;
    visibilityState?: Maybe<CompanyCvVisibilityState>;
};

export type CompanyUploadCvElaborationDetailPersonalInfo = {
    __typename?: 'CompanyUploadCvElaborationDetailPersonalInfo';
    age?: Maybe<Scalars['Int']>;
    birthDate?: Maybe<Scalars['String']>;
    cvDocUrl?: Maybe<Scalars['String']>;
    cvVideoUrl?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    isProtectedCategory?: Maybe<Scalars['Boolean']>;
    personalities?: Maybe<Array<Maybe<CompanyUploadCvElaborationDetailPersonality>>>;
    phone?: Maybe<Scalars['String']>;
    residenceCity?: Maybe<Scalars['String']>;
    workPreferences?: Maybe<CompanyUploadCvElaborationDetailPersonalInfoWorkPreference>;
};

export type CompanyUploadCvElaborationDetailPersonalInfoWorkPreference = {
    __typename?: 'CompanyUploadCvElaborationDetailPersonalInfoWorkPreference';
    cities?: Maybe<Array<Maybe<Scalars['String']>>>;
    countries?: Maybe<Array<Maybe<Scalars['String']>>>;
    home?: Maybe<Scalars['Boolean']>;
    myCity?: Maybe<Scalars['Boolean']>;
};

export type CompanyUploadCvElaborationDetailPersonality = {
    __typename?: 'CompanyUploadCvElaborationDetailPersonality';
    description?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export type CompanyUploadCvElaborationDetailQualification = {
    __typename?: 'CompanyUploadCvElaborationDetailQualification';
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    rate?: Maybe<Scalars['Float']>;
};

export type CompanyUploadCvElaborationDetailResumeExperience = {
    __typename?: 'CompanyUploadCvElaborationDetailResumeExperience';
    companies?: Maybe<Array<Maybe<Scalars['String']>>>;
    companyDimension?: Maybe<Scalars['Int']>;
    rangeExperienceFromInYears?: Maybe<Scalars['Int']>;
    rangeExperienceToInYears?: Maybe<Scalars['Int']>;
    secondaryQualification?: Maybe<CompanyUploadCvElaborationDetailQualification>;
    sector?: Maybe<Scalars['String']>;
    seniority?: Maybe<Scalars['String']>;
    training?: Maybe<Array<Maybe<Scalars['String']>>>;
    turnover?: Maybe<Scalars['Float']>;
};

export type CompanyUploadCvElaborationDetailResumeExperience2 = {
    __typename?: 'CompanyUploadCvElaborationDetailResumeExperience2';
    companies?: Maybe<Array<Maybe<Scalars['String']>>>;
    companyDimension?: Maybe<Scalars['Int']>;
    hardSkills?: Maybe<Array<Maybe<CompanyUploadCvElaborationHardSoftSkills>>>;
    linkedIn?: Maybe<Scalars['String']>;
    mainQualification?: Maybe<CompanyUploadCvElaborationMainQualification>;
    qualifications?: Maybe<Array<Maybe<DataElaborationValueAndPercentageOutput>>>;
    rangeExperienceFromInYears?: Maybe<Scalars['Int']>;
    rangeExperienceToInYears?: Maybe<Scalars['Int']>;
    sectors?: Maybe<Array<Maybe<Scalars['String']>>>;
    seniority?: Maybe<Scalars['String']>;
    skills?: Maybe<Array<Maybe<DataElaborationValueAndPercentageOutput>>>;
    softSkills?: Maybe<Array<Maybe<CompanyUploadCvElaborationHardSoftSkills>>>;
    summaries?: Maybe<Array<Maybe<Scalars['String']>>>;
    training?: Maybe<Array<Maybe<Scalars['String']>>>;
    turnover?: Maybe<Scalars['Float']>;
};

export type CompanyUploadCvElaborationDetailScore = {
    __typename?: 'CompanyUploadCvElaborationDetailScore';
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['Float']>;
};

export type CompanyUploadCvElaborationDetailSkill = {
    __typename?: 'CompanyUploadCvElaborationDetailSkill';
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    score?: Maybe<Scalars['Float']>;
};

export type CompanyUploadCvElaborationHardSoftSkills = {
    __typename?: 'CompanyUploadCvElaborationHardSoftSkills';
    lang?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['Float']>;
};

export type CompanyUploadCvElaborationMainQualification = {
    __typename?: 'CompanyUploadCvElaborationMainQualification';
    name?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['Float']>;
    translations?: Maybe<CompanyUploadCvElaborationMainQualificationTranslations>;
};

export type CompanyUploadCvElaborationMainQualificationTranslations = {
    __typename?: 'CompanyUploadCvElaborationMainQualificationTranslations';
    eng?: Maybe<Scalars['String']>;
    ita?: Maybe<Scalars['String']>;
};

export type CompanyUploadCvElaborationTopKeywords = {
    __typename?: 'CompanyUploadCvElaborationTopKeywords';
    lang?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['Float']>;
};

export enum CompanyUploadDashboardType {
    Hiring = 'HIRING',
    Management = 'MANAGEMENT',
}

export enum CompanyUploadGroupStatus {
    Completed = 'COMPLETED',
    Open = 'OPEN',
    Processing = 'PROCESSING',
    Stopped = 'STOPPED',
}

export enum ContractType {
    FullTime = 'FULL_TIME',
    PartTime = 'PART_TIME',
}

export enum CourseAvailability {
    Private = 'PRIVATE',
    Public = 'PUBLIC',
}

export enum CourseStatus {
    Deleted = 'DELETED',
    Ended = 'ENDED',
    Published = 'PUBLISHED',
    Workinprogress = 'WORKINPROGRESS',
}

export enum CourseTiming {
    Async = 'ASYNC',
    Hybrid = 'HYBRID',
    Sync = 'SYNC',
}

export enum CourseType {
    Hybrid = 'HYBRID',
    Online = 'ONLINE',
    Presence = 'PRESENCE',
}

export type CurrentAppVisualization = {
    __typename?: 'CurrentAppVisualization';
    companyId?: Maybe<Scalars['String']>;
    type?: Maybe<CurrentAppVisualizationType>;
};

export enum CurrentAppVisualizationType {
    Company = 'COMPANY',
    User = 'USER',
}

export type CvReference = {
    id: Scalars['String'];
    type: TalentType;
};

export type CvReferenceWithCorrespondence = {
    correspondence?: InputMaybe<Scalars['Int']>;
    id: Scalars['String'];
    type: TalentType;
};

export type DataElaborationValueAndActionInput = {
    action?: InputMaybe<EditCompanyUploadCvDataElaborationAction>;
    value?: InputMaybe<Scalars['String']>;
};

export type DataElaborationValueAndPercentageInput = {
    action?: InputMaybe<EditCompanyUploadCvDataElaborationAction>;
    percentage?: InputMaybe<Scalars['Int']>;
    value?: InputMaybe<Scalars['String']>;
};

export type DataElaborationValueAndPercentageOutput = {
    __typename?: 'DataElaborationValueAndPercentageOutput';
    definition?: Maybe<Scalars['String']>;
    definitions?: Maybe<Array<Maybe<DataElaborationValueAndPercentageOutputTranslation>>>;
    description?: Maybe<Scalars['String']>;
    percentage?: Maybe<Scalars['Int']>;
    translations?: Maybe<
        Array<Maybe<DataElaborationValueAndPercentageOutputTranslation>>
    >;
};

export type DataElaborationValueAndPercentageOutputTranslation = {
    __typename?: 'DataElaborationValueAndPercentageOutputTranslation';
    id?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export enum EditCompanyUploadCvDataElaborationAction {
    Add = 'ADD',
    Delete = 'DELETE',
    Edit = 'EDIT',
}

export type EditCompanyUploadCvDataElaborationsInput = {
    age?: InputMaybe<Scalars['Int']>;
    city?: InputMaybe<Scalars['String']>;
    companies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    companyDimension?: InputMaybe<Scalars['Int']>;
    cvId: Scalars['ID'];
    email?: InputMaybe<Scalars['String']>;
    fullName?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    primaryQualification?: InputMaybe<Scalars['String']>;
    rangeExperienceFromInYears?: InputMaybe<Scalars['Int']>;
    rangeExperienceToInYears?: InputMaybe<Scalars['Int']>;
    secondaryQualification?: InputMaybe<Scalars['String']>;
    sector?: InputMaybe<Scalars['String']>;
    seniority?: InputMaybe<Scalars['String']>;
    summary?: InputMaybe<Scalars['String']>;
    topSkills?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    turnover?: InputMaybe<Scalars['Float']>;
};

export type EditCompanyUploadCvDataElaborationsInput2 = {
    age?: InputMaybe<Scalars['Int']>;
    birthDate?: InputMaybe<Scalars['String']>;
    companies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    companyDimension?: InputMaybe<Scalars['Int']>;
    cvId: Scalars['ID'];
    cvVideoUrl?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    fullName?: InputMaybe<Scalars['String']>;
    isProtectedCategory?: InputMaybe<Scalars['Boolean']>;
    likeWorkFromCities?: InputMaybe<
        Array<InputMaybe<DataElaborationValueAndActionInput>>
    >;
    likeWorkFromCountries?: InputMaybe<
        Array<InputMaybe<DataElaborationValueAndActionInput>>
    >;
    likeWorkFromHome?: InputMaybe<Scalars['Boolean']>;
    likeWorkMyCity?: InputMaybe<Scalars['Boolean']>;
    linkedInUrl?: InputMaybe<Scalars['String']>;
    otherInfo?: InputMaybe<CompanyCvOtherInfoInput>;
    phone?: InputMaybe<Scalars['String']>;
    qualifications?: InputMaybe<
        Array<InputMaybe<DataElaborationValueAndPercentageInput>>
    >;
    rangeExperienceFromInYears?: InputMaybe<Scalars['Int']>;
    rangeExperienceToInYears?: InputMaybe<Scalars['Int']>;
    residenceCity?: InputMaybe<Scalars['String']>;
    sectors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    seniority?: InputMaybe<Scalars['String']>;
    skills?: InputMaybe<Array<InputMaybe<DataElaborationValueAndPercentageInput>>>;
    summary?: InputMaybe<Scalars['String']>;
    turnover?: InputMaybe<Scalars['Float']>;
};

export type EducationCourse = {
    __typename?: 'EducationCourse';
    address?: Maybe<Scalars['String']>;
    availability?: Maybe<CourseAvailability>;
    companyOwnerId?: Maybe<Scalars['String']>;
    coverImage?: Maybe<Media>;
    educationLessons?: Maybe<Array<Maybe<EducationLesson>>>;
    hours?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    info?: Maybe<Scalars['String']>;
    info_more?: Maybe<Scalars['String']>;
    lessons?: Maybe<Scalars['Int']>;
    link?: Maybe<Scalars['String']>;
    location?: Maybe<Scalars['String']>;
    ownerId?: Maybe<Scalars['ID']>;
    room?: Maybe<Scalars['String']>;
    slides?: Maybe<Array<Maybe<Media>>>;
    status?: Maybe<CourseStatus>;
    students?: Maybe<Array<Maybe<Student>>>;
    students_max?: Maybe<Scalars['Int']>;
    students_min?: Maybe<Scalars['Int']>;
    students_show?: Maybe<Scalars['Int']>;
    subjectarea?: Maybe<Scalars['String']>;
    teachers?: Maybe<Array<Maybe<Teacher>>>;
    timing?: Maybe<CourseTiming>;
    title?: Maybe<Scalars['String']>;
    type?: Maybe<CourseType>;
    videos?: Maybe<Array<Maybe<Media>>>;
    videos_url?: Maybe<Array<Maybe<Media>>>;
};

export type EducationCourseCalendarGeneral = {
    __typename?: 'EducationCourseCalendarGeneral';
    courseId?: Maybe<Scalars['String']>;
    course_title?: Maybe<Scalars['String']>;
    end?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['String']>;
};

export type EducationCourseInsertRequest = {
    address?: InputMaybe<Scalars['String']>;
    availability?: InputMaybe<CourseAvailability>;
    companyOwnerId: Scalars['String'];
    hours?: InputMaybe<Scalars['Int']>;
    info?: InputMaybe<Scalars['String']>;
    info_more?: InputMaybe<Scalars['String']>;
    lessons?: InputMaybe<Scalars['Int']>;
    link?: InputMaybe<Scalars['String']>;
    location?: InputMaybe<Scalars['String']>;
    ownerId: Scalars['ID'];
    room?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<CourseStatus>;
    students_max?: InputMaybe<Scalars['Int']>;
    students_min?: InputMaybe<Scalars['Int']>;
    students_show?: InputMaybe<Scalars['Int']>;
    subjectarea?: InputMaybe<Scalars['String']>;
    timing?: InputMaybe<CourseTiming>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<CourseType>;
};

export type EducationCourseInvite = {
    courseId?: InputMaybe<Scalars['String']>;
    emails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    message?: InputMaybe<Scalars['String']>;
};

export type EducationCourseUpdateRequest = {
    address?: InputMaybe<Scalars['String']>;
    availability?: InputMaybe<CourseAvailability>;
    companyOwnerId?: InputMaybe<Scalars['String']>;
    hours?: InputMaybe<Scalars['Int']>;
    id: Scalars['ID'];
    info?: InputMaybe<Scalars['String']>;
    info_more?: InputMaybe<Scalars['String']>;
    lessons?: InputMaybe<Scalars['Int']>;
    link?: InputMaybe<Scalars['String']>;
    location?: InputMaybe<Scalars['String']>;
    ownerId?: InputMaybe<Scalars['ID']>;
    room?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<CourseStatus>;
    students_max?: InputMaybe<Scalars['Int']>;
    students_min?: InputMaybe<Scalars['Int']>;
    students_show?: InputMaybe<Scalars['Int']>;
    subjectarea?: InputMaybe<Scalars['String']>;
    timing?: InputMaybe<CourseTiming>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<CourseType>;
};

export type EducationLesson = {
    __typename?: 'EducationLesson';
    courseId?: Maybe<Scalars['String']>;
    courseTitle?: Maybe<Scalars['String']>;
    date?: Maybe<Scalars['DateISO_yyyy_mm_dd']>;
    from?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    info?: Maybe<Scalars['String']>;
    join_url?: Maybe<Scalars['String']>;
    lesson_url?: Maybe<Scalars['String']>;
    start_url?: Maybe<Scalars['String']>;
    status?: Maybe<LessonStatus>;
    teacher?: Maybe<Teacher>;
    teacherId?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    to?: Maybe<Scalars['String']>;
};

export type EducationLessonInput = {
    courseId?: InputMaybe<Scalars['String']>;
    date?: InputMaybe<Scalars['DateISO_yyyy_mm_dd']>;
    from?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    info?: InputMaybe<Scalars['String']>;
    lesson_url?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<LessonStatus>;
    teacherId?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    to?: InputMaybe<Scalars['String']>;
};

export type EducationSearchUser = {
    __typename?: 'EducationSearchUser';
    biografy?: Maybe<Scalars['String']>;
    cityResidence?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    languages?: Maybe<Array<Maybe<GeneralInfo>>>;
    name?: Maybe<Scalars['String']>;
    photo?: Maybe<Scalars['String']>;
    qualifications?: Maybe<Array<Maybe<Qualification>>>;
    skills?: Maybe<Array<Maybe<Skill>>>;
    socials?: Maybe<Array<Maybe<Social>>>;
    surname?: Maybe<Scalars['String']>;
};

export type EducationSearchUserInput = {
    courseId: Scalars['ID'];
    limit: Scalars['Int'];
    page: Scalars['Int'];
    searchText?: InputMaybe<Scalars['String']>;
};

export type EducationSearchUserOutput = {
    __typename?: 'EducationSearchUserOutput';
    count?: Maybe<Scalars['Int']>;
    data?: Maybe<Array<Maybe<EducationSearchUser>>>;
};

export type EducationStudent = {
    __typename?: 'EducationStudent';
    biografy?: Maybe<Scalars['String']>;
    cityResidence?: Maybe<Scalars['String']>;
    completed_lessons?: Maybe<Scalars['Float']>;
    completed_perc?: Maybe<Scalars['Float']>;
    courseId?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    languages?: Maybe<Array<Maybe<GeneralInfo>>>;
    motivation?: Maybe<Scalars['String']>;
    photo?: Maybe<Scalars['String']>;
    qualifications?: Maybe<Array<Maybe<Qualification>>>;
    skills?: Maybe<Array<Maybe<Skill>>>;
    socials?: Maybe<Array<Maybe<Social>>>;
    status?: Maybe<StudentCourseStatus>;
    studentEmail?: Maybe<Scalars['String']>;
    studentId?: Maybe<Scalars['String']>;
    studentName?: Maybe<Scalars['String']>;
};

export type EducationStudentInput = {
    completed_lessons?: InputMaybe<Scalars['Float']>;
    completed_perc?: InputMaybe<Scalars['Float']>;
    courseId?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    motivation?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<StudentCourseStatus>;
    studentId?: InputMaybe<Scalars['String']>;
};

export type EducationTeacher = {
    __typename?: 'EducationTeacher';
    companyName?: Maybe<Scalars['String']>;
    completed_lessons?: Maybe<Scalars['Float']>;
    completed_perc?: Maybe<Scalars['Float']>;
    courseId?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    photo?: Maybe<Media>;
    teacherId?: Maybe<Scalars['String']>;
    teacherName?: Maybe<Scalars['String']>;
};

export type EducationTeacherInput = {
    completed_lessons?: InputMaybe<Scalars['Float']>;
    completed_perc?: InputMaybe<Scalars['Float']>;
    courseId?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    teacherId?: InputMaybe<Scalars['String']>;
};

export type Educational = {
    __typename?: 'Educational';
    des: Scalars['String'];
    id: Scalars['ID'];
};

export type Employee = {
    __typename?: 'Employee';
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    surname?: Maybe<Scalars['String']>;
};

export enum EntityType {
    Company = 'COMPANY',
    Educationcourse = 'EDUCATIONCOURSE',
    JobAnnouncement = 'JOB_ANNOUNCEMENT',
    User = 'USER',
}

export type ExternalFileInput = {
    destination: MediaTypes;
    fileURL?: InputMaybe<Scalars['String']>;
    owner?: InputMaybe<Scalars['ID']>;
    ownerType?: InputMaybe<EntityType>;
};

export type File = {
    __typename?: 'File';
    encoding: Scalars['String'];
    filename: Scalars['String'];
    mimetype: Scalars['String'];
};

export type Folder = {
    __typename?: 'Folder';
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    parentId?: Maybe<Scalars['String']>;
    qualifications?: Maybe<Array<Maybe<Qualification>>>;
    skills?: Maybe<Array<Maybe<Skill>>>;
};

export type GeneralInfo = {
    __typename?: 'GeneralInfo';
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    value?: Maybe<Scalars['String']>;
};

export type GeneralInfoUpdateRequest = {
    description?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    value?: InputMaybe<Scalars['String']>;
};

export type HandleCompanyRelationShareInput = {
    dataVisibility?: InputMaybe<SharingCompanyRuleVisibility>;
    requesterCompanyId: Scalars['String'];
    targetCompanyId: Scalars['String'];
};

export type HiringInterview = {
    __typename?: 'HiringInterview';
    creation_date?: Maybe<Scalars['String']>;
    experience?: Maybe<Scalars['Int']>;
    hiringprocessId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    leadership?: Maybe<Scalars['Int']>;
    personality?: Maybe<Scalars['Int']>;
    potential?: Maybe<Scalars['Int']>;
    reliability?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['String']>;
    valuation?: Maybe<Scalars['String']>;
};

export type HiringProcess = {
    __typename?: 'HiringProcess';
    creation_date?: Maybe<Scalars['String']>;
    curriculumEmail?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    status?: Maybe<Scalars['String']>;
    target_reference_id?: Maybe<Scalars['String']>;
    target_reference_type?: Maybe<Scalars['String']>;
    userId?: Maybe<Scalars['String']>;
};

export type HiringProposal = {
    __typename?: 'HiringProposal';
    accepted_date?: Maybe<Scalars['Int']>;
    creation_date?: Maybe<Scalars['String']>;
    date1?: Maybe<Scalars['String']>;
    date2?: Maybe<Scalars['String']>;
    date3?: Maybe<Scalars['String']>;
    hiringinterviewId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    message?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    time1?: Maybe<Scalars['String']>;
    time2?: Maybe<Scalars['String']>;
    time3?: Maybe<Scalars['String']>;
};

export enum HiringType {
    Freelance = 'FREELANCE',
    Hiring = 'HIRING',
}

export type InferenceMapEdge = {
    __typename?: 'InferenceMapEdge';
    sourceId: Scalars['ID'];
    targetId: Scalars['ID'];
    type?: Maybe<CompanyUploadCvDataElaborationRelationType>;
};

export type InferenceMapNode = {
    __typename?: 'InferenceMapNode';
    id: Scalars['ID'];
    isPrimary?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    origin?: Maybe<CompanyCvDataElaborationOrigin>;
};

export type InferenceMapResponse = {
    __typename?: 'InferenceMapResponse';
    edges?: Maybe<Array<Maybe<InferenceMapEdge>>>;
    nodes?: Maybe<Array<Maybe<InferenceMapNode>>>;
    type?: Maybe<CompanyUploadCvDataElaborationCategory>;
};

export type InsertCompanyRequest = {
    acceptTermCondition?: InputMaybe<Scalars['Boolean']>;
    biografy?: InputMaybe<Scalars['String']>;
    businessAreaId?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<CompanyCountry>;
    coverImage?: InputMaybe<Scalars['ID']>;
    email?: InputMaybe<Scalars['String']>;
    expertiseAreas?: InputMaybe<Array<InputMaybe<CompanyExpertiseAreaInput>>>;
    language_id?: InputMaybe<Scalars['String']>;
    name: Scalars['String'];
    phone?: InputMaybe<Scalars['String']>;
    quarters?: InputMaybe<Array<InputMaybe<CompanyQuarterInsertRequest>>>;
    services?: InputMaybe<Array<InputMaybe<CompanyServiceInput>>>;
    socials?: InputMaybe<Array<InputMaybe<SocialInsertRequest>>>;
    vatNumber?: InputMaybe<Scalars['String']>;
    website?: InputMaybe<Scalars['String']>;
};

export type InsertCompanyUploadCv = {
    assignLabels?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    dashboardType?: InputMaybe<CompanyUploadDashboardType>;
    file: Scalars['Upload'];
    fileName?: InputMaybe<Scalars['String']>;
    groupId: Scalars['ID'];
    labelName?: InputMaybe<Scalars['String']>;
    otherInfo?: InputMaybe<JobAnnouncementCandidacyOtherInfo>;
    requestId: Scalars['ID'];
};

export type InsertCompanyUploadCvGroup = {
    companyId: Scalars['ID'];
    dashboardType?: InputMaybe<CompanyUploadDashboardType>;
    description: Scalars['String'];
    numberUploads: Scalars['Int'];
};

export type InsertFolderInput = {
    companyId?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    parentId?: InputMaybe<Scalars['String']>;
    qualifications?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    skills?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type InsertRatingInsert = {
    companyId: Scalars['ID'];
    description: Scalars['String'];
    experience?: InputMaybe<Scalars['Float']>;
    leadership?: InputMaybe<Scalars['Float']>;
    personality?: InputMaybe<Scalars['Float']>;
    potential?: InputMaybe<Scalars['Float']>;
    reliability?: InputMaybe<Scalars['Float']>;
    targetId: Scalars['ID'];
};

export type JobAnnouncement = {
    __typename?: 'JobAnnouncement';
    candidaturesCount?: Maybe<Scalars['Int']>;
    city?: Maybe<Scalars['String']>;
    contractType?: Maybe<ContractType>;
    coverImage?: Maybe<Media>;
    description?: Maybe<Scalars['String']>;
    duration?: Maybe<JobAnnouncentContractDurantion>;
    expirationDate?: Maybe<Scalars['String']>;
    hiringType?: Maybe<HiringType>;
    id?: Maybe<Scalars['ID']>;
    isAssessmentRequired?: Maybe<Scalars['Boolean']>;
    isChatActivate?: Maybe<Scalars['Boolean']>;
    isJobInterviewRequired?: Maybe<Scalars['Boolean']>;
    isTargetAvailableForRelocation?: Maybe<Scalars['Boolean']>;
    isTargetAvailableForRoleChange?: Maybe<Scalars['Boolean']>;
    isTargetAvailableForTransfers?: Maybe<Scalars['Boolean']>;
    locationType?: Maybe<LocationType>;
    owner?: Maybe<CompanyPublic>;
    previewImage?: Maybe<Media>;
    privacyURL?: Maybe<Scalars['String']>;
    qualification?: Maybe<Qualification>;
    referent?: Maybe<Referent>;
    referentId?: Maybe<Scalars['ID']>;
    status?: Maybe<JobAnnouncementStatus>;
    targetAvailability?: Maybe<JobAnnouncentTargetAvailability>;
    targetCandidateRalMax?: Maybe<Scalars['Int']>;
    targetCandidateRalMin?: Maybe<Scalars['Int']>;
    targetDisiredSkills?: Maybe<Array<Maybe<Skill>>>;
    targetExperienceYearsMax?: Maybe<Scalars['Int']>;
    targetExperienceYearsMin?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
};

export type JobAnnouncementAddCandidacyInput = {
    email: Scalars['String'];
    file: Scalars['Upload'];
    jobAnnouncementId: Scalars['ID'];
    name: Scalars['String'];
    otherInfo?: InputMaybe<JobAnnouncementCandidacyOtherInfo>;
    phone: Scalars['String'];
    surname: Scalars['String'];
};

export type JobAnnouncementCandidacyOtherInfo = {
    USCitizenship?: InputMaybe<Scalars['Boolean']>;
    additionalCertificationDate?: InputMaybe<Scalars['String']>;
    additionalCertificationIssuingOrganization?: InputMaybe<Scalars['String']>;
    additionalCertificationName?: InputMaybe<Scalars['String']>;
    additionalCertificationNumber?: InputMaybe<Scalars['String']>;
    addressLine1?: InputMaybe<Scalars['String']>;
    addressLine2?: InputMaybe<Scalars['String']>;
    ageOver18?: InputMaybe<Scalars['Boolean']>;
    alreadyApplied?: InputMaybe<Scalars['Boolean']>;
    alreadyAppliedInfo?: InputMaybe<Scalars['String']>;
    availableWorkDaysHours?: InputMaybe<Scalars['String']>;
    certificationDate?: InputMaybe<Scalars['String']>;
    certificationIssuingOrganization?: InputMaybe<Scalars['String']>;
    certificationName?: InputMaybe<Scalars['String']>;
    certificationNumber?: InputMaybe<Scalars['String']>;
    checkbox1Title?: InputMaybe<Scalars['String']>;
    checkbox1Value?: InputMaybe<Scalars['Boolean']>;
    checkbox2Title?: InputMaybe<Scalars['String']>;
    checkbox2Value?: InputMaybe<Scalars['Boolean']>;
    checkbox3Title?: InputMaybe<Scalars['String']>;
    checkbox3Value?: InputMaybe<Scalars['Boolean']>;
    citizenshipStatus?: InputMaybe<CvCitizenshipStatus>;
    citizenshipStatusOther?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    currentSalary?: InputMaybe<Scalars['Int']>;
    currentlyEmployed?: InputMaybe<Scalars['Boolean']>;
    currentlyEmployedYear?: InputMaybe<Scalars['Boolean']>;
    desiredSalary?: InputMaybe<Scalars['Int']>;
    experienceAzure?: InputMaybe<Scalars['Boolean']>;
    experienceCoreNetworkingTechnologies?: InputMaybe<Scalars['Boolean']>;
    experienceCustomerService?: InputMaybe<Scalars['Boolean']>;
    experienceFirewall?: InputMaybe<Scalars['Boolean']>;
    experienceIT?: InputMaybe<Scalars['Boolean']>;
    experienceLeadership?: InputMaybe<Scalars['Boolean']>;
    experienceLeargeScaleNetworkingSystems?: InputMaybe<Scalars['Boolean']>;
    experienceManagingPartner?: InputMaybe<Scalars['Boolean']>;
    experienceOffice365?: InputMaybe<Scalars['Boolean']>;
    experienceProjectManager?: InputMaybe<Scalars['Boolean']>;
    experienceVirtualization?: InputMaybe<Scalars['Boolean']>;
    experienceYears?: InputMaybe<CvExperienceYears>;
    hasPets?: InputMaybe<Scalars['Boolean']>;
    hasPetsInfo?: InputMaybe<CvPets>;
    haveTransportation?: InputMaybe<Scalars['Boolean']>;
    hiringStartDate?: InputMaybe<Scalars['String']>;
    isVolunteer?: InputMaybe<Scalars['Boolean']>;
    isVolunteerInfo?: InputMaybe<Scalars['String']>;
    militaryBranch?: InputMaybe<Scalars['String']>;
    militaryDetails?: InputMaybe<Scalars['String']>;
    militaryRank?: InputMaybe<Scalars['String']>;
    militarySkills?: InputMaybe<Scalars['String']>;
    militaryYears?: InputMaybe<Scalars['Int']>;
    otherPeopleWorkers?: InputMaybe<Scalars['Boolean']>;
    otherPeopleWorkersInfo?: InputMaybe<Scalars['String']>;
    petsCount?: InputMaybe<Scalars['Int']>;
    postalCode?: InputMaybe<Scalars['String']>;
    selfDescription?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<Scalars['String']>;
    submitDrugTest?: InputMaybe<Scalars['Boolean']>;
    universitySchoolAddressLine1?: InputMaybe<Scalars['String']>;
    universitySchoolAddressLine2?: InputMaybe<Scalars['String']>;
    universitySchoolCity?: InputMaybe<Scalars['String']>;
    universitySchoolCountry?: InputMaybe<Scalars['String']>;
    universitySchoolDiploma?: InputMaybe<Scalars['String']>;
    universitySchoolGraduate?: InputMaybe<Scalars['Boolean']>;
    universitySchoolName?: InputMaybe<Scalars['String']>;
    universitySchoolPostalCode?: InputMaybe<Scalars['String']>;
    universitySchoolState?: InputMaybe<Scalars['String']>;
    universitySchoolYears?: InputMaybe<Scalars['Int']>;
    vocationalSchoolAddressLine1?: InputMaybe<Scalars['String']>;
    vocationalSchoolAddressLine2?: InputMaybe<Scalars['String']>;
    vocationalSchoolCity?: InputMaybe<Scalars['String']>;
    vocationalSchoolCountry?: InputMaybe<Scalars['String']>;
    vocationalSchoolDiploma?: InputMaybe<Scalars['String']>;
    vocationalSchoolGraduate?: InputMaybe<Scalars['Boolean']>;
    vocationalSchoolName?: InputMaybe<Scalars['String']>;
    vocationalSchoolPostalCode?: InputMaybe<Scalars['String']>;
    vocationalSchoolState?: InputMaybe<Scalars['String']>;
    vocationalSchoolYears?: InputMaybe<Scalars['Int']>;
    workEvenings?: InputMaybe<Scalars['Boolean']>;
    workInHighSchool?: InputMaybe<Scalars['Boolean']>;
    workInHighSchoolInfo?: InputMaybe<Scalars['String']>;
    workOnWeekend?: InputMaybe<Scalars['Boolean']>;
    workOvertime?: InputMaybe<Scalars['Boolean']>;
};

export type JobAnnouncementInsertInput = {
    city?: InputMaybe<Scalars['String']>;
    contractType?: InputMaybe<ContractType>;
    coverImage?: InputMaybe<Scalars['ID']>;
    description?: InputMaybe<Scalars['String']>;
    duration?: InputMaybe<JobAnnouncentContractDurantion>;
    expirationDate?: InputMaybe<Scalars['String']>;
    hiringType?: InputMaybe<HiringType>;
    isAssessmentRequired?: InputMaybe<Scalars['Boolean']>;
    isChatActivate?: InputMaybe<Scalars['Boolean']>;
    isJobInterviewRequired?: InputMaybe<Scalars['Boolean']>;
    isTargetAvailableForRelocation?: InputMaybe<Scalars['Boolean']>;
    isTargetAvailableForRoleChange?: InputMaybe<Scalars['Boolean']>;
    isTargetAvailableForTransfers?: InputMaybe<Scalars['Boolean']>;
    locationType: LocationType;
    ownerId: Scalars['ID'];
    previewImage?: InputMaybe<Scalars['ID']>;
    privacyURL: Scalars['String'];
    qualificationId?: InputMaybe<Scalars['String']>;
    referentId?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<JobAnnouncementStatus>;
    targetAvailability?: InputMaybe<JobAnnouncentTargetAvailability>;
    targetCandidateRalMax?: InputMaybe<Scalars['Int']>;
    targetCandidateRalMin?: InputMaybe<Scalars['Int']>;
    targetDisiredSkills?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    targetExperienceYearsMax?: InputMaybe<Scalars['Int']>;
    targetExperienceYearsMin?: InputMaybe<Scalars['Int']>;
    title: Scalars['String'];
};

export enum JobAnnouncementStatus {
    Draft = 'DRAFT',
    Private = 'PRIVATE',
    Public = 'PUBLIC',
}

export type JobAnnouncementUpdateInput = {
    city?: InputMaybe<Scalars['String']>;
    contractType?: InputMaybe<ContractType>;
    coverImage?: InputMaybe<Scalars['ID']>;
    description?: InputMaybe<Scalars['String']>;
    duration?: InputMaybe<JobAnnouncentContractDurantion>;
    expirationDate?: InputMaybe<Scalars['String']>;
    hiringType?: InputMaybe<HiringType>;
    id: Scalars['ID'];
    isAssessmentRequired?: InputMaybe<Scalars['Boolean']>;
    isChatActivate?: InputMaybe<Scalars['Boolean']>;
    isJobInterviewRequired?: InputMaybe<Scalars['Boolean']>;
    isTargetAvailableForRelocation?: InputMaybe<Scalars['Boolean']>;
    isTargetAvailableForRoleChange?: InputMaybe<Scalars['Boolean']>;
    isTargetAvailableForTransfers?: InputMaybe<Scalars['Boolean']>;
    locationType?: InputMaybe<LocationType>;
    previewImage?: InputMaybe<Scalars['ID']>;
    privacyURL?: InputMaybe<Scalars['String']>;
    qualificationId?: InputMaybe<Scalars['String']>;
    referentId?: InputMaybe<Scalars['ID']>;
    status?: InputMaybe<JobAnnouncementStatus>;
    targetAvailability?: InputMaybe<JobAnnouncentTargetAvailability>;
    targetCandidateRalMax?: InputMaybe<Scalars['Int']>;
    targetCandidateRalMin?: InputMaybe<Scalars['Int']>;
    targetDisiredSkills?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    targetExperienceYearsMax?: InputMaybe<Scalars['Int']>;
    targetExperienceYearsMin?: InputMaybe<Scalars['Int']>;
    title: Scalars['String'];
};

export enum JobAnnouncentContractDurantion {
    LessThan_1Year = 'LESS_THAN_1_YEAR',
    MoreThan_1Year = 'MORE_THAN_1_YEAR',
}

export enum JobAnnouncentTargetAvailability {
    Immediately = 'IMMEDIATELY',
    Max_2Months = 'MAX_2_MONTHS',
    Max_6Months = 'MAX_6_MONTHS',
}

export type Keyword = {
    __typename?: 'Keyword';
    name: Scalars['String'];
};

export type Label = {
    __typename?: 'Label';
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    isDefault?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    parentId?: Maybe<Scalars['String']>;
};

export type Language = {
    __typename?: 'Language';
    des: Scalars['String'];
    id: Scalars['ID'];
};

export enum LessonStatus {
    Published = 'PUBLISHED',
}

export enum LocationType {
    Hybrid = 'HYBRID',
    Presence = 'PRESENCE',
    Remote = 'REMOTE',
}

export type Media = {
    __typename?: 'Media';
    fileLabel?: Maybe<Scalars['String']>;
    fileName?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
};

export enum MediaTypes {
    Coverimage = 'COVERIMAGE',
    Curriculum = 'CURRICULUM',
    Curriculumvideo = 'CURRICULUMVIDEO',
    Photo = 'PHOTO',
    Previewimage = 'PREVIEWIMAGE',
    Screenshot = 'SCREENSHOT',
    Slide = 'SLIDE',
    Urlvideo = 'URLVIDEO',
    Video = 'VIDEO',
}

export type MinMaxValueInput = {
    max?: InputMaybe<Scalars['Int']>;
    min?: InputMaybe<Scalars['Int']>;
};

export type MinMaxValueOutput = {
    __typename?: 'MinMaxValueOutput';
    max?: Maybe<Scalars['Int']>;
    min?: Maybe<Scalars['Int']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    acceptPrivacy?: Maybe<User>;
    acceptRequestForCompanyRelationShare?: Maybe<Scalars['Boolean']>;
    acceptTermCondition?: Maybe<User>;
    activeCompanyUploadCV?: Maybe<Scalars['ID']>;
    activeManyCompanyUploadCV?: Maybe<Array<Maybe<Scalars['ID']>>>;
    activeOpenHr?: Maybe<Scalars['Boolean']>;
    addBulkTalentsToSavedSearch?: Maybe<SavedSearch>;
    /** Add candidacy to a job announcement. */
    addCandidacy?: Maybe<Scalars['String']>;
    /** one request per file, specifying the group ID previously created. */
    addCompanyUploadCV?: Maybe<AddCompanyUploadCvResponse>;
    addCvToSavedSearch?: Maybe<SavedSearch>;
    addMeToCompany?: Maybe<User>;
    addUserToCompany?: Maybe<User>;
    addUserToSavedSearch?: Maybe<SavedSearch>;
    archiveCompanyUploadCV?: Maybe<Scalars['ID']>;
    archiveManyCompanyUploadCV?: Maybe<Array<Maybe<Scalars['ID']>>>;
    assignFoldersToTalents?: Maybe<Scalars['Boolean']>;
    assignLabelsToTalents?: Maybe<Scalars['Boolean']>;
    candidateMe?: Maybe<Scalars['Boolean']>;
    changeUserRoleInCompany?: Maybe<User>;
    confirmUserToCompany?: Maybe<User>;
    createCompanyTeamList?: Maybe<CompanyTeamList>;
    /** creates a group of uploads, the returned group ID is used in the second request to upload the actual file.  */
    createCompanyUploadCVGroup?: Maybe<CompanyUploadCvGroup>;
    /**
     * SCOPO: crea un nuovo COLLOQUIO (alias INTERVIEW) correlato ad una OFFERTA DI LAVORO
     *
     * FUNZIONALITA: crea un record nelle seguenti tabelle:
     * - h_hiring_interview: record dei COLLOQUI (alias INTERVIEW) correlati ad una OFFERTA DI LAVORO,
     * - SE vengono passate le date: h_hiring_proposal: record delle PROPOSTE (alias PROPOSAL) di date per effettuare il COLLOQUIO
     *
     * PARAMETRI:
     *
     * hiringprocessId : ID del PROCESS,
     *
     * date1 : string, date2 : string, date3 : le 3 date proposte (formato aaaa-mm-dd),
     *
     * time1 : string, time2 : string, time3 : i 3 orari proposti (formato oo:mm), ciascuno per la relativa data,
     *
     * message : messaggio da inviare al candidato per email
     */
    createHiringInterview?: Maybe<Scalars['String']>;
    /**
     * SCOPO: crea un nuovo flusso di OFFERTA di lavoro
     *
     * FUNZIONALITA: crea un record nelle seguenti tabelle:
     * - h_hiring_process: record dei flussi di OFFERTA DI LAVORO (alias PROCESS),
     * - h_hiring_interview: record dei COLLOQUI (alias INTERVIEW) correlati ad una OFFERTA DI LAVORO,
     * - h_hiring_proposal: record delle PROPOSTE (alias PROPOSAL) di date per effettuare il COLLOQUIO
     *
     * PARAMETRI:
     *
     * userId : ID utente loggato a WH,
     *
     * target_reference_id: ID o del CV o dello USER (dipende da dove arriva)
     *
     * target_reference_type: tipologia di sorgente dati: CV oppure USER
     *
     * curriculumEmail : email del candidato che ha scritto il curriculum,
     *
     * processDescription : decrizione della offerta di lavoro (facoltativo),
     *
     * date1 : string, date2 : string, date3 : le 3 date proposte (formato aaaa-mm-dd),
     *
     * time1 : string, time2 : string, time3 : i 3 orari proposti (formato oo:mm), ciascuno per la relativa data,
     *
     * message : messaggio da inviare al candidato per email
     */
    createHiringProcess?: Maybe<Scalars['String']>;
    /**
     * SCOPO: crea una PROPOSTA (alias PROPOSAL) di date per un COLLOQUIO (alias INTERVIEW) correlato ad una OFFERTA DI LAVORO
     *
     * FUNZIONALITA: crea un record nelle seguenti tabelle:
     * - h_hiring_proposal: record delle PROPOSTE (alias PROPOSAL) di date per effettuare il COLLOQUIO
     *
     * PARAMETRI:
     *
     * hiringinterviewId : id COLLOQUIO (alias INTERVIEW),
     *
     * date1 : string, date2 : string, date3 : le 3 date proposte (formato aaaa-mm-dd),
     *
     * time1 : string, time2 : string, time3 : i 3 orari proposti (formato oo:mm), ciascuno per la relativa data,
     *
     * message : messaggio da inviare al candidato per email
     */
    createHiringProposal?: Maybe<Scalars['String']>;
    createLabel?: Maybe<Label>;
    deleteCompanyTeamList?: Maybe<Scalars['Boolean']>;
    deleteCompanyUploadCV?: Maybe<Scalars['ID']>;
    deleteEducationCourse?: Maybe<EducationCourse>;
    deleteEducationLesson?: Maybe<Scalars['Boolean']>;
    deleteEducationStudent?: Maybe<Scalars['Boolean']>;
    deleteEducationTeacher?: Maybe<Scalars['Boolean']>;
    deleteFolder?: Maybe<Scalars['Boolean']>;
    deleteLabel?: Maybe<Scalars['Boolean']>;
    deleteManyCompanyUploadCV?: Maybe<Array<Maybe<Scalars['ID']>>>;
    deleteMe?: Maybe<Scalars['Boolean']>;
    deleteQualificationType?: Maybe<Scalars['Boolean']>;
    deleteSavedSearch?: Maybe<SavedSearch>;
    deleteSkill?: Maybe<Scalars['Boolean']>;
    deleteSkillType?: Maybe<Scalars['Boolean']>;
    duplicateEducationCourse?: Maybe<EducationCourse>;
    editCompanyUploadCvDataElaborations?: Maybe<Scalars['Boolean']>;
    editCompanyUploadCvDataElaborations2?: Maybe<Scalars['Boolean']>;
    generateApiKey?: Maybe<Scalars['String']>;
    insertBulkEducationStudents?: Maybe<Array<Maybe<EducationStudent>>>;
    insertCVRating?: Maybe<Rating>;
    insertCompany?: Maybe<Company>;
    insertEducationCourse?: Maybe<EducationCourse>;
    insertEducationLesson?: Maybe<EducationLesson>;
    insertEducationStudent?: Maybe<EducationStudent>;
    insertEducationTeacher?: Maybe<EducationTeacher>;
    insertFolder?: Maybe<Folder>;
    /** Insert a new job announcement */
    insertJobAnnouncement?: Maybe<JobAnnouncement>;
    insertManySavedTalents?: Maybe<Array<Maybe<SavedTalent>>>;
    insertQualificationType?: Maybe<QualificationType>;
    insertRequestForCompanyRelationShare?: Maybe<Scalars['Boolean']>;
    insertSavedSearch?: Maybe<SavedSearch>;
    insertSavedTalent?: Maybe<SavedTalent>;
    insertSkill?: Maybe<Skill>;
    insertSkillType?: Maybe<SkillType>;
    insertSurveyUserAnswer?: Maybe<SurveyQuestionProposal>;
    insertTalentsAsPreferredInSavedSearch?: Maybe<SavedSearch>;
    insertUserRating?: Maybe<Rating>;
    inviteToEducationCourse?: Maybe<Scalars['Boolean']>;
    linkExternalMedia?: Maybe<Media>;
    mdummy?: Maybe<Scalars['Boolean']>;
    refuseRequestForCompanyRelationShare?: Maybe<Scalars['Boolean']>;
    refuseUserToCompany?: Maybe<User>;
    removeAsHiredCompanyCV?: Maybe<Scalars['ID']>;
    removeCandidateAsPreferred?: Maybe<Scalars['Boolean']>;
    removeCvFromSavedSearch?: Maybe<Scalars['Boolean']>;
    removeJobAnnouncementAsPreferred?: Maybe<Scalars['Boolean']>;
    removeManyCandidatesAsPreferred?: Maybe<Scalars['Boolean']>;
    removeManyCvFromSavedSearch?: Maybe<Scalars['Boolean']>;
    removeManySavedTalents?: Maybe<Scalars['Boolean']>;
    removeMedia?: Maybe<Scalars['Boolean']>;
    removeSavedTalent?: Maybe<Scalars['Boolean']>;
    removeTalentsAsPreferredInSavedSearch?: Maybe<SavedSearch>;
    removeUserToCompany?: Maybe<User>;
    resendUserWelcomeEmail?: Maybe<Scalars['Boolean']>;
    sendEmailHelp?: Maybe<Scalars['Boolean']>;
    sendHiringInterviewComunication?: Maybe<Scalars['Boolean']>;
    setAsHiredCompanyCV?: Maybe<Scalars['ID']>;
    setCandidateAsAccepted?: Maybe<Scalars['Boolean']>;
    setCandidateAsHired?: Maybe<Scalars['Boolean']>;
    setCandidateAsPreferred?: Maybe<Scalars['Boolean']>;
    setCandidateAsRefused?: Maybe<Scalars['Boolean']>;
    setCompanyUploadCVAsInternal?: Maybe<Scalars['ID']>;
    setCompanyUploadCVAsTotal?: Maybe<Scalars['ID']>;
    setCurrentAppVisualization?: Maybe<CurrentAppVisualization>;
    setJobAnnouncementAsPreferred?: Maybe<Scalars['Boolean']>;
    setManyCandidatesAsAccepted?: Maybe<Scalars['Boolean']>;
    setManyCandidatesAsPreferred?: Maybe<Scalars['Boolean']>;
    setManyCompanyUploadCVAsInternal?: Maybe<Array<Maybe<Scalars['ID']>>>;
    setManyCompanyUploadCVAsTotal?: Maybe<Array<Maybe<Scalars['ID']>>>;
    updateCompany?: Maybe<Company>;
    updateCompanyRelationShare?: Maybe<CompanyRelationShare>;
    updateCompanyTeamList?: Maybe<CompanyTeamList>;
    updateCompanyUploadCVGroup?: Maybe<CompanyUploadCvGroup>;
    updateEducationCourse?: Maybe<EducationCourse>;
    updateEducationLesson?: Maybe<EducationLesson>;
    updateEducationStudent?: Maybe<EducationStudent>;
    updateEducationTeacher?: Maybe<EducationTeacher>;
    updateFolder?: Maybe<Folder>;
    /**
     * SCOPO: aggiorna i dati di COLLOQUIO (alias INTERVIEW) correlato ad una OFFERTA DI LAVORO
     *
     * FUNZIONALITA: aggiorna un record nelle seguenti tabelle:
     * - h_hiring_interview: record dei COLLOQUI (alias INTERVIEW) correlati ad una OFFERTA DI LAVORO,
     *
     * PARAMETRI:
     *
     * id : id COLLOQUIO (alias INTERVIEW)
     *
     * status : WAITING, VALUATING, DONE
     *
     * valuation : valutazione del candidato (testo libero)
     *
     * reliability, personality, potential, experience, leadership: 5 campi in tutto - valori numerici da 1 a 5
     */
    updateHiringInterview?: Maybe<HiringInterview>;
    /**
     * SCOPO: aggiorna alcuni dati di una PROPOSTA (alias PROPOSAL) di date per un COLLOQUIO (alias INTERVIEW) correlato ad una OFFERTA DI LAVORO
     *
     * FUNZIONALITA: aggiorna un record nelle seguenti tabelle:
     * - h_hiring_proposal: record delle PROPOSTE (alias PROPOSAL) di date per effettuare il COLLOQUIO
     *
     * PARAMETRI:
     *
     * id : chiave primaria,
     *
     * status : stato della proposta - SENT, ACCEPTED, REFUSED
     *
     * accepted_date : indica quale data è stata accettata quindi ammette come valori 1,2 oppure 3
     */
    updateHiringProposal?: Maybe<HiringProposal>;
    updateJobAnnouncement?: Maybe<JobAnnouncement>;
    updateLabel?: Maybe<Label>;
    updateQualificationType?: Maybe<QualificationType>;
    updateSavedSearch?: Maybe<SavedSearch>;
    updateSkill?: Maybe<Skill>;
    updateSkillType?: Maybe<SkillType>;
    updateUser?: Maybe<User>;
    uploadFile?: Maybe<Media>;
    userConfirm?: Maybe<Scalars['Boolean']>;
    userSignUp?: Maybe<User>;
    userSignUpAlreadyInAuthProvider?: Maybe<User>;
};

export type MutationAcceptPrivacyArgs = {
    privacyId: Scalars['ID'];
};

export type MutationAcceptRequestForCompanyRelationShareArgs = {
    input: HandleCompanyRelationShareInput;
};

export type MutationAcceptTermConditionArgs = {
    termConditionId: Scalars['ID'];
};

export type MutationActiveCompanyUploadCvArgs = {
    companyId: Scalars['String'];
    reference: CvReference;
};

export type MutationActiveManyCompanyUploadCvArgs = {
    companyId: Scalars['String'];
    references: Array<InputMaybe<CvReference>>;
};

export type MutationActiveOpenHrArgs = {
    id: Scalars['ID'];
};

export type MutationAddBulkTalentsToSavedSearchArgs = {
    references?: InputMaybe<Array<InputMaybe<CvReferenceWithCorrespondence>>>;
    searchId: Scalars['ID'];
    uploadGroupId?: InputMaybe<Scalars['ID']>;
};

export type MutationAddCandidacyArgs = {
    input: JobAnnouncementAddCandidacyInput;
};

export type MutationAddCompanyUploadCvArgs = {
    input?: InputMaybe<InsertCompanyUploadCv>;
};

export type MutationAddCvToSavedSearchArgs = {
    correspondence?: InputMaybe<Scalars['Int']>;
    id: Scalars['String'];
    searchId: Scalars['ID'];
};

export type MutationAddMeToCompanyArgs = {
    companyId: Scalars['ID'];
    role: CompanyEmployeeRole;
};

export type MutationAddUserToCompanyArgs = {
    companyId: Scalars['ID'];
    role: CompanyEmployeeRole;
    userId: Scalars['ID'];
};

export type MutationAddUserToSavedSearchArgs = {
    correspondence?: InputMaybe<Scalars['Int']>;
    id: Scalars['String'];
    searchId: Scalars['ID'];
};

export type MutationArchiveCompanyUploadCvArgs = {
    companyId: Scalars['String'];
    reference: CvReference;
};

export type MutationArchiveManyCompanyUploadCvArgs = {
    companyId: Scalars['String'];
    references: Array<InputMaybe<CvReference>>;
};

export type MutationAssignFoldersToTalentsArgs = {
    input: Array<InputMaybe<AssignFoldersToTalentInput>>;
};

export type MutationAssignLabelsToTalentsArgs = {
    input: Array<InputMaybe<AssignLabelsToTalentInput>>;
};

export type MutationCandidateMeArgs = {
    jobAnnouncementId: Scalars['ID'];
};

export type MutationChangeUserRoleInCompanyArgs = {
    relationId: Scalars['ID'];
    role: CompanyEmployeeRole;
};

export type MutationConfirmUserToCompanyArgs = {
    relationId: Scalars['ID'];
};

export type MutationCreateCompanyTeamListArgs = {
    input?: InputMaybe<CreateCompanyTeamListRequest>;
};

export type MutationCreateCompanyUploadCvGroupArgs = {
    input?: InputMaybe<InsertCompanyUploadCvGroup>;
};

export type MutationCreateHiringInterviewArgs = {
    date1?: InputMaybe<Scalars['String']>;
    date2?: InputMaybe<Scalars['String']>;
    date3?: InputMaybe<Scalars['String']>;
    hiringprocessId?: InputMaybe<Scalars['String']>;
    message: Scalars['String'];
    time1?: InputMaybe<Scalars['String']>;
    time2?: InputMaybe<Scalars['String']>;
    time3?: InputMaybe<Scalars['String']>;
};

export type MutationCreateHiringProcessArgs = {
    curriculumEmail: Scalars['String'];
    date1: Scalars['String'];
    date2?: InputMaybe<Scalars['String']>;
    date3?: InputMaybe<Scalars['String']>;
    message?: InputMaybe<Scalars['String']>;
    processDescription?: InputMaybe<Scalars['String']>;
    target_reference_id: Scalars['String'];
    target_reference_type: Scalars['String'];
    time1: Scalars['String'];
    time2?: InputMaybe<Scalars['String']>;
    time3?: InputMaybe<Scalars['String']>;
    userId?: InputMaybe<Scalars['String']>;
};

export type MutationCreateHiringProposalArgs = {
    date1: Scalars['String'];
    date2?: InputMaybe<Scalars['String']>;
    date3?: InputMaybe<Scalars['String']>;
    hiringinterviewId?: InputMaybe<Scalars['String']>;
    message: Scalars['String'];
    time1: Scalars['String'];
    time2?: InputMaybe<Scalars['String']>;
    time3?: InputMaybe<Scalars['String']>;
};

export type MutationCreateLabelArgs = {
    companyId?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    parentId?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteCompanyTeamListArgs = {
    id: Scalars['String'];
};

export type MutationDeleteCompanyUploadCvArgs = {
    companyId: Scalars['String'];
    reference: CvReference;
};

export type MutationDeleteEducationCourseArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteEducationLessonArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteEducationStudentArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteEducationTeacherArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteFolderArgs = {
    id?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteLabelArgs = {
    id?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteManyCompanyUploadCvArgs = {
    companyId: Scalars['String'];
    references: Array<InputMaybe<CvReference>>;
};

export type MutationDeleteQualificationTypeArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteSavedSearchArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteSkillArgs = {
    id: Scalars['ID'];
};

export type MutationDeleteSkillTypeArgs = {
    id: Scalars['ID'];
};

export type MutationDuplicateEducationCourseArgs = {
    id: Scalars['ID'];
};

export type MutationEditCompanyUploadCvDataElaborationsArgs = {
    input: EditCompanyUploadCvDataElaborationsInput;
};

export type MutationEditCompanyUploadCvDataElaborations2Args = {
    input: EditCompanyUploadCvDataElaborationsInput2;
};

export type MutationGenerateApiKeyArgs = {
    id: Scalars['ID'];
};

export type MutationInsertBulkEducationStudentsArgs = {
    input?: InputMaybe<Array<InputMaybe<EducationStudentInput>>>;
};

export type MutationInsertCvRatingArgs = {
    input?: InputMaybe<InsertRatingInsert>;
};

export type MutationInsertCompanyArgs = {
    input?: InputMaybe<InsertCompanyRequest>;
};

export type MutationInsertEducationCourseArgs = {
    input?: InputMaybe<EducationCourseInsertRequest>;
};

export type MutationInsertEducationLessonArgs = {
    input?: InputMaybe<EducationLessonInput>;
};

export type MutationInsertEducationStudentArgs = {
    input?: InputMaybe<EducationStudentInput>;
};

export type MutationInsertEducationTeacherArgs = {
    input?: InputMaybe<EducationTeacherInput>;
};

export type MutationInsertFolderArgs = {
    input: InsertFolderInput;
};

export type MutationInsertJobAnnouncementArgs = {
    input?: InputMaybe<JobAnnouncementInsertInput>;
};

export type MutationInsertManySavedTalentsArgs = {
    input: InsertSavedTalentsInput;
};

export type MutationInsertQualificationTypeArgs = {
    custom?: InputMaybe<Scalars['Int']>;
    des: Scalars['String'];
    language_id?: InputMaybe<Scalars['String']>;
};

export type MutationInsertRequestForCompanyRelationShareArgs = {
    input: HandleCompanyRelationShareInput;
};

export type MutationInsertSavedSearchArgs = {
    input: InsertSavedSearchInput;
};

export type MutationInsertSavedTalentArgs = {
    input: InsertSavedTalentInput;
};

export type MutationInsertSkillArgs = {
    des: Scalars['String'];
    language_id?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['String']>;
};

export type MutationInsertSkillTypeArgs = {
    custom?: InputMaybe<Scalars['Int']>;
    des: Scalars['String'];
    language_id?: InputMaybe<Scalars['String']>;
};

export type MutationInsertSurveyUserAnswerArgs = {
    proposalId: Scalars['ID'];
    questionId: Scalars['ID'];
    userId?: InputMaybe<Scalars['ID']>;
    value?: InputMaybe<Scalars['String']>;
};

export type MutationInsertTalentsAsPreferredInSavedSearchArgs = {
    references?: InputMaybe<Array<InputMaybe<CvReference>>>;
    searchId: Scalars['ID'];
};

export type MutationInsertUserRatingArgs = {
    input?: InputMaybe<InsertRatingInsert>;
};

export type MutationInviteToEducationCourseArgs = {
    input?: InputMaybe<EducationCourseInvite>;
};

export type MutationLinkExternalMediaArgs = {
    input: ExternalFileInput;
};

export type MutationRefuseRequestForCompanyRelationShareArgs = {
    input: HandleCompanyRelationShareInput;
};

export type MutationRefuseUserToCompanyArgs = {
    relationId: Scalars['ID'];
};

export type MutationRemoveAsHiredCompanyCvArgs = {
    companyId: Scalars['String'];
    reference: CvReference;
};

export type MutationRemoveCandidateAsPreferredArgs = {
    jobAnnouncementId: Scalars['ID'];
    reference: CvReference;
};

export type MutationRemoveCvFromSavedSearchArgs = {
    reference: CvReference;
    searchId: Scalars['ID'];
};

export type MutationRemoveJobAnnouncementAsPreferredArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveManyCandidatesAsPreferredArgs = {
    input?: InputMaybe<RemoveManyCandidatesAsPreferredInput>;
};

export type MutationRemoveManyCvFromSavedSearchArgs = {
    references: Array<InputMaybe<CvReference>>;
    searchId: Scalars['ID'];
};

export type MutationRemoveManySavedTalentsArgs = {
    companyId: Scalars['String'];
    referenceIds: Array<InputMaybe<Scalars['String']>>;
};

export type MutationRemoveMediaArgs = {
    mediaId: Scalars['ID'];
};

export type MutationRemoveSavedTalentArgs = {
    companyId: Scalars['String'];
    referenceId: Scalars['String'];
};

export type MutationRemoveTalentsAsPreferredInSavedSearchArgs = {
    references?: InputMaybe<Array<InputMaybe<CvReference>>>;
    searchId: Scalars['ID'];
};

export type MutationRemoveUserToCompanyArgs = {
    relationId: Scalars['ID'];
};

export type MutationSendEmailHelpArgs = {
    destination_email?: InputMaybe<Scalars['String']>;
    user_company?: InputMaybe<Scalars['String']>;
    user_email: Scalars['String'];
    user_message: Scalars['String'];
    user_name: Scalars['String'];
    user_phone: Scalars['String'];
    user_surname: Scalars['String'];
};

export type MutationSendHiringInterviewComunicationArgs = {
    companyRequester?: InputMaybe<Scalars['String']>;
    message?: InputMaybe<Scalars['String']>;
    reference?: InputMaybe<CvReference>;
};

export type MutationSetAsHiredCompanyCvArgs = {
    companyId: Scalars['String'];
    reference: CvReference;
};

export type MutationSetCandidateAsAcceptedArgs = {
    jobAnnouncementId: Scalars['ID'];
    referenceId: Scalars['ID'];
    referenceType: TalentType;
};

export type MutationSetCandidateAsHiredArgs = {
    jobAnnouncementId: Scalars['ID'];
    referenceId: Scalars['ID'];
    referenceType: TalentType;
};

export type MutationSetCandidateAsPreferredArgs = {
    jobAnnouncementId: Scalars['ID'];
    referenceId: Scalars['ID'];
    referenceType: TalentType;
};

export type MutationSetCandidateAsRefusedArgs = {
    jobAnnouncementId: Scalars['ID'];
    referenceId: Scalars['ID'];
    referenceType: TalentType;
    refuseReason?: InputMaybe<Scalars['String']>;
};

export type MutationSetCompanyUploadCvAsInternalArgs = {
    companyId: Scalars['String'];
    reference: CvReference;
};

export type MutationSetCompanyUploadCvAsTotalArgs = {
    companyId: Scalars['String'];
    reference: CvReference;
};

export type MutationSetCurrentAppVisualizationArgs = {
    companyId?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<CurrentAppVisualizationType>;
};

export type MutationSetJobAnnouncementAsPreferredArgs = {
    id: Scalars['ID'];
};

export type MutationSetManyCandidatesAsAcceptedArgs = {
    input?: InputMaybe<SetManyCandidatesAsAcceptedInput>;
};

export type MutationSetManyCandidatesAsPreferredArgs = {
    input?: InputMaybe<SetManyCandidatesAsPreferredInput>;
};

export type MutationSetManyCompanyUploadCvAsInternalArgs = {
    companyId: Scalars['String'];
    references: Array<InputMaybe<CvReference>>;
};

export type MutationSetManyCompanyUploadCvAsTotalArgs = {
    companyId: Scalars['String'];
    references: Array<InputMaybe<CvReference>>;
};

export type MutationUpdateCompanyArgs = {
    input?: InputMaybe<UpdateCompanyRequest>;
};

export type MutationUpdateCompanyRelationShareArgs = {
    input: UpdateCompanyRelationShareInput;
};

export type MutationUpdateCompanyTeamListArgs = {
    input?: InputMaybe<UpdateCompanyTeamListRequest>;
};

export type MutationUpdateCompanyUploadCvGroupArgs = {
    input?: InputMaybe<UpdateCompanyUploadCvGroup>;
};

export type MutationUpdateEducationCourseArgs = {
    input?: InputMaybe<EducationCourseUpdateRequest>;
};

export type MutationUpdateEducationLessonArgs = {
    input?: InputMaybe<EducationLessonInput>;
};

export type MutationUpdateEducationStudentArgs = {
    input?: InputMaybe<EducationStudentInput>;
};

export type MutationUpdateEducationTeacherArgs = {
    input?: InputMaybe<EducationTeacherInput>;
};

export type MutationUpdateFolderArgs = {
    input: UpdateFolderInput;
};

export type MutationUpdateHiringInterviewArgs = {
    experience?: InputMaybe<Scalars['Int']>;
    id: Scalars['String'];
    leadership?: InputMaybe<Scalars['Int']>;
    personality?: InputMaybe<Scalars['Int']>;
    potential?: InputMaybe<Scalars['Int']>;
    reliability?: InputMaybe<Scalars['Int']>;
    status?: InputMaybe<Scalars['String']>;
    valuation?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateHiringProposalArgs = {
    accepted_date?: InputMaybe<Scalars['Int']>;
    id?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateJobAnnouncementArgs = {
    input: JobAnnouncementUpdateInput;
};

export type MutationUpdateLabelArgs = {
    input: UpdateLabelInput;
};

export type MutationUpdateQualificationTypeArgs = {
    custom?: InputMaybe<Scalars['Int']>;
    des: Scalars['String'];
    id: Scalars['ID'];
    language_id?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateSavedSearchArgs = {
    input: UpdateSavedSearchInput;
};

export type MutationUpdateSkillArgs = {
    des: Scalars['String'];
    id: Scalars['ID'];
    language_id?: InputMaybe<Scalars['String']>;
    typeId?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateSkillTypeArgs = {
    custom?: InputMaybe<Scalars['Int']>;
    des: Scalars['String'];
    id: Scalars['ID'];
    language_id?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateUserArgs = {
    authorization_advertising?: InputMaybe<Scalars['Boolean']>;
    authorization_marketing?: InputMaybe<Scalars['Boolean']>;
    biografy?: InputMaybe<Scalars['String']>;
    birth_date?: InputMaybe<Scalars['String']>;
    certificatesAchieved?: InputMaybe<Array<InputMaybe<GeneralInfoUpdateRequest>>>;
    certificationsAchieved?: InputMaybe<Array<InputMaybe<GeneralInfoUpdateRequest>>>;
    city_residence?: InputMaybe<Scalars['String']>;
    curriculum?: InputMaybe<Scalars['String']>;
    curriculumVideo?: InputMaybe<Scalars['String']>;
    englishlanguageId?: InputMaybe<Scalars['ID']>;
    hobbies?: InputMaybe<Array<InputMaybe<GeneralInfoUpdateRequest>>>;
    id: Scalars['ID'];
    isTourDashboardViewed?: InputMaybe<Scalars['Boolean']>;
    languageId?: InputMaybe<PlatformLanguages>;
    like_offers_x_km?: InputMaybe<Scalars['Int']>;
    like_show_on_profile?: InputMaybe<Scalars['Boolean']>;
    like_work_home?: InputMaybe<Scalars['Boolean']>;
    like_work_my_city?: InputMaybe<Scalars['Boolean']>;
    like_work_other_city?: InputMaybe<Scalars['Boolean']>;
    like_work_other_city_1?: InputMaybe<Scalars['String']>;
    like_work_other_city_2?: InputMaybe<Scalars['String']>;
    like_work_other_city_3?: InputMaybe<Scalars['String']>;
    like_work_other_state?: InputMaybe<Scalars['Boolean']>;
    like_work_other_state_1?: InputMaybe<Scalars['String']>;
    like_work_other_state_2?: InputMaybe<Scalars['String']>;
    like_work_other_state_3?: InputMaybe<Scalars['String']>;
    maxDistanceForDisponibilityWork?: InputMaybe<Scalars['Int']>;
    motherlanguageId?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    note?: InputMaybe<Scalars['String']>;
    onboarding_user_step?: InputMaybe<Scalars['String']>;
    opportunity_freelance?: InputMaybe<Scalars['Boolean']>;
    opportunity_hiring?: InputMaybe<Scalars['Boolean']>;
    opportunity_less_than_1_year?: InputMaybe<Scalars['Boolean']>;
    opportunity_more_than_1_year?: InputMaybe<Scalars['Boolean']>;
    personalities?: InputMaybe<Array<InputMaybe<UserPersonalityInput>>>;
    phone?: InputMaybe<Scalars['String']>;
    photo?: InputMaybe<Scalars['String']>;
    qualifications?: InputMaybe<Array<InputMaybe<UserQualificationInput>>>;
    screenshot?: InputMaybe<Scalars['String']>;
    show_defect_personality_on_profile?: InputMaybe<Scalars['Boolean']>;
    show_merit_personality_on_profile?: InputMaybe<Scalars['Boolean']>;
    skills?: InputMaybe<Array<InputMaybe<UserSkillInput>>>;
    socials?: InputMaybe<Array<InputMaybe<SocialUpdateRequest>>>;
    spokenLanguages?: InputMaybe<Array<InputMaybe<GeneralInfoUpdateRequest>>>;
    surname?: InputMaybe<Scalars['String']>;
    userType?: InputMaybe<UserType>;
    website?: InputMaybe<Scalars['String']>;
    workState?: InputMaybe<UserWorkState>;
};

export type MutationUploadFileArgs = {
    input: UploadFileInput;
};

export type MutationUserConfirmArgs = {
    registrationCode: Scalars['String'];
};

export type MutationUserSignUpArgs = {
    companyAssociation?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    language_id?: InputMaybe<Scalars['String']>;
    pwd?: InputMaybe<Scalars['String']>;
};

export type MutationUserSignUpAlreadyInAuthProviderArgs = {
    email: Scalars['String'];
    language_id?: InputMaybe<Scalars['String']>;
};

export type Nlist = {
    __typename?: 'NLIST';
    name?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum OpenHrStatus {
    Active = 'ACTIVE',
    All = 'ALL',
    Disactive = 'DISACTIVE',
}

export type Personality = {
    __typename?: 'Personality';
    des: Scalars['String'];
    id: Scalars['ID'];
    section: Scalars['String'];
};

export enum PlatformLanguages {
    Eng = 'eng',
    Ita = 'ita',
}

export type Policy = {
    __typename?: 'Policy';
    key?: Maybe<Scalars['String']>;
    regex?: Maybe<Scalars['String']>;
    type?: Maybe<PolicyTypes>;
    value?: Maybe<Scalars['String']>;
};

export enum PolicyTypes {
    Boolean = 'BOOLEAN',
    Integer = 'INTEGER',
}

export enum Priority {
    High = 'HIGH',
    Low = 'LOW',
}

export type Privacy = {
    __typename?: 'Privacy';
    content?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    title?: Maybe<Scalars['String']>;
};

export type Qualification = {
    __typename?: 'Qualification';
    des: Scalars['String'];
    id: Scalars['ID'];
    type?: Maybe<QualificationType>;
};

export type QualificationArea = {
    __typename?: 'QualificationArea';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export type QualificationAreaSearchInput = {
    id?: InputMaybe<Scalars['String']>;
};

export type QualificationSearchInput = {
    areaId?: InputMaybe<Scalars['ID']>;
    length?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    radix?: InputMaybe<Scalars['String']>;
};

export type QualificationType = {
    __typename?: 'QualificationType';
    custom?: Maybe<Scalars['Int']>;
    des: Scalars['String'];
    id: Scalars['ID'];
};

export type Query = {
    __typename?: 'Query';
    /**
     * STATUS: ATTIVA
     * SCOPO: Consente la ricerca di un indirizzo (Utilizza Google Places)
     * PARAMETRI:
     *   - searchtext: testo da ricercare
     *   - language: linguaggio dell'Utente. Se non passato, utilizza il linguaggio di default dell'Utente.
     *   - country: nazione. Se non passata ignora il filtro
     *   - city: citta. Se non passata ignora il filtro
     */
    AddressList?: Maybe<Alist>;
    BusinessareaList?: Maybe<Array<Maybe<Businessarea>>>;
    /**
     * STATUS: ATTIVA
     * SCOPO: Consente la ricerca delle Città (Utilizza Google Places)
     * PARAMETRI:
     *   - searchtext: testo da ricercare
     *   - language: linguaggio dell'Utente. Se non passato, utilizza il linguaggio di default dell'Utente.
     *   - country: nazione. Se non passata ignora il filtro
     */
    CityList?: Maybe<Clist>;
    CompaniesForUser?: Maybe<Array<Maybe<Company>>>;
    CompanyApiKey?: Maybe<Scalars['String']>;
    CompanyById?: Maybe<Company>;
    CompanyPublicById?: Maybe<CompanyPublic>;
    CompanyRelationShareSearch?: Maybe<CompanyRealtionShareSearchOutput>;
    CompanySearch?: Maybe<Array<Maybe<CompanyPublic>>>;
    CompanyTeamListById?: Maybe<CompanyTeamList>;
    CompanyTeamListSearch?: Maybe<Array<Maybe<CompanyTeamList>>>;
    CompanyUploadCVGroupById?: Maybe<CompanyUploadCvGroup>;
    CompanyUploadCVGroupsByCompanyId?: Maybe<CompanyUploadCvGroupResponse>;
    EducationCourseById?: Maybe<EducationCourse>;
    EducationCourseCalendar?: Maybe<Array<Maybe<EducationCourse>>>;
    EducationCourseList?: Maybe<Array<Maybe<EducationCourse>>>;
    EducationCourseListByOwner?: Maybe<Array<Maybe<EducationCourse>>>;
    EducationCourseListByStudent?: Maybe<Array<Maybe<EducationCourse>>>;
    EducationCourseSearch?: Maybe<Array<Maybe<EducationCourse>>>;
    EducationLesson?: Maybe<EducationLesson>;
    EducationLessonCalendarByStudent?: Maybe<Array<Maybe<EducationLesson>>>;
    EducationLessonListByCourse?: Maybe<Array<Maybe<EducationLesson>>>;
    EducationSearchUserForCoursePartecipants?: Maybe<EducationSearchUserOutput>;
    EducationStudent?: Maybe<EducationStudent>;
    EducationStudentListByCourse?: Maybe<Array<Maybe<EducationStudent>>>;
    EducationTeacher?: Maybe<EducationTeacher>;
    EducationTeacherAvailable?: Maybe<Array<Maybe<EducationTeacher>>>;
    EducationTeacherListByCourse?: Maybe<Array<Maybe<EducationTeacher>>>;
    EducationalList?: Maybe<Array<Maybe<Educational>>>;
    HiringInterviewById?: Maybe<HiringInterview>;
    HiringInterviewListByProcess?: Maybe<Array<Maybe<HiringInterview>>>;
    HiringProcessById?: Maybe<HiringProcess>;
    HiringProposalById?: Maybe<HiringProposal>;
    HiringProposalListByInterview?: Maybe<Array<Maybe<HiringProposal>>>;
    HiringProposalSendProposalToUser?: Maybe<HiringProposal>;
    JobAnnouncementById?: Maybe<JobAnnouncement>;
    LanguageList?: Maybe<Array<Maybe<Language>>>;
    /**
     * STATUS: ATTIVA
     * SCOPO: Consente la ricerca delle Nazioni (Utilizza Google Places)
     *
     * PARAMETRI:
     *   - searchtext: testo da ricercare
     *   - language: linguaggio dell'Utente. Se non passato, utilizza il linguaggio di default dell'Utente.
     */
    NationList?: Maybe<Nlist>;
    PasswordPolicy?: Maybe<Array<Maybe<Policy>>>;
    /**
     * WH - status: ATTIVA
     * SCOPO: Consente il reperimento della lista di elementi di tipo Personality (pregi e difetti)
     * PARAMETRI:
     *   - personalitySection. Tipologia: -merit- oppure -defect-
     *   - language_id. Codice lingua. Se non presente viene prelevato il lingiaggio dello User
     */
    PersonalityList?: Maybe<Array<Maybe<Personality>>>;
    PrivacyLast?: Maybe<Privacy>;
    PrivacyList?: Maybe<Array<Maybe<Privacy>>>;
    ProposalsBySurveyQuestionId?: Maybe<SurveyQuestionProposal>;
    QualificationAreaSearch?: Maybe<Array<Maybe<QualificationArea>>>;
    /** returns the list of professional qualifications, pass 'eng' or 'ita' for language_id */
    QualificationList?: Maybe<Array<Maybe<Qualification>>>;
    QualificationSearch?: Maybe<Array<Maybe<Qualification>>>;
    QualificationTypeList?: Maybe<Array<Maybe<QualificationType>>>;
    SearchJobAnnouncement?: Maybe<SearchJobAnnouncementResponse>;
    /** returns the list of skills, pass 'eng' or 'ita' for language_id */
    SkillList?: Maybe<Array<Maybe<Skill>>>;
    SkillSearch?: Maybe<Array<Maybe<Skill>>>;
    SkillSuggestionsForQualificationType?: Maybe<Array<Maybe<Skill>>>;
    SkillTypeList?: Maybe<Array<Maybe<SkillType>>>;
    SkillsForQualification?: Maybe<Array<Maybe<Skill>>>;
    Survey?: Maybe<Survey>;
    /**
     * WH - status: ATTIVA
     * SCOPO: Restituisce l'insieme dei dati inerenti la struttura dei QUIZ alias SURVEY
     *
     * NOTA: la struttura gerarchica è SURVEY -> SURVEYQUESTION(per ogni Quiz elenco delle domande) -> SURVEYQUESTIONANSWER(per ogni domanda elenco delle possibili risposte)
     */
    SurveyAll?: Maybe<Array<Maybe<Survey>>>;
    SurveyUserAnswerByQuestionId?: Maybe<SurveyQuestionProposal>;
    SurveyUserAnswerByUserId?: Maybe<Array<Maybe<SurveyQuestionProposal>>>;
    TermConditionByUserOrCompany?: Maybe<TermCondition>;
    TermConditionLast?: Maybe<TermCondition>;
    TermConditionList?: Maybe<Array<Maybe<TermCondition>>>;
    User?: Maybe<User>;
    UserByToken?: Maybe<User>;
    UserPublic?: Maybe<UserPublic>;
    canEditCompanyCvDataElaborations?: Maybe<Scalars['Boolean']>;
    candidateByJobAnnouncementId?: Maybe<CandidateByJobAnnouncementIdResponse>;
    cityListForSearch?: Maybe<Clist>;
    datailForCompanyUploadCv?: Maybe<CompanyUploadCvElaborationDetail>;
    datailForCompanyUploadCv2?: Maybe<CompanyUploadCvElaborationDetail2>;
    /** returns the employees in the passed company */
    getCompanyEmployees?: Maybe<Array<Maybe<Employee>>>;
    /**
     * The *get-cv-details* api returns candidate details and percentage of correspondence with the job announcement.<br/>
     * You should provide the jobAnnouncementId that you received at the job announcement creation.
     */
    getCvDetails?: Maybe<CompanyUploadCvElaborationDetail2>;
    indexCV?: Maybe<Scalars['Boolean']>;
    inferenceMapForCv?: Maybe<InferenceMapResponse>;
    logsForCompanyUploadCv?: Maybe<Array<Maybe<CompanyUploadCvDataElaborationLog>>>;
    /** qdummy is a test api */
    qdummy?: Maybe<Scalars['Boolean']>;
    ratingsForCV?: Maybe<Array<Maybe<Rating>>>;
    ratingsForUser?: Maybe<Array<Maybe<Rating>>>;
    saveAndIndexCV?: Maybe<Scalars['Boolean']>;
    savedTalentListByCompanyId?: Maybe<Array<Maybe<SavedTalent>>>;
    searchCompanyCv?: Maybe<SearchCompanyCvOutput>;
    searchCompanyUploadCV?: Maybe<SearchCompanyUploadResponse>;
    searchFolder?: Maybe<Array<Maybe<Folder>>>;
    searchKeyword?: Maybe<SearchKeywordResponse>;
    searchLabel?: Maybe<Array<Maybe<Label>>>;
    searchSavedSearches?: Maybe<SearchSavedSearchesOutput>;
    supportedLanguages?: Maybe<Array<Maybe<Language>>>;
};

export type QueryAddressListArgs = {
    city?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<Scalars['String']>;
    searchtext?: InputMaybe<Scalars['String']>;
};

export type QueryBusinessareaListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<BusinessareaType>;
};

export type QueryCityListArgs = {
    country?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<Scalars['String']>;
    searchtext?: InputMaybe<Scalars['String']>;
};

export type QueryCompaniesForUserArgs = {
    companyId?: InputMaybe<Scalars['ID']>;
};

export type QueryCompanyApiKeyArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type QueryCompanyByIdArgs = {
    id: Scalars['ID'];
};

export type QueryCompanyPublicByIdArgs = {
    id: Scalars['ID'];
};

export type QueryCompanyRelationShareSearchArgs = {
    input?: InputMaybe<CompanyRealtionShareSearchInput>;
};

export type QueryCompanySearchArgs = {
    input: CompanySearchRequest;
};

export type QueryCompanyTeamListByIdArgs = {
    id: Scalars['ID'];
};

export type QueryCompanyTeamListSearchArgs = {
    input?: InputMaybe<SearchCompanyTeamListInput>;
};

export type QueryCompanyUploadCvGroupByIdArgs = {
    input?: InputMaybe<CompanyUploadCvGroupByIdRequest>;
};

export type QueryCompanyUploadCvGroupsByCompanyIdArgs = {
    input?: InputMaybe<CompanyUploadCvGroupsByCompanyIdRequest>;
};

export type QueryEducationCourseByIdArgs = {
    id?: InputMaybe<Scalars['ID']>;
};

export type QueryEducationCourseCalendarArgs = {
    calendar_from?: InputMaybe<Scalars['String']>;
    calendar_to?: InputMaybe<Scalars['String']>;
    companyOwnerId?: InputMaybe<Scalars['String']>;
};

export type QueryEducationCourseListArgs = {
    companyOwnerId?: InputMaybe<Scalars['String']>;
};

export type QueryEducationCourseListByOwnerArgs = {
    ownerId?: InputMaybe<Scalars['ID']>;
};

export type QueryEducationCourseListByStudentArgs = {
    availability?: InputMaybe<Array<InputMaybe<CourseAvailability>>>;
    filter?: InputMaybe<Scalars['String']>;
    lessons_from?: InputMaybe<Scalars['Int']>;
    lessons_to?: InputMaybe<Scalars['Int']>;
    status?: InputMaybe<CourseStatus>;
    studentId?: InputMaybe<Scalars['ID']>;
    subjectarea?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Array<InputMaybe<CourseType>>>;
};

export type QueryEducationCourseSearchArgs = {
    availability?: InputMaybe<Array<InputMaybe<CourseAvailability>>>;
    companyOwnerId?: InputMaybe<Scalars['String']>;
    hours_from?: InputMaybe<Scalars['Int']>;
    hours_to?: InputMaybe<Scalars['Int']>;
    lessons_from?: InputMaybe<Scalars['Int']>;
    lessons_to?: InputMaybe<Scalars['Int']>;
    status?: InputMaybe<CourseStatus>;
    studentId?: InputMaybe<Scalars['String']>;
    subjectarea?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    timing?: InputMaybe<Array<InputMaybe<CourseTiming>>>;
    title?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<Array<InputMaybe<CourseType>>>;
};

export type QueryEducationLessonArgs = {
    id: Scalars['ID'];
};

export type QueryEducationLessonCalendarByStudentArgs = {
    calendar_from?: InputMaybe<Scalars['String']>;
    calendar_to?: InputMaybe<Scalars['String']>;
    studentId?: InputMaybe<Scalars['ID']>;
};

export type QueryEducationLessonListByCourseArgs = {
    courseId?: InputMaybe<Scalars['String']>;
};

export type QueryEducationSearchUserForCoursePartecipantsArgs = {
    input?: InputMaybe<EducationSearchUserInput>;
};

export type QueryEducationStudentArgs = {
    id: Scalars['ID'];
};

export type QueryEducationStudentListByCourseArgs = {
    courseId?: InputMaybe<Scalars['String']>;
    searchText?: InputMaybe<Scalars['String']>;
};

export type QueryEducationTeacherArgs = {
    id: Scalars['ID'];
};

export type QueryEducationTeacherAvailableArgs = {
    companyOwnerId?: InputMaybe<Scalars['String']>;
};

export type QueryEducationTeacherListByCourseArgs = {
    courseId?: InputMaybe<Scalars['String']>;
};

export type QueryEducationalListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QueryHiringInterviewByIdArgs = {
    id: Scalars['ID'];
};

export type QueryHiringInterviewListByProcessArgs = {
    hiringprocessId: Scalars['ID'];
};

export type QueryHiringProcessByIdArgs = {
    id: Scalars['ID'];
};

export type QueryHiringProposalByIdArgs = {
    id: Scalars['ID'];
};

export type QueryHiringProposalListByInterviewArgs = {
    hiringinterviewId: Scalars['ID'];
};

export type QueryHiringProposalSendProposalToUserArgs = {
    hiringproposalId: Scalars['ID'];
};

export type QueryJobAnnouncementByIdArgs = {
    id: Scalars['ID'];
};

export type QueryLanguageListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QueryNationListArgs = {
    language?: InputMaybe<Scalars['String']>;
    searchtext?: InputMaybe<Scalars['String']>;
};

export type QueryPersonalityListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
    personalitySection: Scalars['String'];
};

export type QueryPrivacyLastArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QueryPrivacyListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QueryProposalsBySurveyQuestionIdArgs = {
    surveyQuestionId: Scalars['ID'];
    userId?: InputMaybe<Scalars['ID']>;
};

export type QueryQualificationAreaSearchArgs = {
    input?: InputMaybe<QualificationAreaSearchInput>;
};

export type QueryQualificationListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QueryQualificationSearchArgs = {
    input: QualificationSearchInput;
};

export type QueryQualificationTypeListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QuerySearchJobAnnouncementArgs = {
    input?: InputMaybe<SearchJobAnnouncementRequest>;
};

export type QuerySkillListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QuerySkillSearchArgs = {
    text: Scalars['String'];
};

export type QuerySkillSuggestionsForQualificationTypeArgs = {
    language_id?: InputMaybe<Scalars['String']>;
    qualificationTypeId: Scalars['ID'];
};

export type QuerySkillTypeListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QuerySkillsForQualificationArgs = {
    count?: InputMaybe<Scalars['Int']>;
    language_id?: InputMaybe<Scalars['String']>;
    qualificationId: Scalars['String'];
};

export type QuerySurveyArgs = {
    id: Scalars['ID'];
    language_id?: InputMaybe<Scalars['String']>;
};

export type QuerySurveyAllArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QuerySurveyUserAnswerByQuestionIdArgs = {
    questionId: Scalars['ID'];
    userId?: InputMaybe<Scalars['ID']>;
};

export type QuerySurveyUserAnswerByUserIdArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type QueryTermConditionByUserOrCompanyArgs = {
    id?: InputMaybe<Scalars['String']>;
    language_id?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<TermConditionType>;
};

export type QueryTermConditionLastArgs = {
    language_id?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<TermConditionType>;
};

export type QueryTermConditionListArgs = {
    language_id?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<TermConditionType>;
};

export type QueryUserArgs = {
    id?: InputMaybe<Scalars['String']>;
};

export type QueryUserPublicArgs = {
    id: Scalars['ID'];
};

export type QueryCanEditCompanyCvDataElaborationsArgs = {
    companyId?: InputMaybe<Scalars['String']>;
    reference: CvReference;
};

export type QueryCandidateByJobAnnouncementIdArgs = {
    jobAnnouncementId: Scalars['ID'];
    length?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCityListForSearchArgs = {
    searchtext?: InputMaybe<Scalars['String']>;
};

export type QueryDatailForCompanyUploadCvArgs = {
    companyId: Scalars['String'];
    id: Scalars['ID'];
};

export type QueryDatailForCompanyUploadCv2Args = {
    companyId: Scalars['String'];
    id: Scalars['ID'];
    jobAnnouncementId?: InputMaybe<Scalars['String']>;
};

export type QueryGetCompanyEmployeesArgs = {
    companyId: Scalars['ID'];
};

export type QueryGetCvDetailsArgs = {
    companyId: Scalars['String'];
    id: Scalars['ID'];
    jobAnnouncementId?: InputMaybe<Scalars['String']>;
};

export type QueryIndexCvArgs = {
    id: Scalars['ID'];
};

export type QueryInferenceMapForCvArgs = {
    companyId: Scalars['ID'];
    id: Scalars['ID'];
    type?: InputMaybe<CompanyUploadCvDataElaborationCategory>;
};

export type QueryLogsForCompanyUploadCvArgs = {
    id: Scalars['ID'];
};

export type QueryRatingsForCvArgs = {
    input: RatingsListInput;
};

export type QueryRatingsForUserArgs = {
    input: RatingsListInput;
};

export type QuerySaveAndIndexCvArgs = {
    fileUrl?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
};

export type QuerySavedTalentListByCompanyIdArgs = {
    companyId: Scalars['String'];
};

export type QuerySearchCompanyCvArgs = {
    input?: InputMaybe<SearchCompanyCvInput>;
};

export type QuerySearchCompanyUploadCvArgs = {
    input?: InputMaybe<SearchCompanyUploadCvRequest>;
};

export type QuerySearchFolderArgs = {
    input: SearchFolderInput;
};

export type QuerySearchKeywordArgs = {
    input?: InputMaybe<SearchKeywordRequest>;
};

export type QuerySearchLabelArgs = {
    input: SearchLabelInput;
};

export type QuerySearchSavedSearchesArgs = {
    input?: InputMaybe<SearchSavedSearchesInput>;
};

export type QuerySupportedLanguagesArgs = {
    language_id?: InputMaybe<Scalars['String']>;
};

export type Rating = {
    __typename?: 'Rating';
    authorFullName?: Maybe<Scalars['String']>;
    authorImage?: Maybe<Scalars['String']>;
    companyName?: Maybe<Scalars['String']>;
    content?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    ratings?: Maybe<Array<Maybe<RatingsDescriptionValue>>>;
};

export type RatingsDescriptionValue = {
    __typename?: 'RatingsDescriptionValue';
    description?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['Float']>;
};

export type RatingsListInput = {
    companyId: Scalars['String'];
    id: Scalars['String'];
};

export type Referent = {
    __typename?: 'Referent';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    surname?: Maybe<Scalars['String']>;
};

export type RemoveManyCandidatesAsPreferredInput = {
    jobAnnouncementId: Scalars['ID'];
    references?: InputMaybe<Array<InputMaybe<CvReference>>>;
};

export type ResumeStatusFiles = {
    __typename?: 'ResumeStatusFiles';
    actual?: Maybe<Scalars['Int']>;
    status?: Maybe<CompanyCvProcessStatus>;
};

export type SavedSearch = {
    __typename?: 'SavedSearch';
    creationDate?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    query?: Maybe<SavedSearchQueryOutput>;
    results?: Maybe<Array<Maybe<SavedSearchResult>>>;
    visualization?: Maybe<SavedSearchVisualizationOutput>;
};

export type SavedSearchQueryOutput = {
    __typename?: 'SavedSearchQueryOutput';
    companyId?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    groupId?: Maybe<Scalars['String']>;
    hiringType?: Maybe<HiringType>;
    keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
    locationCity?: Maybe<Scalars['String']>;
    locationType?: Maybe<LocationType>;
    origin?: Maybe<SearchCompanyCvOrigin>;
    qualifications?: Maybe<Array<Maybe<Scalars['ID']>>>;
    searchId?: Maybe<Scalars['String']>;
    skills?: Maybe<Array<Maybe<Scalars['ID']>>>;
    state?: Maybe<CompanyCvState>;
};

export type SavedSearchResult = {
    __typename?: 'SavedSearchResult';
    data?: Maybe<CompanySearchCvOutput>;
    id?: Maybe<Scalars['ID']>;
    state?: Maybe<CompanySavedSearchResultState>;
};

export type SavedSearchVisualizationInput = {
    activity?: InputMaybe<Scalars['String']>;
    daily_fare?: InputMaybe<MinMaxValueInput>;
    experience?: InputMaybe<MinMaxValueInput>;
    qualification?: InputMaybe<Scalars['ID']>;
    responsability?: InputMaybe<Scalars['String']>;
    row?: InputMaybe<Scalars['Int']>;
    skills?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    workLoad?: InputMaybe<Scalars['Int']>;
};

export type SavedSearchVisualizationOutput = {
    __typename?: 'SavedSearchVisualizationOutput';
    activity?: Maybe<Scalars['String']>;
    daily_fare?: Maybe<MinMaxValueOutput>;
    experience?: Maybe<MinMaxValueOutput>;
    qualification?: Maybe<Qualification>;
    responsability?: Maybe<Scalars['String']>;
    row?: Maybe<Scalars['Int']>;
    skills?: Maybe<Array<Maybe<Scalars['String']>>>;
    workLoad?: Maybe<Scalars['Int']>;
};

export type SavedTalent = {
    __typename?: 'SavedTalent';
    id?: Maybe<Scalars['ID']>;
    referenceId?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export type SearchCompanyCvInput = {
    cities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    companyId?: InputMaybe<Scalars['String']>;
    folders?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    fullName?: InputMaybe<Scalars['String']>;
    groupId?: InputMaybe<Scalars['String']>;
    hiringType?: InputMaybe<HiringType>;
    keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    labels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
    limit?: InputMaybe<Scalars['Int']>;
    locationCity?: InputMaybe<Scalars['String']>;
    locationType?: InputMaybe<LocationType>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    origin?: InputMaybe<SearchCompanyCvOrigin>;
    protectedCategories?: InputMaybe<Scalars['Boolean']>;
    qualifications?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    searchId?: InputMaybe<Scalars['String']>;
    skills?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    state?: InputMaybe<CompanyCvState>;
};

export enum SearchCompanyCvOrigin {
    All = 'ALL',
    External = 'EXTERNAL',
    Internal = 'INTERNAL',
    Platform = 'PLATFORM',
    UserHr = 'USER_HR',
}

export type SearchCompanyCvOutput = {
    __typename?: 'SearchCompanyCvOutput';
    count?: Maybe<Array<Maybe<CompanySearchCvCountOutput>>>;
    data?: Maybe<Array<Maybe<CompanySearchCvOutput>>>;
};

export type SearchCompanyTeamListInput = {
    companyId?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
};

export type SearchCompanyUploadCvRequest = {
    companyId: Scalars['String'];
    cvFileName?: InputMaybe<Scalars['String']>;
    dashboardType?: InputMaybe<CompanyUploadDashboardType>;
    dateFrom?: InputMaybe<Scalars['String']>;
    dateTo?: InputMaybe<Scalars['String']>;
    length?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type SearchCompanyUploadResponse = {
    __typename?: 'SearchCompanyUploadResponse';
    count?: Maybe<Scalars['Int']>;
    data?: Maybe<Array<Maybe<CompanyUploadCvGroup>>>;
};

export type SearchFolderInput = {
    companyId?: InputMaybe<Scalars['String']>;
    limit?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export enum SearchJobAnnouncementOrderByRequest {
    Candidatures = 'CANDIDATURES',
    None = 'NONE',
    Saved = 'SAVED',
    TopTrend = 'TOP_TREND',
}

export type SearchJobAnnouncementRequest = {
    contractType?: InputMaybe<ContractType>;
    distanceInKm?: InputMaybe<Scalars['Int']>;
    duration?: InputMaybe<JobAnnouncentContractDurantion>;
    experienceYears?: InputMaybe<Scalars['Int']>;
    hiringType?: InputMaybe<HiringType>;
    length?: InputMaybe<Scalars['Int']>;
    maxRal?: InputMaybe<Scalars['Int']>;
    minRal?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<SearchJobAnnouncementOrderByRequest>;
    ownerId?: InputMaybe<Scalars['String']>;
    qualificationId?: InputMaybe<Scalars['String']>;
    state?: InputMaybe<SearchJobAnnouncementStatus>;
    title?: InputMaybe<Scalars['String']>;
    workHome?: InputMaybe<Scalars['Boolean']>;
};

export type SearchJobAnnouncementResponse = {
    __typename?: 'SearchJobAnnouncementResponse';
    count?: Maybe<Scalars['Int']>;
    jobAnnouncements?: Maybe<Array<Maybe<JobAnnouncement>>>;
};

export enum SearchJobAnnouncementStatus {
    Active = 'ACTIVE',
    All = 'ALL',
    Draft = 'DRAFT',
    Expired = 'EXPIRED',
}

export type SearchKeywordRequest = {
    length?: InputMaybe<Scalars['Int']>;
    name: Scalars['String'];
    offset?: InputMaybe<Scalars['Int']>;
};

export type SearchKeywordResponse = {
    __typename?: 'SearchKeywordResponse';
    data?: Maybe<Array<Maybe<Keyword>>>;
};

export type SearchLabelInput = {
    companyId?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['ID']>;
    limit?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
    offset?: InputMaybe<Scalars['Int']>;
};

export type SearchSavedSearchesInput = {
    companyId?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
    jobAnnouncementId?: InputMaybe<Scalars['String']>;
    length?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
    orderBy?: InputMaybe<Scalars['String']>;
    teamlistId?: InputMaybe<Scalars['String']>;
};

export type SetManyCandidatesAsAcceptedInput = {
    jobAnnouncementId: Scalars['ID'];
    references?: InputMaybe<Array<InputMaybe<CvReference>>>;
};

export type SetManyCandidatesAsPreferredInput = {
    jobAnnouncementId: Scalars['ID'];
    references?: InputMaybe<Array<InputMaybe<CvReference>>>;
};

export enum SharingCompanyRuleType {
    Curriculum = 'CURRICULUM',
    Feedback = 'FEEDBACK',
}

export enum SharingCompanyRuleVisibility {
    Active = 'ACTIVE',
    None = 'NONE',
    Passive = 'PASSIVE',
    Reciprocal = 'RECIPROCAL',
    Revoked = 'REVOKED',
    ToBeAccepted = 'TO_BE_ACCEPTED',
}

export type Skill = {
    __typename?: 'Skill';
    custom?: Maybe<Scalars['Boolean']>;
    des: Scalars['String'];
    id: Scalars['ID'];
    type?: Maybe<SkillType>;
};

export type SkillType = {
    __typename?: 'SkillType';
    custom?: Maybe<Scalars['Int']>;
    des: Scalars['String'];
    id: Scalars['ID'];
};

export type Social = {
    __typename?: 'Social';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type SocialInsertRequest = {
    name?: InputMaybe<Scalars['String']>;
    value?: InputMaybe<Scalars['String']>;
};

export enum SocialNames {
    Facebook = 'FACEBOOK',
    Instagram = 'INSTAGRAM',
    Linkedin = 'LINKEDIN',
    Twitter = 'TWITTER',
    Youtube = 'YOUTUBE',
}

export type SocialUpdateRequest = {
    id?: InputMaybe<Scalars['ID']>;
    name?: InputMaybe<Scalars['String']>;
    value?: InputMaybe<Scalars['String']>;
};

export type Student = {
    __typename?: 'Student';
    courseId?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    motivation?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    photo?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    studentId?: Maybe<Scalars['String']>;
    surname?: Maybe<Scalars['String']>;
};

export enum StudentCourseStatus {
    Abandoned = 'ABANDONED',
    Completed = 'COMPLETED',
    Denied = 'DENIED',
    Revoked = 'REVOKED',
    Subscribed = 'SUBSCRIBED',
    Waiting = 'WAITING',
}

export type Subscription = {
    __typename?: 'Subscription';
    CompanyUploadCvChange?: Maybe<CompanyUploadCvSubscription>;
    UserNotifications?: Maybe<UserNotificationSubscription>;
    sdummy?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionCompanyUploadCvChangeArgs = {
    userId?: InputMaybe<Scalars['String']>;
};

export type SubscriptionUserNotificationsArgs = {
    userId?: InputMaybe<Scalars['String']>;
};

export type Survey = {
    __typename?: 'Survey';
    id: Scalars['ID'];
    orderby?: Maybe<Scalars['Int']>;
    questions?: Maybe<Array<Maybe<SurveyQuestion>>>;
    releaseId?: Maybe<Scalars['String']>;
    title: Scalars['String'];
};

export type SurveyInput = {
    id: Scalars['ID'];
    value: Scalars['Float'];
};

export type SurveyQuestion = {
    __typename?: 'SurveyQuestion';
    code?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    label?: Maybe<Scalars['String']>;
    orderby?: Maybe<Scalars['Int']>;
    proposals?: Maybe<Array<Maybe<SurveyQuestionProposal>>>;
    sublabel?: Maybe<Scalars['String']>;
    surveyId?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
    unitmis?: Maybe<Scalars['String']>;
};

export type SurveyQuestionProposal = {
    __typename?: 'SurveyQuestionProposal';
    currentUserAnswer?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    label?: Maybe<Scalars['String']>;
    maxlabel?: Maybe<Scalars['String']>;
    maxvalue?: Maybe<Scalars['Float']>;
    minlabel?: Maybe<Scalars['String']>;
    minvalue?: Maybe<Scalars['Float']>;
    orderby?: Maybe<Scalars['Int']>;
    preselectedmaxvalue?: Maybe<Scalars['Float']>;
    preselectedminvalue?: Maybe<Scalars['Float']>;
    value?: Maybe<Scalars['Int']>;
};

export enum TalentType {
    Cv = 'CV',
    User = 'USER',
}

export type Teacher = {
    __typename?: 'Teacher';
    courseId?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    photo?: Maybe<Scalars['String']>;
    surname?: Maybe<Scalars['String']>;
    teacherId?: Maybe<Scalars['String']>;
};

export type TermCondition = {
    __typename?: 'TermCondition';
    content?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    title?: Maybe<Scalars['String']>;
};

export enum TermConditionType {
    Company = 'COMPANY',
    User = 'USER',
}

export type UpdateCompanyRelationShareInput = {
    dataVisibility?: InputMaybe<SharingCompanyRuleVisibility>;
    id: Scalars['ID'];
    requesterCompanyId: Scalars['String'];
};

export type UpdateCompanyRequest = {
    biografy?: InputMaybe<Scalars['String']>;
    businessAreaId?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<CompanyCountry>;
    coverImage?: InputMaybe<Scalars['ID']>;
    email?: InputMaybe<Scalars['String']>;
    expertiseAreas?: InputMaybe<Array<InputMaybe<CompanyExpertiseAreaInput>>>;
    id: Scalars['ID'];
    isApproved?: InputMaybe<Scalars['Boolean']>;
    language_id?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    privacyUrl?: InputMaybe<Scalars['String']>;
    quarters?: InputMaybe<Array<InputMaybe<CompanyQuarterUpdateRequest>>>;
    services?: InputMaybe<Array<InputMaybe<CompanyServiceInput>>>;
    socials?: InputMaybe<Array<InputMaybe<SocialUpdateRequest>>>;
    vatNumber?: InputMaybe<Scalars['String']>;
    website?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyUploadCvGroup = {
    companyId: Scalars['ID'];
    dashboardType?: InputMaybe<CompanyUploadDashboardType>;
    description: Scalars['String'];
    id: Scalars['ID'];
    numberUploads: Scalars['Int'];
};

export type UpdateFolderInput = {
    description?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    parentId?: InputMaybe<Scalars['String']>;
    qualifications?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
    skills?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UpdateLabelInput = {
    description?: InputMaybe<Scalars['String']>;
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    parentId?: InputMaybe<Scalars['String']>;
};

export type UploadFileInput = {
    destination: MediaTypes;
    file: Scalars['Upload'];
    fileLabel?: InputMaybe<Scalars['String']>;
    owner?: InputMaybe<Scalars['ID']>;
    ownerType?: InputMaybe<EntityType>;
};

export type User = {
    __typename?: 'User';
    actionsToDo?: Maybe<Array<Maybe<UserToDoAction>>>;
    authorization_advertising?: Maybe<Scalars['Boolean']>;
    authorization_marketing?: Maybe<Scalars['Boolean']>;
    biografy?: Maybe<Scalars['String']>;
    birth_date?: Maybe<Scalars['String']>;
    businessarea?: Maybe<Businessarea>;
    certificatesAchieved?: Maybe<Array<Maybe<GeneralInfo>>>;
    certificationsAchieved?: Maybe<Array<Maybe<GeneralInfo>>>;
    city_residence?: Maybe<Scalars['String']>;
    currentAppVisualization?: Maybe<CurrentAppVisualization>;
    curriculum?: Maybe<Media>;
    curriculumVideo?: Maybe<Media>;
    educational?: Maybe<Educational>;
    email?: Maybe<Scalars['String']>;
    hash_code?: Maybe<Scalars['String']>;
    hobbies?: Maybe<Array<Maybe<GeneralInfo>>>;
    id?: Maybe<Scalars['ID']>;
    languageId?: Maybe<PlatformLanguages>;
    like_offers_x_km?: Maybe<Scalars['Int']>;
    like_show_on_profile?: Maybe<Scalars['Boolean']>;
    like_work_home?: Maybe<Scalars['Boolean']>;
    like_work_my_city?: Maybe<Scalars['Boolean']>;
    like_work_other_city?: Maybe<Scalars['Boolean']>;
    like_work_other_city_1?: Maybe<Scalars['String']>;
    like_work_other_city_2?: Maybe<Scalars['String']>;
    like_work_other_city_3?: Maybe<Scalars['String']>;
    like_work_other_state?: Maybe<Scalars['Boolean']>;
    like_work_other_state_1?: Maybe<Scalars['String']>;
    like_work_other_state_2?: Maybe<Scalars['String']>;
    like_work_other_state_3?: Maybe<Scalars['String']>;
    maxDistanceForDisponibilityWork?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    note?: Maybe<Scalars['String']>;
    onboarding_user_step?: Maybe<Scalars['String']>;
    opportunity_freelance?: Maybe<Scalars['Boolean']>;
    opportunity_hiring?: Maybe<Scalars['Boolean']>;
    opportunity_less_than_1_year?: Maybe<Scalars['Boolean']>;
    opportunity_more_than_1_year?: Maybe<Scalars['Boolean']>;
    personalities?: Maybe<Array<Maybe<Personality>>>;
    phone?: Maybe<Scalars['String']>;
    photo?: Maybe<Media>;
    qualifications?: Maybe<Array<Maybe<UserQualification>>>;
    screenshot?: Maybe<Media>;
    show_defect_personality_on_profile?: Maybe<Scalars['Boolean']>;
    show_merit_personality_on_profile?: Maybe<Scalars['Boolean']>;
    skills?: Maybe<Array<Maybe<Skill>>>;
    socials?: Maybe<Array<Maybe<Social>>>;
    spokenLanguages?: Maybe<Array<Maybe<GeneralInfo>>>;
    surname?: Maybe<Scalars['String']>;
    token?: Maybe<Scalars['String']>;
    userType?: Maybe<Scalars['String']>;
    website?: Maybe<Scalars['String']>;
    workState?: Maybe<UserWorkState>;
};

export type UserListSearchParam = {
    __typename?: 'UserListSearchParam';
    qualifications?: Maybe<Array<Maybe<Qualification>>>;
    skills?: Maybe<Array<Maybe<Skill>>>;
};

export type UserMinimalPublic = {
    __typename?: 'UserMinimalPublic';
    businessName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    surname?: Maybe<Scalars['String']>;
};

export type UserNotification = {
    __typename?: 'UserNotification';
    companyId?: Maybe<Scalars['String']>;
    creationDate?: Maybe<Scalars['String']>;
    priority?: Maybe<Priority>;
    referenceId?: Maybe<Scalars['String']>;
    referenceType?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type UserNotificationSubscription = {
    __typename?: 'UserNotificationSubscription';
    data?: Maybe<UserNotification>;
    mutation: Scalars['String'];
    userId: Scalars['String'];
};

export type UserPersonalityInput = {
    id: Scalars['ID'];
};

export type UserPublic = {
    __typename?: 'UserPublic';
    biografy?: Maybe<Scalars['String']>;
    birth_date?: Maybe<Scalars['String']>;
    businessarea?: Maybe<Businessarea>;
    city_residence?: Maybe<Scalars['String']>;
    educational?: Maybe<Educational>;
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    languageId?: Maybe<PlatformLanguages>;
    name?: Maybe<Scalars['String']>;
    personalities?: Maybe<Array<Maybe<Personality>>>;
    phone?: Maybe<Scalars['String']>;
    photo?: Maybe<Media>;
    qualifications?: Maybe<Array<Maybe<UserQualification>>>;
    screenshot?: Maybe<Media>;
    skills?: Maybe<Array<Maybe<Skill>>>;
    socials?: Maybe<Array<Maybe<Social>>>;
    spokenLanguages?: Maybe<Array<Maybe<GeneralInfo>>>;
    surname?: Maybe<Scalars['String']>;
    userType?: Maybe<Scalars['String']>;
    website?: Maybe<Scalars['String']>;
};

export type UserQualification = {
    __typename?: 'UserQualification';
    experienceInYears?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
    qualification?: Maybe<Qualification>;
};

export type UserQualificationInput = {
    experienceInYears?: InputMaybe<Scalars['Int']>;
    id?: InputMaybe<Scalars['ID']>;
    qualificationId: Scalars['ID'];
};

export type UserSkillInput = {
    id: Scalars['ID'];
};

export type UserToDoAction = {
    __typename?: 'UserToDoAction';
    message?: Maybe<Scalars['String']>;
    priority?: Maybe<Scalars['String']>;
    type?: Maybe<Scalars['String']>;
};

export enum UserType {
    Talent = 'TALENT',
    User = 'USER',
}

export enum UserWorkState {
    NotSetted = 'NOT_SETTED',
    OpenToWork = 'OPEN_TO_WORK',
}

export type AddCompanyUploadCvResponse = {
    __typename?: 'addCompanyUploadCVResponse';
    fileName?: Maybe<Scalars['String']>;
    group?: Maybe<CompanyUploadCvGroup>;
    requestId?: Maybe<Scalars['ID']>;
};

export type CreateCompanyTeamListRequest = {
    companyId?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type InsertSavedSearchInput = {
    companyId: Scalars['String'];
    name: Scalars['String'];
    query: CompanySavedSearchQuery;
    references?: InputMaybe<Array<InputMaybe<CvReferenceWithCorrespondence>>>;
    teamlistId?: InputMaybe<Scalars['String']>;
    visualization?: InputMaybe<SavedSearchVisualizationInput>;
};

export type InsertSavedTalentInput = {
    companyId: Scalars['String'];
    reference?: InputMaybe<CvReference>;
};

export type InsertSavedTalentsInput = {
    companyId: Scalars['String'];
    references?: InputMaybe<Array<InputMaybe<CvReference>>>;
};

export enum OnBoardingUser {
    Birthday = 'birthday',
    City = 'city',
    Competences = 'competences',
    Completed = 'completed',
    DefineMyselfDefects = 'defineMyselfDefects',
    DefineMyselfMerits = 'defineMyselfMerits',
    IntroduceMyself = 'introduceMyself',
    Name = 'name',
    Privacy = 'privacy',
    ProfessionalQualification = 'professionalQualification',
    ShowOpportunities = 'showOpportunities',
    Terms = 'terms',
    UserType = 'userType',
    WorkPreference = 'workPreference',
}

export type SearchSavedSearchesOutput = {
    __typename?: 'searchSavedSearchesOutput';
    count: Scalars['Int'];
    data?: Maybe<Array<Maybe<SavedSearch>>>;
};

export type UpdateCompanyTeamListRequest = {
    description: Scalars['String'];
    id: Scalars['String'];
    name?: InputMaybe<Scalars['String']>;
};

export type UpdateSavedSearchInput = {
    id: Scalars['ID'];
    name?: InputMaybe<Scalars['String']>;
    query: CompanySavedSearchQuery;
    teamlistId?: InputMaybe<Scalars['String']>;
    visualization?: InputMaybe<SavedSearchVisualizationInput>;
};

export type AcceptPrivacyMutationVariables = Exact<{
    privacyId: Scalars['ID'];
}>;

export type AcceptPrivacyMutation = {
    __typename?: 'Mutation';
    acceptPrivacy?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type AcceptRequestForCompanyRelationShareMutationVariables = Exact<{
    input: HandleCompanyRelationShareInput;
}>;

export type AcceptRequestForCompanyRelationShareMutation = {
    __typename?: 'Mutation';
    acceptRequestForCompanyRelationShare?: boolean | null;
};

export type AcceptTermConditionMutationVariables = Exact<{
    termConditionId: Scalars['ID'];
}>;

export type AcceptTermConditionMutation = {
    __typename?: 'Mutation';
    acceptTermCondition?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type ActiveCompanyUploadCvMutationVariables = Exact<{
    reference: CvReference;
    companyId: Scalars['String'];
}>;

export type ActiveCompanyUploadCvMutation = {
    __typename?: 'Mutation';
    activeCompanyUploadCV?: string | null;
};

export type ActiveManyCompanyUploadCvMutationVariables = Exact<{
    references: Array<InputMaybe<CvReference>> | InputMaybe<CvReference>;
    companyId: Scalars['String'];
}>;

export type ActiveManyCompanyUploadCvMutation = {
    __typename?: 'Mutation';
    activeManyCompanyUploadCV?: Array<string | null> | null;
};

export type ActiveOpenHrMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type ActiveOpenHrMutation = {
    __typename?: 'Mutation';
    activeOpenHr?: boolean | null;
};

export type AddBulkTalentsToSavedSearchMutationVariables = Exact<{
    searchId: Scalars['ID'];
    references?: InputMaybe<
        | Array<InputMaybe<CvReferenceWithCorrespondence>>
        | InputMaybe<CvReferenceWithCorrespondence>
    >;
    uploadGroupId?: InputMaybe<Scalars['ID']>;
}>;

export type AddBulkTalentsToSavedSearchMutation = {
    __typename?: 'Mutation';
    addBulkTalentsToSavedSearch?: {
        __typename?: 'SavedSearch';
        id?: string | null;
        name?: string | null;
        creationDate?: string | null;
        query?: {
            __typename?: 'SavedSearchQueryOutput';
            state?: CompanyCvState | null;
            searchId?: string | null;
            groupId?: string | null;
            companyId?: string | null;
            keywords?: Array<string | null> | null;
            skills?: Array<string | null> | null;
            hiringType?: HiringType | null;
            origin?: SearchCompanyCvOrigin | null;
            locationType?: LocationType | null;
            locationCity?: string | null;
            qualifications?: Array<string | null> | null;
            fullName?: string | null;
        } | null;
        visualization?: {
            __typename?: 'SavedSearchVisualizationOutput';
            row?: number | null;
            skills?: Array<string | null> | null;
            workLoad?: number | null;
            activity?: string | null;
            responsability?: string | null;
            daily_fare?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            experience?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null;
        results?: Array<{
            __typename?: 'SavedSearchResult';
            id?: string | null;
            state?: CompanySavedSearchResultState | null;
            data?: {
                __typename?: 'CompanySearchCvOutput';
                id?: string | null;
                fullName?: string | null;
                rating?: number | null;
                fileName?: string | null;
                state?: CompanyCvState | null;
                processNames?: Array<string | null> | null;
                numberOfRatings?: number | null;
                primaryQualification?: string | null;
                lastUpdateDate?: string | null;
                uploadFileDate?: string | null;
                savedBy?: number | null;
                type?: string | null;
                isPreferred?: boolean | null;
                origin?: SearchCompanyCvOrigin | null;
                correspondence?: number | null;
                keywords?: Array<string | null> | null;
                visibilityState?: CompanyCvVisibilityState | null;
                tags?: Array<{
                    __typename?: 'Label';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    isDefault?: boolean | null;
                    description?: string | null;
                } | null> | null;
                folders?: Array<{
                    __typename?: 'Folder';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    description?: string | null;
                    qualifications?: Array<{
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null> | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                } | null> | null;
                qualification?: {
                    __typename?: 'CompanySearchCvOutputQualification';
                    name?: string | null;
                    translations?: Array<{
                        __typename?: 'CompanySearchCvOutputQualificationTranslation';
                        id?: string | null;
                        value?: string | null;
                    } | null> | null;
                } | null;
                handledBy?: {
                    __typename?: 'CompanySearchCvHandlingOutput';
                    id?: string | null;
                    companyName?: string | null;
                    companyImage?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};

export type AddCandidacyMutationVariables = Exact<{
    input: JobAnnouncementAddCandidacyInput;
}>;

export type AddCandidacyMutation = {
    __typename?: 'Mutation';
    addCandidacy?: string | null;
};

export type AddCompanyUploadCvMutationVariables = Exact<{
    input?: InputMaybe<InsertCompanyUploadCv>;
}>;

export type AddCompanyUploadCvMutation = {
    __typename?: 'Mutation';
    addCompanyUploadCV?: {
        __typename?: 'addCompanyUploadCVResponse';
        fileName?: string | null;
        requestId?: string | null;
        group?: {
            __typename?: 'CompanyUploadCVGroup';
            id?: string | null;
            status?: CompanyUploadGroupStatus | null;
            uploadCount?: number | null;
            description?: string | null;
            creationDate?: string | null;
            dashboardType?: CompanyUploadDashboardType | null;
            resume?: Array<{
                __typename?: 'ResumeStatusFiles';
                status?: CompanyCvProcessStatus | null;
                actual?: number | null;
            } | null> | null;
            uploads?: Array<{
                __typename?: 'CompanyUploadCV';
                id?: string | null;
                url?: string | null;
                name?: string | null;
                creationDate?: string | null;
                processNames?: Array<string | null> | null;
                processStatus?: CompanyCvProcessStatus | null;
            } | null> | null;
        } | null;
    } | null;
};

export type AddCvToSavedSearchMutationVariables = Exact<{
    searchId: Scalars['ID'];
    id: Scalars['String'];
    correspondence?: InputMaybe<Scalars['Int']>;
}>;

export type AddCvToSavedSearchMutation = {
    __typename?: 'Mutation';
    addCvToSavedSearch?: {
        __typename?: 'SavedSearch';
        id?: string | null;
        name?: string | null;
        creationDate?: string | null;
        query?: {
            __typename?: 'SavedSearchQueryOutput';
            state?: CompanyCvState | null;
            searchId?: string | null;
            groupId?: string | null;
            companyId?: string | null;
            keywords?: Array<string | null> | null;
            skills?: Array<string | null> | null;
            hiringType?: HiringType | null;
            origin?: SearchCompanyCvOrigin | null;
            locationType?: LocationType | null;
            locationCity?: string | null;
            qualifications?: Array<string | null> | null;
            fullName?: string | null;
        } | null;
        visualization?: {
            __typename?: 'SavedSearchVisualizationOutput';
            row?: number | null;
            skills?: Array<string | null> | null;
            workLoad?: number | null;
            activity?: string | null;
            responsability?: string | null;
            daily_fare?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            experience?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null;
        results?: Array<{
            __typename?: 'SavedSearchResult';
            id?: string | null;
            state?: CompanySavedSearchResultState | null;
            data?: {
                __typename?: 'CompanySearchCvOutput';
                id?: string | null;
                fullName?: string | null;
                rating?: number | null;
                fileName?: string | null;
                state?: CompanyCvState | null;
                processNames?: Array<string | null> | null;
                numberOfRatings?: number | null;
                primaryQualification?: string | null;
                lastUpdateDate?: string | null;
                uploadFileDate?: string | null;
                savedBy?: number | null;
                type?: string | null;
                isPreferred?: boolean | null;
                origin?: SearchCompanyCvOrigin | null;
                correspondence?: number | null;
                keywords?: Array<string | null> | null;
                visibilityState?: CompanyCvVisibilityState | null;
                tags?: Array<{
                    __typename?: 'Label';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    isDefault?: boolean | null;
                    description?: string | null;
                } | null> | null;
                folders?: Array<{
                    __typename?: 'Folder';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    description?: string | null;
                    qualifications?: Array<{
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null> | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                } | null> | null;
                qualification?: {
                    __typename?: 'CompanySearchCvOutputQualification';
                    name?: string | null;
                    translations?: Array<{
                        __typename?: 'CompanySearchCvOutputQualificationTranslation';
                        id?: string | null;
                        value?: string | null;
                    } | null> | null;
                } | null;
                handledBy?: {
                    __typename?: 'CompanySearchCvHandlingOutput';
                    id?: string | null;
                    companyName?: string | null;
                    companyImage?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};

export type AddMeToCompanyMutationVariables = Exact<{
    role: CompanyEmployeeRole;
    companyId: Scalars['ID'];
}>;

export type AddMeToCompanyMutation = {
    __typename?: 'Mutation';
    addMeToCompany?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type AddUserToCompanyMutationVariables = Exact<{
    role: CompanyEmployeeRole;
    userId: Scalars['ID'];
    companyId: Scalars['ID'];
}>;

export type AddUserToCompanyMutation = {
    __typename?: 'Mutation';
    addUserToCompany?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type AddUserToSavedSearchMutationVariables = Exact<{
    searchId: Scalars['ID'];
    id: Scalars['String'];
    correspondence?: InputMaybe<Scalars['Int']>;
}>;

export type AddUserToSavedSearchMutation = {
    __typename?: 'Mutation';
    addUserToSavedSearch?: {
        __typename?: 'SavedSearch';
        id?: string | null;
        name?: string | null;
        creationDate?: string | null;
        query?: {
            __typename?: 'SavedSearchQueryOutput';
            state?: CompanyCvState | null;
            searchId?: string | null;
            groupId?: string | null;
            companyId?: string | null;
            keywords?: Array<string | null> | null;
            skills?: Array<string | null> | null;
            hiringType?: HiringType | null;
            origin?: SearchCompanyCvOrigin | null;
            locationType?: LocationType | null;
            locationCity?: string | null;
            qualifications?: Array<string | null> | null;
            fullName?: string | null;
        } | null;
        visualization?: {
            __typename?: 'SavedSearchVisualizationOutput';
            row?: number | null;
            skills?: Array<string | null> | null;
            workLoad?: number | null;
            activity?: string | null;
            responsability?: string | null;
            daily_fare?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            experience?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null;
        results?: Array<{
            __typename?: 'SavedSearchResult';
            id?: string | null;
            state?: CompanySavedSearchResultState | null;
            data?: {
                __typename?: 'CompanySearchCvOutput';
                id?: string | null;
                fullName?: string | null;
                rating?: number | null;
                fileName?: string | null;
                state?: CompanyCvState | null;
                processNames?: Array<string | null> | null;
                numberOfRatings?: number | null;
                primaryQualification?: string | null;
                lastUpdateDate?: string | null;
                uploadFileDate?: string | null;
                savedBy?: number | null;
                type?: string | null;
                isPreferred?: boolean | null;
                origin?: SearchCompanyCvOrigin | null;
                correspondence?: number | null;
                keywords?: Array<string | null> | null;
                visibilityState?: CompanyCvVisibilityState | null;
                tags?: Array<{
                    __typename?: 'Label';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    isDefault?: boolean | null;
                    description?: string | null;
                } | null> | null;
                folders?: Array<{
                    __typename?: 'Folder';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    description?: string | null;
                    qualifications?: Array<{
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null> | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                } | null> | null;
                qualification?: {
                    __typename?: 'CompanySearchCvOutputQualification';
                    name?: string | null;
                    translations?: Array<{
                        __typename?: 'CompanySearchCvOutputQualificationTranslation';
                        id?: string | null;
                        value?: string | null;
                    } | null> | null;
                } | null;
                handledBy?: {
                    __typename?: 'CompanySearchCvHandlingOutput';
                    id?: string | null;
                    companyName?: string | null;
                    companyImage?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};

export type ArchiveCompanyUploadCvMutationVariables = Exact<{
    reference: CvReference;
    companyId: Scalars['String'];
}>;

export type ArchiveCompanyUploadCvMutation = {
    __typename?: 'Mutation';
    archiveCompanyUploadCV?: string | null;
};

export type ArchiveManyCompanyUploadCvMutationVariables = Exact<{
    references: Array<InputMaybe<CvReference>> | InputMaybe<CvReference>;
    companyId: Scalars['String'];
}>;

export type ArchiveManyCompanyUploadCvMutation = {
    __typename?: 'Mutation';
    archiveManyCompanyUploadCV?: Array<string | null> | null;
};

export type AssignFoldersToTalentsMutationVariables = Exact<{
    input:
        | Array<InputMaybe<AssignFoldersToTalentInput>>
        | InputMaybe<AssignFoldersToTalentInput>;
}>;

export type AssignFoldersToTalentsMutation = {
    __typename?: 'Mutation';
    assignFoldersToTalents?: boolean | null;
};

export type AssignLabelsToTalentsMutationVariables = Exact<{
    input:
        | Array<InputMaybe<AssignLabelsToTalentInput>>
        | InputMaybe<AssignLabelsToTalentInput>;
}>;

export type AssignLabelsToTalentsMutation = {
    __typename?: 'Mutation';
    assignLabelsToTalents?: boolean | null;
};

export type CandidateMeMutationVariables = Exact<{
    jobAnnouncementId: Scalars['ID'];
}>;

export type CandidateMeMutation = {
    __typename?: 'Mutation';
    candidateMe?: boolean | null;
};

export type ChangeUserRoleInCompanyMutationVariables = Exact<{
    relationId: Scalars['ID'];
    role: CompanyEmployeeRole;
}>;

export type ChangeUserRoleInCompanyMutation = {
    __typename?: 'Mutation';
    changeUserRoleInCompany?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type ConfirmUserToCompanyMutationVariables = Exact<{
    relationId: Scalars['ID'];
}>;

export type ConfirmUserToCompanyMutation = {
    __typename?: 'Mutation';
    confirmUserToCompany?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type CreateCompanyTeamListMutationVariables = Exact<{
    input?: InputMaybe<CreateCompanyTeamListRequest>;
}>;

export type CreateCompanyTeamListMutation = {
    __typename?: 'Mutation';
    createCompanyTeamList?: {
        __typename?: 'CompanyTeamList';
        id: string;
        name?: string | null;
        description?: string | null;
        lastUpdate?: string | null;
        creationDate?: string | null;
        savedSearches?: Array<{
            __typename?: 'CompanyTeamListSavedSearch';
            id?: string | null;
            name?: string | null;
            query?: {
                __typename?: 'SavedSearchQueryOutput';
                state?: CompanyCvState | null;
                searchId?: string | null;
                groupId?: string | null;
                companyId?: string | null;
                keywords?: Array<string | null> | null;
                skills?: Array<string | null> | null;
                hiringType?: HiringType | null;
                origin?: SearchCompanyCvOrigin | null;
                locationType?: LocationType | null;
                locationCity?: string | null;
                qualifications?: Array<string | null> | null;
                fullName?: string | null;
            } | null;
            visualization?: {
                __typename?: 'SavedSearchVisualizationOutput';
                row?: number | null;
                skills?: Array<string | null> | null;
                workLoad?: number | null;
                activity?: string | null;
                responsability?: string | null;
                daily_fare?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                experience?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                qualification?: {
                    __typename?: 'Qualification';
                    id: string;
                    des: string;
                    type?: {
                        __typename?: 'QualificationType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null;
            } | null;
            results?: Array<{
                __typename?: 'CompanyTeamListSavedSearchResult';
                percentUsage?: number | null;
                talent?: {
                    __typename?: 'CompanySearchCvOutput';
                    id?: string | null;
                    fullName?: string | null;
                    rating?: number | null;
                    fileName?: string | null;
                    state?: CompanyCvState | null;
                    processNames?: Array<string | null> | null;
                    numberOfRatings?: number | null;
                    primaryQualification?: string | null;
                    lastUpdateDate?: string | null;
                    uploadFileDate?: string | null;
                    savedBy?: number | null;
                    type?: string | null;
                    isPreferred?: boolean | null;
                    origin?: SearchCompanyCvOrigin | null;
                    correspondence?: number | null;
                    keywords?: Array<string | null> | null;
                    visibilityState?: CompanyCvVisibilityState | null;
                    tags?: Array<{
                        __typename?: 'Label';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        isDefault?: boolean | null;
                        description?: string | null;
                    } | null> | null;
                    folders?: Array<{
                        __typename?: 'Folder';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        description?: string | null;
                    } | null> | null;
                    qualification?: {
                        __typename?: 'CompanySearchCvOutputQualification';
                        name?: string | null;
                    } | null;
                    handledBy?: {
                        __typename?: 'CompanySearchCvHandlingOutput';
                        id?: string | null;
                        companyName?: string | null;
                        companyImage?: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type CreateCompanyUploadCvGroupMutationVariables = Exact<{
    input?: InputMaybe<InsertCompanyUploadCvGroup>;
}>;

export type CreateCompanyUploadCvGroupMutation = {
    __typename?: 'Mutation';
    createCompanyUploadCVGroup?: {
        __typename?: 'CompanyUploadCVGroup';
        id?: string | null;
        status?: CompanyUploadGroupStatus | null;
        uploadCount?: number | null;
        description?: string | null;
        creationDate?: string | null;
        dashboardType?: CompanyUploadDashboardType | null;
        resume?: Array<{
            __typename?: 'ResumeStatusFiles';
            status?: CompanyCvProcessStatus | null;
            actual?: number | null;
        } | null> | null;
        uploads?: Array<{
            __typename?: 'CompanyUploadCV';
            id?: string | null;
            url?: string | null;
            name?: string | null;
            creationDate?: string | null;
            processNames?: Array<string | null> | null;
            processStatus?: CompanyCvProcessStatus | null;
        } | null> | null;
    } | null;
};

export type CreateHiringInterviewMutationVariables = Exact<{
    hiringprocessId?: InputMaybe<Scalars['String']>;
    date1?: InputMaybe<Scalars['String']>;
    date2?: InputMaybe<Scalars['String']>;
    date3?: InputMaybe<Scalars['String']>;
    time1?: InputMaybe<Scalars['String']>;
    time2?: InputMaybe<Scalars['String']>;
    time3?: InputMaybe<Scalars['String']>;
    message: Scalars['String'];
}>;

export type CreateHiringInterviewMutation = {
    __typename?: 'Mutation';
    createHiringInterview?: string | null;
};

export type CreateHiringProcessMutationVariables = Exact<{
    userId?: InputMaybe<Scalars['String']>;
    target_reference_id: Scalars['String'];
    target_reference_type: Scalars['String'];
    curriculumEmail: Scalars['String'];
    processDescription?: InputMaybe<Scalars['String']>;
    date1: Scalars['String'];
    date2?: InputMaybe<Scalars['String']>;
    date3?: InputMaybe<Scalars['String']>;
    time1: Scalars['String'];
    time2?: InputMaybe<Scalars['String']>;
    time3?: InputMaybe<Scalars['String']>;
    message?: InputMaybe<Scalars['String']>;
}>;

export type CreateHiringProcessMutation = {
    __typename?: 'Mutation';
    createHiringProcess?: string | null;
};

export type CreateHiringProposalMutationVariables = Exact<{
    hiringinterviewId?: InputMaybe<Scalars['String']>;
    date1: Scalars['String'];
    date2?: InputMaybe<Scalars['String']>;
    date3?: InputMaybe<Scalars['String']>;
    time1: Scalars['String'];
    time2?: InputMaybe<Scalars['String']>;
    time3?: InputMaybe<Scalars['String']>;
    message: Scalars['String'];
}>;

export type CreateHiringProposalMutation = {
    __typename?: 'Mutation';
    createHiringProposal?: string | null;
};

export type CreateLabelMutationVariables = Exact<{
    name?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    parentId?: InputMaybe<Scalars['String']>;
    companyId?: InputMaybe<Scalars['String']>;
}>;

export type CreateLabelMutation = {
    __typename?: 'Mutation';
    createLabel?: {
        __typename?: 'Label';
        id?: string | null;
        name?: string | null;
        parentId?: string | null;
        isDefault?: boolean | null;
        description?: string | null;
    } | null;
};

export type DeleteCompanyTeamListMutationVariables = Exact<{
    id: Scalars['String'];
}>;

export type DeleteCompanyTeamListMutation = {
    __typename?: 'Mutation';
    deleteCompanyTeamList?: boolean | null;
};

export type DeleteCompanyUploadCvMutationVariables = Exact<{
    reference: CvReference;
    companyId: Scalars['String'];
}>;

export type DeleteCompanyUploadCvMutation = {
    __typename?: 'Mutation';
    deleteCompanyUploadCV?: string | null;
};

export type DeleteEducationCourseMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteEducationCourseMutation = {
    __typename?: 'Mutation';
    deleteEducationCourse?: {
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null;
};

export type DeleteEducationLessonMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteEducationLessonMutation = {
    __typename?: 'Mutation';
    deleteEducationLesson?: boolean | null;
};

export type DeleteEducationStudentMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteEducationStudentMutation = {
    __typename?: 'Mutation';
    deleteEducationStudent?: boolean | null;
};

export type DeleteEducationTeacherMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteEducationTeacherMutation = {
    __typename?: 'Mutation';
    deleteEducationTeacher?: boolean | null;
};

export type DeleteFolderMutationVariables = Exact<{
    id?: InputMaybe<Scalars['String']>;
}>;

export type DeleteFolderMutation = {
    __typename?: 'Mutation';
    deleteFolder?: boolean | null;
};

export type DeleteLabelMutationVariables = Exact<{
    id?: InputMaybe<Scalars['String']>;
}>;

export type DeleteLabelMutation = {
    __typename?: 'Mutation';
    deleteLabel?: boolean | null;
};

export type DeleteManyCompanyUploadCvMutationVariables = Exact<{
    references: Array<InputMaybe<CvReference>> | InputMaybe<CvReference>;
    companyId: Scalars['String'];
}>;

export type DeleteManyCompanyUploadCvMutation = {
    __typename?: 'Mutation';
    deleteManyCompanyUploadCV?: Array<string | null> | null;
};

export type DeleteMeMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteMeMutation = { __typename?: 'Mutation'; deleteMe?: boolean | null };

export type DeleteQualificationTypeMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteQualificationTypeMutation = {
    __typename?: 'Mutation';
    deleteQualificationType?: boolean | null;
};

export type DeleteSavedSearchMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteSavedSearchMutation = {
    __typename?: 'Mutation';
    deleteSavedSearch?: {
        __typename?: 'SavedSearch';
        id?: string | null;
        name?: string | null;
        creationDate?: string | null;
        query?: {
            __typename?: 'SavedSearchQueryOutput';
            state?: CompanyCvState | null;
            searchId?: string | null;
            groupId?: string | null;
            companyId?: string | null;
            keywords?: Array<string | null> | null;
            skills?: Array<string | null> | null;
            hiringType?: HiringType | null;
            origin?: SearchCompanyCvOrigin | null;
            locationType?: LocationType | null;
            locationCity?: string | null;
            qualifications?: Array<string | null> | null;
            fullName?: string | null;
        } | null;
        visualization?: {
            __typename?: 'SavedSearchVisualizationOutput';
            row?: number | null;
            skills?: Array<string | null> | null;
            workLoad?: number | null;
            activity?: string | null;
            responsability?: string | null;
            daily_fare?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            experience?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null;
        results?: Array<{
            __typename?: 'SavedSearchResult';
            id?: string | null;
            state?: CompanySavedSearchResultState | null;
            data?: {
                __typename?: 'CompanySearchCvOutput';
                id?: string | null;
                fullName?: string | null;
                rating?: number | null;
                fileName?: string | null;
                state?: CompanyCvState | null;
                processNames?: Array<string | null> | null;
                numberOfRatings?: number | null;
                primaryQualification?: string | null;
                lastUpdateDate?: string | null;
                uploadFileDate?: string | null;
                savedBy?: number | null;
                type?: string | null;
                isPreferred?: boolean | null;
                origin?: SearchCompanyCvOrigin | null;
                correspondence?: number | null;
                keywords?: Array<string | null> | null;
                visibilityState?: CompanyCvVisibilityState | null;
                tags?: Array<{
                    __typename?: 'Label';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    isDefault?: boolean | null;
                    description?: string | null;
                } | null> | null;
                folders?: Array<{
                    __typename?: 'Folder';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    description?: string | null;
                    qualifications?: Array<{
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null> | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                } | null> | null;
                qualification?: {
                    __typename?: 'CompanySearchCvOutputQualification';
                    name?: string | null;
                    translations?: Array<{
                        __typename?: 'CompanySearchCvOutputQualificationTranslation';
                        id?: string | null;
                        value?: string | null;
                    } | null> | null;
                } | null;
                handledBy?: {
                    __typename?: 'CompanySearchCvHandlingOutput';
                    id?: string | null;
                    companyName?: string | null;
                    companyImage?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};

export type DeleteSkillMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteSkillMutation = {
    __typename?: 'Mutation';
    deleteSkill?: boolean | null;
};

export type DeleteSkillTypeMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteSkillTypeMutation = {
    __typename?: 'Mutation';
    deleteSkillType?: boolean | null;
};

export type DuplicateEducationCourseMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DuplicateEducationCourseMutation = {
    __typename?: 'Mutation';
    duplicateEducationCourse?: {
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null;
};

export type EditCompanyUploadCvDataElaborationsMutationVariables = Exact<{
    input: EditCompanyUploadCvDataElaborationsInput;
}>;

export type EditCompanyUploadCvDataElaborationsMutation = {
    __typename?: 'Mutation';
    editCompanyUploadCvDataElaborations?: boolean | null;
};

export type EditCompanyUploadCvDataElaborations2MutationVariables = Exact<{
    input: EditCompanyUploadCvDataElaborationsInput2;
}>;

export type EditCompanyUploadCvDataElaborations2Mutation = {
    __typename?: 'Mutation';
    editCompanyUploadCvDataElaborations2?: boolean | null;
};

export type GenerateApiKeyMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GenerateApiKeyMutation = {
    __typename?: 'Mutation';
    generateApiKey?: string | null;
};

export type InsertBulkEducationStudentsMutationVariables = Exact<{
    input?: InputMaybe<
        Array<InputMaybe<EducationStudentInput>> | InputMaybe<EducationStudentInput>
    >;
}>;

export type InsertBulkEducationStudentsMutation = {
    __typename?: 'Mutation';
    insertBulkEducationStudents?: Array<{
        __typename?: 'EducationStudent';
        id?: string | null;
        courseId?: string | null;
        studentId?: string | null;
        studentName?: string | null;
        studentEmail?: string | null;
        status?: StudentCourseStatus | null;
        motivation?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        biografy?: string | null;
        cityResidence?: string | null;
        photo?: string | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        languages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
    } | null> | null;
};

export type InsertCvRatingMutationVariables = Exact<{
    input?: InputMaybe<InsertRatingInsert>;
}>;

export type InsertCvRatingMutation = {
    __typename?: 'Mutation';
    insertCVRating?: {
        __typename?: 'Rating';
        id: string;
        authorFullName?: string | null;
        authorImage?: string | null;
        companyName?: string | null;
        content?: string | null;
        ratings?: Array<{
            __typename?: 'RatingsDescriptionValue';
            value?: number | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type InsertCompanyMutationVariables = Exact<{
    input?: InputMaybe<InsertCompanyRequest>;
}>;

export type InsertCompanyMutation = {
    __typename?: 'Mutation';
    insertCompany?: {
        __typename?: 'Company';
        id: string;
        url: string;
        name: string;
        biografy?: string | null;
        vatNumber?: string | null;
        country?: CompanyCountry | null;
        privacyUrl?: string | null;
        isApproved?: boolean | null;
        hasOpenActive?: boolean | null;
        creationDate?: string | null;
        website?: string | null;
        businessArea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        services?: Array<{
            __typename?: 'CompanyService';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        quarters?: Array<{
            __typename?: 'CompanyQuarter';
            id: string;
            city?: string | null;
            type?: CompanyHeadquarterType | null;
            location?: string | null;
        } | null> | null;
        employees?: Array<{
            __typename?: 'CompanyEmployee';
            id?: string | null;
            role?: string | null;
            state?: string | null;
            user?: {
                __typename?: 'UserPublic';
                id?: string | null;
                email?: string | null;
                name?: string | null;
                surname?: string | null;
                userType?: string | null;
                birth_date?: string | null;
                city_residence?: string | null;
                biografy?: string | null;
                website?: string | null;
                phone?: string | null;
                languageId?: PlatformLanguages | null;
                photo?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                screenshot?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    des: string;
                    custom?: boolean | null;
                    type?: {
                        __typename?: 'SkillType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null> | null;
                socials?: Array<{
                    __typename?: 'Social';
                    id?: string | null;
                    name?: string | null;
                    value?: string | null;
                } | null> | null;
                educational?: {
                    __typename?: 'Educational';
                    id: string;
                    des: string;
                } | null;
                businessarea?: {
                    __typename?: 'Businessarea';
                    id: string;
                    type: BusinessareaType;
                    description: string;
                } | null;
                personalities?: Array<{
                    __typename?: 'Personality';
                    id: string;
                    section: string;
                    des: string;
                } | null> | null;
                qualifications?: Array<{
                    __typename?: 'UserQualification';
                    id: string;
                    experienceInYears?: number | null;
                    qualification?: {
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null;
                } | null> | null;
                spokenLanguages?: Array<{
                    __typename?: 'GeneralInfo';
                    id?: string | null;
                    value?: string | null;
                    description?: string | null;
                } | null> | null;
            } | null;
        } | null> | null;
        expertiseAreas?: Array<{
            __typename?: 'CompanyExpertiseArea';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        contacts?: Array<{
            __typename?: 'CompanyContact';
            type?: string | null;
            value?: string | null;
        } | null> | null;
        statistics?: Array<{
            __typename?: 'CompanyStatistic';
            name?: string | null;
            value?: string | null;
        } | null> | null;
    } | null;
};

export type InsertEducationCourseMutationVariables = Exact<{
    input?: InputMaybe<EducationCourseInsertRequest>;
}>;

export type InsertEducationCourseMutation = {
    __typename?: 'Mutation';
    insertEducationCourse?: {
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null;
};

export type InsertEducationLessonMutationVariables = Exact<{
    input?: InputMaybe<EducationLessonInput>;
}>;

export type InsertEducationLessonMutation = {
    __typename?: 'Mutation';
    insertEducationLesson?: {
        __typename?: 'EducationLesson';
        id?: string | null;
        courseId?: string | null;
        courseTitle?: string | null;
        title?: string | null;
        status?: LessonStatus | null;
        info?: string | null;
        teacherId?: string | null;
        date?: any | null;
        from?: string | null;
        to?: string | null;
        start_url?: string | null;
        join_url?: string | null;
        lesson_url?: string | null;
        teacher?: {
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null;
    } | null;
};

export type InsertEducationStudentMutationVariables = Exact<{
    input?: InputMaybe<EducationStudentInput>;
}>;

export type InsertEducationStudentMutation = {
    __typename?: 'Mutation';
    insertEducationStudent?: {
        __typename?: 'EducationStudent';
        id?: string | null;
        courseId?: string | null;
        studentId?: string | null;
        studentName?: string | null;
        studentEmail?: string | null;
        status?: StudentCourseStatus | null;
        motivation?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        biografy?: string | null;
        cityResidence?: string | null;
        photo?: string | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        languages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
    } | null;
};

export type InsertEducationTeacherMutationVariables = Exact<{
    input?: InputMaybe<EducationTeacherInput>;
}>;

export type InsertEducationTeacherMutation = {
    __typename?: 'Mutation';
    insertEducationTeacher?: {
        __typename?: 'EducationTeacher';
        id?: string | null;
        courseId?: string | null;
        teacherId?: string | null;
        teacherName?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        email?: string | null;
        companyName?: string | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
    } | null;
};

export type InsertFolderMutationVariables = Exact<{
    input: InsertFolderInput;
}>;

export type InsertFolderMutation = {
    __typename?: 'Mutation';
    insertFolder?: {
        __typename?: 'Folder';
        id?: string | null;
        name?: string | null;
        parentId?: string | null;
        description?: string | null;
        qualifications?: Array<{
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
    } | null;
};

export type InsertJobAnnouncementMutationVariables = Exact<{
    input: JobAnnouncementInsertInput;
}>;

export type InsertJobAnnouncementMutation = {
    __typename?: 'Mutation';
    insertJobAnnouncement?: {
        __typename?: 'JobAnnouncement';
        id?: string | null;
        city?: string | null;
        title?: string | null;
        status?: JobAnnouncementStatus | null;
        privacyURL?: string | null;
        description?: string | null;
        duration?: JobAnnouncentContractDurantion | null;
        hiringType?: HiringType | null;
        contractType?: ContractType | null;
        locationType?: LocationType | null;
        expirationDate?: string | null;
        targetCandidateRalMin?: number | null;
        targetCandidateRalMax?: number | null;
        targetExperienceYearsMin?: number | null;
        targetExperienceYearsMax?: number | null;
        isChatActivate?: boolean | null;
        isJobInterviewRequired?: boolean | null;
        isAssessmentRequired?: boolean | null;
        targetAvailability?: JobAnnouncentTargetAvailability | null;
        isTargetAvailableForTransfers?: boolean | null;
        isTargetAvailableForRoleChange?: boolean | null;
        isTargetAvailableForRelocation?: boolean | null;
        candidaturesCount?: number | null;
        referentId?: string | null;
        owner?: {
            __typename?: 'CompanyPublic';
            id: string;
            url: string;
            name: string;
            biografy?: string | null;
            country?: CompanyCountry | null;
            vatNumber?: string | null;
            isApproved?: boolean | null;
            website?: string | null;
            creationDate?: string | null;
            businessArea?: {
                __typename?: 'Businessarea';
                id: string;
                type: BusinessareaType;
                description: string;
            } | null;
            coverImage?: {
                __typename?: 'Media';
                id: string;
                fileName?: string | null;
                fileLabel?: string | null;
            } | null;
            socials?: Array<{
                __typename?: 'Social';
                id?: string | null;
                name?: string | null;
                value?: string | null;
            } | null> | null;
            services?: Array<{
                __typename?: 'CompanyService';
                title?: string | null;
                description?: string | null;
            } | null> | null;
            quarters?: Array<{
                __typename?: 'CompanyQuarter';
                id: string;
                city?: string | null;
                type?: CompanyHeadquarterType | null;
                location?: string | null;
            } | null> | null;
            contacts?: Array<{
                __typename?: 'CompanyContact';
                type?: string | null;
                value?: string | null;
            } | null> | null;
            employees?: Array<{
                __typename?: 'CompanyEmployee';
                id?: string | null;
                role?: string | null;
                state?: string | null;
                user?: {
                    __typename?: 'UserPublic';
                    id?: string | null;
                    email?: string | null;
                    name?: string | null;
                    surname?: string | null;
                    userType?: string | null;
                    birth_date?: string | null;
                    city_residence?: string | null;
                    biografy?: string | null;
                    website?: string | null;
                    phone?: string | null;
                    languageId?: PlatformLanguages | null;
                    photo?: {
                        __typename?: 'Media';
                        id: string;
                        fileName?: string | null;
                        fileLabel?: string | null;
                    } | null;
                    screenshot?: {
                        __typename?: 'Media';
                        id: string;
                        fileName?: string | null;
                        fileLabel?: string | null;
                    } | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                    socials?: Array<{
                        __typename?: 'Social';
                        id?: string | null;
                        name?: string | null;
                        value?: string | null;
                    } | null> | null;
                    educational?: {
                        __typename?: 'Educational';
                        id: string;
                        des: string;
                    } | null;
                    businessarea?: {
                        __typename?: 'Businessarea';
                        id: string;
                        type: BusinessareaType;
                        description: string;
                    } | null;
                    personalities?: Array<{
                        __typename?: 'Personality';
                        id: string;
                        section: string;
                        des: string;
                    } | null> | null;
                    qualifications?: Array<{
                        __typename?: 'UserQualification';
                        id: string;
                        experienceInYears?: number | null;
                    } | null> | null;
                    spokenLanguages?: Array<{
                        __typename?: 'GeneralInfo';
                        id?: string | null;
                        value?: string | null;
                        description?: string | null;
                    } | null> | null;
                } | null;
            } | null> | null;
            expertiseAreas?: Array<{
                __typename?: 'CompanyExpertiseArea';
                title?: string | null;
                description?: string | null;
            } | null> | null;
        } | null;
        previewImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        targetDisiredSkills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        qualification?: {
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null;
    } | null;
};

export type InsertManySavedTalentsMutationVariables = Exact<{
    input: InsertSavedTalentsInput;
}>;

export type InsertManySavedTalentsMutation = {
    __typename?: 'Mutation';
    insertManySavedTalents?: Array<{
        __typename?: 'SavedTalent';
        id?: string | null;
        type?: string | null;
        referenceId?: string | null;
    } | null> | null;
};

export type InsertQualificationTypeMutationVariables = Exact<{
    des: Scalars['String'];
    custom?: InputMaybe<Scalars['Int']>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type InsertQualificationTypeMutation = {
    __typename?: 'Mutation';
    insertQualificationType?: {
        __typename?: 'QualificationType';
        id: string;
        des: string;
        custom?: number | null;
    } | null;
};

export type InsertRequestForCompanyRelationShareMutationVariables = Exact<{
    input: HandleCompanyRelationShareInput;
}>;

export type InsertRequestForCompanyRelationShareMutation = {
    __typename?: 'Mutation';
    insertRequestForCompanyRelationShare?: boolean | null;
};

export type InsertSavedSearchMutationVariables = Exact<{
    input: InsertSavedSearchInput;
}>;

export type InsertSavedSearchMutation = {
    __typename?: 'Mutation';
    insertSavedSearch?: {
        __typename?: 'SavedSearch';
        id?: string | null;
        name?: string | null;
        creationDate?: string | null;
        query?: {
            __typename?: 'SavedSearchQueryOutput';
            state?: CompanyCvState | null;
            searchId?: string | null;
            groupId?: string | null;
            companyId?: string | null;
            keywords?: Array<string | null> | null;
            skills?: Array<string | null> | null;
            hiringType?: HiringType | null;
            origin?: SearchCompanyCvOrigin | null;
            locationType?: LocationType | null;
            locationCity?: string | null;
            qualifications?: Array<string | null> | null;
            fullName?: string | null;
        } | null;
        visualization?: {
            __typename?: 'SavedSearchVisualizationOutput';
            row?: number | null;
            skills?: Array<string | null> | null;
            workLoad?: number | null;
            activity?: string | null;
            responsability?: string | null;
            daily_fare?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            experience?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null;
        results?: Array<{
            __typename?: 'SavedSearchResult';
            id?: string | null;
            state?: CompanySavedSearchResultState | null;
            data?: {
                __typename?: 'CompanySearchCvOutput';
                id?: string | null;
                fullName?: string | null;
                rating?: number | null;
                fileName?: string | null;
                state?: CompanyCvState | null;
                processNames?: Array<string | null> | null;
                numberOfRatings?: number | null;
                primaryQualification?: string | null;
                lastUpdateDate?: string | null;
                uploadFileDate?: string | null;
                savedBy?: number | null;
                type?: string | null;
                isPreferred?: boolean | null;
                origin?: SearchCompanyCvOrigin | null;
                correspondence?: number | null;
                keywords?: Array<string | null> | null;
                visibilityState?: CompanyCvVisibilityState | null;
                tags?: Array<{
                    __typename?: 'Label';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    isDefault?: boolean | null;
                    description?: string | null;
                } | null> | null;
                folders?: Array<{
                    __typename?: 'Folder';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    description?: string | null;
                    qualifications?: Array<{
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null> | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                } | null> | null;
                qualification?: {
                    __typename?: 'CompanySearchCvOutputQualification';
                    name?: string | null;
                    translations?: Array<{
                        __typename?: 'CompanySearchCvOutputQualificationTranslation';
                        id?: string | null;
                        value?: string | null;
                    } | null> | null;
                } | null;
                handledBy?: {
                    __typename?: 'CompanySearchCvHandlingOutput';
                    id?: string | null;
                    companyName?: string | null;
                    companyImage?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};

export type InsertSavedTalentMutationVariables = Exact<{
    input: InsertSavedTalentInput;
}>;

export type InsertSavedTalentMutation = {
    __typename?: 'Mutation';
    insertSavedTalent?: {
        __typename?: 'SavedTalent';
        id?: string | null;
        type?: string | null;
        referenceId?: string | null;
    } | null;
};

export type InsertSkillMutationVariables = Exact<{
    des: Scalars['String'];
    typeId?: InputMaybe<Scalars['String']>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type InsertSkillMutation = {
    __typename?: 'Mutation';
    insertSkill?: {
        __typename?: 'Skill';
        id: string;
        des: string;
        custom?: boolean | null;
        type?: {
            __typename?: 'SkillType';
            id: string;
            des: string;
            custom?: number | null;
        } | null;
    } | null;
};

export type InsertSkillTypeMutationVariables = Exact<{
    des: Scalars['String'];
    custom?: InputMaybe<Scalars['Int']>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type InsertSkillTypeMutation = {
    __typename?: 'Mutation';
    insertSkillType?: {
        __typename?: 'SkillType';
        id: string;
        des: string;
        custom?: number | null;
    } | null;
};

export type InsertSurveyUserAnswerMutationVariables = Exact<{
    proposalId: Scalars['ID'];
    questionId: Scalars['ID'];
    value?: InputMaybe<Scalars['String']>;
    userId?: InputMaybe<Scalars['ID']>;
}>;

export type InsertSurveyUserAnswerMutation = {
    __typename?: 'Mutation';
    insertSurveyUserAnswer?: {
        __typename?: 'SurveyQuestionProposal';
        id: string;
        orderby?: number | null;
        label?: string | null;
        value?: number | null;
        minvalue?: number | null;
        maxvalue?: number | null;
        preselectedminvalue?: number | null;
        preselectedmaxvalue?: number | null;
        minlabel?: string | null;
        maxlabel?: string | null;
        currentUserAnswer?: string | null;
    } | null;
};

export type InsertTalentsAsPreferredInSavedSearchMutationVariables = Exact<{
    references?: InputMaybe<Array<InputMaybe<CvReference>> | InputMaybe<CvReference>>;
    searchId: Scalars['ID'];
}>;

export type InsertTalentsAsPreferredInSavedSearchMutation = {
    __typename?: 'Mutation';
    insertTalentsAsPreferredInSavedSearch?: {
        __typename?: 'SavedSearch';
        id?: string | null;
        name?: string | null;
        creationDate?: string | null;
        query?: {
            __typename?: 'SavedSearchQueryOutput';
            state?: CompanyCvState | null;
            searchId?: string | null;
            groupId?: string | null;
            companyId?: string | null;
            keywords?: Array<string | null> | null;
            skills?: Array<string | null> | null;
            hiringType?: HiringType | null;
            origin?: SearchCompanyCvOrigin | null;
            locationType?: LocationType | null;
            locationCity?: string | null;
            qualifications?: Array<string | null> | null;
            fullName?: string | null;
        } | null;
        visualization?: {
            __typename?: 'SavedSearchVisualizationOutput';
            row?: number | null;
            skills?: Array<string | null> | null;
            workLoad?: number | null;
            activity?: string | null;
            responsability?: string | null;
            daily_fare?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            experience?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null;
        results?: Array<{
            __typename?: 'SavedSearchResult';
            id?: string | null;
            state?: CompanySavedSearchResultState | null;
            data?: {
                __typename?: 'CompanySearchCvOutput';
                id?: string | null;
                fullName?: string | null;
                rating?: number | null;
                fileName?: string | null;
                state?: CompanyCvState | null;
                processNames?: Array<string | null> | null;
                numberOfRatings?: number | null;
                primaryQualification?: string | null;
                lastUpdateDate?: string | null;
                uploadFileDate?: string | null;
                savedBy?: number | null;
                type?: string | null;
                isPreferred?: boolean | null;
                origin?: SearchCompanyCvOrigin | null;
                correspondence?: number | null;
                keywords?: Array<string | null> | null;
                visibilityState?: CompanyCvVisibilityState | null;
                tags?: Array<{
                    __typename?: 'Label';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    isDefault?: boolean | null;
                    description?: string | null;
                } | null> | null;
                folders?: Array<{
                    __typename?: 'Folder';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    description?: string | null;
                    qualifications?: Array<{
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null> | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                } | null> | null;
                qualification?: {
                    __typename?: 'CompanySearchCvOutputQualification';
                    name?: string | null;
                    translations?: Array<{
                        __typename?: 'CompanySearchCvOutputQualificationTranslation';
                        id?: string | null;
                        value?: string | null;
                    } | null> | null;
                } | null;
                handledBy?: {
                    __typename?: 'CompanySearchCvHandlingOutput';
                    id?: string | null;
                    companyName?: string | null;
                    companyImage?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};

export type InsertUserRatingMutationVariables = Exact<{
    input?: InputMaybe<InsertRatingInsert>;
}>;

export type InsertUserRatingMutation = {
    __typename?: 'Mutation';
    insertUserRating?: {
        __typename?: 'Rating';
        id: string;
        authorFullName?: string | null;
        authorImage?: string | null;
        companyName?: string | null;
        content?: string | null;
        ratings?: Array<{
            __typename?: 'RatingsDescriptionValue';
            value?: number | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type InviteToEducationCourseMutationVariables = Exact<{
    input?: InputMaybe<EducationCourseInvite>;
}>;

export type InviteToEducationCourseMutation = {
    __typename?: 'Mutation';
    inviteToEducationCourse?: boolean | null;
};

export type LinkExternalMediaMutationVariables = Exact<{
    input: ExternalFileInput;
}>;

export type LinkExternalMediaMutation = {
    __typename?: 'Mutation';
    linkExternalMedia?: {
        __typename?: 'Media';
        id: string;
        fileName?: string | null;
        fileLabel?: string | null;
    } | null;
};

export type MdummyMutationVariables = Exact<{ [key: string]: never }>;

export type MdummyMutation = { __typename?: 'Mutation'; mdummy?: boolean | null };

export type RefuseRequestForCompanyRelationShareMutationVariables = Exact<{
    input: HandleCompanyRelationShareInput;
}>;

export type RefuseRequestForCompanyRelationShareMutation = {
    __typename?: 'Mutation';
    refuseRequestForCompanyRelationShare?: boolean | null;
};

export type RefuseUserToCompanyMutationVariables = Exact<{
    relationId: Scalars['ID'];
}>;

export type RefuseUserToCompanyMutation = {
    __typename?: 'Mutation';
    refuseUserToCompany?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type RemoveAsHiredCompanyCvMutationVariables = Exact<{
    reference: CvReference;
    companyId: Scalars['String'];
}>;

export type RemoveAsHiredCompanyCvMutation = {
    __typename?: 'Mutation';
    removeAsHiredCompanyCV?: string | null;
};

export type RemoveCandidateAsPreferredMutationVariables = Exact<{
    reference: CvReference;
    jobAnnouncementId: Scalars['ID'];
}>;

export type RemoveCandidateAsPreferredMutation = {
    __typename?: 'Mutation';
    removeCandidateAsPreferred?: boolean | null;
};

export type RemoveCvFromSavedSearchMutationVariables = Exact<{
    searchId: Scalars['ID'];
    reference: CvReference;
}>;

export type RemoveCvFromSavedSearchMutation = {
    __typename?: 'Mutation';
    removeCvFromSavedSearch?: boolean | null;
};

export type RemoveJobAnnouncementAsPreferredMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveJobAnnouncementAsPreferredMutation = {
    __typename?: 'Mutation';
    removeJobAnnouncementAsPreferred?: boolean | null;
};

export type RemoveManyCandidatesAsPreferredMutationVariables = Exact<{
    input?: InputMaybe<RemoveManyCandidatesAsPreferredInput>;
}>;

export type RemoveManyCandidatesAsPreferredMutation = {
    __typename?: 'Mutation';
    removeManyCandidatesAsPreferred?: boolean | null;
};

export type RemoveManyCvFromSavedSearchMutationVariables = Exact<{
    searchId: Scalars['ID'];
    references: Array<InputMaybe<CvReference>> | InputMaybe<CvReference>;
}>;

export type RemoveManyCvFromSavedSearchMutation = {
    __typename?: 'Mutation';
    removeManyCvFromSavedSearch?: boolean | null;
};

export type RemoveManySavedTalentsMutationVariables = Exact<{
    referenceIds: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
    companyId: Scalars['String'];
}>;

export type RemoveManySavedTalentsMutation = {
    __typename?: 'Mutation';
    removeManySavedTalents?: boolean | null;
};

export type RemoveMediaMutationVariables = Exact<{
    mediaId: Scalars['ID'];
}>;

export type RemoveMediaMutation = {
    __typename?: 'Mutation';
    removeMedia?: boolean | null;
};

export type RemoveSavedTalentMutationVariables = Exact<{
    referenceId: Scalars['String'];
    companyId: Scalars['String'];
}>;

export type RemoveSavedTalentMutation = {
    __typename?: 'Mutation';
    removeSavedTalent?: boolean | null;
};

export type RemoveTalentsAsPreferredInSavedSearchMutationVariables = Exact<{
    references?: InputMaybe<Array<InputMaybe<CvReference>> | InputMaybe<CvReference>>;
    searchId: Scalars['ID'];
}>;

export type RemoveTalentsAsPreferredInSavedSearchMutation = {
    __typename?: 'Mutation';
    removeTalentsAsPreferredInSavedSearch?: {
        __typename?: 'SavedSearch';
        id?: string | null;
        name?: string | null;
        creationDate?: string | null;
        query?: {
            __typename?: 'SavedSearchQueryOutput';
            state?: CompanyCvState | null;
            searchId?: string | null;
            groupId?: string | null;
            companyId?: string | null;
            keywords?: Array<string | null> | null;
            skills?: Array<string | null> | null;
            hiringType?: HiringType | null;
            origin?: SearchCompanyCvOrigin | null;
            locationType?: LocationType | null;
            locationCity?: string | null;
            qualifications?: Array<string | null> | null;
            fullName?: string | null;
        } | null;
        visualization?: {
            __typename?: 'SavedSearchVisualizationOutput';
            row?: number | null;
            skills?: Array<string | null> | null;
            workLoad?: number | null;
            activity?: string | null;
            responsability?: string | null;
            daily_fare?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            experience?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null;
        results?: Array<{
            __typename?: 'SavedSearchResult';
            id?: string | null;
            state?: CompanySavedSearchResultState | null;
            data?: {
                __typename?: 'CompanySearchCvOutput';
                id?: string | null;
                fullName?: string | null;
                rating?: number | null;
                fileName?: string | null;
                state?: CompanyCvState | null;
                processNames?: Array<string | null> | null;
                numberOfRatings?: number | null;
                primaryQualification?: string | null;
                lastUpdateDate?: string | null;
                uploadFileDate?: string | null;
                savedBy?: number | null;
                type?: string | null;
                isPreferred?: boolean | null;
                origin?: SearchCompanyCvOrigin | null;
                correspondence?: number | null;
                keywords?: Array<string | null> | null;
                visibilityState?: CompanyCvVisibilityState | null;
                tags?: Array<{
                    __typename?: 'Label';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    isDefault?: boolean | null;
                    description?: string | null;
                } | null> | null;
                folders?: Array<{
                    __typename?: 'Folder';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    description?: string | null;
                    qualifications?: Array<{
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null> | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                } | null> | null;
                qualification?: {
                    __typename?: 'CompanySearchCvOutputQualification';
                    name?: string | null;
                    translations?: Array<{
                        __typename?: 'CompanySearchCvOutputQualificationTranslation';
                        id?: string | null;
                        value?: string | null;
                    } | null> | null;
                } | null;
                handledBy?: {
                    __typename?: 'CompanySearchCvHandlingOutput';
                    id?: string | null;
                    companyName?: string | null;
                    companyImage?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};

export type RemoveUserToCompanyMutationVariables = Exact<{
    relationId: Scalars['ID'];
}>;

export type RemoveUserToCompanyMutation = {
    __typename?: 'Mutation';
    removeUserToCompany?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type ResendUserWelcomeEmailMutationVariables = Exact<{ [key: string]: never }>;

export type ResendUserWelcomeEmailMutation = {
    __typename?: 'Mutation';
    resendUserWelcomeEmail?: boolean | null;
};

export type SendEmailHelpMutationVariables = Exact<{
    user_name: Scalars['String'];
    user_surname: Scalars['String'];
    user_email: Scalars['String'];
    user_phone: Scalars['String'];
    user_message: Scalars['String'];
    user_company?: InputMaybe<Scalars['String']>;
    destination_email?: InputMaybe<Scalars['String']>;
}>;

export type SendEmailHelpMutation = {
    __typename?: 'Mutation';
    sendEmailHelp?: boolean | null;
};

export type SendHiringInterviewComunicationMutationVariables = Exact<{
    reference?: InputMaybe<CvReference>;
    companyRequester?: InputMaybe<Scalars['String']>;
    message?: InputMaybe<Scalars['String']>;
}>;

export type SendHiringInterviewComunicationMutation = {
    __typename?: 'Mutation';
    sendHiringInterviewComunication?: boolean | null;
};

export type SetAsHiredCompanyCvMutationVariables = Exact<{
    reference: CvReference;
    companyId: Scalars['String'];
}>;

export type SetAsHiredCompanyCvMutation = {
    __typename?: 'Mutation';
    setAsHiredCompanyCV?: string | null;
};

export type SetCandidateAsAcceptedMutationVariables = Exact<{
    referenceId: Scalars['ID'];
    referenceType: TalentType;
    jobAnnouncementId: Scalars['ID'];
}>;

export type SetCandidateAsAcceptedMutation = {
    __typename?: 'Mutation';
    setCandidateAsAccepted?: boolean | null;
};

export type SetCandidateAsHiredMutationVariables = Exact<{
    referenceId: Scalars['ID'];
    referenceType: TalentType;
    jobAnnouncementId: Scalars['ID'];
}>;

export type SetCandidateAsHiredMutation = {
    __typename?: 'Mutation';
    setCandidateAsHired?: boolean | null;
};

export type SetCandidateAsPreferredMutationVariables = Exact<{
    referenceId: Scalars['ID'];
    referenceType: TalentType;
    jobAnnouncementId: Scalars['ID'];
}>;

export type SetCandidateAsPreferredMutation = {
    __typename?: 'Mutation';
    setCandidateAsPreferred?: boolean | null;
};

export type SetCandidateAsRefusedMutationVariables = Exact<{
    referenceId: Scalars['ID'];
    referenceType: TalentType;
    jobAnnouncementId: Scalars['ID'];
    refuseReason?: InputMaybe<Scalars['String']>;
}>;

export type SetCandidateAsRefusedMutation = {
    __typename?: 'Mutation';
    setCandidateAsRefused?: boolean | null;
};

export type SetCompanyUploadCvAsInternalMutationVariables = Exact<{
    reference: CvReference;
    companyId: Scalars['String'];
}>;

export type SetCompanyUploadCvAsInternalMutation = {
    __typename?: 'Mutation';
    setCompanyUploadCVAsInternal?: string | null;
};

export type SetCompanyUploadCvAsTotalMutationVariables = Exact<{
    reference: CvReference;
    companyId: Scalars['String'];
}>;

export type SetCompanyUploadCvAsTotalMutation = {
    __typename?: 'Mutation';
    setCompanyUploadCVAsTotal?: string | null;
};

export type SetCurrentAppVisualizationMutationVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
    type?: InputMaybe<CurrentAppVisualizationType>;
    companyId?: InputMaybe<Scalars['String']>;
}>;

export type SetCurrentAppVisualizationMutation = {
    __typename?: 'Mutation';
    setCurrentAppVisualization?: {
        __typename?: 'CurrentAppVisualization';
        type?: CurrentAppVisualizationType | null;
        companyId?: string | null;
    } | null;
};

export type SetJobAnnouncementAsPreferredMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SetJobAnnouncementAsPreferredMutation = {
    __typename?: 'Mutation';
    setJobAnnouncementAsPreferred?: boolean | null;
};

export type SetManyCandidatesAsAcceptedMutationVariables = Exact<{
    input?: InputMaybe<SetManyCandidatesAsAcceptedInput>;
}>;

export type SetManyCandidatesAsAcceptedMutation = {
    __typename?: 'Mutation';
    setManyCandidatesAsAccepted?: boolean | null;
};

export type SetManyCandidatesAsPreferredMutationVariables = Exact<{
    input?: InputMaybe<SetManyCandidatesAsPreferredInput>;
}>;

export type SetManyCandidatesAsPreferredMutation = {
    __typename?: 'Mutation';
    setManyCandidatesAsPreferred?: boolean | null;
};

export type SetManyCompanyUploadCvAsInternalMutationVariables = Exact<{
    references: Array<InputMaybe<CvReference>> | InputMaybe<CvReference>;
    companyId: Scalars['String'];
}>;

export type SetManyCompanyUploadCvAsInternalMutation = {
    __typename?: 'Mutation';
    setManyCompanyUploadCVAsInternal?: Array<string | null> | null;
};

export type SetManyCompanyUploadCvAsTotalMutationVariables = Exact<{
    references: Array<InputMaybe<CvReference>> | InputMaybe<CvReference>;
    companyId: Scalars['String'];
}>;

export type SetManyCompanyUploadCvAsTotalMutation = {
    __typename?: 'Mutation';
    setManyCompanyUploadCVAsTotal?: Array<string | null> | null;
};

export type UpdateCompanyMutationVariables = Exact<{
    input?: InputMaybe<UpdateCompanyRequest>;
}>;

export type UpdateCompanyMutation = {
    __typename?: 'Mutation';
    updateCompany?: {
        __typename?: 'Company';
        id: string;
        url: string;
        name: string;
        biografy?: string | null;
        vatNumber?: string | null;
        country?: CompanyCountry | null;
        privacyUrl?: string | null;
        isApproved?: boolean | null;
        hasOpenActive?: boolean | null;
        creationDate?: string | null;
        website?: string | null;
        businessArea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        services?: Array<{
            __typename?: 'CompanyService';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        quarters?: Array<{
            __typename?: 'CompanyQuarter';
            id: string;
            city?: string | null;
            type?: CompanyHeadquarterType | null;
            location?: string | null;
        } | null> | null;
        employees?: Array<{
            __typename?: 'CompanyEmployee';
            id?: string | null;
            role?: string | null;
            state?: string | null;
            user?: {
                __typename?: 'UserPublic';
                id?: string | null;
                email?: string | null;
                name?: string | null;
                surname?: string | null;
                userType?: string | null;
                birth_date?: string | null;
                city_residence?: string | null;
                biografy?: string | null;
                website?: string | null;
                phone?: string | null;
                languageId?: PlatformLanguages | null;
                photo?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                screenshot?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    des: string;
                    custom?: boolean | null;
                    type?: {
                        __typename?: 'SkillType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null> | null;
                socials?: Array<{
                    __typename?: 'Social';
                    id?: string | null;
                    name?: string | null;
                    value?: string | null;
                } | null> | null;
                educational?: {
                    __typename?: 'Educational';
                    id: string;
                    des: string;
                } | null;
                businessarea?: {
                    __typename?: 'Businessarea';
                    id: string;
                    type: BusinessareaType;
                    description: string;
                } | null;
                personalities?: Array<{
                    __typename?: 'Personality';
                    id: string;
                    section: string;
                    des: string;
                } | null> | null;
                qualifications?: Array<{
                    __typename?: 'UserQualification';
                    id: string;
                    experienceInYears?: number | null;
                    qualification?: {
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null;
                } | null> | null;
                spokenLanguages?: Array<{
                    __typename?: 'GeneralInfo';
                    id?: string | null;
                    value?: string | null;
                    description?: string | null;
                } | null> | null;
            } | null;
        } | null> | null;
        expertiseAreas?: Array<{
            __typename?: 'CompanyExpertiseArea';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        contacts?: Array<{
            __typename?: 'CompanyContact';
            type?: string | null;
            value?: string | null;
        } | null> | null;
        statistics?: Array<{
            __typename?: 'CompanyStatistic';
            name?: string | null;
            value?: string | null;
        } | null> | null;
    } | null;
};

export type UpdateCompanyRelationShareMutationVariables = Exact<{
    input: UpdateCompanyRelationShareInput;
}>;

export type UpdateCompanyRelationShareMutation = {
    __typename?: 'Mutation';
    updateCompanyRelationShare?: {
        __typename?: 'CompanyRelationShare';
        id?: string | null;
        type?: SharingCompanyRuleType | null;
        state?: CompanyShareRuleState | null;
        dataVisibility?: SharingCompanyRuleVisibility | null;
        company?: {
            __typename?: 'CompanyPublic';
            id: string;
            url: string;
            name: string;
            biografy?: string | null;
            country?: CompanyCountry | null;
            vatNumber?: string | null;
            isApproved?: boolean | null;
            website?: string | null;
            creationDate?: string | null;
            businessArea?: {
                __typename?: 'Businessarea';
                id: string;
                type: BusinessareaType;
                description: string;
            } | null;
            coverImage?: {
                __typename?: 'Media';
                id: string;
                fileName?: string | null;
                fileLabel?: string | null;
            } | null;
            socials?: Array<{
                __typename?: 'Social';
                id?: string | null;
                name?: string | null;
                value?: string | null;
            } | null> | null;
            services?: Array<{
                __typename?: 'CompanyService';
                title?: string | null;
                description?: string | null;
            } | null> | null;
            quarters?: Array<{
                __typename?: 'CompanyQuarter';
                id: string;
                city?: string | null;
                type?: CompanyHeadquarterType | null;
                location?: string | null;
            } | null> | null;
            contacts?: Array<{
                __typename?: 'CompanyContact';
                type?: string | null;
                value?: string | null;
            } | null> | null;
            employees?: Array<{
                __typename?: 'CompanyEmployee';
                id?: string | null;
                role?: string | null;
                state?: string | null;
                user?: {
                    __typename?: 'UserPublic';
                    id?: string | null;
                    email?: string | null;
                    name?: string | null;
                    surname?: string | null;
                    userType?: string | null;
                    birth_date?: string | null;
                    city_residence?: string | null;
                    biografy?: string | null;
                    website?: string | null;
                    phone?: string | null;
                    languageId?: PlatformLanguages | null;
                    photo?: {
                        __typename?: 'Media';
                        id: string;
                        fileName?: string | null;
                        fileLabel?: string | null;
                    } | null;
                    screenshot?: {
                        __typename?: 'Media';
                        id: string;
                        fileName?: string | null;
                        fileLabel?: string | null;
                    } | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                    socials?: Array<{
                        __typename?: 'Social';
                        id?: string | null;
                        name?: string | null;
                        value?: string | null;
                    } | null> | null;
                    educational?: {
                        __typename?: 'Educational';
                        id: string;
                        des: string;
                    } | null;
                    businessarea?: {
                        __typename?: 'Businessarea';
                        id: string;
                        type: BusinessareaType;
                        description: string;
                    } | null;
                    personalities?: Array<{
                        __typename?: 'Personality';
                        id: string;
                        section: string;
                        des: string;
                    } | null> | null;
                    qualifications?: Array<{
                        __typename?: 'UserQualification';
                        id: string;
                        experienceInYears?: number | null;
                    } | null> | null;
                    spokenLanguages?: Array<{
                        __typename?: 'GeneralInfo';
                        id?: string | null;
                        value?: string | null;
                        description?: string | null;
                    } | null> | null;
                } | null;
            } | null> | null;
            expertiseAreas?: Array<{
                __typename?: 'CompanyExpertiseArea';
                title?: string | null;
                description?: string | null;
            } | null> | null;
        } | null;
    } | null;
};

export type UpdateCompanyTeamListMutationVariables = Exact<{
    input?: InputMaybe<UpdateCompanyTeamListRequest>;
}>;

export type UpdateCompanyTeamListMutation = {
    __typename?: 'Mutation';
    updateCompanyTeamList?: {
        __typename?: 'CompanyTeamList';
        id: string;
        name?: string | null;
        description?: string | null;
        lastUpdate?: string | null;
        creationDate?: string | null;
        savedSearches?: Array<{
            __typename?: 'CompanyTeamListSavedSearch';
            id?: string | null;
            name?: string | null;
            query?: {
                __typename?: 'SavedSearchQueryOutput';
                state?: CompanyCvState | null;
                searchId?: string | null;
                groupId?: string | null;
                companyId?: string | null;
                keywords?: Array<string | null> | null;
                skills?: Array<string | null> | null;
                hiringType?: HiringType | null;
                origin?: SearchCompanyCvOrigin | null;
                locationType?: LocationType | null;
                locationCity?: string | null;
                qualifications?: Array<string | null> | null;
                fullName?: string | null;
            } | null;
            visualization?: {
                __typename?: 'SavedSearchVisualizationOutput';
                row?: number | null;
                skills?: Array<string | null> | null;
                workLoad?: number | null;
                activity?: string | null;
                responsability?: string | null;
                daily_fare?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                experience?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                qualification?: {
                    __typename?: 'Qualification';
                    id: string;
                    des: string;
                    type?: {
                        __typename?: 'QualificationType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null;
            } | null;
            results?: Array<{
                __typename?: 'CompanyTeamListSavedSearchResult';
                percentUsage?: number | null;
                talent?: {
                    __typename?: 'CompanySearchCvOutput';
                    id?: string | null;
                    fullName?: string | null;
                    rating?: number | null;
                    fileName?: string | null;
                    state?: CompanyCvState | null;
                    processNames?: Array<string | null> | null;
                    numberOfRatings?: number | null;
                    primaryQualification?: string | null;
                    lastUpdateDate?: string | null;
                    uploadFileDate?: string | null;
                    savedBy?: number | null;
                    type?: string | null;
                    isPreferred?: boolean | null;
                    origin?: SearchCompanyCvOrigin | null;
                    correspondence?: number | null;
                    keywords?: Array<string | null> | null;
                    visibilityState?: CompanyCvVisibilityState | null;
                    tags?: Array<{
                        __typename?: 'Label';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        isDefault?: boolean | null;
                        description?: string | null;
                    } | null> | null;
                    folders?: Array<{
                        __typename?: 'Folder';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        description?: string | null;
                    } | null> | null;
                    qualification?: {
                        __typename?: 'CompanySearchCvOutputQualification';
                        name?: string | null;
                    } | null;
                    handledBy?: {
                        __typename?: 'CompanySearchCvHandlingOutput';
                        id?: string | null;
                        companyName?: string | null;
                        companyImage?: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type UpdateCompanyUploadCvGroupMutationVariables = Exact<{
    input?: InputMaybe<UpdateCompanyUploadCvGroup>;
}>;

export type UpdateCompanyUploadCvGroupMutation = {
    __typename?: 'Mutation';
    updateCompanyUploadCVGroup?: {
        __typename?: 'CompanyUploadCVGroup';
        id?: string | null;
        status?: CompanyUploadGroupStatus | null;
        uploadCount?: number | null;
        description?: string | null;
        creationDate?: string | null;
        dashboardType?: CompanyUploadDashboardType | null;
        resume?: Array<{
            __typename?: 'ResumeStatusFiles';
            status?: CompanyCvProcessStatus | null;
            actual?: number | null;
        } | null> | null;
        uploads?: Array<{
            __typename?: 'CompanyUploadCV';
            id?: string | null;
            url?: string | null;
            name?: string | null;
            creationDate?: string | null;
            processNames?: Array<string | null> | null;
            processStatus?: CompanyCvProcessStatus | null;
        } | null> | null;
    } | null;
};

export type UpdateEducationCourseMutationVariables = Exact<{
    input?: InputMaybe<EducationCourseUpdateRequest>;
}>;

export type UpdateEducationCourseMutation = {
    __typename?: 'Mutation';
    updateEducationCourse?: {
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null;
};

export type UpdateEducationLessonMutationVariables = Exact<{
    input?: InputMaybe<EducationLessonInput>;
}>;

export type UpdateEducationLessonMutation = {
    __typename?: 'Mutation';
    updateEducationLesson?: {
        __typename?: 'EducationLesson';
        id?: string | null;
        courseId?: string | null;
        courseTitle?: string | null;
        title?: string | null;
        status?: LessonStatus | null;
        info?: string | null;
        teacherId?: string | null;
        date?: any | null;
        from?: string | null;
        to?: string | null;
        start_url?: string | null;
        join_url?: string | null;
        lesson_url?: string | null;
        teacher?: {
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null;
    } | null;
};

export type UpdateEducationStudentMutationVariables = Exact<{
    input?: InputMaybe<EducationStudentInput>;
}>;

export type UpdateEducationStudentMutation = {
    __typename?: 'Mutation';
    updateEducationStudent?: {
        __typename?: 'EducationStudent';
        id?: string | null;
        courseId?: string | null;
        studentId?: string | null;
        studentName?: string | null;
        studentEmail?: string | null;
        status?: StudentCourseStatus | null;
        motivation?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        biografy?: string | null;
        cityResidence?: string | null;
        photo?: string | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        languages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
    } | null;
};

export type UpdateEducationTeacherMutationVariables = Exact<{
    input?: InputMaybe<EducationTeacherInput>;
}>;

export type UpdateEducationTeacherMutation = {
    __typename?: 'Mutation';
    updateEducationTeacher?: {
        __typename?: 'EducationTeacher';
        id?: string | null;
        courseId?: string | null;
        teacherId?: string | null;
        teacherName?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        email?: string | null;
        companyName?: string | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
    } | null;
};

export type UpdateFolderMutationVariables = Exact<{
    input: UpdateFolderInput;
}>;

export type UpdateFolderMutation = {
    __typename?: 'Mutation';
    updateFolder?: {
        __typename?: 'Folder';
        id?: string | null;
        name?: string | null;
        parentId?: string | null;
        description?: string | null;
        qualifications?: Array<{
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
    } | null;
};

export type UpdateHiringInterviewMutationVariables = Exact<{
    id: Scalars['String'];
    status?: InputMaybe<Scalars['String']>;
    valuation?: InputMaybe<Scalars['String']>;
    reliability?: InputMaybe<Scalars['Int']>;
    personality?: InputMaybe<Scalars['Int']>;
    potential?: InputMaybe<Scalars['Int']>;
    experience?: InputMaybe<Scalars['Int']>;
    leadership?: InputMaybe<Scalars['Int']>;
}>;

export type UpdateHiringInterviewMutation = {
    __typename?: 'Mutation';
    updateHiringInterview?: {
        __typename?: 'HiringInterview';
        id: string;
        status?: string | null;
        creation_date?: string | null;
        valuation?: string | null;
        hiringprocessId?: string | null;
        reliability?: number | null;
        personality?: number | null;
        potential?: number | null;
        experience?: number | null;
        leadership?: number | null;
    } | null;
};

export type UpdateHiringProposalMutationVariables = Exact<{
    id?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<Scalars['String']>;
    accepted_date?: InputMaybe<Scalars['Int']>;
}>;

export type UpdateHiringProposalMutation = {
    __typename?: 'Mutation';
    updateHiringProposal?: {
        __typename?: 'HiringProposal';
        id: string;
        status?: string | null;
        creation_date?: string | null;
        hiringinterviewId?: string | null;
        date1?: string | null;
        date2?: string | null;
        date3?: string | null;
        time1?: string | null;
        time2?: string | null;
        time3?: string | null;
        message?: string | null;
        accepted_date?: number | null;
    } | null;
};

export type UpdateJobAnnouncementMutationVariables = Exact<{
    input: JobAnnouncementUpdateInput;
}>;

export type UpdateJobAnnouncementMutation = {
    __typename?: 'Mutation';
    updateJobAnnouncement?: {
        __typename?: 'JobAnnouncement';
        id?: string | null;
        city?: string | null;
        title?: string | null;
        status?: JobAnnouncementStatus | null;
        privacyURL?: string | null;
        description?: string | null;
        duration?: JobAnnouncentContractDurantion | null;
        hiringType?: HiringType | null;
        contractType?: ContractType | null;
        locationType?: LocationType | null;
        expirationDate?: string | null;
        targetCandidateRalMin?: number | null;
        targetCandidateRalMax?: number | null;
        targetExperienceYearsMin?: number | null;
        targetExperienceYearsMax?: number | null;
        isChatActivate?: boolean | null;
        isJobInterviewRequired?: boolean | null;
        isAssessmentRequired?: boolean | null;
        targetAvailability?: JobAnnouncentTargetAvailability | null;
        isTargetAvailableForTransfers?: boolean | null;
        isTargetAvailableForRoleChange?: boolean | null;
        isTargetAvailableForRelocation?: boolean | null;
        candidaturesCount?: number | null;
        referentId?: string | null;
        owner?: {
            __typename?: 'CompanyPublic';
            id: string;
            url: string;
            name: string;
            biografy?: string | null;
            country?: CompanyCountry | null;
            vatNumber?: string | null;
            isApproved?: boolean | null;
            website?: string | null;
            creationDate?: string | null;
            businessArea?: {
                __typename?: 'Businessarea';
                id: string;
                type: BusinessareaType;
                description: string;
            } | null;
            coverImage?: {
                __typename?: 'Media';
                id: string;
                fileName?: string | null;
                fileLabel?: string | null;
            } | null;
            socials?: Array<{
                __typename?: 'Social';
                id?: string | null;
                name?: string | null;
                value?: string | null;
            } | null> | null;
            services?: Array<{
                __typename?: 'CompanyService';
                title?: string | null;
                description?: string | null;
            } | null> | null;
            quarters?: Array<{
                __typename?: 'CompanyQuarter';
                id: string;
                city?: string | null;
                type?: CompanyHeadquarterType | null;
                location?: string | null;
            } | null> | null;
            contacts?: Array<{
                __typename?: 'CompanyContact';
                type?: string | null;
                value?: string | null;
            } | null> | null;
            employees?: Array<{
                __typename?: 'CompanyEmployee';
                id?: string | null;
                role?: string | null;
                state?: string | null;
                user?: {
                    __typename?: 'UserPublic';
                    id?: string | null;
                    email?: string | null;
                    name?: string | null;
                    surname?: string | null;
                    userType?: string | null;
                    birth_date?: string | null;
                    city_residence?: string | null;
                    biografy?: string | null;
                    website?: string | null;
                    phone?: string | null;
                    languageId?: PlatformLanguages | null;
                    photo?: {
                        __typename?: 'Media';
                        id: string;
                        fileName?: string | null;
                        fileLabel?: string | null;
                    } | null;
                    screenshot?: {
                        __typename?: 'Media';
                        id: string;
                        fileName?: string | null;
                        fileLabel?: string | null;
                    } | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                    socials?: Array<{
                        __typename?: 'Social';
                        id?: string | null;
                        name?: string | null;
                        value?: string | null;
                    } | null> | null;
                    educational?: {
                        __typename?: 'Educational';
                        id: string;
                        des: string;
                    } | null;
                    businessarea?: {
                        __typename?: 'Businessarea';
                        id: string;
                        type: BusinessareaType;
                        description: string;
                    } | null;
                    personalities?: Array<{
                        __typename?: 'Personality';
                        id: string;
                        section: string;
                        des: string;
                    } | null> | null;
                    qualifications?: Array<{
                        __typename?: 'UserQualification';
                        id: string;
                        experienceInYears?: number | null;
                    } | null> | null;
                    spokenLanguages?: Array<{
                        __typename?: 'GeneralInfo';
                        id?: string | null;
                        value?: string | null;
                        description?: string | null;
                    } | null> | null;
                } | null;
            } | null> | null;
            expertiseAreas?: Array<{
                __typename?: 'CompanyExpertiseArea';
                title?: string | null;
                description?: string | null;
            } | null> | null;
        } | null;
        previewImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        targetDisiredSkills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        qualification?: {
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null;
    } | null;
};

export type UpdateLabelMutationVariables = Exact<{
    input: UpdateLabelInput;
}>;

export type UpdateLabelMutation = {
    __typename?: 'Mutation';
    updateLabel?: {
        __typename?: 'Label';
        id?: string | null;
        name?: string | null;
        parentId?: string | null;
        isDefault?: boolean | null;
        description?: string | null;
    } | null;
};

export type UpdateQualificationTypeMutationVariables = Exact<{
    id: Scalars['ID'];
    des: Scalars['String'];
    custom?: InputMaybe<Scalars['Int']>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type UpdateQualificationTypeMutation = {
    __typename?: 'Mutation';
    updateQualificationType?: {
        __typename?: 'QualificationType';
        id: string;
        des: string;
        custom?: number | null;
    } | null;
};

export type UpdateSavedSearchMutationVariables = Exact<{
    input: UpdateSavedSearchInput;
}>;

export type UpdateSavedSearchMutation = {
    __typename?: 'Mutation';
    updateSavedSearch?: {
        __typename?: 'SavedSearch';
        id?: string | null;
        name?: string | null;
        creationDate?: string | null;
        query?: {
            __typename?: 'SavedSearchQueryOutput';
            state?: CompanyCvState | null;
            searchId?: string | null;
            groupId?: string | null;
            companyId?: string | null;
            keywords?: Array<string | null> | null;
            skills?: Array<string | null> | null;
            hiringType?: HiringType | null;
            origin?: SearchCompanyCvOrigin | null;
            locationType?: LocationType | null;
            locationCity?: string | null;
            qualifications?: Array<string | null> | null;
            fullName?: string | null;
        } | null;
        visualization?: {
            __typename?: 'SavedSearchVisualizationOutput';
            row?: number | null;
            skills?: Array<string | null> | null;
            workLoad?: number | null;
            activity?: string | null;
            responsability?: string | null;
            daily_fare?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            experience?: {
                __typename?: 'MinMaxValueOutput';
                min?: number | null;
                max?: number | null;
            } | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null;
        results?: Array<{
            __typename?: 'SavedSearchResult';
            id?: string | null;
            state?: CompanySavedSearchResultState | null;
            data?: {
                __typename?: 'CompanySearchCvOutput';
                id?: string | null;
                fullName?: string | null;
                rating?: number | null;
                fileName?: string | null;
                state?: CompanyCvState | null;
                processNames?: Array<string | null> | null;
                numberOfRatings?: number | null;
                primaryQualification?: string | null;
                lastUpdateDate?: string | null;
                uploadFileDate?: string | null;
                savedBy?: number | null;
                type?: string | null;
                isPreferred?: boolean | null;
                origin?: SearchCompanyCvOrigin | null;
                correspondence?: number | null;
                keywords?: Array<string | null> | null;
                visibilityState?: CompanyCvVisibilityState | null;
                tags?: Array<{
                    __typename?: 'Label';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    isDefault?: boolean | null;
                    description?: string | null;
                } | null> | null;
                folders?: Array<{
                    __typename?: 'Folder';
                    id?: string | null;
                    name?: string | null;
                    parentId?: string | null;
                    description?: string | null;
                    qualifications?: Array<{
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null> | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                } | null> | null;
                qualification?: {
                    __typename?: 'CompanySearchCvOutputQualification';
                    name?: string | null;
                    translations?: Array<{
                        __typename?: 'CompanySearchCvOutputQualificationTranslation';
                        id?: string | null;
                        value?: string | null;
                    } | null> | null;
                } | null;
                handledBy?: {
                    __typename?: 'CompanySearchCvHandlingOutput';
                    id?: string | null;
                    companyName?: string | null;
                    companyImage?: string | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};

export type UpdateSkillMutationVariables = Exact<{
    id: Scalars['ID'];
    des: Scalars['String'];
    typeId?: InputMaybe<Scalars['String']>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type UpdateSkillMutation = {
    __typename?: 'Mutation';
    updateSkill?: {
        __typename?: 'Skill';
        id: string;
        des: string;
        custom?: boolean | null;
        type?: {
            __typename?: 'SkillType';
            id: string;
            des: string;
            custom?: number | null;
        } | null;
    } | null;
};

export type UpdateSkillTypeMutationVariables = Exact<{
    id: Scalars['ID'];
    des: Scalars['String'];
    custom?: InputMaybe<Scalars['Int']>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type UpdateSkillTypeMutation = {
    __typename?: 'Mutation';
    updateSkillType?: {
        __typename?: 'SkillType';
        id: string;
        des: string;
        custom?: number | null;
    } | null;
};

export type UpdateUserMutationVariables = Exact<{
    id: Scalars['ID'];
    like_work_home?: InputMaybe<Scalars['Boolean']>;
    like_work_other_city?: InputMaybe<Scalars['Boolean']>;
    like_work_other_state?: InputMaybe<Scalars['Boolean']>;
    like_work_other_city_1?: InputMaybe<Scalars['String']>;
    like_work_other_city_2?: InputMaybe<Scalars['String']>;
    like_work_other_city_3?: InputMaybe<Scalars['String']>;
    like_work_other_state_1?: InputMaybe<Scalars['String']>;
    like_work_other_state_2?: InputMaybe<Scalars['String']>;
    like_work_other_state_3?: InputMaybe<Scalars['String']>;
    like_show_on_profile?: InputMaybe<Scalars['Boolean']>;
    show_merit_personality_on_profile?: InputMaybe<Scalars['Boolean']>;
    show_defect_personality_on_profile?: InputMaybe<Scalars['Boolean']>;
    opportunity_less_than_1_year?: InputMaybe<Scalars['Boolean']>;
    opportunity_more_than_1_year?: InputMaybe<Scalars['Boolean']>;
    opportunity_hiring?: InputMaybe<Scalars['Boolean']>;
    opportunity_freelance?: InputMaybe<Scalars['Boolean']>;
    workState?: InputMaybe<UserWorkState>;
    maxDistanceForDisponibilityWork?: InputMaybe<Scalars['Int']>;
    isTourDashboardViewed?: InputMaybe<Scalars['Boolean']>;
    authorization_advertising?: InputMaybe<Scalars['Boolean']>;
    authorization_marketing?: InputMaybe<Scalars['Boolean']>;
    userType?: InputMaybe<UserType>;
    name?: InputMaybe<Scalars['String']>;
    surname?: InputMaybe<Scalars['String']>;
    birth_date?: InputMaybe<Scalars['String']>;
    city_residence?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    like_work_my_city?: InputMaybe<Scalars['Boolean']>;
    like_offers_x_km?: InputMaybe<Scalars['Int']>;
    onboarding_user_step?: InputMaybe<Scalars['String']>;
    note?: InputMaybe<Scalars['String']>;
    biografy?: InputMaybe<Scalars['String']>;
    website?: InputMaybe<Scalars['String']>;
    socials?: InputMaybe<
        Array<InputMaybe<SocialUpdateRequest>> | InputMaybe<SocialUpdateRequest>
    >;
    photo?: InputMaybe<Scalars['String']>;
    screenshot?: InputMaybe<Scalars['String']>;
    curriculum?: InputMaybe<Scalars['String']>;
    curriculumVideo?: InputMaybe<Scalars['String']>;
    skills?: InputMaybe<Array<InputMaybe<UserSkillInput>> | InputMaybe<UserSkillInput>>;
    personalities?: InputMaybe<
        Array<InputMaybe<UserPersonalityInput>> | InputMaybe<UserPersonalityInput>
    >;
    qualifications?: InputMaybe<
        Array<InputMaybe<UserQualificationInput>> | InputMaybe<UserQualificationInput>
    >;
    hobbies?: InputMaybe<
        Array<InputMaybe<GeneralInfoUpdateRequest>> | InputMaybe<GeneralInfoUpdateRequest>
    >;
    spokenLanguages?: InputMaybe<
        Array<InputMaybe<GeneralInfoUpdateRequest>> | InputMaybe<GeneralInfoUpdateRequest>
    >;
    certificatesAchieved?: InputMaybe<
        Array<InputMaybe<GeneralInfoUpdateRequest>> | InputMaybe<GeneralInfoUpdateRequest>
    >;
    certificationsAchieved?: InputMaybe<
        Array<InputMaybe<GeneralInfoUpdateRequest>> | InputMaybe<GeneralInfoUpdateRequest>
    >;
    motherlanguageId?: InputMaybe<Scalars['ID']>;
    englishlanguageId?: InputMaybe<Scalars['ID']>;
    languageId?: InputMaybe<PlatformLanguages>;
}>;

export type UpdateUserMutation = {
    __typename?: 'Mutation';
    updateUser?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type UploadFileMutationVariables = Exact<{
    input: UploadFileInput;
}>;

export type UploadFileMutation = {
    __typename?: 'Mutation';
    uploadFile?: {
        __typename?: 'Media';
        id: string;
        fileName?: string | null;
        fileLabel?: string | null;
    } | null;
};

export type UserConfirmMutationVariables = Exact<{
    registrationCode: Scalars['String'];
}>;

export type UserConfirmMutation = {
    __typename?: 'Mutation';
    userConfirm?: boolean | null;
};

export type UserSignUpMutationVariables = Exact<{
    pwd?: InputMaybe<Scalars['String']>;
    email?: InputMaybe<Scalars['String']>;
    language_id?: InputMaybe<Scalars['String']>;
    companyAssociation?: InputMaybe<Scalars['String']>;
}>;

export type UserSignUpMutation = {
    __typename?: 'Mutation';
    userSignUp?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type UserSignUpAlreadyInAuthProviderMutationVariables = Exact<{
    email: Scalars['String'];
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type UserSignUpAlreadyInAuthProviderMutation = {
    __typename?: 'Mutation';
    userSignUpAlreadyInAuthProvider?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type AddressListQueryVariables = Exact<{
    searchtext?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
    city?: InputMaybe<Scalars['String']>;
}>;

export type AddressListQuery = {
    __typename?: 'Query';
    AddressList?: { __typename?: 'ALIST'; name?: Array<string | null> | null } | null;
};

export type BusinessareaListQueryVariables = Exact<{
    type?: InputMaybe<BusinessareaType>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type BusinessareaListQuery = {
    __typename?: 'Query';
    BusinessareaList?: Array<{
        __typename?: 'Businessarea';
        id: string;
        type: BusinessareaType;
        description: string;
    } | null> | null;
};

export type CityListQueryVariables = Exact<{
    searchtext?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<Scalars['String']>;
    country?: InputMaybe<Scalars['String']>;
}>;

export type CityListQuery = {
    __typename?: 'Query';
    CityList?: { __typename?: 'CLIST'; name?: Array<string | null> | null } | null;
};

export type CompaniesForUserQueryVariables = Exact<{
    companyId?: InputMaybe<Scalars['ID']>;
}>;

export type CompaniesForUserQuery = {
    __typename?: 'Query';
    CompaniesForUser?: Array<{
        __typename?: 'Company';
        id: string;
        url: string;
        name: string;
        biografy?: string | null;
        vatNumber?: string | null;
        country?: CompanyCountry | null;
        privacyUrl?: string | null;
        isApproved?: boolean | null;
        hasOpenActive?: boolean | null;
        creationDate?: string | null;
        website?: string | null;
        businessArea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        services?: Array<{
            __typename?: 'CompanyService';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        quarters?: Array<{
            __typename?: 'CompanyQuarter';
            id: string;
            city?: string | null;
            type?: CompanyHeadquarterType | null;
            location?: string | null;
        } | null> | null;
        employees?: Array<{
            __typename?: 'CompanyEmployee';
            id?: string | null;
            role?: string | null;
            state?: string | null;
            user?: {
                __typename?: 'UserPublic';
                id?: string | null;
                email?: string | null;
                name?: string | null;
                surname?: string | null;
                userType?: string | null;
                birth_date?: string | null;
                city_residence?: string | null;
                biografy?: string | null;
                website?: string | null;
                phone?: string | null;
                languageId?: PlatformLanguages | null;
                photo?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                screenshot?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    des: string;
                    custom?: boolean | null;
                    type?: {
                        __typename?: 'SkillType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null> | null;
                socials?: Array<{
                    __typename?: 'Social';
                    id?: string | null;
                    name?: string | null;
                    value?: string | null;
                } | null> | null;
                educational?: {
                    __typename?: 'Educational';
                    id: string;
                    des: string;
                } | null;
                businessarea?: {
                    __typename?: 'Businessarea';
                    id: string;
                    type: BusinessareaType;
                    description: string;
                } | null;
                personalities?: Array<{
                    __typename?: 'Personality';
                    id: string;
                    section: string;
                    des: string;
                } | null> | null;
                qualifications?: Array<{
                    __typename?: 'UserQualification';
                    id: string;
                    experienceInYears?: number | null;
                    qualification?: {
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null;
                } | null> | null;
                spokenLanguages?: Array<{
                    __typename?: 'GeneralInfo';
                    id?: string | null;
                    value?: string | null;
                    description?: string | null;
                } | null> | null;
            } | null;
        } | null> | null;
        expertiseAreas?: Array<{
            __typename?: 'CompanyExpertiseArea';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        contacts?: Array<{
            __typename?: 'CompanyContact';
            type?: string | null;
            value?: string | null;
        } | null> | null;
        statistics?: Array<{
            __typename?: 'CompanyStatistic';
            name?: string | null;
            value?: string | null;
        } | null> | null;
    } | null> | null;
};

export type CompanyApiKeyQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type CompanyApiKeyQuery = { __typename?: 'Query'; CompanyApiKey?: string | null };

export type CompanyByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CompanyByIdQuery = {
    __typename?: 'Query';
    CompanyById?: {
        __typename?: 'Company';
        id: string;
        url: string;
        name: string;
        biografy?: string | null;
        vatNumber?: string | null;
        country?: CompanyCountry | null;
        privacyUrl?: string | null;
        isApproved?: boolean | null;
        hasOpenActive?: boolean | null;
        creationDate?: string | null;
        website?: string | null;
        businessArea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        services?: Array<{
            __typename?: 'CompanyService';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        quarters?: Array<{
            __typename?: 'CompanyQuarter';
            id: string;
            city?: string | null;
            type?: CompanyHeadquarterType | null;
            location?: string | null;
        } | null> | null;
        employees?: Array<{
            __typename?: 'CompanyEmployee';
            id?: string | null;
            role?: string | null;
            state?: string | null;
            user?: {
                __typename?: 'UserPublic';
                id?: string | null;
                email?: string | null;
                name?: string | null;
                surname?: string | null;
                userType?: string | null;
                birth_date?: string | null;
                city_residence?: string | null;
                biografy?: string | null;
                website?: string | null;
                phone?: string | null;
                languageId?: PlatformLanguages | null;
                photo?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                screenshot?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    des: string;
                    custom?: boolean | null;
                    type?: {
                        __typename?: 'SkillType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null> | null;
                socials?: Array<{
                    __typename?: 'Social';
                    id?: string | null;
                    name?: string | null;
                    value?: string | null;
                } | null> | null;
                educational?: {
                    __typename?: 'Educational';
                    id: string;
                    des: string;
                } | null;
                businessarea?: {
                    __typename?: 'Businessarea';
                    id: string;
                    type: BusinessareaType;
                    description: string;
                } | null;
                personalities?: Array<{
                    __typename?: 'Personality';
                    id: string;
                    section: string;
                    des: string;
                } | null> | null;
                qualifications?: Array<{
                    __typename?: 'UserQualification';
                    id: string;
                    experienceInYears?: number | null;
                    qualification?: {
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null;
                } | null> | null;
                spokenLanguages?: Array<{
                    __typename?: 'GeneralInfo';
                    id?: string | null;
                    value?: string | null;
                    description?: string | null;
                } | null> | null;
            } | null;
        } | null> | null;
        expertiseAreas?: Array<{
            __typename?: 'CompanyExpertiseArea';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        contacts?: Array<{
            __typename?: 'CompanyContact';
            type?: string | null;
            value?: string | null;
        } | null> | null;
        statistics?: Array<{
            __typename?: 'CompanyStatistic';
            name?: string | null;
            value?: string | null;
        } | null> | null;
    } | null;
};

export type CompanyPublicByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CompanyPublicByIdQuery = {
    __typename?: 'Query';
    CompanyPublicById?: {
        __typename?: 'CompanyPublic';
        id: string;
        url: string;
        name: string;
        biografy?: string | null;
        country?: CompanyCountry | null;
        vatNumber?: string | null;
        isApproved?: boolean | null;
        website?: string | null;
        creationDate?: string | null;
        businessArea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        services?: Array<{
            __typename?: 'CompanyService';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        quarters?: Array<{
            __typename?: 'CompanyQuarter';
            id: string;
            city?: string | null;
            type?: CompanyHeadquarterType | null;
            location?: string | null;
        } | null> | null;
        contacts?: Array<{
            __typename?: 'CompanyContact';
            type?: string | null;
            value?: string | null;
        } | null> | null;
        employees?: Array<{
            __typename?: 'CompanyEmployee';
            id?: string | null;
            role?: string | null;
            state?: string | null;
            user?: {
                __typename?: 'UserPublic';
                id?: string | null;
                email?: string | null;
                name?: string | null;
                surname?: string | null;
                userType?: string | null;
                birth_date?: string | null;
                city_residence?: string | null;
                biografy?: string | null;
                website?: string | null;
                phone?: string | null;
                languageId?: PlatformLanguages | null;
                photo?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                screenshot?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    des: string;
                    custom?: boolean | null;
                    type?: {
                        __typename?: 'SkillType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null> | null;
                socials?: Array<{
                    __typename?: 'Social';
                    id?: string | null;
                    name?: string | null;
                    value?: string | null;
                } | null> | null;
                educational?: {
                    __typename?: 'Educational';
                    id: string;
                    des: string;
                } | null;
                businessarea?: {
                    __typename?: 'Businessarea';
                    id: string;
                    type: BusinessareaType;
                    description: string;
                } | null;
                personalities?: Array<{
                    __typename?: 'Personality';
                    id: string;
                    section: string;
                    des: string;
                } | null> | null;
                qualifications?: Array<{
                    __typename?: 'UserQualification';
                    id: string;
                    experienceInYears?: number | null;
                    qualification?: {
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null;
                } | null> | null;
                spokenLanguages?: Array<{
                    __typename?: 'GeneralInfo';
                    id?: string | null;
                    value?: string | null;
                    description?: string | null;
                } | null> | null;
            } | null;
        } | null> | null;
        expertiseAreas?: Array<{
            __typename?: 'CompanyExpertiseArea';
            title?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type CompanyRelationShareSearchQueryVariables = Exact<{
    input?: InputMaybe<CompanyRealtionShareSearchInput>;
}>;

export type CompanyRelationShareSearchQuery = {
    __typename?: 'Query';
    CompanyRelationShareSearch?: {
        __typename?: 'CompanyRealtionShareSearchOutput';
        count?: number | null;
        data?: Array<{
            __typename?: 'CompanyRelationShare';
            id?: string | null;
            type?: SharingCompanyRuleType | null;
            state?: CompanyShareRuleState | null;
            dataVisibility?: SharingCompanyRuleVisibility | null;
            company?: {
                __typename?: 'CompanyPublic';
                id: string;
                url: string;
                name: string;
                biografy?: string | null;
                country?: CompanyCountry | null;
                vatNumber?: string | null;
                isApproved?: boolean | null;
                website?: string | null;
                creationDate?: string | null;
                businessArea?: {
                    __typename?: 'Businessarea';
                    id: string;
                    type: BusinessareaType;
                    description: string;
                } | null;
                coverImage?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                socials?: Array<{
                    __typename?: 'Social';
                    id?: string | null;
                    name?: string | null;
                    value?: string | null;
                } | null> | null;
                services?: Array<{
                    __typename?: 'CompanyService';
                    title?: string | null;
                    description?: string | null;
                } | null> | null;
                quarters?: Array<{
                    __typename?: 'CompanyQuarter';
                    id: string;
                    city?: string | null;
                    type?: CompanyHeadquarterType | null;
                    location?: string | null;
                } | null> | null;
                contacts?: Array<{
                    __typename?: 'CompanyContact';
                    type?: string | null;
                    value?: string | null;
                } | null> | null;
                employees?: Array<{
                    __typename?: 'CompanyEmployee';
                    id?: string | null;
                    role?: string | null;
                    state?: string | null;
                    user?: {
                        __typename?: 'UserPublic';
                        id?: string | null;
                        email?: string | null;
                        name?: string | null;
                        surname?: string | null;
                        userType?: string | null;
                        birth_date?: string | null;
                        city_residence?: string | null;
                        biografy?: string | null;
                        website?: string | null;
                        phone?: string | null;
                        languageId?: PlatformLanguages | null;
                    } | null;
                } | null> | null;
                expertiseAreas?: Array<{
                    __typename?: 'CompanyExpertiseArea';
                    title?: string | null;
                    description?: string | null;
                } | null> | null;
            } | null;
        } | null> | null;
    } | null;
};

export type CompanySearchQueryVariables = Exact<{
    input: CompanySearchRequest;
}>;

export type CompanySearchQuery = {
    __typename?: 'Query';
    CompanySearch?: Array<{
        __typename?: 'CompanyPublic';
        id: string;
        url: string;
        name: string;
        biografy?: string | null;
        country?: CompanyCountry | null;
        vatNumber?: string | null;
        isApproved?: boolean | null;
        website?: string | null;
        creationDate?: string | null;
        businessArea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        services?: Array<{
            __typename?: 'CompanyService';
            title?: string | null;
            description?: string | null;
        } | null> | null;
        quarters?: Array<{
            __typename?: 'CompanyQuarter';
            id: string;
            city?: string | null;
            type?: CompanyHeadquarterType | null;
            location?: string | null;
        } | null> | null;
        contacts?: Array<{
            __typename?: 'CompanyContact';
            type?: string | null;
            value?: string | null;
        } | null> | null;
        employees?: Array<{
            __typename?: 'CompanyEmployee';
            id?: string | null;
            role?: string | null;
            state?: string | null;
            user?: {
                __typename?: 'UserPublic';
                id?: string | null;
                email?: string | null;
                name?: string | null;
                surname?: string | null;
                userType?: string | null;
                birth_date?: string | null;
                city_residence?: string | null;
                biografy?: string | null;
                website?: string | null;
                phone?: string | null;
                languageId?: PlatformLanguages | null;
                photo?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                screenshot?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    des: string;
                    custom?: boolean | null;
                    type?: {
                        __typename?: 'SkillType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null> | null;
                socials?: Array<{
                    __typename?: 'Social';
                    id?: string | null;
                    name?: string | null;
                    value?: string | null;
                } | null> | null;
                educational?: {
                    __typename?: 'Educational';
                    id: string;
                    des: string;
                } | null;
                businessarea?: {
                    __typename?: 'Businessarea';
                    id: string;
                    type: BusinessareaType;
                    description: string;
                } | null;
                personalities?: Array<{
                    __typename?: 'Personality';
                    id: string;
                    section: string;
                    des: string;
                } | null> | null;
                qualifications?: Array<{
                    __typename?: 'UserQualification';
                    id: string;
                    experienceInYears?: number | null;
                    qualification?: {
                        __typename?: 'Qualification';
                        id: string;
                        des: string;
                    } | null;
                } | null> | null;
                spokenLanguages?: Array<{
                    __typename?: 'GeneralInfo';
                    id?: string | null;
                    value?: string | null;
                    description?: string | null;
                } | null> | null;
            } | null;
        } | null> | null;
        expertiseAreas?: Array<{
            __typename?: 'CompanyExpertiseArea';
            title?: string | null;
            description?: string | null;
        } | null> | null;
    } | null> | null;
};

export type CompanyTeamListByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type CompanyTeamListByIdQuery = {
    __typename?: 'Query';
    CompanyTeamListById?: {
        __typename?: 'CompanyTeamList';
        id: string;
        name?: string | null;
        description?: string | null;
        lastUpdate?: string | null;
        creationDate?: string | null;
        savedSearches?: Array<{
            __typename?: 'CompanyTeamListSavedSearch';
            id?: string | null;
            name?: string | null;
            query?: {
                __typename?: 'SavedSearchQueryOutput';
                state?: CompanyCvState | null;
                searchId?: string | null;
                groupId?: string | null;
                companyId?: string | null;
                keywords?: Array<string | null> | null;
                skills?: Array<string | null> | null;
                hiringType?: HiringType | null;
                origin?: SearchCompanyCvOrigin | null;
                locationType?: LocationType | null;
                locationCity?: string | null;
                qualifications?: Array<string | null> | null;
                fullName?: string | null;
            } | null;
            visualization?: {
                __typename?: 'SavedSearchVisualizationOutput';
                row?: number | null;
                skills?: Array<string | null> | null;
                workLoad?: number | null;
                activity?: string | null;
                responsability?: string | null;
                daily_fare?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                experience?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                qualification?: {
                    __typename?: 'Qualification';
                    id: string;
                    des: string;
                    type?: {
                        __typename?: 'QualificationType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null;
            } | null;
            results?: Array<{
                __typename?: 'CompanyTeamListSavedSearchResult';
                percentUsage?: number | null;
                talent?: {
                    __typename?: 'CompanySearchCvOutput';
                    id?: string | null;
                    fullName?: string | null;
                    rating?: number | null;
                    fileName?: string | null;
                    state?: CompanyCvState | null;
                    processNames?: Array<string | null> | null;
                    numberOfRatings?: number | null;
                    primaryQualification?: string | null;
                    lastUpdateDate?: string | null;
                    uploadFileDate?: string | null;
                    savedBy?: number | null;
                    type?: string | null;
                    isPreferred?: boolean | null;
                    origin?: SearchCompanyCvOrigin | null;
                    correspondence?: number | null;
                    keywords?: Array<string | null> | null;
                    visibilityState?: CompanyCvVisibilityState | null;
                    tags?: Array<{
                        __typename?: 'Label';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        isDefault?: boolean | null;
                        description?: string | null;
                    } | null> | null;
                    folders?: Array<{
                        __typename?: 'Folder';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        description?: string | null;
                    } | null> | null;
                    qualification?: {
                        __typename?: 'CompanySearchCvOutputQualification';
                        name?: string | null;
                    } | null;
                    handledBy?: {
                        __typename?: 'CompanySearchCvHandlingOutput';
                        id?: string | null;
                        companyName?: string | null;
                        companyImage?: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type CompanyTeamListSearchQueryVariables = Exact<{
    input?: InputMaybe<SearchCompanyTeamListInput>;
}>;

export type CompanyTeamListSearchQuery = {
    __typename?: 'Query';
    CompanyTeamListSearch?: Array<{
        __typename?: 'CompanyTeamList';
        id: string;
        name?: string | null;
        description?: string | null;
        lastUpdate?: string | null;
        creationDate?: string | null;
        savedSearches?: Array<{
            __typename?: 'CompanyTeamListSavedSearch';
            id?: string | null;
            name?: string | null;
            query?: {
                __typename?: 'SavedSearchQueryOutput';
                state?: CompanyCvState | null;
                searchId?: string | null;
                groupId?: string | null;
                companyId?: string | null;
                keywords?: Array<string | null> | null;
                skills?: Array<string | null> | null;
                hiringType?: HiringType | null;
                origin?: SearchCompanyCvOrigin | null;
                locationType?: LocationType | null;
                locationCity?: string | null;
                qualifications?: Array<string | null> | null;
                fullName?: string | null;
            } | null;
            visualization?: {
                __typename?: 'SavedSearchVisualizationOutput';
                row?: number | null;
                skills?: Array<string | null> | null;
                workLoad?: number | null;
                activity?: string | null;
                responsability?: string | null;
                daily_fare?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                experience?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                qualification?: {
                    __typename?: 'Qualification';
                    id: string;
                    des: string;
                    type?: {
                        __typename?: 'QualificationType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null;
            } | null;
            results?: Array<{
                __typename?: 'CompanyTeamListSavedSearchResult';
                percentUsage?: number | null;
                talent?: {
                    __typename?: 'CompanySearchCvOutput';
                    id?: string | null;
                    fullName?: string | null;
                    rating?: number | null;
                    fileName?: string | null;
                    state?: CompanyCvState | null;
                    processNames?: Array<string | null> | null;
                    numberOfRatings?: number | null;
                    primaryQualification?: string | null;
                    lastUpdateDate?: string | null;
                    uploadFileDate?: string | null;
                    savedBy?: number | null;
                    type?: string | null;
                    isPreferred?: boolean | null;
                    origin?: SearchCompanyCvOrigin | null;
                    correspondence?: number | null;
                    keywords?: Array<string | null> | null;
                    visibilityState?: CompanyCvVisibilityState | null;
                    tags?: Array<{
                        __typename?: 'Label';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        isDefault?: boolean | null;
                        description?: string | null;
                    } | null> | null;
                    folders?: Array<{
                        __typename?: 'Folder';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        description?: string | null;
                    } | null> | null;
                    qualification?: {
                        __typename?: 'CompanySearchCvOutputQualification';
                        name?: string | null;
                    } | null;
                    handledBy?: {
                        __typename?: 'CompanySearchCvHandlingOutput';
                        id?: string | null;
                        companyName?: string | null;
                        companyImage?: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
};

export type CompanyUploadCvGroupByIdQueryVariables = Exact<{
    input?: InputMaybe<CompanyUploadCvGroupByIdRequest>;
}>;

export type CompanyUploadCvGroupByIdQuery = {
    __typename?: 'Query';
    CompanyUploadCVGroupById?: {
        __typename?: 'CompanyUploadCVGroup';
        id?: string | null;
        status?: CompanyUploadGroupStatus | null;
        uploadCount?: number | null;
        description?: string | null;
        creationDate?: string | null;
        dashboardType?: CompanyUploadDashboardType | null;
        resume?: Array<{
            __typename?: 'ResumeStatusFiles';
            status?: CompanyCvProcessStatus | null;
            actual?: number | null;
        } | null> | null;
        uploads?: Array<{
            __typename?: 'CompanyUploadCV';
            id?: string | null;
            url?: string | null;
            name?: string | null;
            creationDate?: string | null;
            processNames?: Array<string | null> | null;
            processStatus?: CompanyCvProcessStatus | null;
        } | null> | null;
    } | null;
};

export type CompanyUploadCvGroupsByCompanyIdQueryVariables = Exact<{
    input?: InputMaybe<CompanyUploadCvGroupsByCompanyIdRequest>;
}>;

export type CompanyUploadCvGroupsByCompanyIdQuery = {
    __typename?: 'Query';
    CompanyUploadCVGroupsByCompanyId?: {
        __typename?: 'CompanyUploadCVGroupResponse';
        count?: number | null;
        data?: Array<{
            __typename?: 'CompanyUploadCVGroup';
            id?: string | null;
            status?: CompanyUploadGroupStatus | null;
            uploadCount?: number | null;
            description?: string | null;
            creationDate?: string | null;
            dashboardType?: CompanyUploadDashboardType | null;
            resume?: Array<{
                __typename?: 'ResumeStatusFiles';
                status?: CompanyCvProcessStatus | null;
                actual?: number | null;
            } | null> | null;
            uploads?: Array<{
                __typename?: 'CompanyUploadCV';
                id?: string | null;
                url?: string | null;
                name?: string | null;
                creationDate?: string | null;
                processNames?: Array<string | null> | null;
                processStatus?: CompanyCvProcessStatus | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type EducationCourseByIdQueryVariables = Exact<{
    id?: InputMaybe<Scalars['ID']>;
}>;

export type EducationCourseByIdQuery = {
    __typename?: 'Query';
    EducationCourseById?: {
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null;
};

export type EducationCourseCalendarQueryVariables = Exact<{
    companyOwnerId?: InputMaybe<Scalars['String']>;
    calendar_from?: InputMaybe<Scalars['String']>;
    calendar_to?: InputMaybe<Scalars['String']>;
}>;

export type EducationCourseCalendarQuery = {
    __typename?: 'Query';
    EducationCourseCalendar?: Array<{
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null> | null;
};

export type EducationCourseListQueryVariables = Exact<{
    companyOwnerId?: InputMaybe<Scalars['String']>;
}>;

export type EducationCourseListQuery = {
    __typename?: 'Query';
    EducationCourseList?: Array<{
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null> | null;
};

export type EducationCourseListByOwnerQueryVariables = Exact<{
    ownerId?: InputMaybe<Scalars['ID']>;
}>;

export type EducationCourseListByOwnerQuery = {
    __typename?: 'Query';
    EducationCourseListByOwner?: Array<{
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null> | null;
};

export type EducationCourseListByStudentQueryVariables = Exact<{
    studentId?: InputMaybe<Scalars['ID']>;
    title?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<CourseStatus>;
    subjectarea?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
    type?: InputMaybe<Array<InputMaybe<CourseType>> | InputMaybe<CourseType>>;
    lessons_from?: InputMaybe<Scalars['Int']>;
    lessons_to?: InputMaybe<Scalars['Int']>;
    filter?: InputMaybe<Scalars['String']>;
    availability?: InputMaybe<
        Array<InputMaybe<CourseAvailability>> | InputMaybe<CourseAvailability>
    >;
}>;

export type EducationCourseListByStudentQuery = {
    __typename?: 'Query';
    EducationCourseListByStudent?: Array<{
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null> | null;
};

export type EducationCourseSearchQueryVariables = Exact<{
    studentId?: InputMaybe<Scalars['String']>;
    companyOwnerId?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
    status?: InputMaybe<CourseStatus>;
    subjectarea?: InputMaybe<
        Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
    >;
    type?: InputMaybe<Array<InputMaybe<CourseType>> | InputMaybe<CourseType>>;
    availability?: InputMaybe<
        Array<InputMaybe<CourseAvailability>> | InputMaybe<CourseAvailability>
    >;
    timing?: InputMaybe<Array<InputMaybe<CourseTiming>> | InputMaybe<CourseTiming>>;
    lessons_from?: InputMaybe<Scalars['Int']>;
    lessons_to?: InputMaybe<Scalars['Int']>;
    hours_from?: InputMaybe<Scalars['Int']>;
    hours_to?: InputMaybe<Scalars['Int']>;
}>;

export type EducationCourseSearchQuery = {
    __typename?: 'Query';
    EducationCourseSearch?: Array<{
        __typename?: 'EducationCourse';
        id?: string | null;
        ownerId?: string | null;
        companyOwnerId?: string | null;
        title?: string | null;
        lessons?: number | null;
        students_min?: number | null;
        students_max?: number | null;
        students_show?: number | null;
        timing?: CourseTiming | null;
        type?: CourseType | null;
        room?: string | null;
        address?: string | null;
        location?: string | null;
        hours?: number | null;
        availability?: CourseAvailability | null;
        link?: string | null;
        status?: CourseStatus | null;
        subjectarea?: string | null;
        info?: string | null;
        info_more?: string | null;
        teachers?: Array<{
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        students?: Array<{
            __typename?: 'Student';
            id?: string | null;
            status?: string | null;
            motivation?: string | null;
            studentId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null> | null;
        educationLessons?: Array<{
            __typename?: 'EducationLesson';
            id?: string | null;
            courseId?: string | null;
            courseTitle?: string | null;
            title?: string | null;
            status?: LessonStatus | null;
            info?: string | null;
            teacherId?: string | null;
            date?: any | null;
            from?: string | null;
            to?: string | null;
            start_url?: string | null;
            join_url?: string | null;
            lesson_url?: string | null;
            teacher?: {
                __typename?: 'Teacher';
                id?: string | null;
                teacherId?: string | null;
                courseId?: string | null;
                name?: string | null;
                surname?: string | null;
                email?: string | null;
                photo?: string | null;
            } | null;
        } | null> | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        videos?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        videos_url?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
        slides?: Array<{
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null> | null;
    } | null> | null;
};

export type EducationLessonQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type EducationLessonQuery = {
    __typename?: 'Query';
    EducationLesson?: {
        __typename?: 'EducationLesson';
        id?: string | null;
        courseId?: string | null;
        courseTitle?: string | null;
        title?: string | null;
        status?: LessonStatus | null;
        info?: string | null;
        teacherId?: string | null;
        date?: any | null;
        from?: string | null;
        to?: string | null;
        start_url?: string | null;
        join_url?: string | null;
        lesson_url?: string | null;
        teacher?: {
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null;
    } | null;
};

export type EducationLessonCalendarByStudentQueryVariables = Exact<{
    studentId?: InputMaybe<Scalars['ID']>;
    calendar_from?: InputMaybe<Scalars['String']>;
    calendar_to?: InputMaybe<Scalars['String']>;
}>;

export type EducationLessonCalendarByStudentQuery = {
    __typename?: 'Query';
    EducationLessonCalendarByStudent?: Array<{
        __typename?: 'EducationLesson';
        id?: string | null;
        courseId?: string | null;
        courseTitle?: string | null;
        title?: string | null;
        status?: LessonStatus | null;
        info?: string | null;
        teacherId?: string | null;
        date?: any | null;
        from?: string | null;
        to?: string | null;
        start_url?: string | null;
        join_url?: string | null;
        lesson_url?: string | null;
        teacher?: {
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null;
    } | null> | null;
};

export type EducationLessonListByCourseQueryVariables = Exact<{
    courseId?: InputMaybe<Scalars['String']>;
}>;

export type EducationLessonListByCourseQuery = {
    __typename?: 'Query';
    EducationLessonListByCourse?: Array<{
        __typename?: 'EducationLesson';
        id?: string | null;
        courseId?: string | null;
        courseTitle?: string | null;
        title?: string | null;
        status?: LessonStatus | null;
        info?: string | null;
        teacherId?: string | null;
        date?: any | null;
        from?: string | null;
        to?: string | null;
        start_url?: string | null;
        join_url?: string | null;
        lesson_url?: string | null;
        teacher?: {
            __typename?: 'Teacher';
            id?: string | null;
            teacherId?: string | null;
            courseId?: string | null;
            name?: string | null;
            surname?: string | null;
            email?: string | null;
            photo?: string | null;
        } | null;
    } | null> | null;
};

export type EducationSearchUserForCoursePartecipantsQueryVariables = Exact<{
    input?: InputMaybe<EducationSearchUserInput>;
}>;

export type EducationSearchUserForCoursePartecipantsQuery = {
    __typename?: 'Query';
    EducationSearchUserForCoursePartecipants?: {
        __typename?: 'EducationSearchUserOutput';
        count?: number | null;
        data?: Array<{
            __typename?: 'EducationSearchUser';
            id?: string | null;
            name?: string | null;
            surname?: string | null;
            photo?: string | null;
            company?: string | null;
            email?: string | null;
            biografy?: string | null;
            cityResidence?: string | null;
            socials?: Array<{
                __typename?: 'Social';
                id?: string | null;
                name?: string | null;
                value?: string | null;
            } | null> | null;
            skills?: Array<{
                __typename?: 'Skill';
                id: string;
                des: string;
                custom?: boolean | null;
                type?: {
                    __typename?: 'SkillType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null> | null;
            languages?: Array<{
                __typename?: 'GeneralInfo';
                id?: string | null;
                value?: string | null;
                description?: string | null;
            } | null> | null;
            qualifications?: Array<{
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type EducationStudentQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type EducationStudentQuery = {
    __typename?: 'Query';
    EducationStudent?: {
        __typename?: 'EducationStudent';
        id?: string | null;
        courseId?: string | null;
        studentId?: string | null;
        studentName?: string | null;
        studentEmail?: string | null;
        status?: StudentCourseStatus | null;
        motivation?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        biografy?: string | null;
        cityResidence?: string | null;
        photo?: string | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        languages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
    } | null;
};

export type EducationStudentListByCourseQueryVariables = Exact<{
    courseId?: InputMaybe<Scalars['String']>;
    searchText?: InputMaybe<Scalars['String']>;
}>;

export type EducationStudentListByCourseQuery = {
    __typename?: 'Query';
    EducationStudentListByCourse?: Array<{
        __typename?: 'EducationStudent';
        id?: string | null;
        courseId?: string | null;
        studentId?: string | null;
        studentName?: string | null;
        studentEmail?: string | null;
        status?: StudentCourseStatus | null;
        motivation?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        biografy?: string | null;
        cityResidence?: string | null;
        photo?: string | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        languages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
    } | null> | null;
};

export type EducationTeacherQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type EducationTeacherQuery = {
    __typename?: 'Query';
    EducationTeacher?: {
        __typename?: 'EducationTeacher';
        id?: string | null;
        courseId?: string | null;
        teacherId?: string | null;
        teacherName?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        email?: string | null;
        companyName?: string | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
    } | null;
};

export type EducationTeacherAvailableQueryVariables = Exact<{
    companyOwnerId?: InputMaybe<Scalars['String']>;
}>;

export type EducationTeacherAvailableQuery = {
    __typename?: 'Query';
    EducationTeacherAvailable?: Array<{
        __typename?: 'EducationTeacher';
        id?: string | null;
        courseId?: string | null;
        teacherId?: string | null;
        teacherName?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        email?: string | null;
        companyName?: string | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
    } | null> | null;
};

export type EducationTeacherListByCourseQueryVariables = Exact<{
    courseId?: InputMaybe<Scalars['String']>;
}>;

export type EducationTeacherListByCourseQuery = {
    __typename?: 'Query';
    EducationTeacherListByCourse?: Array<{
        __typename?: 'EducationTeacher';
        id?: string | null;
        courseId?: string | null;
        teacherId?: string | null;
        teacherName?: string | null;
        completed_perc?: number | null;
        completed_lessons?: number | null;
        email?: string | null;
        companyName?: string | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
    } | null> | null;
};

export type EducationalListQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type EducationalListQuery = {
    __typename?: 'Query';
    EducationalList?: Array<{
        __typename?: 'Educational';
        id: string;
        des: string;
    } | null> | null;
};

export type HiringInterviewByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type HiringInterviewByIdQuery = {
    __typename?: 'Query';
    HiringInterviewById?: {
        __typename?: 'HiringInterview';
        id: string;
        status?: string | null;
        creation_date?: string | null;
        valuation?: string | null;
        hiringprocessId?: string | null;
        reliability?: number | null;
        personality?: number | null;
        potential?: number | null;
        experience?: number | null;
        leadership?: number | null;
    } | null;
};

export type HiringInterviewListByProcessQueryVariables = Exact<{
    hiringprocessId: Scalars['ID'];
}>;

export type HiringInterviewListByProcessQuery = {
    __typename?: 'Query';
    HiringInterviewListByProcess?: Array<{
        __typename?: 'HiringInterview';
        id: string;
        status?: string | null;
        creation_date?: string | null;
        valuation?: string | null;
        hiringprocessId?: string | null;
        reliability?: number | null;
        personality?: number | null;
        potential?: number | null;
        experience?: number | null;
        leadership?: number | null;
    } | null> | null;
};

export type HiringProcessByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type HiringProcessByIdQuery = {
    __typename?: 'Query';
    HiringProcessById?: {
        __typename?: 'HiringProcess';
        id: string;
        status?: string | null;
        creation_date?: string | null;
        description?: string | null;
        target_reference_id?: string | null;
        target_reference_type?: string | null;
        curriculumEmail?: string | null;
        userId?: string | null;
    } | null;
};

export type HiringProposalByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type HiringProposalByIdQuery = {
    __typename?: 'Query';
    HiringProposalById?: {
        __typename?: 'HiringProposal';
        id: string;
        status?: string | null;
        creation_date?: string | null;
        hiringinterviewId?: string | null;
        date1?: string | null;
        date2?: string | null;
        date3?: string | null;
        time1?: string | null;
        time2?: string | null;
        time3?: string | null;
        message?: string | null;
        accepted_date?: number | null;
    } | null;
};

export type HiringProposalListByInterviewQueryVariables = Exact<{
    hiringinterviewId: Scalars['ID'];
}>;

export type HiringProposalListByInterviewQuery = {
    __typename?: 'Query';
    HiringProposalListByInterview?: Array<{
        __typename?: 'HiringProposal';
        id: string;
        status?: string | null;
        creation_date?: string | null;
        hiringinterviewId?: string | null;
        date1?: string | null;
        date2?: string | null;
        date3?: string | null;
        time1?: string | null;
        time2?: string | null;
        time3?: string | null;
        message?: string | null;
        accepted_date?: number | null;
    } | null> | null;
};

export type HiringProposalSendProposalToUserQueryVariables = Exact<{
    hiringproposalId: Scalars['ID'];
}>;

export type HiringProposalSendProposalToUserQuery = {
    __typename?: 'Query';
    HiringProposalSendProposalToUser?: {
        __typename?: 'HiringProposal';
        id: string;
        status?: string | null;
        creation_date?: string | null;
        hiringinterviewId?: string | null;
        date1?: string | null;
        date2?: string | null;
        date3?: string | null;
        time1?: string | null;
        time2?: string | null;
        time3?: string | null;
        message?: string | null;
        accepted_date?: number | null;
    } | null;
};

export type JobAnnouncementByIdQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type JobAnnouncementByIdQuery = {
    __typename?: 'Query';
    JobAnnouncementById?: {
        __typename?: 'JobAnnouncement';
        id?: string | null;
        city?: string | null;
        title?: string | null;
        status?: JobAnnouncementStatus | null;
        privacyURL?: string | null;
        description?: string | null;
        duration?: JobAnnouncentContractDurantion | null;
        hiringType?: HiringType | null;
        contractType?: ContractType | null;
        locationType?: LocationType | null;
        expirationDate?: string | null;
        targetCandidateRalMin?: number | null;
        targetCandidateRalMax?: number | null;
        targetExperienceYearsMin?: number | null;
        targetExperienceYearsMax?: number | null;
        isChatActivate?: boolean | null;
        isJobInterviewRequired?: boolean | null;
        isAssessmentRequired?: boolean | null;
        targetAvailability?: JobAnnouncentTargetAvailability | null;
        isTargetAvailableForTransfers?: boolean | null;
        isTargetAvailableForRoleChange?: boolean | null;
        isTargetAvailableForRelocation?: boolean | null;
        candidaturesCount?: number | null;
        referentId?: string | null;
        owner?: {
            __typename?: 'CompanyPublic';
            id: string;
            url: string;
            name: string;
            biografy?: string | null;
            country?: CompanyCountry | null;
            vatNumber?: string | null;
            isApproved?: boolean | null;
            website?: string | null;
            creationDate?: string | null;
            businessArea?: {
                __typename?: 'Businessarea';
                id: string;
                type: BusinessareaType;
                description: string;
            } | null;
            coverImage?: {
                __typename?: 'Media';
                id: string;
                fileName?: string | null;
                fileLabel?: string | null;
            } | null;
            socials?: Array<{
                __typename?: 'Social';
                id?: string | null;
                name?: string | null;
                value?: string | null;
            } | null> | null;
            services?: Array<{
                __typename?: 'CompanyService';
                title?: string | null;
                description?: string | null;
            } | null> | null;
            quarters?: Array<{
                __typename?: 'CompanyQuarter';
                id: string;
                city?: string | null;
                type?: CompanyHeadquarterType | null;
                location?: string | null;
            } | null> | null;
            contacts?: Array<{
                __typename?: 'CompanyContact';
                type?: string | null;
                value?: string | null;
            } | null> | null;
            employees?: Array<{
                __typename?: 'CompanyEmployee';
                id?: string | null;
                role?: string | null;
                state?: string | null;
                user?: {
                    __typename?: 'UserPublic';
                    id?: string | null;
                    email?: string | null;
                    name?: string | null;
                    surname?: string | null;
                    userType?: string | null;
                    birth_date?: string | null;
                    city_residence?: string | null;
                    biografy?: string | null;
                    website?: string | null;
                    phone?: string | null;
                    languageId?: PlatformLanguages | null;
                    photo?: {
                        __typename?: 'Media';
                        id: string;
                        fileName?: string | null;
                        fileLabel?: string | null;
                    } | null;
                    screenshot?: {
                        __typename?: 'Media';
                        id: string;
                        fileName?: string | null;
                        fileLabel?: string | null;
                    } | null;
                    skills?: Array<{
                        __typename?: 'Skill';
                        id: string;
                        des: string;
                        custom?: boolean | null;
                    } | null> | null;
                    socials?: Array<{
                        __typename?: 'Social';
                        id?: string | null;
                        name?: string | null;
                        value?: string | null;
                    } | null> | null;
                    educational?: {
                        __typename?: 'Educational';
                        id: string;
                        des: string;
                    } | null;
                    businessarea?: {
                        __typename?: 'Businessarea';
                        id: string;
                        type: BusinessareaType;
                        description: string;
                    } | null;
                    personalities?: Array<{
                        __typename?: 'Personality';
                        id: string;
                        section: string;
                        des: string;
                    } | null> | null;
                    qualifications?: Array<{
                        __typename?: 'UserQualification';
                        id: string;
                        experienceInYears?: number | null;
                    } | null> | null;
                    spokenLanguages?: Array<{
                        __typename?: 'GeneralInfo';
                        id?: string | null;
                        value?: string | null;
                        description?: string | null;
                    } | null> | null;
                } | null;
            } | null> | null;
            expertiseAreas?: Array<{
                __typename?: 'CompanyExpertiseArea';
                title?: string | null;
                description?: string | null;
            } | null> | null;
        } | null;
        previewImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        coverImage?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        targetDisiredSkills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        qualification?: {
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null;
        referent?: {
            __typename?: 'Referent';
            id?: string | null;
            name?: string | null;
            surname?: string | null;
        } | null;
    } | null;
};

export type LanguageListQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type LanguageListQuery = {
    __typename?: 'Query';
    LanguageList?: Array<{
        __typename?: 'Language';
        id: string;
        des: string;
    } | null> | null;
};

export type NationListQueryVariables = Exact<{
    searchtext?: InputMaybe<Scalars['String']>;
    language?: InputMaybe<Scalars['String']>;
}>;

export type NationListQuery = {
    __typename?: 'Query';
    NationList?: { __typename?: 'NLIST'; name?: Array<string | null> | null } | null;
};

export type PasswordPolicyQueryVariables = Exact<{ [key: string]: never }>;

export type PasswordPolicyQuery = {
    __typename?: 'Query';
    PasswordPolicy?: Array<{
        __typename?: 'Policy';
        key?: string | null;
        value?: string | null;
        regex?: string | null;
        type?: PolicyTypes | null;
    } | null> | null;
};

export type PersonalityListQueryVariables = Exact<{
    personalitySection: Scalars['String'];
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type PersonalityListQuery = {
    __typename?: 'Query';
    PersonalityList?: Array<{
        __typename?: 'Personality';
        id: string;
        section: string;
        des: string;
    } | null> | null;
};

export type PrivacyLastQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type PrivacyLastQuery = {
    __typename?: 'Query';
    PrivacyLast?: {
        __typename?: 'Privacy';
        id: string;
        title?: string | null;
        content?: string | null;
    } | null;
};

export type PrivacyListQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type PrivacyListQuery = {
    __typename?: 'Query';
    PrivacyList?: Array<{
        __typename?: 'Privacy';
        id: string;
        title?: string | null;
        content?: string | null;
    } | null> | null;
};

export type ProposalsBySurveyQuestionIdQueryVariables = Exact<{
    surveyQuestionId: Scalars['ID'];
    userId?: InputMaybe<Scalars['ID']>;
}>;

export type ProposalsBySurveyQuestionIdQuery = {
    __typename?: 'Query';
    ProposalsBySurveyQuestionId?: {
        __typename?: 'SurveyQuestionProposal';
        id: string;
        orderby?: number | null;
        label?: string | null;
        value?: number | null;
        minvalue?: number | null;
        maxvalue?: number | null;
        preselectedminvalue?: number | null;
        preselectedmaxvalue?: number | null;
        minlabel?: string | null;
        maxlabel?: string | null;
        currentUserAnswer?: string | null;
    } | null;
};

export type QualificationAreaSearchQueryVariables = Exact<{
    input?: InputMaybe<QualificationAreaSearchInput>;
}>;

export type QualificationAreaSearchQuery = {
    __typename?: 'Query';
    QualificationAreaSearch?: Array<{
        __typename?: 'QualificationArea';
        id: string;
        name: string;
    } | null> | null;
};

export type QualificationListQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type QualificationListQuery = {
    __typename?: 'Query';
    QualificationList?: Array<{
        __typename?: 'Qualification';
        id: string;
        des: string;
        type?: {
            __typename?: 'QualificationType';
            id: string;
            des: string;
            custom?: number | null;
        } | null;
    } | null> | null;
};

export type QualificationSearchQueryVariables = Exact<{
    input: QualificationSearchInput;
}>;

export type QualificationSearchQuery = {
    __typename?: 'Query';
    QualificationSearch?: Array<{
        __typename?: 'Qualification';
        id: string;
        des: string;
        type?: {
            __typename?: 'QualificationType';
            id: string;
            des: string;
            custom?: number | null;
        } | null;
    } | null> | null;
};

export type QualificationTypeListQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type QualificationTypeListQuery = {
    __typename?: 'Query';
    QualificationTypeList?: Array<{
        __typename?: 'QualificationType';
        id: string;
        des: string;
        custom?: number | null;
    } | null> | null;
};

export type SearchJobAnnouncementQueryVariables = Exact<{
    input?: InputMaybe<SearchJobAnnouncementRequest>;
}>;

export type SearchJobAnnouncementQuery = {
    __typename?: 'Query';
    SearchJobAnnouncement?: {
        __typename?: 'SearchJobAnnouncementResponse';
        count?: number | null;
        jobAnnouncements?: Array<{
            __typename?: 'JobAnnouncement';
            id?: string | null;
            city?: string | null;
            title?: string | null;
            status?: JobAnnouncementStatus | null;
            privacyURL?: string | null;
            description?: string | null;
            duration?: JobAnnouncentContractDurantion | null;
            hiringType?: HiringType | null;
            contractType?: ContractType | null;
            locationType?: LocationType | null;
            expirationDate?: string | null;
            targetCandidateRalMin?: number | null;
            targetCandidateRalMax?: number | null;
            targetExperienceYearsMin?: number | null;
            targetExperienceYearsMax?: number | null;
            isChatActivate?: boolean | null;
            isJobInterviewRequired?: boolean | null;
            isAssessmentRequired?: boolean | null;
            targetAvailability?: JobAnnouncentTargetAvailability | null;
            isTargetAvailableForTransfers?: boolean | null;
            isTargetAvailableForRoleChange?: boolean | null;
            isTargetAvailableForRelocation?: boolean | null;
            candidaturesCount?: number | null;
            referentId?: string | null;
            owner?: {
                __typename?: 'CompanyPublic';
                id: string;
                url: string;
                name: string;
                biografy?: string | null;
                country?: CompanyCountry | null;
                vatNumber?: string | null;
                isApproved?: boolean | null;
                website?: string | null;
                creationDate?: string | null;
                businessArea?: {
                    __typename?: 'Businessarea';
                    id: string;
                    type: BusinessareaType;
                    description: string;
                } | null;
                coverImage?: {
                    __typename?: 'Media';
                    id: string;
                    fileName?: string | null;
                    fileLabel?: string | null;
                } | null;
                socials?: Array<{
                    __typename?: 'Social';
                    id?: string | null;
                    name?: string | null;
                    value?: string | null;
                } | null> | null;
                services?: Array<{
                    __typename?: 'CompanyService';
                    title?: string | null;
                    description?: string | null;
                } | null> | null;
                quarters?: Array<{
                    __typename?: 'CompanyQuarter';
                    id: string;
                    city?: string | null;
                    type?: CompanyHeadquarterType | null;
                    location?: string | null;
                } | null> | null;
                contacts?: Array<{
                    __typename?: 'CompanyContact';
                    type?: string | null;
                    value?: string | null;
                } | null> | null;
                employees?: Array<{
                    __typename?: 'CompanyEmployee';
                    id?: string | null;
                    role?: string | null;
                    state?: string | null;
                    user?: {
                        __typename?: 'UserPublic';
                        id?: string | null;
                        email?: string | null;
                        name?: string | null;
                        surname?: string | null;
                        userType?: string | null;
                        birth_date?: string | null;
                        city_residence?: string | null;
                        biografy?: string | null;
                        website?: string | null;
                        phone?: string | null;
                        languageId?: PlatformLanguages | null;
                    } | null;
                } | null> | null;
                expertiseAreas?: Array<{
                    __typename?: 'CompanyExpertiseArea';
                    title?: string | null;
                    description?: string | null;
                } | null> | null;
            } | null;
            previewImage?: {
                __typename?: 'Media';
                id: string;
                fileName?: string | null;
                fileLabel?: string | null;
            } | null;
            coverImage?: {
                __typename?: 'Media';
                id: string;
                fileName?: string | null;
                fileLabel?: string | null;
            } | null;
            targetDisiredSkills?: Array<{
                __typename?: 'Skill';
                id: string;
                des: string;
                custom?: boolean | null;
                type?: {
                    __typename?: 'SkillType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null> | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};

export type SkillListQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type SkillListQuery = {
    __typename?: 'Query';
    SkillList?: Array<{
        __typename?: 'Skill';
        id: string;
        des: string;
        custom?: boolean | null;
        type?: {
            __typename?: 'SkillType';
            id: string;
            des: string;
            custom?: number | null;
        } | null;
    } | null> | null;
};

export type SkillSearchQueryVariables = Exact<{
    text: Scalars['String'];
}>;

export type SkillSearchQuery = {
    __typename?: 'Query';
    SkillSearch?: Array<{
        __typename?: 'Skill';
        id: string;
        des: string;
        custom?: boolean | null;
        type?: {
            __typename?: 'SkillType';
            id: string;
            des: string;
            custom?: number | null;
        } | null;
    } | null> | null;
};

export type SkillSuggestionsForQualificationTypeQueryVariables = Exact<{
    qualificationTypeId: Scalars['ID'];
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type SkillSuggestionsForQualificationTypeQuery = {
    __typename?: 'Query';
    SkillSuggestionsForQualificationType?: Array<{
        __typename?: 'Skill';
        id: string;
        des: string;
        custom?: boolean | null;
        type?: {
            __typename?: 'SkillType';
            id: string;
            des: string;
            custom?: number | null;
        } | null;
    } | null> | null;
};

export type SkillTypeListQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type SkillTypeListQuery = {
    __typename?: 'Query';
    SkillTypeList?: Array<{
        __typename?: 'SkillType';
        id: string;
        des: string;
        custom?: number | null;
    } | null> | null;
};

export type SkillsForQualificationQueryVariables = Exact<{
    qualificationId: Scalars['String'];
    language_id?: InputMaybe<Scalars['String']>;
    count?: InputMaybe<Scalars['Int']>;
}>;

export type SkillsForQualificationQuery = {
    __typename?: 'Query';
    SkillsForQualification?: Array<{
        __typename?: 'Skill';
        id: string;
        des: string;
        custom?: boolean | null;
        type?: {
            __typename?: 'SkillType';
            id: string;
            des: string;
            custom?: number | null;
        } | null;
    } | null> | null;
};

export type SurveyQueryVariables = Exact<{
    id: Scalars['ID'];
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type SurveyQuery = {
    __typename?: 'Query';
    Survey?: {
        __typename?: 'Survey';
        id: string;
        releaseId?: string | null;
        title: string;
        orderby?: number | null;
        questions?: Array<{
            __typename?: 'SurveyQuestion';
            id: string;
            surveyId?: string | null;
            orderby?: number | null;
            label?: string | null;
            sublabel?: string | null;
            type?: string | null;
            code?: string | null;
            unitmis?: string | null;
            proposals?: Array<{
                __typename?: 'SurveyQuestionProposal';
                id: string;
                orderby?: number | null;
                label?: string | null;
                value?: number | null;
                minvalue?: number | null;
                maxvalue?: number | null;
                preselectedminvalue?: number | null;
                preselectedmaxvalue?: number | null;
                minlabel?: string | null;
                maxlabel?: string | null;
                currentUserAnswer?: string | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type SurveyAllQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type SurveyAllQuery = {
    __typename?: 'Query';
    SurveyAll?: Array<{
        __typename?: 'Survey';
        id: string;
        releaseId?: string | null;
        title: string;
        orderby?: number | null;
        questions?: Array<{
            __typename?: 'SurveyQuestion';
            id: string;
            surveyId?: string | null;
            orderby?: number | null;
            label?: string | null;
            sublabel?: string | null;
            type?: string | null;
            code?: string | null;
            unitmis?: string | null;
            proposals?: Array<{
                __typename?: 'SurveyQuestionProposal';
                id: string;
                orderby?: number | null;
                label?: string | null;
                value?: number | null;
                minvalue?: number | null;
                maxvalue?: number | null;
                preselectedminvalue?: number | null;
                preselectedmaxvalue?: number | null;
                minlabel?: string | null;
                maxlabel?: string | null;
                currentUserAnswer?: string | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
};

export type SurveyUserAnswerByQuestionIdQueryVariables = Exact<{
    questionId: Scalars['ID'];
    userId?: InputMaybe<Scalars['ID']>;
}>;

export type SurveyUserAnswerByQuestionIdQuery = {
    __typename?: 'Query';
    SurveyUserAnswerByQuestionId?: {
        __typename?: 'SurveyQuestionProposal';
        id: string;
        orderby?: number | null;
        label?: string | null;
        value?: number | null;
        minvalue?: number | null;
        maxvalue?: number | null;
        preselectedminvalue?: number | null;
        preselectedmaxvalue?: number | null;
        minlabel?: string | null;
        maxlabel?: string | null;
        currentUserAnswer?: string | null;
    } | null;
};

export type SurveyUserAnswerByUserIdQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type SurveyUserAnswerByUserIdQuery = {
    __typename?: 'Query';
    SurveyUserAnswerByUserId?: Array<{
        __typename?: 'SurveyQuestionProposal';
        id: string;
        orderby?: number | null;
        label?: string | null;
        value?: number | null;
        minvalue?: number | null;
        maxvalue?: number | null;
        preselectedminvalue?: number | null;
        preselectedmaxvalue?: number | null;
        minlabel?: string | null;
        maxlabel?: string | null;
        currentUserAnswer?: string | null;
    } | null> | null;
};

export type TermConditionByUserOrCompanyQueryVariables = Exact<{
    id?: InputMaybe<Scalars['String']>;
    type?: InputMaybe<TermConditionType>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type TermConditionByUserOrCompanyQuery = {
    __typename?: 'Query';
    TermConditionByUserOrCompany?: {
        __typename?: 'TermCondition';
        id: string;
        title?: string | null;
        content?: string | null;
    } | null;
};

export type TermConditionLastQueryVariables = Exact<{
    type?: InputMaybe<TermConditionType>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type TermConditionLastQuery = {
    __typename?: 'Query';
    TermConditionLast?: {
        __typename?: 'TermCondition';
        id: string;
        title?: string | null;
        content?: string | null;
    } | null;
};

export type TermConditionListQueryVariables = Exact<{
    type?: InputMaybe<TermConditionType>;
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type TermConditionListQuery = {
    __typename?: 'Query';
    TermConditionList?: Array<{
        __typename?: 'TermCondition';
        id: string;
        title?: string | null;
        content?: string | null;
    } | null> | null;
};

export type UserQueryVariables = Exact<{
    id?: InputMaybe<Scalars['String']>;
}>;

export type UserQuery = {
    __typename?: 'Query';
    User?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type UserByTokenQueryVariables = Exact<{ [key: string]: never }>;

export type UserByTokenQuery = {
    __typename?: 'Query';
    UserByToken?: {
        __typename?: 'User';
        id?: string | null;
        token?: string | null;
        email?: string | null;
        like_work_home?: boolean | null;
        like_work_other_city?: boolean | null;
        like_work_other_state?: boolean | null;
        like_work_other_city_1?: string | null;
        like_work_other_city_2?: string | null;
        like_work_other_city_3?: string | null;
        like_work_other_state_1?: string | null;
        like_work_other_state_2?: string | null;
        like_work_other_state_3?: string | null;
        like_show_on_profile?: boolean | null;
        show_merit_personality_on_profile?: boolean | null;
        show_defect_personality_on_profile?: boolean | null;
        workState?: UserWorkState | null;
        maxDistanceForDisponibilityWork?: number | null;
        opportunity_less_than_1_year?: boolean | null;
        opportunity_more_than_1_year?: boolean | null;
        opportunity_hiring?: boolean | null;
        opportunity_freelance?: boolean | null;
        authorization_advertising?: boolean | null;
        authorization_marketing?: boolean | null;
        userType?: string | null;
        name?: string | null;
        surname?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        like_work_my_city?: boolean | null;
        like_offers_x_km?: number | null;
        onboarding_user_step?: string | null;
        website?: string | null;
        phone?: string | null;
        hash_code?: string | null;
        note?: string | null;
        biografy?: string | null;
        languageId?: PlatformLanguages | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculum?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        curriculumVideo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        actionsToDo?: Array<{
            __typename?: 'UserToDoAction';
            type?: string | null;
            message?: string | null;
            priority?: string | null;
        } | null> | null;
        currentAppVisualization?: {
            __typename?: 'CurrentAppVisualization';
            type?: CurrentAppVisualizationType | null;
            companyId?: string | null;
        } | null;
        hobbies?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificatesAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
        certificationsAchieved?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type UserPublicQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type UserPublicQuery = {
    __typename?: 'Query';
    UserPublic?: {
        __typename?: 'UserPublic';
        id?: string | null;
        email?: string | null;
        name?: string | null;
        surname?: string | null;
        userType?: string | null;
        birth_date?: string | null;
        city_residence?: string | null;
        biografy?: string | null;
        website?: string | null;
        phone?: string | null;
        languageId?: PlatformLanguages | null;
        photo?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        screenshot?: {
            __typename?: 'Media';
            id: string;
            fileName?: string | null;
            fileLabel?: string | null;
        } | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        socials?: Array<{
            __typename?: 'Social';
            id?: string | null;
            name?: string | null;
            value?: string | null;
        } | null> | null;
        educational?: { __typename?: 'Educational'; id: string; des: string } | null;
        businessarea?: {
            __typename?: 'Businessarea';
            id: string;
            type: BusinessareaType;
            description: string;
        } | null;
        personalities?: Array<{
            __typename?: 'Personality';
            id: string;
            section: string;
            des: string;
        } | null> | null;
        qualifications?: Array<{
            __typename?: 'UserQualification';
            id: string;
            experienceInYears?: number | null;
            qualification?: {
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null;
        } | null> | null;
        spokenLanguages?: Array<{
            __typename?: 'GeneralInfo';
            id?: string | null;
            value?: string | null;
            description?: string | null;
        } | null> | null;
    } | null;
};

export type CanEditCompanyCvDataElaborationsQueryVariables = Exact<{
    companyId?: InputMaybe<Scalars['String']>;
    reference: CvReference;
}>;

export type CanEditCompanyCvDataElaborationsQuery = {
    __typename?: 'Query';
    canEditCompanyCvDataElaborations?: boolean | null;
};

export type CandidateByJobAnnouncementIdQueryVariables = Exact<{
    jobAnnouncementId: Scalars['ID'];
    length?: InputMaybe<Scalars['Int']>;
    offset?: InputMaybe<Scalars['Int']>;
}>;

export type CandidateByJobAnnouncementIdQuery = {
    __typename?: 'Query';
    candidateByJobAnnouncementId?: {
        __typename?: 'CandidateByJobAnnouncementIdResponse';
        count?: number | null;
        data?: Array<{
            __typename?: 'CandidateOutput';
            id?: string | null;
            fullName?: string | null;
            rating?: number | null;
            fileName?: string | null;
            cvDocUrl?: string | null;
            numberOfRatings?: number | null;
            primaryQualification?: string | null;
            uploadFileDate?: string | null;
            savedBy?: number | null;
            type?: string | null;
            isPreferred?: boolean | null;
            processNames?: Array<string | null> | null;
            processStatus?: CompanyCvProcessStatus | null;
            candidateState?: CandidateState | null;
            candidateStateReason?: string | null;
            origin?: SearchCompanyCvOrigin | null;
            correspondence?: number | null;
            visibilityState?: CompanyCvVisibilityState | null;
            handledBy?: {
                __typename?: 'CompanySearchCvHandlingOutput';
                id?: string | null;
                companyName?: string | null;
                companyImage?: string | null;
            } | null;
            qualification?: {
                __typename?: 'CompanySearchCvOutputQualification';
                name?: string | null;
                translations?: Array<{
                    __typename?: 'CompanySearchCvOutputQualificationTranslation';
                    id?: string | null;
                    value?: string | null;
                } | null> | null;
            } | null;
        } | null> | null;
    } | null;
};

export type CityListForSearchQueryVariables = Exact<{
    searchtext?: InputMaybe<Scalars['String']>;
}>;

export type CityListForSearchQuery = {
    __typename?: 'Query';
    cityListForSearch?: {
        __typename?: 'CLIST';
        name?: Array<string | null> | null;
    } | null;
};

export type DatailForCompanyUploadCvQueryVariables = Exact<{
    id: Scalars['ID'];
    companyId: Scalars['String'];
}>;

export type DatailForCompanyUploadCvQuery = {
    __typename?: 'Query';
    datailForCompanyUploadCv?: {
        __typename?: 'CompanyUploadCvElaborationDetail';
        id?: string | null;
        age?: number | null;
        type?: string | null;
        city?: string | null;
        email?: string | null;
        phone?: string | null;
        summary?: string | null;
        sources?: Array<string | null> | null;
        fileName?: string | null;
        fullName?: string | null;
        cvDocUrl?: string | null;
        cvVideoUrl?: string | null;
        isPreferred?: boolean | null;
        processNames?: Array<string | null> | null;
        processStatus?: CompanyCvProcessStatus | null;
        lastUpdateDate?: string | null;
        handledBy?: {
            __typename?: 'CompanyCvHandlerOutput';
            companyId?: string | null;
            companyName?: string | null;
            companyImage?: string | null;
            userPhone?: string | null;
            userEmail?: string | null;
        } | null;
        scores?: Array<{
            __typename?: 'CompanyUploadCvElaborationDetailScore';
            id?: string | null;
            score?: number | null;
            description?: string | null;
        } | null> | null;
        topSkills?: Array<{
            __typename?: 'CompanyUploadCvElaborationDetailSkill';
            id?: string | null;
            score?: number | null;
            description?: string | null;
        } | null> | null;
        primaryQualification?: {
            __typename?: 'CompanyUploadCvElaborationDetailQualification';
            id?: string | null;
            rate?: number | null;
            description?: string | null;
        } | null;
        resumeExperience?: {
            __typename?: 'CompanyUploadCvElaborationDetailResumeExperience';
            sector?: string | null;
            turnover?: number | null;
            seniority?: string | null;
            rangeExperienceFromInYears?: number | null;
            rangeExperienceToInYears?: number | null;
            companyDimension?: number | null;
            companies?: Array<string | null> | null;
            training?: Array<string | null> | null;
            secondaryQualification?: {
                __typename?: 'CompanyUploadCvElaborationDetailQualification';
                id?: string | null;
                rate?: number | null;
                description?: string | null;
            } | null;
        } | null;
        otherInfo?: {
            __typename?: 'CompanyCVOtherInfo';
            addressLine1?: string | null;
            addressLine2?: string | null;
            city?: string | null;
            state?: string | null;
            postalCode?: string | null;
            country?: string | null;
            experienceYears?: CvExperienceYears | null;
            selfDescription?: string | null;
            USCitizenship?: boolean | null;
            citizenshipStatus?: CvCitizenshipStatus | null;
            citizenshipStatusOther?: string | null;
            ageOver18?: boolean | null;
            alreadyApplied?: boolean | null;
            alreadyAppliedInfo?: string | null;
            otherPeopleWorkers?: boolean | null;
            otherPeopleWorkersInfo?: string | null;
            haveTransportation?: boolean | null;
            submitDrugTest?: boolean | null;
            workInHighSchool?: boolean | null;
            workInHighSchoolInfo?: string | null;
            isVolunteer?: boolean | null;
            isVolunteerInfo?: string | null;
            hasPets?: boolean | null;
            hasPetsInfo?: CvPets | null;
            petsCount?: number | null;
            currentlyEmployed?: boolean | null;
            currentlyEmployedYear?: boolean | null;
            currentSalary?: number | null;
            desiredSalary?: number | null;
            availableWorkDaysHours?: string | null;
            hiringStartDate?: string | null;
            workOnWeekend?: boolean | null;
            workEvenings?: boolean | null;
            workOvertime?: boolean | null;
            checkbox1Title?: string | null;
            checkbox1Value?: boolean | null;
            checkbox2Title?: string | null;
            checkbox2Value?: boolean | null;
            checkbox3Title?: string | null;
            checkbox3Value?: boolean | null;
            experienceIT?: boolean | null;
            experienceProjectManager?: boolean | null;
            experienceLeadership?: boolean | null;
            experienceCoreNetworkingTechnologies?: boolean | null;
            experienceLeargeScaleNetworkingSystems?: boolean | null;
            experienceManagingPartner?: boolean | null;
            experienceCustomerService?: boolean | null;
            experienceFirewall?: boolean | null;
            experienceVirtualization?: boolean | null;
            experienceOffice365?: boolean | null;
            experienceAzure?: boolean | null;
            militaryBranch?: string | null;
            militaryRank?: string | null;
            militaryYears?: number | null;
            militarySkills?: string | null;
            militaryDetails?: string | null;
            vocationalSchoolName?: string | null;
            vocationalSchoolAddressLine1?: string | null;
            vocationalSchoolAddressLine2?: string | null;
            vocationalSchoolCity?: string | null;
            vocationalSchoolState?: string | null;
            vocationalSchoolPostalCode?: string | null;
            vocationalSchoolCountry?: string | null;
            vocationalSchoolYears?: number | null;
            vocationalSchoolGraduate?: boolean | null;
            vocationalSchoolDiploma?: string | null;
            universitySchoolName?: string | null;
            universitySchoolAddressLine1?: string | null;
            universitySchoolAddressLine2?: string | null;
            universitySchoolCity?: string | null;
            universitySchoolState?: string | null;
            universitySchoolPostalCode?: string | null;
            universitySchoolCountry?: string | null;
            universitySchoolYears?: number | null;
            universitySchoolGraduate?: boolean | null;
            universitySchoolDiploma?: string | null;
            certificationName?: string | null;
            certificationIssuingOrganization?: string | null;
            certificationNumber?: string | null;
            certificationDate?: string | null;
            additionalCertificationName?: string | null;
            additionalCertificationIssuingOrganization?: string | null;
            additionalCertificationNumber?: string | null;
            additionalCertificationDate?: string | null;
        } | null;
    } | null;
};

export type DatailForCompanyUploadCv2QueryVariables = Exact<{
    id: Scalars['ID'];
    companyId: Scalars['String'];
}>;

export type DatailForCompanyUploadCv2Query = {
    __typename?: 'Query';
    datailForCompanyUploadCv2?: {
        __typename?: 'CompanyUploadCvElaborationDetail2';
        id?: string | null;
        type?: string | null;
        state?: CompanyCvState | null;
        origin?: SearchCompanyCvOrigin | null;
        sources?: Array<string | null> | null;
        fileName?: string | null;
        isPreferred?: boolean | null;
        processNames?: Array<string | null> | null;
        processStatus?: CompanyCvProcessStatus | null;
        lastUpdateDate?: string | null;
        visibilityState?: CompanyCvVisibilityState | null;
        tags?: Array<{
            __typename?: 'Label';
            id?: string | null;
            name?: string | null;
            parentId?: string | null;
            isDefault?: boolean | null;
            description?: string | null;
        } | null> | null;
        scores?: Array<{
            __typename?: 'CompanyUploadCvElaborationDetailScore';
            id?: string | null;
            score?: number | null;
            description?: string | null;
        } | null> | null;
        folders?: Array<{
            __typename?: 'Folder';
            id?: string | null;
            name?: string | null;
            parentId?: string | null;
            description?: string | null;
            qualifications?: Array<{
                __typename?: 'Qualification';
                id: string;
                des: string;
                type?: {
                    __typename?: 'QualificationType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null> | null;
            skills?: Array<{
                __typename?: 'Skill';
                id: string;
                des: string;
                custom?: boolean | null;
                type?: {
                    __typename?: 'SkillType';
                    id: string;
                    des: string;
                    custom?: number | null;
                } | null;
            } | null> | null;
        } | null> | null;
        keywords?: Array<{
            __typename?: 'DataElaborationValueAndPercentageOutput';
            percentage?: number | null;
            definition?: string | null;
            description?: string | null;
            definitions?: Array<{
                __typename?: 'DataElaborationValueAndPercentageOutputTranslation';
                id?: string | null;
                value?: string | null;
            } | null> | null;
            translations?: Array<{
                __typename?: 'DataElaborationValueAndPercentageOutputTranslation';
                id?: string | null;
                value?: string | null;
            } | null> | null;
        } | null> | null;
        topKeywords?: Array<{
            __typename?: 'CompanyUploadCvElaborationTopKeywords';
            lang?: string | null;
            name?: string | null;
            score?: number | null;
        } | null> | null;
        handledBy?: {
            __typename?: 'CompanyCvHandlerOutput';
            companyId?: string | null;
            companyName?: string | null;
            companyImage?: string | null;
            userPhone?: string | null;
            userEmail?: string | null;
        } | null;
        personalInfo?: {
            __typename?: 'CompanyUploadCvElaborationDetailPersonalInfo';
            age?: number | null;
            email?: string | null;
            phone?: string | null;
            cvDocUrl?: string | null;
            fullName?: string | null;
            birthDate?: string | null;
            cvVideoUrl?: string | null;
            residenceCity?: string | null;
            isProtectedCategory?: boolean | null;
            personalities?: Array<{
                __typename?: 'CompanyUploadCvElaborationDetailPersonality';
                type?: string | null;
                description?: string | null;
            } | null> | null;
            workPreferences?: {
                __typename?: 'CompanyUploadCvElaborationDetailPersonalInfoWorkPreference';
                home?: boolean | null;
                myCity?: boolean | null;
                cities?: Array<string | null> | null;
                countries?: Array<string | null> | null;
            } | null;
        } | null;
        resumeExperience?: {
            __typename?: 'CompanyUploadCvElaborationDetailResumeExperience2';
            sectors?: Array<string | null> | null;
            summaries?: Array<string | null> | null;
            linkedIn?: string | null;
            training?: Array<string | null> | null;
            turnover?: number | null;
            seniority?: string | null;
            companies?: Array<string | null> | null;
            companyDimension?: number | null;
            rangeExperienceToInYears?: number | null;
            rangeExperienceFromInYears?: number | null;
            skills?: Array<{
                __typename?: 'DataElaborationValueAndPercentageOutput';
                percentage?: number | null;
                definition?: string | null;
                description?: string | null;
                definitions?: Array<{
                    __typename?: 'DataElaborationValueAndPercentageOutputTranslation';
                    id?: string | null;
                    value?: string | null;
                } | null> | null;
                translations?: Array<{
                    __typename?: 'DataElaborationValueAndPercentageOutputTranslation';
                    id?: string | null;
                    value?: string | null;
                } | null> | null;
            } | null> | null;
            qualifications?: Array<{
                __typename?: 'DataElaborationValueAndPercentageOutput';
                percentage?: number | null;
                definition?: string | null;
                description?: string | null;
                definitions?: Array<{
                    __typename?: 'DataElaborationValueAndPercentageOutputTranslation';
                    id?: string | null;
                    value?: string | null;
                } | null> | null;
                translations?: Array<{
                    __typename?: 'DataElaborationValueAndPercentageOutputTranslation';
                    id?: string | null;
                    value?: string | null;
                } | null> | null;
            } | null> | null;
            mainQualification?: {
                __typename?: 'CompanyUploadCvElaborationMainQualification';
                name?: string | null;
                score?: number | null;
                translations?: {
                    __typename?: 'CompanyUploadCvElaborationMainQualificationTranslations';
                    ita?: string | null;
                    eng?: string | null;
                } | null;
            } | null;
            softSkills?: Array<{
                __typename?: 'CompanyUploadCvElaborationHardSoftSkills';
                lang?: string | null;
                name?: string | null;
                score?: number | null;
            } | null> | null;
            hardSkills?: Array<{
                __typename?: 'CompanyUploadCvElaborationHardSoftSkills';
                lang?: string | null;
                name?: string | null;
                score?: number | null;
            } | null> | null;
        } | null;
        otherInfo?: {
            __typename?: 'CompanyCVOtherInfo';
            addressLine1?: string | null;
            addressLine2?: string | null;
            city?: string | null;
            state?: string | null;
            postalCode?: string | null;
            country?: string | null;
            experienceYears?: CvExperienceYears | null;
            selfDescription?: string | null;
            USCitizenship?: boolean | null;
            citizenshipStatus?: CvCitizenshipStatus | null;
            citizenshipStatusOther?: string | null;
            ageOver18?: boolean | null;
            alreadyApplied?: boolean | null;
            alreadyAppliedInfo?: string | null;
            otherPeopleWorkers?: boolean | null;
            otherPeopleWorkersInfo?: string | null;
            haveTransportation?: boolean | null;
            submitDrugTest?: boolean | null;
            workInHighSchool?: boolean | null;
            workInHighSchoolInfo?: string | null;
            isVolunteer?: boolean | null;
            isVolunteerInfo?: string | null;
            hasPets?: boolean | null;
            hasPetsInfo?: CvPets | null;
            petsCount?: number | null;
            currentlyEmployed?: boolean | null;
            currentlyEmployedYear?: boolean | null;
            currentSalary?: number | null;
            desiredSalary?: number | null;
            availableWorkDaysHours?: string | null;
            hiringStartDate?: string | null;
            workOnWeekend?: boolean | null;
            workEvenings?: boolean | null;
            workOvertime?: boolean | null;
            checkbox1Title?: string | null;
            checkbox1Value?: boolean | null;
            checkbox2Title?: string | null;
            checkbox2Value?: boolean | null;
            checkbox3Title?: string | null;
            checkbox3Value?: boolean | null;
            experienceIT?: boolean | null;
            experienceProjectManager?: boolean | null;
            experienceLeadership?: boolean | null;
            experienceCoreNetworkingTechnologies?: boolean | null;
            experienceLeargeScaleNetworkingSystems?: boolean | null;
            experienceManagingPartner?: boolean | null;
            experienceCustomerService?: boolean | null;
            experienceFirewall?: boolean | null;
            experienceVirtualization?: boolean | null;
            experienceOffice365?: boolean | null;
            experienceAzure?: boolean | null;
            militaryBranch?: string | null;
            militaryRank?: string | null;
            militaryYears?: number | null;
            militarySkills?: string | null;
            militaryDetails?: string | null;
            vocationalSchoolName?: string | null;
            vocationalSchoolAddressLine1?: string | null;
            vocationalSchoolAddressLine2?: string | null;
            vocationalSchoolCity?: string | null;
            vocationalSchoolState?: string | null;
            vocationalSchoolPostalCode?: string | null;
            vocationalSchoolCountry?: string | null;
            vocationalSchoolYears?: number | null;
            vocationalSchoolGraduate?: boolean | null;
            vocationalSchoolDiploma?: string | null;
            universitySchoolName?: string | null;
            universitySchoolAddressLine1?: string | null;
            universitySchoolAddressLine2?: string | null;
            universitySchoolCity?: string | null;
            universitySchoolState?: string | null;
            universitySchoolPostalCode?: string | null;
            universitySchoolCountry?: string | null;
            universitySchoolYears?: number | null;
            universitySchoolGraduate?: boolean | null;
            universitySchoolDiploma?: string | null;
            certificationName?: string | null;
            certificationIssuingOrganization?: string | null;
            certificationNumber?: string | null;
            certificationDate?: string | null;
            additionalCertificationName?: string | null;
            additionalCertificationIssuingOrganization?: string | null;
            additionalCertificationNumber?: string | null;
            additionalCertificationDate?: string | null;
        } | null;
        evaluations?: Array<{
            __typename?: 'CompanyCVEvaluation';
            languageId?: string | null;
            evaluation?: {
                __typename?: 'CompanyCVEvaluationInfo';
                text?: string | null;
                scores?: {
                    __typename?: 'CompanyCVEvaluationScores';
                    reliability?: number | null;
                    personality?: number | null;
                    potential?: number | null;
                    experience?: number | null;
                    leadership?: number | null;
                } | null;
            } | null;
        } | null> | null;
        sectors?: Array<{
            __typename?: 'CompanyCVSectors';
            lang?: string | null;
            name?: string | null;
        } | null> | null;
    } | null;
};

export type GetCompanyEmployeesQueryVariables = Exact<{
    companyId: Scalars['ID'];
}>;

export type GetCompanyEmployeesQuery = {
    __typename?: 'Query';
    getCompanyEmployees?: Array<{
        __typename?: 'Employee';
        id?: string | null;
        name?: string | null;
        surname?: string | null;
        email?: string | null;
    } | null> | null;
};

export type IndexCvQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type IndexCvQuery = { __typename?: 'Query'; indexCV?: boolean | null };

export type InferenceMapForCvQueryVariables = Exact<{
    id: Scalars['ID'];
    companyId: Scalars['ID'];
    type?: InputMaybe<CompanyUploadCvDataElaborationCategory>;
}>;

export type InferenceMapForCvQuery = {
    __typename?: 'Query';
    inferenceMapForCv?: {
        __typename?: 'InferenceMapResponse';
        type?: CompanyUploadCvDataElaborationCategory | null;
        nodes?: Array<{
            __typename?: 'InferenceMapNode';
            id: string;
            name?: string | null;
            origin?: CompanyCvDataElaborationOrigin | null;
            isPrimary?: boolean | null;
        } | null> | null;
        edges?: Array<{
            __typename?: 'InferenceMapEdge';
            sourceId: string;
            targetId: string;
            type?: CompanyUploadCvDataElaborationRelationType | null;
        } | null> | null;
    } | null;
};

export type LogsForCompanyUploadCvQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type LogsForCompanyUploadCvQuery = {
    __typename?: 'Query';
    logsForCompanyUploadCv?: Array<{
        __typename?: 'CompanyUploadCvDataElaborationLog';
        field?: string | null;
        action?: CompanyCvDataElaborationLogAction | null;
        newContent?: string | null;
        oldContent?: string | null;
        companyName?: string | null;
        userFullName?: string | null;
        creationDate?: string | null;
    } | null> | null;
};

export type QdummyQueryVariables = Exact<{ [key: string]: never }>;

export type QdummyQuery = { __typename?: 'Query'; qdummy?: boolean | null };

export type RatingsForCvQueryVariables = Exact<{
    input: RatingsListInput;
}>;

export type RatingsForCvQuery = {
    __typename?: 'Query';
    ratingsForCV?: Array<{
        __typename?: 'Rating';
        id: string;
        authorFullName?: string | null;
        authorImage?: string | null;
        companyName?: string | null;
        content?: string | null;
        ratings?: Array<{
            __typename?: 'RatingsDescriptionValue';
            value?: number | null;
            description?: string | null;
        } | null> | null;
    } | null> | null;
};

export type RatingsForUserQueryVariables = Exact<{
    input: RatingsListInput;
}>;

export type RatingsForUserQuery = {
    __typename?: 'Query';
    ratingsForUser?: Array<{
        __typename?: 'Rating';
        id: string;
        authorFullName?: string | null;
        authorImage?: string | null;
        companyName?: string | null;
        content?: string | null;
        ratings?: Array<{
            __typename?: 'RatingsDescriptionValue';
            value?: number | null;
            description?: string | null;
        } | null> | null;
    } | null> | null;
};

export type SaveAndIndexCvQueryVariables = Exact<{
    id: Scalars['ID'];
    fileUrl?: InputMaybe<Scalars['String']>;
}>;

export type SaveAndIndexCvQuery = {
    __typename?: 'Query';
    saveAndIndexCV?: boolean | null;
};

export type SavedTalentListByCompanyIdQueryVariables = Exact<{
    companyId: Scalars['String'];
}>;

export type SavedTalentListByCompanyIdQuery = {
    __typename?: 'Query';
    savedTalentListByCompanyId?: Array<{
        __typename?: 'SavedTalent';
        id?: string | null;
        type?: string | null;
        referenceId?: string | null;
    } | null> | null;
};

export type SearchCompanyCvQueryVariables = Exact<{
    input?: InputMaybe<SearchCompanyCvInput>;
}>;

export type SearchCompanyCvQuery = {
    __typename?: 'Query';
    searchCompanyCv?: {
        __typename?: 'SearchCompanyCvOutput';
        data?: Array<{
            __typename?: 'CompanySearchCvOutput';
            id?: string | null;
            fullName?: string | null;
            rating?: number | null;
            fileName?: string | null;
            state?: CompanyCvState | null;
            processNames?: Array<string | null> | null;
            numberOfRatings?: number | null;
            primaryQualification?: string | null;
            lastUpdateDate?: string | null;
            uploadFileDate?: string | null;
            savedBy?: number | null;
            type?: string | null;
            isPreferred?: boolean | null;
            origin?: SearchCompanyCvOrigin | null;
            correspondence?: number | null;
            keywords?: Array<string | null> | null;
            visibilityState?: CompanyCvVisibilityState | null;
            tags?: Array<{
                __typename?: 'Label';
                id?: string | null;
                name?: string | null;
                parentId?: string | null;
                isDefault?: boolean | null;
                description?: string | null;
            } | null> | null;
            folders?: Array<{
                __typename?: 'Folder';
                id?: string | null;
                name?: string | null;
                parentId?: string | null;
                description?: string | null;
                qualifications?: Array<{
                    __typename?: 'Qualification';
                    id: string;
                    des: string;
                    type?: {
                        __typename?: 'QualificationType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null> | null;
                skills?: Array<{
                    __typename?: 'Skill';
                    id: string;
                    des: string;
                    custom?: boolean | null;
                    type?: {
                        __typename?: 'SkillType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null> | null;
            } | null> | null;
            qualification?: {
                __typename?: 'CompanySearchCvOutputQualification';
                name?: string | null;
                translations?: Array<{
                    __typename?: 'CompanySearchCvOutputQualificationTranslation';
                    id?: string | null;
                    value?: string | null;
                } | null> | null;
            } | null;
            handledBy?: {
                __typename?: 'CompanySearchCvHandlingOutput';
                id?: string | null;
                companyName?: string | null;
                companyImage?: string | null;
            } | null;
        } | null> | null;
        count?: Array<{
            __typename?: 'CompanySearchCvCountOutput';
            number?: number | null;
            origin?: SearchCompanyCvOrigin | null;
        } | null> | null;
    } | null;
};

export type SearchCompanyUploadCvQueryVariables = Exact<{
    input?: InputMaybe<SearchCompanyUploadCvRequest>;
}>;

export type SearchCompanyUploadCvQuery = {
    __typename?: 'Query';
    searchCompanyUploadCV?: {
        __typename?: 'SearchCompanyUploadResponse';
        count?: number | null;
        data?: Array<{
            __typename?: 'CompanyUploadCVGroup';
            id?: string | null;
            status?: CompanyUploadGroupStatus | null;
            uploadCount?: number | null;
            description?: string | null;
            creationDate?: string | null;
            dashboardType?: CompanyUploadDashboardType | null;
            resume?: Array<{
                __typename?: 'ResumeStatusFiles';
                status?: CompanyCvProcessStatus | null;
                actual?: number | null;
            } | null> | null;
            uploads?: Array<{
                __typename?: 'CompanyUploadCV';
                id?: string | null;
                url?: string | null;
                name?: string | null;
                creationDate?: string | null;
                processNames?: Array<string | null> | null;
                processStatus?: CompanyCvProcessStatus | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type SearchFolderQueryVariables = Exact<{
    input: SearchFolderInput;
}>;

export type SearchFolderQuery = {
    __typename?: 'Query';
    searchFolder?: Array<{
        __typename?: 'Folder';
        id?: string | null;
        name?: string | null;
        parentId?: string | null;
        description?: string | null;
        qualifications?: Array<{
            __typename?: 'Qualification';
            id: string;
            des: string;
            type?: {
                __typename?: 'QualificationType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
        skills?: Array<{
            __typename?: 'Skill';
            id: string;
            des: string;
            custom?: boolean | null;
            type?: {
                __typename?: 'SkillType';
                id: string;
                des: string;
                custom?: number | null;
            } | null;
        } | null> | null;
    } | null> | null;
};

export type SearchKeywordQueryVariables = Exact<{
    input?: InputMaybe<SearchKeywordRequest>;
}>;

export type SearchKeywordQuery = {
    __typename?: 'Query';
    searchKeyword?: {
        __typename?: 'SearchKeywordResponse';
        data?: Array<{ __typename?: 'Keyword'; name: string } | null> | null;
    } | null;
};

export type SearchLabelQueryVariables = Exact<{
    input: SearchLabelInput;
}>;

export type SearchLabelQuery = {
    __typename?: 'Query';
    searchLabel?: Array<{
        __typename?: 'Label';
        id?: string | null;
        name?: string | null;
        parentId?: string | null;
        isDefault?: boolean | null;
        description?: string | null;
    } | null> | null;
};

export type SearchSavedSearchesQueryVariables = Exact<{
    input?: InputMaybe<SearchSavedSearchesInput>;
}>;

export type SearchSavedSearchesQuery = {
    __typename?: 'Query';
    searchSavedSearches?: {
        __typename?: 'searchSavedSearchesOutput';
        count: number;
        data?: Array<{
            __typename?: 'SavedSearch';
            id?: string | null;
            name?: string | null;
            creationDate?: string | null;
            query?: {
                __typename?: 'SavedSearchQueryOutput';
                state?: CompanyCvState | null;
                searchId?: string | null;
                groupId?: string | null;
                companyId?: string | null;
                keywords?: Array<string | null> | null;
                skills?: Array<string | null> | null;
                hiringType?: HiringType | null;
                origin?: SearchCompanyCvOrigin | null;
                locationType?: LocationType | null;
                locationCity?: string | null;
                qualifications?: Array<string | null> | null;
                fullName?: string | null;
            } | null;
            visualization?: {
                __typename?: 'SavedSearchVisualizationOutput';
                row?: number | null;
                skills?: Array<string | null> | null;
                workLoad?: number | null;
                activity?: string | null;
                responsability?: string | null;
                daily_fare?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                experience?: {
                    __typename?: 'MinMaxValueOutput';
                    min?: number | null;
                    max?: number | null;
                } | null;
                qualification?: {
                    __typename?: 'Qualification';
                    id: string;
                    des: string;
                    type?: {
                        __typename?: 'QualificationType';
                        id: string;
                        des: string;
                        custom?: number | null;
                    } | null;
                } | null;
            } | null;
            results?: Array<{
                __typename?: 'SavedSearchResult';
                id?: string | null;
                state?: CompanySavedSearchResultState | null;
                data?: {
                    __typename?: 'CompanySearchCvOutput';
                    id?: string | null;
                    fullName?: string | null;
                    rating?: number | null;
                    fileName?: string | null;
                    state?: CompanyCvState | null;
                    processNames?: Array<string | null> | null;
                    numberOfRatings?: number | null;
                    primaryQualification?: string | null;
                    lastUpdateDate?: string | null;
                    uploadFileDate?: string | null;
                    savedBy?: number | null;
                    type?: string | null;
                    isPreferred?: boolean | null;
                    origin?: SearchCompanyCvOrigin | null;
                    correspondence?: number | null;
                    keywords?: Array<string | null> | null;
                    visibilityState?: CompanyCvVisibilityState | null;
                    tags?: Array<{
                        __typename?: 'Label';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        isDefault?: boolean | null;
                        description?: string | null;
                    } | null> | null;
                    folders?: Array<{
                        __typename?: 'Folder';
                        id?: string | null;
                        name?: string | null;
                        parentId?: string | null;
                        description?: string | null;
                    } | null> | null;
                    qualification?: {
                        __typename?: 'CompanySearchCvOutputQualification';
                        name?: string | null;
                    } | null;
                    handledBy?: {
                        __typename?: 'CompanySearchCvHandlingOutput';
                        id?: string | null;
                        companyName?: string | null;
                        companyImage?: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null> | null;
    } | null;
};

export type SupportedLanguagesQueryVariables = Exact<{
    language_id?: InputMaybe<Scalars['String']>;
}>;

export type SupportedLanguagesQuery = {
    __typename?: 'Query';
    supportedLanguages?: Array<{
        __typename?: 'Language';
        id: string;
        des: string;
    } | null> | null;
};

export type CompanyUploadCvChangeSubscriptionVariables = Exact<{
    userId?: InputMaybe<Scalars['String']>;
}>;

export type CompanyUploadCvChangeSubscription = {
    __typename?: 'Subscription';
    CompanyUploadCvChange?: {
        __typename?: 'CompanyUploadCVSubscription';
        mutation: string;
        data: {
            __typename?: 'CompanyUploadCVGroup';
            id?: string | null;
            status?: CompanyUploadGroupStatus | null;
            uploadCount?: number | null;
            description?: string | null;
            creationDate?: string | null;
            dashboardType?: CompanyUploadDashboardType | null;
            resume?: Array<{
                __typename?: 'ResumeStatusFiles';
                status?: CompanyCvProcessStatus | null;
                actual?: number | null;
            } | null> | null;
            uploads?: Array<{
                __typename?: 'CompanyUploadCV';
                id?: string | null;
                url?: string | null;
                name?: string | null;
                creationDate?: string | null;
                processNames?: Array<string | null> | null;
                processStatus?: CompanyCvProcessStatus | null;
            } | null> | null;
        };
    } | null;
};

export type UserNotificationsSubscriptionVariables = Exact<{
    userId?: InputMaybe<Scalars['String']>;
}>;

export type UserNotificationsSubscription = {
    __typename?: 'Subscription';
    UserNotifications?: {
        __typename?: 'UserNotificationSubscription';
        mutation: string;
        userId: string;
        data?: {
            __typename?: 'UserNotification';
            type: string;
            priority?: Priority | null;
            companyId?: string | null;
            creationDate?: string | null;
            referenceId?: string | null;
            referenceType?: string | null;
        } | null;
    } | null;
};

export type SdummySubscriptionVariables = Exact<{ [key: string]: never }>;

export type SdummySubscription = { __typename?: 'Subscription'; sdummy?: boolean | null };

export const AcceptPrivacyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'acceptPrivacy' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'privacyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acceptPrivacy' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'privacyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'privacyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AcceptPrivacyMutation, AcceptPrivacyMutationVariables>;
export const AcceptRequestForCompanyRelationShareDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'acceptRequestForCompanyRelationShare' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'HandleCompanyRelationShareInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'acceptRequestForCompanyRelationShare',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AcceptRequestForCompanyRelationShareMutation,
    AcceptRequestForCompanyRelationShareMutationVariables
>;
export const AcceptTermConditionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'acceptTermCondition' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'termConditionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acceptTermCondition' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'termConditionId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'termConditionId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AcceptTermConditionMutation,
    AcceptTermConditionMutationVariables
>;
export const ActiveCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'activeCompanyUploadCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activeCompanyUploadCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ActiveCompanyUploadCvMutation,
    ActiveCompanyUploadCvMutationVariables
>;
export const ActiveManyCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'activeManyCompanyUploadCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'references' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CvReference' },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activeManyCompanyUploadCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'references' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'references' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ActiveManyCompanyUploadCvMutation,
    ActiveManyCompanyUploadCvMutationVariables
>;
export const ActiveOpenHrDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'activeOpenHr' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activeOpenHr' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ActiveOpenHrMutation, ActiveOpenHrMutationVariables>;
export const AddBulkTalentsToSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addBulkTalentsToSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'references' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'CvReferenceWithCorrespondence',
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uploadGroupId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addBulkTalentsToSavedSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'references' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'references' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'uploadGroupId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'uploadGroupId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'query' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'searchId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'groupId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'keywords' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'visualization' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'row' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'workLoad' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'activity' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'daily_fare',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experience',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'responsability',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'results' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'tags',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isDefault',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folders',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rating',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'numberOfRatings',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'primaryQualification',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'translations',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastUpdateDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'uploadFileDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'handledBy',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyImage',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'savedBy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isPreferred',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'correspondence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'visibilityState',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AddBulkTalentsToSavedSearchMutation,
    AddBulkTalentsToSavedSearchMutationVariables
>;
export const AddCandidacyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addCandidacy' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'JobAnnouncementAddCandidacyInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addCandidacy' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddCandidacyMutation, AddCandidacyMutationVariables>;
export const AddCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addCompanyUploadCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'InsertCompanyUploadCV' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addCompanyUploadCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'group' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'resume' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'status',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'actual',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'uploads' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'url',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'creationDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processStatus',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uploadCount',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dashboardType',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'requestId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AddCompanyUploadCvMutation,
    AddCompanyUploadCvMutationVariables
>;
export const AddCvToSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addCvToSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'correspondence' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addCvToSavedSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'correspondence' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'correspondence' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'query' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'searchId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'groupId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'keywords' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'visualization' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'row' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'workLoad' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'activity' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'daily_fare',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experience',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'responsability',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'results' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'tags',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isDefault',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folders',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rating',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'numberOfRatings',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'primaryQualification',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'translations',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastUpdateDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'uploadFileDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'handledBy',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyImage',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'savedBy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isPreferred',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'correspondence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'visibilityState',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AddCvToSavedSearchMutation,
    AddCvToSavedSearchMutationVariables
>;
export const AddMeToCompanyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addMeToCompany' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CompanyEmployeeRole' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addMeToCompany' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'role' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'role' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddMeToCompanyMutation, AddMeToCompanyMutationVariables>;
export const AddUserToCompanyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addUserToCompany' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CompanyEmployeeRole' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addUserToCompany' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'role' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'role' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddUserToCompanyMutation, AddUserToCompanyMutationVariables>;
export const AddUserToSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'addUserToSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'correspondence' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'addUserToSavedSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'correspondence' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'correspondence' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'query' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'searchId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'groupId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'keywords' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'visualization' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'row' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'workLoad' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'activity' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'daily_fare',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experience',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'responsability',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'results' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'tags',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isDefault',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folders',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rating',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'numberOfRatings',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'primaryQualification',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'translations',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastUpdateDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'uploadFileDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'handledBy',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyImage',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'savedBy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isPreferred',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'correspondence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'visibilityState',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AddUserToSavedSearchMutation,
    AddUserToSavedSearchMutationVariables
>;
export const ArchiveCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'archiveCompanyUploadCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'archiveCompanyUploadCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArchiveCompanyUploadCvMutation,
    ArchiveCompanyUploadCvMutationVariables
>;
export const ArchiveManyCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'archiveManyCompanyUploadCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'references' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CvReference' },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'archiveManyCompanyUploadCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'references' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'references' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ArchiveManyCompanyUploadCvMutation,
    ArchiveManyCompanyUploadCvMutationVariables
>;
export const AssignFoldersToTalentsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'assignFoldersToTalents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: {
                                    kind: 'Name',
                                    value: 'AssignFoldersToTalentInput',
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignFoldersToTalents' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AssignFoldersToTalentsMutation,
    AssignFoldersToTalentsMutationVariables
>;
export const AssignLabelsToTalentsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'assignLabelsToTalents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: {
                                    kind: 'Name',
                                    value: 'AssignLabelsToTalentInput',
                                },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignLabelsToTalents' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    AssignLabelsToTalentsMutation,
    AssignLabelsToTalentsMutationVariables
>;
export const CandidateMeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'candidateMe' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobAnnouncementId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'candidateMe' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'jobAnnouncementId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'jobAnnouncementId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CandidateMeMutation, CandidateMeMutationVariables>;
export const ChangeUserRoleInCompanyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'changeUserRoleInCompany' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'relationId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'role' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CompanyEmployeeRole' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changeUserRoleInCompany' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'relationId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'relationId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'role' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'role' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ChangeUserRoleInCompanyMutation,
    ChangeUserRoleInCompanyMutationVariables
>;
export const ConfirmUserToCompanyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'confirmUserToCompany' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'relationId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'confirmUserToCompany' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'relationId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'relationId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ConfirmUserToCompanyMutation,
    ConfirmUserToCompanyMutationVariables
>;
export const CreateCompanyTeamListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createCompanyTeamList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'createCompanyTeamListRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createCompanyTeamList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUpdate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'savedSearches' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'query' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'searchId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'groupId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'companyId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'hiringType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationCity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'visualization',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'row',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'workLoad',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'activity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'daily_fare',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'experience',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'responsability',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'results' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'talent',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'tags',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'isDefault',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'folders',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fullName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'rating',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'state',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'processNames',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'numberOfRatings',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'primaryQualification',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'lastUpdateDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'uploadFileDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'handledBy',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyImage',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'savedBy',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isPreferred',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'origin',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'correspondence',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'keywords',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'visibilityState',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'percentUsage',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateCompanyTeamListMutation,
    CreateCompanyTeamListMutationVariables
>;
export const CreateCompanyUploadCvGroupDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createCompanyUploadCVGroup' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'InsertCompanyUploadCVGroup' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createCompanyUploadCVGroup' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resume' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'actual' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uploads' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'processNames',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'processStatus',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uploadCount' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dashboardType' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateCompanyUploadCvGroupMutation,
    CreateCompanyUploadCvGroupMutationVariables
>;
export const CreateHiringInterviewDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createHiringInterview' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hiringprocessId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date1' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date2' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date3' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time1' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time2' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time3' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createHiringInterview' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hiringprocessId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hiringprocessId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date1' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date1' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date2' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date2' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date3' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date3' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'time1' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'time1' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'time2' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'time2' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'time3' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'time3' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'message' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'message' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateHiringInterviewMutation,
    CreateHiringInterviewMutationVariables
>;
export const CreateHiringProcessDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createHiringProcess' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'target_reference_id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'target_reference_type' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'curriculumEmail' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'processDescription' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date1' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date2' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date3' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time1' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time2' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time3' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createHiringProcess' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'target_reference_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'target_reference_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'target_reference_type' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'target_reference_type',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'curriculumEmail' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'curriculumEmail' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'processDescription' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'processDescription' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date1' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date1' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date2' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date2' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date3' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date3' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'time1' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'time1' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'time2' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'time2' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'time3' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'time3' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'message' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'message' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateHiringProcessMutation,
    CreateHiringProcessMutationVariables
>;
export const CreateHiringProposalDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createHiringProposal' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hiringinterviewId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date1' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date2' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'date3' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time1' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time2' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'time3' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createHiringProposal' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hiringinterviewId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hiringinterviewId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date1' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date1' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date2' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date2' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'date3' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'date3' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'time1' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'time1' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'time2' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'time2' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'time3' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'time3' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'message' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'message' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateHiringProposalMutation,
    CreateHiringProposalMutationVariables
>;
export const CreateLabelDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'createLabel' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'description' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parentId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createLabel' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'description' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'description' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'parentId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'parentId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isDefault' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateLabelMutation, CreateLabelMutationVariables>;
export const DeleteCompanyTeamListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteCompanyTeamList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteCompanyTeamList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteCompanyTeamListMutation,
    DeleteCompanyTeamListMutationVariables
>;
export const DeleteCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteCompanyUploadCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteCompanyUploadCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteCompanyUploadCvMutation,
    DeleteCompanyUploadCvMutationVariables
>;
export const DeleteEducationCourseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteEducationCourse' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteEducationCourse' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteEducationCourseMutation,
    DeleteEducationCourseMutationVariables
>;
export const DeleteEducationLessonDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteEducationLesson' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteEducationLesson' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteEducationLessonMutation,
    DeleteEducationLessonMutationVariables
>;
export const DeleteEducationStudentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteEducationStudent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteEducationStudent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteEducationStudentMutation,
    DeleteEducationStudentMutationVariables
>;
export const DeleteEducationTeacherDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteEducationTeacher' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteEducationTeacher' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteEducationTeacherMutation,
    DeleteEducationTeacherMutationVariables
>;
export const DeleteFolderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteFolder' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteFolder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const DeleteLabelDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteLabel' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteLabel' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteLabelMutation, DeleteLabelMutationVariables>;
export const DeleteManyCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteManyCompanyUploadCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'references' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CvReference' },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteManyCompanyUploadCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'references' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'references' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteManyCompanyUploadCvMutation,
    DeleteManyCompanyUploadCvMutationVariables
>;
export const DeleteMeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteMe' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'deleteMe' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteMeMutation, DeleteMeMutationVariables>;
export const DeleteQualificationTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteQualificationType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteQualificationType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteQualificationTypeMutation,
    DeleteQualificationTypeMutationVariables
>;
export const DeleteSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteSavedSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'query' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'searchId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'groupId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'keywords' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'visualization' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'row' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'workLoad' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'activity' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'daily_fare',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experience',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'responsability',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'results' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'tags',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isDefault',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folders',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rating',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'numberOfRatings',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'primaryQualification',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'translations',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastUpdateDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'uploadFileDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'handledBy',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyImage',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'savedBy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isPreferred',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'correspondence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'visibilityState',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DeleteSavedSearchMutation,
    DeleteSavedSearchMutationVariables
>;
export const DeleteSkillDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteSkill' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteSkill' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteSkillMutation, DeleteSkillMutationVariables>;
export const DeleteSkillTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'deleteSkillType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteSkillType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteSkillTypeMutation, DeleteSkillTypeMutationVariables>;
export const DuplicateEducationCourseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'duplicateEducationCourse' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'duplicateEducationCourse' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DuplicateEducationCourseMutation,
    DuplicateEducationCourseMutationVariables
>;
export const EditCompanyUploadCvDataElaborationsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'editCompanyUploadCvDataElaborations' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'EditCompanyUploadCvDataElaborationsInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'editCompanyUploadCvDataElaborations',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditCompanyUploadCvDataElaborationsMutation,
    EditCompanyUploadCvDataElaborationsMutationVariables
>;
export const EditCompanyUploadCvDataElaborations2Document = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'editCompanyUploadCvDataElaborations2' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'EditCompanyUploadCvDataElaborationsInput2',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'editCompanyUploadCvDataElaborations2',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EditCompanyUploadCvDataElaborations2Mutation,
    EditCompanyUploadCvDataElaborations2MutationVariables
>;
export const GenerateApiKeyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'generateApiKey' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'generateApiKey' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;
export const InsertBulkEducationStudentsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertBulkEducationStudents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'EducationStudentInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertBulkEducationStudents' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentEmail' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'motivation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cityResidence' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertBulkEducationStudentsMutation,
    InsertBulkEducationStudentsMutationVariables
>;
export const InsertCvRatingDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertCVRating' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'InsertRatingInsert' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertCVRating' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorFullName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorImage' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ratings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InsertCvRatingMutation, InsertCvRatingMutationVariables>;
export const InsertCompanyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertCompany' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'InsertCompanyRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertCompany' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vatNumber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'privacyUrl' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isApproved' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasOpenActive' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessArea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'services' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quarters' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'city' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'location' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'employees' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'user' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'userType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'birth_date',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city_residence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'biografy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'website',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'phone',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'screenshot',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'socials',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'educational',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'businessarea',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'personalities',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'section',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'experienceInYears',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'spokenLanguages',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'languageId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expertiseAreas' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statistics' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InsertCompanyMutation, InsertCompanyMutationVariables>;
export const InsertEducationCourseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertEducationCourse' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationCourseInsertRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertEducationCourse' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertEducationCourseMutation,
    InsertEducationCourseMutationVariables
>;
export const InsertEducationLessonDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertEducationLesson' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationLessonInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertEducationLesson' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseTitle' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'join_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacher' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lesson_url' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertEducationLessonMutation,
    InsertEducationLessonMutationVariables
>;
export const InsertEducationStudentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertEducationStudent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationStudentInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertEducationStudent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentEmail' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'motivation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cityResidence' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertEducationStudentMutation,
    InsertEducationStudentMutationVariables
>;
export const InsertEducationTeacherDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertEducationTeacher' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationTeacherInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertEducationTeacher' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertEducationTeacherMutation,
    InsertEducationTeacherMutationVariables
>;
export const InsertFolderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertFolder' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'InsertFolderInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertFolder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InsertFolderMutation, InsertFolderMutationVariables>;
export const InsertJobAnnouncementDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertJobAnnouncement' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JobAnnouncementInsertInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertJobAnnouncement' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'owner' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'biografy' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'country' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vatNumber',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isApproved',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'businessArea',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'coverImage',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileLabel',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'website' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'socials' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'services' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'quarters' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'location',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'contacts' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'employees',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'user',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'email',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'surname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'userType',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'birth_date',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'city_residence',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'biografy',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'website',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'phone',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'photo',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileLabel',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'screenshot',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileLabel',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'socials',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'educational',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'businessarea',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'type',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'personalities',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'section',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'experienceInYears',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'spokenLanguages',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'languageId',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'role',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'expertiseAreas',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'privacyURL' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'previewImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contractType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'locationType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expirationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetCandidateRalMin',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetCandidateRalMax',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetExperienceYearsMin',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetExperienceYearsMax',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targetDisiredSkills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isChatActivate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isJobInterviewRequired',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isAssessmentRequired' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualification' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targetAvailability' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTargetAvailableForTransfers',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTargetAvailableForRoleChange',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTargetAvailableForRelocation',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'candidaturesCount' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'referentId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertJobAnnouncementMutation,
    InsertJobAnnouncementMutationVariables
>;
export const InsertManySavedTalentsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertManySavedTalents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'insertSavedTalentsInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertManySavedTalents' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'referenceId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertManySavedTalentsMutation,
    InsertManySavedTalentsMutationVariables
>;
export const InsertQualificationTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertQualificationType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'des' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'custom' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertQualificationType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'des' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'des' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'custom' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertQualificationTypeMutation,
    InsertQualificationTypeMutationVariables
>;
export const InsertRequestForCompanyRelationShareDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertRequestForCompanyRelationShare' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'HandleCompanyRelationShareInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'insertRequestForCompanyRelationShare',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertRequestForCompanyRelationShareMutation,
    InsertRequestForCompanyRelationShareMutationVariables
>;
export const InsertSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'insertSavedSearchInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertSavedSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'query' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'searchId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'groupId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'keywords' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'visualization' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'row' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'workLoad' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'activity' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'daily_fare',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experience',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'responsability',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'results' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'tags',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isDefault',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folders',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rating',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'numberOfRatings',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'primaryQualification',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'translations',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastUpdateDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'uploadFileDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'handledBy',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyImage',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'savedBy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isPreferred',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'correspondence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'visibilityState',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertSavedSearchMutation,
    InsertSavedSearchMutationVariables
>;
export const InsertSavedTalentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertSavedTalent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'insertSavedTalentInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertSavedTalent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'referenceId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertSavedTalentMutation,
    InsertSavedTalentMutationVariables
>;
export const InsertSkillDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertSkill' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'des' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'typeId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertSkill' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'des' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'des' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'typeId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'typeId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InsertSkillMutation, InsertSkillMutationVariables>;
export const InsertSkillTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertSkillType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'des' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'custom' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertSkillType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'des' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'des' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'custom' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InsertSkillTypeMutation, InsertSkillTypeMutationVariables>;
export const InsertSurveyUserAnswerDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertSurveyUserAnswer' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'proposalId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'questionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'value' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertSurveyUserAnswer' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'proposalId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'proposalId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'questionId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'questionId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'value' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'value' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'orderby' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preselectedminvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preselectedmaxvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minlabel' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxlabel' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currentUserAnswer' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertSurveyUserAnswerMutation,
    InsertSurveyUserAnswerMutationVariables
>;
export const InsertTalentsAsPreferredInSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertTalentsAsPreferredInSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'references' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'insertTalentsAsPreferredInSavedSearch',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'references' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'references' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'query' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'searchId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'groupId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'keywords' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'visualization' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'row' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'workLoad' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'activity' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'daily_fare',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experience',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'responsability',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'results' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'tags',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isDefault',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folders',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rating',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'numberOfRatings',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'primaryQualification',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'translations',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastUpdateDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'uploadFileDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'handledBy',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyImage',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'savedBy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isPreferred',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'correspondence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'visibilityState',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InsertTalentsAsPreferredInSavedSearchMutation,
    InsertTalentsAsPreferredInSavedSearchMutationVariables
>;
export const InsertUserRatingDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'insertUserRating' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'InsertRatingInsert' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'insertUserRating' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorFullName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorImage' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ratings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InsertUserRatingMutation, InsertUserRatingMutationVariables>;
export const InviteToEducationCourseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'inviteToEducationCourse' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationCourseInvite' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inviteToEducationCourse' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    InviteToEducationCourseMutation,
    InviteToEducationCourseMutationVariables
>;
export const LinkExternalMediaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'linkExternalMedia' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ExternalFileInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkExternalMedia' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileLabel' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    LinkExternalMediaMutation,
    LinkExternalMediaMutationVariables
>;
export const MdummyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'mdummy' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'mdummy' } }],
            },
        },
    ],
} as unknown as DocumentNode<MdummyMutation, MdummyMutationVariables>;
export const RefuseRequestForCompanyRelationShareDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'refuseRequestForCompanyRelationShare' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'HandleCompanyRelationShareInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'refuseRequestForCompanyRelationShare',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RefuseRequestForCompanyRelationShareMutation,
    RefuseRequestForCompanyRelationShareMutationVariables
>;
export const RefuseUserToCompanyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'refuseUserToCompany' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'relationId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'refuseUserToCompany' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'relationId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'relationId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RefuseUserToCompanyMutation,
    RefuseUserToCompanyMutationVariables
>;
export const RemoveAsHiredCompanyCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeAsHiredCompanyCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeAsHiredCompanyCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveAsHiredCompanyCvMutation,
    RemoveAsHiredCompanyCvMutationVariables
>;
export const RemoveCandidateAsPreferredDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeCandidateAsPreferred' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobAnnouncementId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeCandidateAsPreferred' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'jobAnnouncementId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'jobAnnouncementId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveCandidateAsPreferredMutation,
    RemoveCandidateAsPreferredMutationVariables
>;
export const RemoveCvFromSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeCvFromSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeCvFromSavedSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveCvFromSavedSearchMutation,
    RemoveCvFromSavedSearchMutationVariables
>;
export const RemoveJobAnnouncementAsPreferredDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeJobAnnouncementAsPreferred' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeJobAnnouncementAsPreferred' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveJobAnnouncementAsPreferredMutation,
    RemoveJobAnnouncementAsPreferredMutationVariables
>;
export const RemoveManyCandidatesAsPreferredDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeManyCandidatesAsPreferred' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'RemoveManyCandidatesAsPreferredInput',
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeManyCandidatesAsPreferred' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveManyCandidatesAsPreferredMutation,
    RemoveManyCandidatesAsPreferredMutationVariables
>;
export const RemoveManyCvFromSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeManyCvFromSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'references' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CvReference' },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeManyCvFromSavedSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'references' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'references' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveManyCvFromSavedSearchMutation,
    RemoveManyCvFromSavedSearchMutationVariables
>;
export const RemoveManySavedTalentsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeManySavedTalents' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceIds' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'String' },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeManySavedTalents' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceIds' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceIds' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveManySavedTalentsMutation,
    RemoveManySavedTalentsMutationVariables
>;
export const RemoveMediaDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeMedia' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'mediaId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeMedia' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'mediaId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'mediaId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RemoveMediaMutation, RemoveMediaMutationVariables>;
export const RemoveSavedTalentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeSavedTalent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeSavedTalent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveSavedTalentMutation,
    RemoveSavedTalentMutationVariables
>;
export const RemoveTalentsAsPreferredInSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeTalentsAsPreferredInSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'references' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'removeTalentsAsPreferredInSavedSearch',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'references' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'references' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'query' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'searchId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'groupId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'keywords' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'visualization' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'row' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'workLoad' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'activity' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'daily_fare',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experience',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'responsability',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'results' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'tags',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isDefault',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folders',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rating',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'numberOfRatings',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'primaryQualification',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'translations',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastUpdateDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'uploadFileDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'handledBy',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyImage',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'savedBy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isPreferred',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'correspondence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'visibilityState',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveTalentsAsPreferredInSavedSearchMutation,
    RemoveTalentsAsPreferredInSavedSearchMutationVariables
>;
export const RemoveUserToCompanyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'removeUserToCompany' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'relationId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'removeUserToCompany' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'relationId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'relationId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RemoveUserToCompanyMutation,
    RemoveUserToCompanyMutationVariables
>;
export const ResendUserWelcomeEmailDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'resendUserWelcomeEmail' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resendUserWelcomeEmail' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ResendUserWelcomeEmailMutation,
    ResendUserWelcomeEmailMutationVariables
>;
export const SendEmailHelpDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'sendEmailHelp' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_name' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_surname' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_email' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_phone' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_message' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'user_company' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'destination_email' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendEmailHelp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_surname' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_surname' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_email' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_phone' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_phone' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_message' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_message' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'user_company' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'user_company' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'destination_email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'destination_email' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SendEmailHelpMutation, SendEmailHelpMutationVariables>;
export const SendHiringInterviewComunicationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'sendHiringInterviewComunication' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'CvReference' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyRequester' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendHiringInterviewComunication' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyRequester' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyRequester' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'message' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'message' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SendHiringInterviewComunicationMutation,
    SendHiringInterviewComunicationMutationVariables
>;
export const SetAsHiredCompanyCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setAsHiredCompanyCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setAsHiredCompanyCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetAsHiredCompanyCvMutation,
    SetAsHiredCompanyCvMutationVariables
>;
export const SetCandidateAsAcceptedDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setCandidateAsAccepted' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceType' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TalentType' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobAnnouncementId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setCandidateAsAccepted' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceType' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceType' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'jobAnnouncementId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'jobAnnouncementId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetCandidateAsAcceptedMutation,
    SetCandidateAsAcceptedMutationVariables
>;
export const SetCandidateAsHiredDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setCandidateAsHired' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceType' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TalentType' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobAnnouncementId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setCandidateAsHired' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceType' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceType' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'jobAnnouncementId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'jobAnnouncementId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetCandidateAsHiredMutation,
    SetCandidateAsHiredMutationVariables
>;
export const SetCandidateAsPreferredDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setCandidateAsPreferred' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceType' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TalentType' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobAnnouncementId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setCandidateAsPreferred' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceType' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceType' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'jobAnnouncementId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'jobAnnouncementId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetCandidateAsPreferredMutation,
    SetCandidateAsPreferredMutationVariables
>;
export const SetCandidateAsRefusedDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setCandidateAsRefused' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'referenceType' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'TalentType' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobAnnouncementId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'refuseReason' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setCandidateAsRefused' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'referenceType' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'referenceType' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'jobAnnouncementId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'jobAnnouncementId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'refuseReason' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'refuseReason' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetCandidateAsRefusedMutation,
    SetCandidateAsRefusedMutationVariables
>;
export const SetCompanyUploadCvAsInternalDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setCompanyUploadCVAsInternal' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setCompanyUploadCVAsInternal' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetCompanyUploadCvAsInternalMutation,
    SetCompanyUploadCvAsInternalMutationVariables
>;
export const SetCompanyUploadCvAsTotalDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setCompanyUploadCVAsTotal' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setCompanyUploadCVAsTotal' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetCompanyUploadCvAsTotalMutation,
    SetCompanyUploadCvAsTotalMutationVariables
>;
export const SetCurrentAppVisualizationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setCurrentAppVisualization' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'CurrentAppVisualizationType' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setCurrentAppVisualization' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetCurrentAppVisualizationMutation,
    SetCurrentAppVisualizationMutationVariables
>;
export const SetJobAnnouncementAsPreferredDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setJobAnnouncementAsPreferred' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setJobAnnouncementAsPreferred' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetJobAnnouncementAsPreferredMutation,
    SetJobAnnouncementAsPreferredMutationVariables
>;
export const SetManyCandidatesAsAcceptedDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setManyCandidatesAsAccepted' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SetManyCandidatesAsAcceptedInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setManyCandidatesAsAccepted' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetManyCandidatesAsAcceptedMutation,
    SetManyCandidatesAsAcceptedMutationVariables
>;
export const SetManyCandidatesAsPreferredDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setManyCandidatesAsPreferred' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'SetManyCandidatesAsPreferredInput',
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setManyCandidatesAsPreferred' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetManyCandidatesAsPreferredMutation,
    SetManyCandidatesAsPreferredMutationVariables
>;
export const SetManyCompanyUploadCvAsInternalDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setManyCompanyUploadCVAsInternal' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'references' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CvReference' },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setManyCompanyUploadCVAsInternal' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'references' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'references' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetManyCompanyUploadCvAsInternalMutation,
    SetManyCompanyUploadCvAsInternalMutationVariables
>;
export const SetManyCompanyUploadCvAsTotalDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'setManyCompanyUploadCVAsTotal' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'references' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CvReference' },
                            },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setManyCompanyUploadCVAsTotal' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'references' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'references' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SetManyCompanyUploadCvAsTotalMutation,
    SetManyCompanyUploadCvAsTotalMutationVariables
>;
export const UpdateCompanyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateCompany' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'UpdateCompanyRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateCompany' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vatNumber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'privacyUrl' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isApproved' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasOpenActive' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessArea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'services' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quarters' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'city' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'location' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'employees' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'user' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'userType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'birth_date',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city_residence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'biografy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'website',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'phone',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'screenshot',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'socials',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'educational',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'businessarea',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'personalities',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'section',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'experienceInYears',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'spokenLanguages',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'languageId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expertiseAreas' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statistics' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateCompanyRelationShareDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateCompanyRelationShare' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'UpdateCompanyRelationShareInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateCompanyRelationShare' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'company' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'biografy' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'country' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vatNumber',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isApproved',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'businessArea',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'coverImage',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileLabel',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'website' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'socials' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'services' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'quarters' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'location',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'contacts' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'employees',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'user',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'email',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'surname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'userType',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'birth_date',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'city_residence',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'biografy',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'website',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'phone',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'photo',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileLabel',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'screenshot',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileLabel',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'socials',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'educational',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'businessarea',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'type',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'personalities',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'section',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'experienceInYears',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'spokenLanguages',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'languageId',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'role',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'expertiseAreas',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dataVisibility' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateCompanyRelationShareMutation,
    UpdateCompanyRelationShareMutationVariables
>;
export const UpdateCompanyTeamListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateCompanyTeamList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'updateCompanyTeamListRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateCompanyTeamList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUpdate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'savedSearches' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'query' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'searchId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'groupId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'companyId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'hiringType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationCity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'visualization',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'row',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'workLoad',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'activity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'daily_fare',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'experience',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'responsability',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'results' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'talent',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'tags',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'isDefault',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'folders',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fullName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'rating',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'state',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'processNames',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'numberOfRatings',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'primaryQualification',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'lastUpdateDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'uploadFileDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'handledBy',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyImage',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'savedBy',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isPreferred',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'origin',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'correspondence',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'keywords',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'visibilityState',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'percentUsage',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateCompanyTeamListMutation,
    UpdateCompanyTeamListMutationVariables
>;
export const UpdateCompanyUploadCvGroupDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateCompanyUploadCVGroup' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'UpdateCompanyUploadCVGroup' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateCompanyUploadCVGroup' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resume' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'actual' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uploads' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'processNames',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'processStatus',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uploadCount' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dashboardType' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateCompanyUploadCvGroupMutation,
    UpdateCompanyUploadCvGroupMutationVariables
>;
export const UpdateEducationCourseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateEducationCourse' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationCourseUpdateRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateEducationCourse' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateEducationCourseMutation,
    UpdateEducationCourseMutationVariables
>;
export const UpdateEducationLessonDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateEducationLesson' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationLessonInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateEducationLesson' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseTitle' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'join_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacher' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lesson_url' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateEducationLessonMutation,
    UpdateEducationLessonMutationVariables
>;
export const UpdateEducationStudentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateEducationStudent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationStudentInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateEducationStudent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentEmail' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'motivation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cityResidence' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateEducationStudentMutation,
    UpdateEducationStudentMutationVariables
>;
export const UpdateEducationTeacherDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateEducationTeacher' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationTeacherInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateEducationTeacher' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateEducationTeacherMutation,
    UpdateEducationTeacherMutationVariables
>;
export const UpdateFolderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateFolder' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpdateFolderInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateFolder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateFolderMutation, UpdateFolderMutationVariables>;
export const UpdateHiringInterviewDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateHiringInterview' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'valuation' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reliability' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personality' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'potential' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'experience' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'leadership' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateHiringInterview' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'status' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'valuation' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'valuation' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reliability' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reliability' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'personality' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'personality' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'potential' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'potential' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'experience' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'experience' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'leadership' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'leadership' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creation_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringprocessId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'reliability' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personality' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'potential' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'experience' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leadership' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateHiringInterviewMutation,
    UpdateHiringInterviewMutationVariables
>;
export const UpdateHiringProposalDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateHiringProposal' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accepted_date' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateHiringProposal' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'status' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'accepted_date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'accepted_date' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creation_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringinterviewId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'date1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'date2' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'date3' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time2' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time3' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accepted_date' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateHiringProposalMutation,
    UpdateHiringProposalMutationVariables
>;
export const UpdateJobAnnouncementDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateJobAnnouncement' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'JobAnnouncementUpdateInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateJobAnnouncement' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'owner' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'biografy' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'country' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vatNumber',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isApproved',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'businessArea',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'coverImage',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileLabel',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'website' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'socials' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'services' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'quarters' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'location',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'contacts' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'employees',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'user',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'email',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'surname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'userType',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'birth_date',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'city_residence',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'biografy',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'website',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'phone',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'photo',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileLabel',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'screenshot',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileLabel',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'socials',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'educational',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'businessarea',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'type',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'personalities',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'section',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'experienceInYears',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'spokenLanguages',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'languageId',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'role',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'expertiseAreas',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'privacyURL' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'previewImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contractType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'locationType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expirationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetCandidateRalMin',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetCandidateRalMax',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetExperienceYearsMin',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetExperienceYearsMax',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targetDisiredSkills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isChatActivate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isJobInterviewRequired',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isAssessmentRequired' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualification' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targetAvailability' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTargetAvailableForTransfers',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTargetAvailableForRoleChange',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTargetAvailableForRelocation',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'candidaturesCount' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'referentId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateJobAnnouncementMutation,
    UpdateJobAnnouncementMutationVariables
>;
export const UpdateLabelDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateLabel' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpdateLabelInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateLabel' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isDefault' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateLabelMutation, UpdateLabelMutationVariables>;
export const UpdateQualificationTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateQualificationType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'des' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'custom' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateQualificationType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'des' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'des' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'custom' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateQualificationTypeMutation,
    UpdateQualificationTypeMutationVariables
>;
export const UpdateSavedSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSavedSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'updateSavedSearchInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateSavedSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'query' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'searchId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'groupId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'keywords' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'visualization' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'row' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'workLoad' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'activity' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'daily_fare',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experience',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'min',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'max',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'responsability',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'results' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'tags',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isDefault',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'folders',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'parentId',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rating',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'numberOfRatings',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'primaryQualification',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'translations',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lastUpdateDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'uploadFileDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'handledBy',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'companyImage',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'savedBy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isPreferred',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'correspondence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'visibilityState',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateSavedSearchMutation,
    UpdateSavedSearchMutationVariables
>;
export const UpdateSkillDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSkill' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'des' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'typeId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateSkill' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'des' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'des' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'typeId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'typeId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateSkillMutation, UpdateSkillMutationVariables>;
export const UpdateSkillTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateSkillType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'des' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'custom' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateSkillType' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'des' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'des' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'custom' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateSkillTypeMutation, UpdateSkillTypeMutationVariables>;
export const UpdateUserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'updateUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_home' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_other_city' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_other_state' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_other_city_1' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_other_city_2' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_other_city_3' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_other_state_1' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_other_state_2' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_other_state_3' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_show_on_profile' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'show_merit_personality_on_profile',
                        },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: {
                            kind: 'Name',
                            value: 'show_defect_personality_on_profile',
                        },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'opportunity_less_than_1_year' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'opportunity_more_than_1_year' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'opportunity_hiring' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'opportunity_freelance' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workState' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'UserWorkState' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'maxDistanceForDisponibilityWork' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isTourDashboardViewed' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'authorization_advertising' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'authorization_marketing' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userType' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'UserType' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'surname' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'birth_date' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'city_residence' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phone' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_work_my_city' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'like_offers_x_km' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'onboarding_user_step' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'biografy' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'website' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'socials' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SocialUpdateRequest' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'photo' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'screenshot' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'curriculum' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'curriculumVideo' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'skills' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UserSkillInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personalities' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UserPersonalityInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'qualifications' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UserQualificationInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hobbies' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeneralInfoUpdateRequest' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'spokenLanguages' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeneralInfoUpdateRequest' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'certificatesAchieved' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeneralInfoUpdateRequest' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'certificationsAchieved' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'GeneralInfoUpdateRequest' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'motherlanguageId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'englishlanguageId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'languageId' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'PlatformLanguages' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_home' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_other_city' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_other_state' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_other_city_1' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_other_city_2' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_other_city_3' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_other_state_1' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_other_state_2' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_other_state_3' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_show_on_profile' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'show_merit_personality_on_profile',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'show_defect_personality_on_profile',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'opportunity_less_than_1_year',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'opportunity_more_than_1_year',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'opportunity_hiring' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'opportunity_freelance' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'workState' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'maxDistanceForDisponibilityWork',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'isTourDashboardViewed' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTourDashboardViewed',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: {
                                    kind: 'Name',
                                    value: 'authorization_advertising',
                                },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'authorization_marketing' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userType' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'name' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'name' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'surname' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'birth_date' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'city_residence' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'phone' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'phone' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_work_my_city' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'like_offers_x_km' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'onboarding_user_step' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'note' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'note' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'biografy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'website' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'website' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'socials' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'socials' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'photo' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'photo' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'screenshot' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'screenshot' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'curriculum' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'curriculum' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'curriculumVideo' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'skills' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'skills' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'personalities' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'personalities' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'qualifications' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'qualifications' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hobbies' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hobbies' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'spokenLanguages' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'certificatesAchieved' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'certificationsAchieved' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'motherlanguageId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'motherlanguageId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'englishlanguageId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'englishlanguageId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'languageId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadFileDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'uploadFile' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UploadFileInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'uploadFile' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileLabel' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UploadFileMutation, UploadFileMutationVariables>;
export const UserConfirmDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userConfirm' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'registrationCode' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userConfirm' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'registrationCode' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'registrationCode' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserConfirmMutation, UserConfirmMutationVariables>;
export const UserSignUpDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userSignUp' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'pwd' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyAssociation' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userSignUp' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'pwd' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'pwd' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyAssociation' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyAssociation' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserSignUpMutation, UserSignUpMutationVariables>;
export const UserSignUpAlreadyInAuthProviderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'userSignUpAlreadyInAuthProvider' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userSignUpAlreadyInAuthProvider' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'email' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'email' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserSignUpAlreadyInAuthProviderMutation,
    UserSignUpAlreadyInAuthProviderMutationVariables
>;
export const AddressListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'AddressList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchtext' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'country' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AddressList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchtext' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchtext' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'country' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'country' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'city' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'city' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AddressListQuery, AddressListQueryVariables>;
export const BusinessareaListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'BusinessareaList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'BusinessareaType' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'BusinessareaList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<BusinessareaListQuery, BusinessareaListQueryVariables>;
export const CityListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CityList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchtext' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'country' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CityList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchtext' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchtext' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'country' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'country' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CityListQuery, CityListQueryVariables>;
export const CompaniesForUserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompaniesForUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompaniesForUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vatNumber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'privacyUrl' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isApproved' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasOpenActive' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessArea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'services' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quarters' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'city' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'location' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'employees' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'user' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'userType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'birth_date',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city_residence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'biografy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'website',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'phone',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'screenshot',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'socials',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'educational',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'businessarea',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'personalities',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'section',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'experienceInYears',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'spokenLanguages',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'languageId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expertiseAreas' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statistics' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CompaniesForUserQuery, CompaniesForUserQueryVariables>;
export const CompanyApiKeyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompanyApiKey' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanyApiKey' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CompanyApiKeyQuery, CompanyApiKeyQueryVariables>;
export const CompanyByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompanyById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanyById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vatNumber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'privacyUrl' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isApproved' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasOpenActive' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessArea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'services' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quarters' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'city' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'location' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'employees' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'user' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'userType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'birth_date',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city_residence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'biografy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'website',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'phone',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'screenshot',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'socials',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'educational',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'businessarea',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'personalities',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'section',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'experienceInYears',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'spokenLanguages',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'languageId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expertiseAreas' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'statistics' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CompanyByIdQuery, CompanyByIdQueryVariables>;
export const CompanyPublicByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompanyPublicById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanyPublicById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vatNumber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isApproved' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessArea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'services' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quarters' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'city' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'location' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'employees' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'user' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'userType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'birth_date',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city_residence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'biografy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'website',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'phone',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'screenshot',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'socials',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'educational',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'businessarea',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'personalities',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'section',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'experienceInYears',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'spokenLanguages',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'languageId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expertiseAreas' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CompanyPublicByIdQuery, CompanyPublicByIdQueryVariables>;
export const CompanyRelationShareSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompanyRelationShareSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'CompanyRealtionShareSearchInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanyRelationShareSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'company' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'url',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'biografy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'country',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vatNumber',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isApproved',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'businessArea',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'coverImage',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'website',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'socials',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'services',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'quarters',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'city',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'location',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'contacts',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'employees',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'user',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'email',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'surname',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'userType',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'birth_date',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'city_residence',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'biografy',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'website',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'phone',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'languageId',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'role',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'state',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'expertiseAreas',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'creationDate',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dataVisibility',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CompanyRelationShareSearchQuery,
    CompanyRelationShareSearchQueryVariables
>;
export const CompanySearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompanySearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CompanySearchRequest' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanySearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'country' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'vatNumber' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isApproved' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessArea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'services' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'quarters' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'city' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'location' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'employees' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'user' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'userType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'birth_date',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city_residence',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'biografy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'website',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'phone',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'screenshot',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'socials',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'educational',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'businessarea',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'personalities',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'section',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'experienceInYears',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'spokenLanguages',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'languageId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'role' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expertiseAreas' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CompanySearchQuery, CompanySearchQueryVariables>;
export const CompanyTeamListByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompanyTeamListById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanyTeamListById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUpdate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'savedSearches' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'query' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'searchId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'groupId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'companyId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'hiringType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationCity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'visualization',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'row',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'workLoad',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'activity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'daily_fare',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'experience',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'responsability',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'results' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'talent',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'tags',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'isDefault',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'folders',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fullName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'rating',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'state',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'processNames',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'numberOfRatings',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'primaryQualification',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'lastUpdateDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'uploadFileDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'handledBy',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyImage',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'savedBy',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isPreferred',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'origin',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'correspondence',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'keywords',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'visibilityState',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'percentUsage',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CompanyTeamListByIdQuery, CompanyTeamListByIdQueryVariables>;
export const CompanyTeamListSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompanyTeamListSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SearchCompanyTeamListInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanyTeamListSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUpdate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'savedSearches' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'query' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'searchId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'groupId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'companyId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'hiringType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationCity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'visualization',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'row',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'workLoad',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'activity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'daily_fare',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'experience',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'responsability',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'results' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'talent',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'tags',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'isDefault',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'folders',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fullName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'rating',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'state',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'processNames',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'numberOfRatings',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'primaryQualification',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'lastUpdateDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'uploadFileDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'handledBy',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyImage',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'savedBy',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isPreferred',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'origin',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'correspondence',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'keywords',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'visibilityState',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'percentUsage',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CompanyTeamListSearchQuery,
    CompanyTeamListSearchQueryVariables
>;
export const CompanyUploadCvGroupByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompanyUploadCVGroupById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'CompanyUploadCVGroupByIdRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanyUploadCVGroupById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resume' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'actual' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uploads' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'processNames',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'processStatus',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uploadCount' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dashboardType' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CompanyUploadCvGroupByIdQuery,
    CompanyUploadCvGroupByIdQueryVariables
>;
export const CompanyUploadCvGroupsByCompanyIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'CompanyUploadCVGroupsByCompanyId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'CompanyUploadCVGroupsByCompanyIdRequest',
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanyUploadCVGroupsByCompanyId' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'resume' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'status',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'actual',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'uploads' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'url',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'creationDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processStatus',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uploadCount',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dashboardType',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CompanyUploadCvGroupsByCompanyIdQuery,
    CompanyUploadCvGroupsByCompanyIdQueryVariables
>;
export const EducationCourseByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationCourseById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationCourseById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EducationCourseByIdQuery, EducationCourseByIdQueryVariables>;
export const EducationCourseCalendarDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationCourseCalendar' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyOwnerId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'calendar_from' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'calendar_to' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationCourseCalendar' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyOwnerId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'calendar_from' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'calendar_from' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'calendar_to' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'calendar_to' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationCourseCalendarQuery,
    EducationCourseCalendarQueryVariables
>;
export const EducationCourseListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationCourseList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyOwnerId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationCourseList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyOwnerId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EducationCourseListQuery, EducationCourseListQueryVariables>;
export const EducationCourseListByOwnerDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationCourseListByOwner' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ownerId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationCourseListByOwner' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'ownerId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationCourseListByOwnerQuery,
    EducationCourseListByOwnerQueryVariables
>;
export const EducationCourseListByStudentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationCourseListByStudent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'studentId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'CourseStatus' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'subjectarea' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CourseType' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lessons_from' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lessons_to' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filter' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'availability' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CourseAvailability' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationCourseListByStudent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'studentId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'studentId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'status' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'subjectarea' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'lessons_from' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'lessons_from' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'lessons_to' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'lessons_to' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filter' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filter' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'availability' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationCourseListByStudentQuery,
    EducationCourseListByStudentQueryVariables
>;
export const EducationCourseSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationCourseSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'studentId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyOwnerId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'title' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'CourseStatus' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'subjectarea' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CourseType' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'availability' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CourseAvailability' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'timing' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CourseTiming' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lessons_from' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lessons_to' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hours_from' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hours_to' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationCourseSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'studentId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'studentId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyOwnerId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'title' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'status' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'subjectarea' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'availability' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'timing' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'lessons_from' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'lessons_from' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'lessons_to' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'lessons_to' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hours_from' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hours_from' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hours_to' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hours_to' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ownerId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_min' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_max' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students_show' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'timing' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'room' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'address' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'location' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'availability' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'subjectarea' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'info_more' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teachers' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'students' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'motivation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'studentId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educationLessons' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'courseTitle',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'info' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'date' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'from' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'to' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start_url',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'join_url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'teacher' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'teacherId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'courseId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'surname',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'email',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'photo',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lesson_url',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'videos_url' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slides' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationCourseSearchQuery,
    EducationCourseSearchQueryVariables
>;
export const EducationLessonDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationLesson' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationLesson' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseTitle' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'join_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacher' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lesson_url' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EducationLessonQuery, EducationLessonQueryVariables>;
export const EducationLessonCalendarByStudentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationLessonCalendarByStudent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'studentId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'calendar_from' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'calendar_to' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationLessonCalendarByStudent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'studentId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'studentId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'calendar_from' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'calendar_from' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'calendar_to' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'calendar_to' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseTitle' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'join_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacher' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lesson_url' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationLessonCalendarByStudentQuery,
    EducationLessonCalendarByStudentQueryVariables
>;
export const EducationLessonListByCourseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationLessonListByCourse' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'courseId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationLessonListByCourse' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'courseId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseTitle' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'info' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'from' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'to' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'join_url' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacher' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'teacherId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'courseId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lesson_url' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationLessonListByCourseQuery,
    EducationLessonListByCourseQueryVariables
>;
export const EducationSearchUserForCoursePartecipantsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationSearchUserForCoursePartecipants' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'EducationSearchUserInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'EducationSearchUserForCoursePartecipants',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'photo' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'company' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'biografy' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'cityResidence',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'socials' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'languages',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationSearchUserForCoursePartecipantsQuery,
    EducationSearchUserForCoursePartecipantsQueryVariables
>;
export const EducationStudentDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationStudent' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationStudent' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentEmail' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'motivation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cityResidence' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EducationStudentQuery, EducationStudentQueryVariables>;
export const EducationStudentListByCourseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationStudentListByCourse' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'courseId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchText' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationStudentListByCourse' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'courseId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchText' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchText' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'studentEmail' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'motivation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cityResidence' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'photo' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationStudentListByCourseQuery,
    EducationStudentListByCourseQueryVariables
>;
export const EducationTeacherDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationTeacher' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationTeacher' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EducationTeacherQuery, EducationTeacherQueryVariables>;
export const EducationTeacherAvailableDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationTeacherAvailable' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyOwnerId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationTeacherAvailable' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyOwnerId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyOwnerId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationTeacherAvailableQuery,
    EducationTeacherAvailableQueryVariables
>;
export const EducationTeacherListByCourseDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationTeacherListByCourse' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'courseId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationTeacherListByCourse' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'courseId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'courseId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teacherName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_perc' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'completed_lessons' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    EducationTeacherListByCourseQuery,
    EducationTeacherListByCourseQueryVariables
>;
export const EducationalListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'EducationalList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'EducationalList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EducationalListQuery, EducationalListQueryVariables>;
export const HiringInterviewByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'HiringInterviewById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HiringInterviewById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creation_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringprocessId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'reliability' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personality' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'potential' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'experience' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leadership' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<HiringInterviewByIdQuery, HiringInterviewByIdQueryVariables>;
export const HiringInterviewListByProcessDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'HiringInterviewListByProcess' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hiringprocessId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HiringInterviewListByProcess' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hiringprocessId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hiringprocessId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creation_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'valuation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringprocessId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'reliability' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personality' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'potential' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'experience' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'leadership' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    HiringInterviewListByProcessQuery,
    HiringInterviewListByProcessQueryVariables
>;
export const HiringProcessByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'HiringProcessById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HiringProcessById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creation_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'target_reference_id' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'target_reference_type',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumEmail' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<HiringProcessByIdQuery, HiringProcessByIdQueryVariables>;
export const HiringProposalByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'HiringProposalById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HiringProposalById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creation_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringinterviewId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'date1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'date2' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'date3' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time2' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time3' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accepted_date' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<HiringProposalByIdQuery, HiringProposalByIdQueryVariables>;
export const HiringProposalListByInterviewDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'HiringProposalListByInterview' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hiringinterviewId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HiringProposalListByInterview' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hiringinterviewId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hiringinterviewId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creation_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringinterviewId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'date1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'date2' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'date3' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time2' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time3' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accepted_date' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    HiringProposalListByInterviewQuery,
    HiringProposalListByInterviewQueryVariables
>;
export const HiringProposalSendProposalToUserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'HiringProposalSendProposalToUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hiringproposalId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'HiringProposalSendProposalToUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'hiringproposalId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'hiringproposalId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creation_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringinterviewId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'date1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'date2' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'date3' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time1' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time2' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'time3' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accepted_date' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    HiringProposalSendProposalToUserQuery,
    HiringProposalSendProposalToUserQueryVariables
>;
export const JobAnnouncementByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'JobAnnouncementById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'JobAnnouncementById' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'owner' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'biografy' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'country' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vatNumber',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isApproved',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'businessArea',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'coverImage',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileLabel',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'website' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'socials' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'services' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'quarters' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'city',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'location',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'contacts' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'employees',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'user',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'email',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'surname',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'userType',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'birth_date',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'city_residence',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'biografy',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'website',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'phone',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'photo',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileLabel',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'screenshot',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'fileLabel',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'skills',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'socials',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'educational',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'businessarea',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'type',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'personalities',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'section',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualifications',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'experienceInYears',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'spokenLanguages',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'value',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'languageId',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'role',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'expertiseAreas',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'title',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'privacyURL' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'previewImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'coverImage' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'duration' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hiringType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'contractType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'locationType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'expirationDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetCandidateRalMin',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetCandidateRalMax',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetExperienceYearsMin',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'targetExperienceYearsMax',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targetDisiredSkills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isChatActivate' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isJobInterviewRequired',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isAssessmentRequired' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualification' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'targetAvailability' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTargetAvailableForTransfers',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTargetAvailableForRoleChange',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isTargetAvailableForRelocation',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'candidaturesCount' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'referentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'referent' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surname' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<JobAnnouncementByIdQuery, JobAnnouncementByIdQueryVariables>;
export const LanguageListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'LanguageList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LanguageList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LanguageListQuery, LanguageListQueryVariables>;
export const NationListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'NationList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchtext' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'NationList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchtext' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchtext' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<NationListQuery, NationListQueryVariables>;
export const PasswordPolicyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PasswordPolicy' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PasswordPolicy' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'regex' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PasswordPolicyQuery, PasswordPolicyQueryVariables>;
export const PersonalityListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PersonalityList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'personalitySection' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PersonalityList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'personalitySection' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'personalitySection' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'section' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PersonalityListQuery, PersonalityListQueryVariables>;
export const PrivacyLastDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PrivacyLast' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PrivacyLast' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PrivacyLastQuery, PrivacyLastQueryVariables>;
export const PrivacyListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'PrivacyList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'PrivacyList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PrivacyListQuery, PrivacyListQueryVariables>;
export const ProposalsBySurveyQuestionIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ProposalsBySurveyQuestionId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'surveyQuestionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ProposalsBySurveyQuestionId' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'surveyQuestionId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'surveyQuestionId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'orderby' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preselectedminvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preselectedmaxvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minlabel' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxlabel' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currentUserAnswer' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ProposalsBySurveyQuestionIdQuery,
    ProposalsBySurveyQuestionIdQueryVariables
>;
export const QualificationAreaSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'QualificationAreaSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'QualificationAreaSearchInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'QualificationAreaSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    QualificationAreaSearchQuery,
    QualificationAreaSearchQueryVariables
>;
export const QualificationListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'QualificationList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'QualificationList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<QualificationListQuery, QualificationListQueryVariables>;
export const QualificationSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'QualificationSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'QualificationSearchInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'QualificationSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<QualificationSearchQuery, QualificationSearchQueryVariables>;
export const QualificationTypeListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'QualificationTypeList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'QualificationTypeList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    QualificationTypeListQuery,
    QualificationTypeListQueryVariables
>;
export const SearchJobAnnouncementDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SearchJobAnnouncement' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SearchJobAnnouncementRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SearchJobAnnouncement' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'jobAnnouncements' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'city' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'title' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'owner' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'url',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'biografy',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'country',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'vatNumber',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isApproved',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'businessArea',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'coverImage',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileLabel',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'website',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'socials',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'name',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'services',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'quarters',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'city',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'location',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'contacts',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'employees',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'user',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'email',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'surname',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'userType',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'birth_date',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'city_residence',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'biografy',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'website',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'phone',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'languageId',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'role',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'state',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'expertiseAreas',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'title',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'description',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'creationDate',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'privacyURL',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'previewImage',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileLabel',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'coverImage',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fileLabel',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'duration' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'contractType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'locationType',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'expirationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'targetCandidateRalMin',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'targetCandidateRalMax',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'targetExperienceYearsMin',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'targetExperienceYearsMax',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'targetDisiredSkills',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isChatActivate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isJobInterviewRequired',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isAssessmentRequired',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'targetAvailability',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isTargetAvailableForTransfers',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isTargetAvailableForRoleChange',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isTargetAvailableForRelocation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'candidaturesCount',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'referentId',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SearchJobAnnouncementQuery,
    SearchJobAnnouncementQueryVariables
>;
export const SkillListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SkillList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SkillList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SkillListQuery, SkillListQueryVariables>;
export const SkillSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SkillSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SkillSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'text' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'text' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SkillSearchQuery, SkillSearchQueryVariables>;
export const SkillSuggestionsForQualificationTypeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SkillSuggestionsForQualificationType' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'qualificationTypeId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'SkillSuggestionsForQualificationType',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'qualificationTypeId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'qualificationTypeId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SkillSuggestionsForQualificationTypeQuery,
    SkillSuggestionsForQualificationTypeQueryVariables
>;
export const SkillTypeListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SkillTypeList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SkillTypeList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SkillTypeListQuery, SkillTypeListQueryVariables>;
export const SkillsForQualificationDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SkillsForQualification' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'qualificationId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'count' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SkillsForQualification' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'qualificationId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'qualificationId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'count' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'count' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'type' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'custom' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SkillsForQualificationQuery,
    SkillsForQualificationQueryVariables
>;
export const SurveyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'Survey' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Survey' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'releaseId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'orderby' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'questions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surveyId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'orderby' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'sublabel' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'unitmis' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'proposals',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'orderby',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'label',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'minvalue',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'maxvalue',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'preselectedminvalue',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'preselectedmaxvalue',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'minlabel',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'maxlabel',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'currentUserAnswer',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SurveyQuery, SurveyQueryVariables>;
export const SurveyAllDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SurveyAll' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SurveyAll' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'releaseId' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'orderby' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'questions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'surveyId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'orderby' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'label' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'sublabel' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'code' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'unitmis' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'proposals',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'orderby',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'label',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'minvalue',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'maxvalue',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'preselectedminvalue',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'preselectedmaxvalue',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'minlabel',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'maxlabel',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'currentUserAnswer',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SurveyAllQuery, SurveyAllQueryVariables>;
export const SurveyUserAnswerByQuestionIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SurveyUserAnswerByQuestionId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'questionId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SurveyUserAnswerByQuestionId' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'questionId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'questionId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'orderby' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preselectedminvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preselectedmaxvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minlabel' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxlabel' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currentUserAnswer' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SurveyUserAnswerByQuestionIdQuery,
    SurveyUserAnswerByQuestionIdQueryVariables
>;
export const SurveyUserAnswerByUserIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'SurveyUserAnswerByUserId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'SurveyUserAnswerByUserId' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'orderby' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preselectedminvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preselectedmaxvalue' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'minlabel' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'maxlabel' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currentUserAnswer' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SurveyUserAnswerByUserIdQuery,
    SurveyUserAnswerByUserIdQueryVariables
>;
export const TermConditionByUserOrCompanyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'TermConditionByUserOrCompany' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'TermConditionType' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'TermConditionByUserOrCompany' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    TermConditionByUserOrCompanyQuery,
    TermConditionByUserOrCompanyQueryVariables
>;
export const TermConditionLastDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'TermConditionLast' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'TermConditionType' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'TermConditionLast' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TermConditionLastQuery, TermConditionLastQueryVariables>;
export const TermConditionListDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'TermConditionList' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'TermConditionType' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'TermConditionList' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TermConditionListQuery, TermConditionListQueryVariables>;
export const UserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'User' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'User' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const UserByTokenDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'UserByToken' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserByToken' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_home' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_other_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_city_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_1',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_2',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'like_work_other_state_3',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_show_on_profile' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_merit_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'show_defect_personality_on_profile',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'workState' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'maxDistanceForDisponibilityWork',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_less_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_more_than_1_year',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'opportunity_hiring' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'opportunity_freelance',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_advertising',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'authorization_marketing',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_work_my_city' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'like_offers_x_km' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'onboarding_user_step' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculum' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'curriculumVideo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hash_code' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'note' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'actionsToDo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'message' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'currentAppVisualization',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hobbies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificatesAchieved' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'certificationsAchieved',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserByTokenQuery, UserByTokenQueryVariables>;
export const UserPublicDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'UserPublic' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserPublic' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userType' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'birth_date' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'city_residence' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'biografy' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'website' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'photo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'screenshot' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'fileLabel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'socials' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'educational' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'businessarea' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalities' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'section' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'spokenLanguages' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'languageId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserPublicQuery, UserPublicQueryVariables>;
export const CanEditCompanyCvDataElaborationsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'canEditCompanyCvDataElaborations' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reference' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'CvReference' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'canEditCompanyCvDataElaborations' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'reference' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'reference' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CanEditCompanyCvDataElaborationsQuery,
    CanEditCompanyCvDataElaborationsQueryVariables
>;
export const CandidateByJobAnnouncementIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'candidateByJobAnnouncementId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobAnnouncementId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'length' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'offset' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'candidateByJobAnnouncementId' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'jobAnnouncementId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'jobAnnouncementId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'length' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'length' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'offset' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'offset' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rating' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'cvDocUrl' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'numberOfRatings',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'primaryQualification',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uploadFileDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'handledBy',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'companyName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'companyImage',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'savedBy' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isPreferred',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'processNames',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'processStatus',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'candidateState',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'candidateStateReason',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'correspondence',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'visibilityState',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'translations',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CandidateByJobAnnouncementIdQuery,
    CandidateByJobAnnouncementIdQueryVariables
>;
export const CityListForSearchDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'cityListForSearch' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'searchtext' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cityListForSearch' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'searchtext' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'searchtext' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CityListForSearchQuery, CityListForSearchQueryVariables>;
export const DatailForCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'datailForCompanyUploadCv' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datailForCompanyUploadCv' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'age' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'summary' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sources' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cvDocUrl' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'handledBy' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyImage',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'userPhone',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'userEmail',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cvVideoUrl' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isPreferred' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'processNames' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'processStatus' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUpdateDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scores' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'score' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topSkills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'score' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'primaryQualification' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rate' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resumeExperience' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'sector' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'turnover' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'seniority',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'secondaryQualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'rate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'rangeExperienceFromInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'rangeExperienceToInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyDimension',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companies',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'training' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'otherInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'addressLine1',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'addressLine2',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'city' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'postalCode',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'country' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'selfDescription',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'USCitizenship',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'citizenshipStatus',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'citizenshipStatusOther',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ageOver18',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'alreadyApplied',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'alreadyAppliedInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'otherPeopleWorkers',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'otherPeopleWorkersInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'haveTransportation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'submitDrugTest',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workInHighSchool',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workInHighSchoolInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isVolunteer',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isVolunteerInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hasPets' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hasPetsInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'petsCount',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'currentlyEmployed',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'currentlyEmployedYear',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'currentSalary',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'desiredSalary',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'availableWorkDaysHours',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringStartDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workOnWeekend',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workEvenings',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workOvertime',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox1Title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox1Value',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox2Title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox2Value',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox3Title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox3Value',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceIT',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceProjectManager',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceLeadership',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceCoreNetworkingTechnologies',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceLeargeScaleNetworkingSystems',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceManagingPartner',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceCustomerService',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceFirewall',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceVirtualization',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceOffice365',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceAzure',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militaryBranch',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militaryRank',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militaryYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militarySkills',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militaryDetails',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolAddressLine1',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolAddressLine2',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolState',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolPostalCode',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolCountry',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolGraduate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolDiploma',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolAddressLine1',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolAddressLine2',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolState',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolPostalCode',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolCountry',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolGraduate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolDiploma',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'certificationName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'certificationIssuingOrganization',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'certificationNumber',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'certificationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'additionalCertificationName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'additionalCertificationIssuingOrganization',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'additionalCertificationNumber',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'additionalCertificationDate',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DatailForCompanyUploadCvQuery,
    DatailForCompanyUploadCvQueryVariables
>;
export const DatailForCompanyUploadCv2Document = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'datailForCompanyUploadCv2' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'datailForCompanyUploadCv2' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'tags' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'parentId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isDefault',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'origin' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'scores' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'score' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'folders' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'parentId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sources' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fileName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'keywords' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'percentage',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'definition',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'definitions',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'translations',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'value',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'topKeywords' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lang' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'score' },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'handledBy' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyImage',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'userPhone',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'userEmail',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isPreferred' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'processNames' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'personalInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'age' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'phone' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'cvDocUrl' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'birthDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'cvVideoUrl',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'personalities',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'type',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'residenceCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workPreferences',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'home',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'myCity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'cities',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'countries',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isProtectedCategory',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'processStatus' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastUpdateDate' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'visibilityState' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resumeExperience' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'sectors' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'summaries',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'linkedIn' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'training' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'turnover' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'seniority',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'skills' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'percentage',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'definition',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'definitions',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'translations',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companies',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualifications',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'percentage',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'definition',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'definitions',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'translations',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyDimension',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'rangeExperienceToInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'rangeExperienceFromInYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'mainQualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'translations',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'ita',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'eng',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'score',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'softSkills',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lang',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'score',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hardSkills',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'lang',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'score',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'otherInfo' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'addressLine1',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'addressLine2',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'city' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'postalCode',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'country' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'selfDescription',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'USCitizenship',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'citizenshipStatus',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'citizenshipStatusOther',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ageOver18',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'alreadyApplied',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'alreadyAppliedInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'otherPeopleWorkers',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'otherPeopleWorkersInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'haveTransportation',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'submitDrugTest',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workInHighSchool',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workInHighSchoolInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isVolunteer',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isVolunteerInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'hasPets' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hasPetsInfo',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'petsCount',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'currentlyEmployed',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'currentlyEmployedYear',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'currentSalary',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'desiredSalary',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'availableWorkDaysHours',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hiringStartDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workOnWeekend',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workEvenings',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'workOvertime',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox1Title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox1Value',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox2Title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox2Value',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox3Title',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'checkbox3Value',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceIT',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceProjectManager',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceLeadership',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceCoreNetworkingTechnologies',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceLeargeScaleNetworkingSystems',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceManagingPartner',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceCustomerService',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceFirewall',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceVirtualization',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceOffice365',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'experienceAzure',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militaryBranch',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militaryRank',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militaryYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militarySkills',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'militaryDetails',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolAddressLine1',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolAddressLine2',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolState',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolPostalCode',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolCountry',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolGraduate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'vocationalSchoolDiploma',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolAddressLine1',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolAddressLine2',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolCity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolState',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolPostalCode',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolCountry',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolYears',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolGraduate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'universitySchoolDiploma',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'certificationName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'certificationIssuingOrganization',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'certificationNumber',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'certificationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'additionalCertificationName',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'additionalCertificationIssuingOrganization',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'additionalCertificationNumber',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'additionalCertificationDate',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'evaluations' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'languageId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'evaluation',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'text',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'scores',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'reliability',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'personality',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'potential',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'experience',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'leadership',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sectors' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'lang' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    DatailForCompanyUploadCv2Query,
    DatailForCompanyUploadCv2QueryVariables
>;
export const GetCompanyEmployeesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'getCompanyEmployees' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getCompanyEmployees' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'surname' },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetCompanyEmployeesQuery, GetCompanyEmployeesQueryVariables>;
export const IndexCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'indexCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'indexCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IndexCvQuery, IndexCvQueryVariables>;
export const InferenceMapForCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'inferenceMapForCv' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'CompanyUploadCVDataElaborationCategory',
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inferenceMapForCv' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'type' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'type' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isPrimary',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'sourceId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'targetId' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<InferenceMapForCvQuery, InferenceMapForCvQueryVariables>;
export const LogsForCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'logsForCompanyUploadCv' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'logsForCompanyUploadCv' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'action' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'newContent' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'oldContent' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userFullName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'creationDate' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    LogsForCompanyUploadCvQuery,
    LogsForCompanyUploadCvQueryVariables
>;
export const QdummyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'qdummy' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'qdummy' } }],
            },
        },
    ],
} as unknown as DocumentNode<QdummyQuery, QdummyQueryVariables>;
export const RatingsForCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ratingsForCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RatingsListInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratingsForCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorFullName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorImage' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ratings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RatingsForCvQuery, RatingsForCvQueryVariables>;
export const RatingsForUserDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ratingsForUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'RatingsListInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ratingsForUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorFullName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'authorImage' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ratings' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'value' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RatingsForUserQuery, RatingsForUserQueryVariables>;
export const SaveAndIndexCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'saveAndIndexCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fileUrl' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saveAndIndexCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'fileUrl' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'fileUrl' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SaveAndIndexCvQuery, SaveAndIndexCvQueryVariables>;
export const SavedTalentListByCompanyIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'savedTalentListByCompanyId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedTalentListByCompanyId' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'companyId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'companyId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'referenceId' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SavedTalentListByCompanyIdQuery,
    SavedTalentListByCompanyIdQueryVariables
>;
export const SearchCompanyCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'searchCompanyCv' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SearchCompanyCvInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchCompanyCv' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'tags' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'parentId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'isDefault',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'folders' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'parentId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'description',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'custom',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fullName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'rating' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'fileName' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'state' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'processNames',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'numberOfRatings',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'primaryQualification',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'qualification',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'translations',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'value',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lastUpdateDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uploadFileDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'handledBy',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'companyName',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'companyImage',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'savedBy' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isPreferred',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'correspondence',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'keywords' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'visibilityState',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'count' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'number' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'origin' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchCompanyCvQuery, SearchCompanyCvQueryVariables>;
export const SearchCompanyUploadCvDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'searchCompanyUploadCV' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SearchCompanyUploadCVRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchCompanyUploadCV' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'resume' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'status',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'actual',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'uploads' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'url',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'creationDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processStatus',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uploadCount',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dashboardType',
                                                },
                                            },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SearchCompanyUploadCvQuery,
    SearchCompanyUploadCvQueryVariables
>;
export const SearchFolderDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'searchFolder' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SearchFolderInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchFolder' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'qualifications' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skills' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'des' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'des',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'custom',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'custom' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchFolderQuery, SearchFolderQueryVariables>;
export const SearchKeywordDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'searchKeyword' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SearchKeywordRequest' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchKeyword' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchKeywordQuery, SearchKeywordQueryVariables>;
export const SearchLabelDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'searchLabel' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SearchLabelInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchLabel' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'parentId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'isDefault' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'description' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchLabelQuery, SearchLabelQueryVariables>;
export const SearchSavedSearchesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'searchSavedSearches' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'SearchSavedSearchesInput' },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'searchSavedSearches' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'query' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'searchId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'groupId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'companyId',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'keywords',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'hiringType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'origin',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationType',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'locationCity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualifications',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'fullName',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'visualization',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'row',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'skills',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'workLoad',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'activity',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'daily_fare',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'experience',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'min',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'max',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'qualification',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'des',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'des',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'custom',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'responsability',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'results' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'data',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'id',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'tags',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'isDefault',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'folders',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'parentId',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'description',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fullName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'rating',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'fileName',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'state',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'processNames',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'numberOfRatings',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'primaryQualification',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'qualification',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'name',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'lastUpdateDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'uploadFileDate',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'handledBy',
                                                                        },
                                                                        selectionSet: {
                                                                            kind: 'SelectionSet',
                                                                            selections: [
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'id',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyName',
                                                                                    },
                                                                                },
                                                                                {
                                                                                    kind: 'Field',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'companyImage',
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'savedBy',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'type',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'isPreferred',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'origin',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'correspondence',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'keywords',
                                                                        },
                                                                    },
                                                                    {
                                                                        kind: 'Field',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'visibilityState',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'state',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SearchSavedSearchesQuery, SearchSavedSearchesQueryVariables>;
export const SupportedLanguagesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'supportedLanguages' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language_id' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'supportedLanguages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'language_id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'language_id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'des' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SupportedLanguagesQuery, SupportedLanguagesQueryVariables>;
export const CompanyUploadCvChangeDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'CompanyUploadCvChange' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'CompanyUploadCvChange' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mutation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'status' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'resume' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'status',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'actual',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'uploads' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'url',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'name',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'creationDate',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processNames',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'processStatus',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'uploadCount',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'dashboardType',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CompanyUploadCvChangeSubscription,
    CompanyUploadCvChangeSubscriptionVariables
>;
export const UserNotificationsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'UserNotifications' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userId' },
                    },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserNotifications' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'userId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'userId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mutation' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'userId' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'type' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'priority' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'companyId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'creationDate',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'referenceId',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'referenceType',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserNotificationsSubscription,
    UserNotificationsSubscriptionVariables
>;
export const SdummyDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'sdummy' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'sdummy' } }],
            },
        },
    ],
} as unknown as DocumentNode<SdummySubscription, SdummySubscriptionVariables>;
