import {
    CompanyCountry,
    CompanyEmployeeRole,
    CurrentAppVisualizationType,
    OnBoardingUser,
    User,
    UserType,
} from '@/graphql/types/graphql';

import { Maybe } from 'graphql/jsutils/Maybe';
import _ from 'lodash';
import { defineStore } from 'pinia';
import { useDialogsStore } from './dialogs';

export interface UserState {
    user: Partial<Maybe<User>>;
    fetching: boolean;
    loadedCompanies: boolean;
    filteredCompany: string[];
    allUserCompanies: any[];
    companies: any[];
}

export const useUserStore = defineStore('user', {
    state: (): UserState => {
        return {
            user: undefined,
            fetching: false,
            loadedCompanies: false,
            filteredCompany: [],
            allUserCompanies: [],
            companies: [],
        };
    },
    getters: {
        isLoggedIn: (state) => !_.isUndefined(state.user),
        getUserId: (state) => state.user?.id as string,
        getName: (state) => (state.user?.name ? state.user?.name : ''),
        getSurname: (state) => (state.user?.surname ? state.user?.surname : ''),
        isAdmin: (state) => state.user?.email === 'davide@whp.ai',
        isOnboardingCompleted: (state) => {
            return state.user?.onboarding_user_step == OnBoardingUser.Completed;
        },
        isTalent: (state) => state.user?.userType == UserType.Talent,
        viewTypeUser: (state) => {
            if (state.user) {
                const isUser =
                    state.user?.currentAppVisualization?.type ==
                    CurrentAppVisualizationType.User;
                return isUser;
            } else {
                return null;
            }
        },

        currentCompany: (state) => {
            const company = _.find(state.companies, {
                companyId: state.user?.currentAppVisualization?.companyId,
            });
            let privacyUrl = '';
            if (company) {
                const findAllInfoCompany = state.allUserCompanies.find(
                    (c) => c.id === company.companyId
                );
                if (findAllInfoCompany) {
                    privacyUrl = findAllInfoCompany.privacyUrl;
                }
                return {
                    ...company,
                    privacyUrl,
                };
            } else return null;
        },
        isCurrentCumpanyEU: (state) => {
            const company = _.find(state.allUserCompanies, {
                id: state.user?.currentAppVisualization?.companyId,
            });
            return company && company.country == CompanyCountry.Eu;
        },
        isCurrentCumpanyUSA: (state) => {
            const company = _.find(state.allUserCompanies, {
                id: state.user?.currentAppVisualization?.companyId,
            });
            return company && company.country == CompanyCountry.Usa;
        },
        isAdminInCurrentCompany: (state) => {
            const company = _.find(state.companies, {
                companyId: state.user?.currentAppVisualization?.companyId,
            });
            return company && company.role === CompanyEmployeeRole.Admin;
        },
        isEmployeeInCurrentCompany: (state) => {
            const company = _.find(state.companies, {
                companyId: state.user?.currentAppVisualization?.companyId,
            });
            return company && company.role === CompanyEmployeeRole.Employee;
        },
        allCompanies: (state) => (state.companies ? state.companies : []) as any[],
        privacyAccepted: (state) => {
            const notAccepted = state.user?.actionsToDo?.find(
                (el) => el!.type === 'ACCEPT_PRIVACY'
            );
            return notAccepted ? false : true;
        },
        termsAccepted: (state) => {
            const notAccepted = state.user?.actionsToDo?.find(
                (el) => el!.type === 'ACCEPT_TERMCONDITION'
            );
            return notAccepted ? false : true;
        },
        secondTermsAccepted: (state) => {
            const notAccepted = state.user?.actionsToDo?.find(
                (el) => el!.type === 'ACCEPT_COMPANY_TERMCONDITION'
            );
            return notAccepted ? false : true;
        },
        emailVerified: (state) => {
            const emailNotVerified = state.user?.actionsToDo?.find(
                (el) => el!.type === 'CONFIRM_EMAIL'
            );
            return emailNotVerified ? false : true;
        },
    },
    actions: {
        filterCompanies(searchText: string) {
            this.filteredCompany = this.allCompanies
                .filter((el) => el.id !== this.currentCompany?.companyId)
                .map((company) => {
                    return company.companyName as string;
                })
                .filter((des) => {
                    return (
                        (des || '')
                            .toLowerCase()
                            .indexOf((searchText || '').toLowerCase()) > -1
                    );
                });
        },
        resetFilteredCompanies() {
            this.filteredCompany = this.allCompanies
                .filter((el) => el.id !== this.currentCompany?.companyId)
                .map((el) => el!.companyName) as string[];
        },
        setUser(user: Maybe<Partial<User>>) {
            this.user = user;
            const dialogsStore = useDialogsStore();
            if (this.emailVerified) {
                dialogsStore.terms = !this.termsAccepted && !user?.onboarding_user_step;
            }
        },
    },
});
